import React, { useState, useMemo } from "react";
import { LatLngTuple } from "leaflet";

import { Fleet } from "shared/types";
import { Map as CommonMap } from "../../common/Map";
import { useItemSelector } from "shared/hooks/useItemSelector";
import { PlanSegmentsPanel } from "./PlanSegmentsPanel";
import { StopMarkers } from "./StopMarkers";
import { VehicleMarker } from "../../common";
import { DirectionalPolyline } from "./DirectionalPolyline/DirectionalPolyline";
import { SegmentStatus } from "./PlanSegmentsPanel/SegmentRow";

const selectArrowSegmentSize = (zoomLevel: number) => (zoomLevel < 15 ? 0 : zoomLevel < 17 ? 13 : 9);
const createStopId = (segment: Fleet.Segment) =>
  `${segment.stop.city}${segment.stop.street}${segment.stop.location.lat}${segment.stop.location.lng}`;
const createRouteSegmentsType = (segment: Fleet.Segment[] | undefined) => {
  const routeSegmentsStatus: SegmentStatus[] = [];
  let pendingSegmentFound = false;
  if (segment && segment.length > 0) {
    segment.reduce((prev, curr) => {
      if (
        (prev.segmentStatus.code === Fleet.SegmentStatuses.Pending ||
          prev.segmentStatus.code === Fleet.SegmentStatuses.Confirmed) &&
        (curr.segmentStatus.code === Fleet.SegmentStatuses.Pending ||
          curr.segmentStatus.code === Fleet.SegmentStatuses.Confirmed)
      ) {
        routeSegmentsStatus.push(SegmentStatus.UPCOMING);
        pendingSegmentFound = true;
      }
      if (
        (prev.segmentStatus.code === Fleet.SegmentStatuses.Completed ||
          prev.segmentStatus.code === Fleet.SegmentStatuses.Cancelled) &&
        (curr.segmentStatus.code === Fleet.SegmentStatuses.Pending ||
          curr.segmentStatus.code === Fleet.SegmentStatuses.Confirmed)
      ) {
        if (!pendingSegmentFound) {
          routeSegmentsStatus.push(SegmentStatus.CURRENT);
        } else {
          routeSegmentsStatus.push(SegmentStatus.UPCOMING);
        }
      }
      if (
        (prev.segmentStatus.code === Fleet.SegmentStatuses.Completed ||
          prev.segmentStatus.code === Fleet.SegmentStatuses.Cancelled) &&
        curr.segmentStatus.code === Fleet.SegmentStatuses.Completed
      ) {
        routeSegmentsStatus.push(SegmentStatus.COMPLETED);
      }
      if (curr.segmentStatus.code === Fleet.SegmentStatuses.Cancelled) {
        routeSegmentsStatus.push(SegmentStatus.CANCELLED);
      }
      return curr;
    });
  }
  return routeSegmentsStatus;
};

interface Props {
  enableHighlights?: boolean;
  missionState?: Fleet.MissionState;
  vehiclePosition?: Fleet.VehiclePosition;
  children?: React.ReactChildren | React.ReactChild;
}

export function Map({
  missionState,
  vehiclePosition,
  children,
  enableHighlights = true,
}: Props): React.ReactElement {
  const [mapArrowSegmentSize, setMapArrowSegmentSize] = useState(0);
  const { isItemSelected, setItemSelected } = useItemSelector(enableHighlights, createStopId);
  const route = useMemo(
    () =>
      missionState?.route.map(
        (route) => route.routeSegment.map(({ lat, lng }) => [lat, lng]) as LatLngTuple[]
      ),
    [missionState]
  );
  const bounds = useMemo(
    () => (route && route.length > 0 ? route?.reduce((prev, curr) => prev?.concat(curr)) : undefined),
    [route]
  );
  const routeSegmentsStatus = useMemo(() => createRouteSegmentsType(missionState?.plan), [missionState]);
  const handleStopHighlight = (segment?: Fleet.Segment) => () => {
    setItemSelected(segment);
  };
  return (
    <CommonMap
      onViewportChanged={({ zoom }: { zoom?: number }) => {
        if (typeof zoom === "number") {
          setMapArrowSegmentSize(selectArrowSegmentSize(zoom));
        }
      }}
      height="calc(100vh - 135px)"
      bounds={bounds}
    >
      {children}
      {missionState && (
        <>
          <PlanSegmentsPanel
            plan={missionState.plan}
            handleSegmentHighlight={handleStopHighlight}
            isSegmentHighlighted={isItemSelected}
          />
          <StopMarkers
            plan={missionState.plan}
            handleStopHighlight={handleStopHighlight}
            isStopHighlighted={isItemSelected}
          />
        </>
      )}
      {vehiclePosition && <VehicleMarker {...vehiclePosition} />}
      <DirectionalPolyline
        positions={route}
        arrowSegmentSize={mapArrowSegmentSize}
        routeSegmentsStatus={routeSegmentsStatus}
      />
    </CommonMap>
  );
}
