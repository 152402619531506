import { useState } from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames";
import { useMap } from "react-leaflet";

import { Fleet } from "shared/types";
import { useAccordion } from "shared/hooks/useAccordion";
import styles from "./PlanSegmentsPanel.module.css";
import { locationToLatLng } from "shared/mappers";
import { SegmentRow, SegmentStatus } from "./SegmentRow";

interface Props {
  plan: Fleet.Segment[];
  isSegmentHighlighted: (segment: Fleet.Segment) => boolean;
  handleSegmentHighlight: (segment?: Fleet.Segment) => () => void;
}

export function PlanSegmentsPanel({ plan, isSegmentHighlighted, handleSegmentHighlight }: Props) {
  const map = useMap();
  const [isPanelExpanded, setPanelExpanded] = useState(true);
  const { isExpanded, toggleExpand: toggleVisibility } = useAccordion(
    plan,
    (plan) => plan.plannedServiceTime
  );
  const handleGoTo = (segment: Fleet.Segment) => () => map.panTo(locationToLatLng(segment.stop.location));
  const handleVisibility = (segment: Fleet.Segment) => () => {
    toggleVisibility(segment);
  };
  return (
    <div className="leaflet-top leaflet-right">
      <div className="leaflet-control leaflet-bar">
        <div className={styles.mainBox}>
          <h5 onClick={() => setPanelExpanded((prev) => !prev)}>
            Plan
            <i className={classNames("fas text-muted", isPanelExpanded ? "fa-caret-up" : "fa-caret-down")} />
          </h5>
          <Collapse in={isPanelExpanded}>
            <ul>
              {(() => {
                let currentFound = false;
                return plan.map((segment) => {
                  let status = SegmentStatus.UPCOMING;
                  switch (segment.segmentStatus.code) {
                    case Fleet.SegmentStatuses.Pending:
                    case Fleet.SegmentStatuses.Confirmed:
                      status = SegmentStatus.UPCOMING;
                      if (!currentFound) {
                        status = SegmentStatus.CURRENT;
                        currentFound = true;
                      }
                      break;
                    default:
                    case Fleet.SegmentStatuses.Cancelled:
                      status = SegmentStatus.CANCELLED;
                      break;
                    case Fleet.SegmentStatuses.Completed:
                      status = SegmentStatus.COMPLETED;
                      break;
                  }
                  return (
                    <li key={segment.plannedServiceTime}>
                      <SegmentRow
                        segment={segment}
                        status={status}
                        isExpanded={isExpanded(segment)}
                        isHighlighted={isSegmentHighlighted(segment)}
                        handleVisibility={handleVisibility}
                        handleGoTo={handleGoTo}
                        handleHighlight={handleSegmentHighlight}
                      />
                    </li>
                  );
                });
              })()}
            </ul>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
