import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { Common, Fleet } from "shared/types";
import { TimestampTransforms } from "shared/mappers";
import { FleetApiClient, SettingsApiClient } from "shared/api";
import { CancelModal } from "./CancelModal";
import { ControlledTable } from "../../common/Table/ControlledTable";
import { ActionsCell } from "./ActionsCell";
import { useTranslation } from "react-i18next";

interface Props {
  dataState: Common.Paginated<Fleet.MissionOverviewListItemDto>;
  isFetching: boolean;
  reloadMissions: (params?: Common.PaginationParams) => Promise<void>;
}

export function Table({ dataState, isFetching, reloadMissions }: Props): ReactElement {
  const { t } = useTranslation("missions") as Common.UseTranslationResult;
  const [defaultCancelMessage, setDefaultCancelMessage] = useState<string>("");
  const [cancelModalContent, setCancelModalContent] =
    useState<Fleet.MissionOverviewListItemDto & { message: string }>();
  const getMessage = useCallback(async () => {
    const result = await SettingsApiClient.getDefaultCancelPushMessage();
    setDefaultCancelMessage(result.data);
  }, []);

  useEffect(() => {
    void getMessage();
  }, [setDefaultCancelMessage, getMessage]);

  const openCancelMissionModal = useCallback(
    (missionOverviewItem: Fleet.MissionOverviewListItemDto) => () => {
      setCancelModalContent({ message: defaultCancelMessage, ...missionOverviewItem });
    },

    [setCancelModalContent, defaultCancelMessage]
  );
  const closeCancelMissionModal = useCallback(() => {
    setCancelModalContent(undefined);
  }, [setCancelModalContent]);

  const handleRemoveMission = useCallback(
    async (missionId: string) => {
      try {
        await FleetApiClient.missions.removeMission(missionId);
        await reloadMissions();
      } catch (err) {
        console.warn(err);
      }
    },
    [reloadMissions]
  );

  const handleFixMission = useCallback(
    async (missionId: string, method: string) => {
      try {
        await FleetApiClient.missions.fixMission(missionId, method);
        await reloadMissions();
      } catch (err) {
        console.warn(err);
      }
    },
    [reloadMissions]
  );

  const missionColumns = useMemo<Column<Fleet.MissionOverviewListItemDto>[]>(
    () => [
      {
        Header: t("table.header.startTime"),
        columns: [
          {
            id: "initialPlannedStartTime",
            Header: t("table.header.initialPlannedStartTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.initialPlannedStartTime),
            sortAccessor: "initialPlannedStartTime",
          },
          {
            id: "plannedStartTime",
            Header: t("table.header.plannedStartTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.plannedStartTime),
            sortAccessor: "plannedStartTime",
          },
          {
            id: "reportedStartTime",
            Header: t("table.header.reportedStartTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.reportedStartTime),
            sortAccessor: "reportedStartTime",
          },
        ],
      },
      {
        Header: t("table.header.endTime"),
        columns: [
          {
            id: "initialPlannedEndTime",
            Header: t("table.header.initialPlannedEndTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.initialPlannedEndTime),
            sortAccessor: "initialPlannedEndTime",
          },
          {
            id: "plannedEndTime",
            Header: t("table.header.plannedEndTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.plannedEndTime),
            sortAccessor: "plannedEndTime",
          },
          {
            id: "reportedEndTime",
            Header: t("table.header.reportedEndTime"),
            accessor: (original) => TimestampTransforms.toFullDate(original.reportedEndTime),
            sortAccessor: "reportedEndTime",
          },
        ],
      },
      {
        Header: t("table.header.vehicleName"),
        accessor: "vehicleName",
        sortAccessor: "vehicleName",
      },
      {
        Header: t("table.header.status"),
        accessor: "statusDescription",
        sortAccessor: "status",
      },
      {
        id: "actions",
        width: 161,
        Header: <div className="text-center">{t("table.header.actions")}</div>,
        Cell: function Cell({ row }: CellProps<Fleet.MissionOverviewListItemDto>) {
          return (
            <ActionsCell
              {...row.original}
              openCancelModal={openCancelMissionModal(row.original)}
              onRemoveMission={handleRemoveMission}
              onFixMission={handleFixMission}
            />
          );
        },
      },
    ],
    [handleFixMission, handleRemoveMission, openCancelMissionModal, t]
  );

  const cancelMission = useCallback(
    async (cancelMission: Fleet.CancelMission) => {
      try {
        await FleetApiClient.missions.cancelMissionByOperator(cancelMission);
        await reloadMissions();
        closeCancelMissionModal();
      } catch (err) {
        console.warn(err);
      }
    },
    [closeCancelMissionModal, reloadMissions]
  );

  return (
    <>
      <ControlledTable
        {...dataState}
        columns={missionColumns}
        fetchData={reloadMissions}
        isFetching={isFetching}
      />
      <CancelModal seedData={cancelModalContent} onClose={closeCancelMissionModal} onSave={cancelMission} />
    </>
  );
}
