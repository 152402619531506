export const vehicle_availability = {
  header: "Dostępność pojazdu",
  breadCrumbName: "Dostępności",
  filters: {
    id: "Id dostępności",
    validFrom: "Ważna od",
    validTo: "Ważna do",
  },
  patternDisplay: {
    duration: "Czas trwania",
  },
  table: {
    header: {
      name: "Nazwa",
      validFrom: "Ważna od",
      validTo: "Ważna do",
      timeZone: "Strefa czasowa",
      excludedDays: "Dni wykluczone",
      actions: "Akcje",
    },
  },
  create: "Stwórz nową",
  form: {
    createHeader: "Dodaj dostępność",
    editHeader: "Zmień dostępność",
    nameHint: "Nazwa dostępności",
    name: "Nazwa",
    validFrom: "Ważna od",
    validTo: "Ważna do",
    excludedDays: "Dni wykluczone",
    pattern: "Schematy",
    saveConfirm: "Czy na pewno chcesz zapisać zmiany?",
    datesInput: {
      header: "Data",
      noData: "Brak",
    },
    patternsInput: {
      patternHeader: "Schemat {{index}}",
      slotsHeader: "Przedziały czasowe",
      slotsStart: "Start",
      slotsEnd: "Koniec",
      slotsDuration: "Czas trwania",
      slotsNoData: "Brak",
      weekDaysHeader: "Obsługiwane dni tygodnia",
    },
    validation: {
      excludedDaysUnique: "Wszystkie daty muszą być unikatowe",
      slotsMinimum: "Należy wybrać przynajmniej jeden przedział",
      slotsNonOverlapping: "Przedziały nie mogą na siebie nachodzić",
      weekDaysMinimum: "Należy wybrać przynajmniej jeden dzień tygodnia",
      validDatesRequired: "Dostępność musi mieć określony przedział ważności",
      validNameRequired: "Należy wprowadzić nazwę dostępności",
      validFromBeforeValidTo: "Data początku nie może być późniejsza niż data końca",
    },
  },
};
