import { Address } from "./Common";

// Vehicle
interface VehicleProperties extends Record<string, unknown> {
  id: string;
  number: string;
  startPosition: Address;
  capacity: number;
}

export interface Vehicle extends VehicleProperties {
  errors: Partial<Record<keyof VehicleProperties, string>>;
}

// Passenger request
interface PassengerRequestProperties extends Record<string, unknown> {
  id: string;
  name: string;
  phone: string;
  from: Address;
  to: Address;
  departureLeftMargin: number;
  departureOptimalTime: string;
  departureRightMargin: number;
  arrivalLeftMargin: number;
  arrivalOptimalTime: string;
  arrivalRightMargin: number;
}

export interface PassengerRequest extends PassengerRequestProperties {
  errors: Partial<Record<keyof PassengerRequestProperties, string>>;
}

// Data Load
export interface LoadedData {
  passengerRequests: Record<string, PassengerRequest>;
  vehicles: Record<string, Vehicle>;
}

// Algorithm Options
export enum AlgorithmType {
  LOCAL_SEARCH = "local-search",
  GUIDED_LOCAL_SEARCH = "guided-local-search",
  ROUTE_MINIMIZATION = "route-minimization",
  ROUTE_MINIMIZATION_LOCAL_SEARCH = "route-minimization-local-search",
  GUIDED_ROUTE_MINIMIZATION_LOCAL_SEARCH = "guided-rm-local-search",
  MEMETIC = "memetic",
}

export interface AlgorithmOption {
  displayLabel: string;
  identifier: string;
}

interface AvailableAlgorithmOptions {
  initialSolutionGenerators: AlgorithmOption[];
  evaluators: AlgorithmOption[];
  penaltyEvaluators: AlgorithmOption[];
}

export interface AlgorithmConfiguration {
  initialSolutionGenerator: string;
  evaluators: { identifier: string; weight: number }[];
}

export interface PreloadedAlgorithmOptions {
  availableAlgorithmOptions: AvailableAlgorithmOptions;
  defaultConfiguration: AlgorithmConfiguration;
}

// Form
export interface FormValues {
  passengerRequests: Record<string, PassengerRequest>;
  vehicles: Record<string, Vehicle>;
  algorithmType: AlgorithmType;
  algorithmConfiguration: AlgorithmConfiguration;
}

// Result
export interface ResultVehiclePlanStop {
  stopId: string;
  timestamp: string;
  distance: number;
  pickupPassengerIds: string[];
  dropoffPassengerIds: string[];
}

interface ResultVehicle {
  id: string;
  name: string;
  distanceInMeters: number;
  plan: ResultVehiclePlanStop[];
  route: number[][];
}

interface ResultStop {
  id: string;
  name: string;
  latLng: number[];
  startingVehicleIds: string[];
  finishingVehicleIds: string[];
}

interface ResultPassenger {
  id: string;
  name: string;
  phone?: string;
}
export interface Result {
  fleetPlan?: {
    vehicleIds: string[];
    stopIds: string[];
    totalDistanceInMeters: number;
    vehicleSolutionUrl: string;
    passengerSolutionUrl: string;
    totalDurationInMinutes: number;
    drivingDurationInMinutes: number;
  };
  entities?: {
    vehicles: Record<string, ResultVehicle>;
    stops: Record<string, ResultStop>;
    passengers: Record<string, ResultPassenger>;
  };
}
