import { Role } from "shared/constants";

export enum Properties {
  CATEGORY_ITEMS = "categoryItems",
  DROPDOWN_ITEMS = "dropdownItems",
}

interface Dropdown {
  iconClass?: string;
  [Properties.DROPDOWN_ITEMS]: {
    [key: string]: Item;
  };
}

interface Category {
  [Properties.CATEGORY_ITEMS]: {
    [key: string]: Item | Dropdown;
  };
}

export interface Item {
  link: string;
  iconClass?: string;
  visibleToRoles?: Role[];
}

type TopLevelItem = Dropdown | Category | Item;

export interface Shape {
  [key: string]: TopLevelItem;
}

// Type guards
export function isDropdown(navigationItem: TopLevelItem): navigationItem is Dropdown {
  return (navigationItem as Dropdown)[Properties.DROPDOWN_ITEMS] !== undefined;
}

export function isCategory(navigationItem: TopLevelItem): navigationItem is Category {
  return (navigationItem as Category)[Properties.CATEGORY_ITEMS] !== undefined;
}
