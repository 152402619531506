import { ReactElement, useMemo } from "react";
import { CellProps, Column } from "react-table";
import { TimestampTransforms } from "shared/mappers";
import { Common, Fleet } from "shared/types";
import { ControlledTable } from "components/common/Table/ControlledTable";
import { useTranslation } from "react-i18next";
import { HourlyScale } from "./HourlyScale";
import { PatternHourlyDisplay } from "./PatternHourlyDisplay";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { UseTranslationResult } from "shared/types/Common";

interface Props {
  dataState: Common.Paginated<Fleet.VehicleAvailabilityListItem>;
  isFetching: boolean;
  reloadAvailabilites: (params: Common.PaginationParams) => Promise<void>;
  openEditForm: (id: string) => void;
  openNewForm: () => void;
  openDuplicateForm: (id: string) => void;
}

const hourlyScaleStep = 4;
export function Table({
  isFetching,
  reloadAvailabilites,
  dataState,
  openEditForm,
  openNewForm,
  openDuplicateForm,
}: Props): ReactElement {
  const { t } = useTranslation("vehicle/availability") as UseTranslationResult;
  const availabilityColumns = useMemo<Column<Fleet.VehicleAvailabilityListItem>[]>(
    () => [
      {
        Header: t("table.header.name"),
        accessor: (row) => row.name,
        sortAccessor: "name",
      },
      {
        Header: t("table.header.validFrom"),
        accessor: (row) => TimestampTransforms.toFullDate(row.validFrom),
        sortAccessor: "validFrom",
      },
      {
        Header: t("table.header.validTo"),
        accessor: (row) => TimestampTransforms.toFullDate(row.validTo),
        sortAccessor: "validTo",
      },
      {
        Header: t("table.header.timeZone"),
        accessor: "timeZoneId",
        sortAccessor: "timeZoneId",
      },
      {
        id: "excludedDays",
        Header: t("table.header.excludedDays"),
        accessor: (row): string =>
          row.excludedDays.length <= 0
            ? t("general:empty")
            : row.excludedDays.map(TimestampTransforms.toDate).join(", "),
        width: 200,
      },
      {
        id: "patterns",
        Header: <HourlyScale hourlyScaleStep={hourlyScaleStep} />,
        Cell: PatternHourlyDisplay,
        width: 780,
      },
      {
        id: "selection",
        Header: function Header() {
          return <div className="text-center">{t("table.header.actions")}</div>;
        },
        width: 90,
        Cell: function Cell(cellProps: CellProps<Fleet.VehicleAvailabilityListItem>) {
          return (
            <div className="dropdown-actions text-center">
              <DropdownButton
                id="actionsDropdown"
                size="sm"
                title={<i className="fas fa-ellipsis-h" />}
                variant="outline-primary"
              >
                <Dropdown.Item
                  as={Button}
                  onClick={() => {
                    openEditForm(cellProps.row.original.id);
                  }}
                  title={t("general:edit")}
                >
                  <i className="mr-1 fas fa-edit" /> {t("general:edit")}
                </Dropdown.Item>
                <Dropdown.Item
                  as={Button}
                  onClick={() => {
                    openDuplicateForm(cellProps.row.original.id);
                  }}
                  title={t("general:duplicate")}
                >
                  <i className="mr-1 fas fa-clipboard" /> {t("general:duplicate")}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        },
      },
    ],
    [openDuplicateForm, openEditForm, t]
  );

  return (
    <ControlledTable
      {...dataState}
      columns={availabilityColumns}
      fetchData={reloadAvailabilites}
      isFetching={isFetching}
      additionalButtons={
        <>
          <Button className="mr-1" size="sm" variant="success" onClick={openNewForm}>
            {t("create")} <i className="ml-1 fas fa-plus" />
          </Button>
        </>
      }
    />
  );
}
