import React, { ChangeEvent, FormEvent, useMemo } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import {
  createMachine,
  state,
  transition,
  reduce,
  invoke,
  immediate,
  action,
  guard,
  MachineState,
} from "robot3";
import { useMachine } from "react-robot";
import actionCreatorFactory from "typescript-fsa";
import { produce } from "immer";
import classNames from "classnames";
import { AxiosResponse } from "axios";

import { Planner } from "shared/types";
import { PlannerApiClient } from "shared/api";

const actionCreator = actionCreatorFactory();

interface FormValues {
  passengerRequests?: File;
  vehicles?: File;
}

interface Context {
  formValues: FormValues;
  errors: Partial<Record<keyof FormValues, string>>;
}

enum Ids {
  PASSENGERS = "passengersUploadInput",
  VEHICLES = "vehiclesUploadInput",
}

enum States {
  CLOSED = "closed",
  OPENED = "opened",
  VALIDATING = "validating",
  HAS_ERRORS = "has-errors",
  SUBMITTING = "submitting",
}

enum Events {
  OPEN_MODAL = "open-modal",
  CLOSE_MODAL = "close-modal",
  CHANGE_INPUT = "change-input",
  SUBMIT = "submit",
  DONE = "done",
  ERROR = "error",
}

const validate = (ctx: Context): Context => {
  const result: Context = { ...ctx, errors: {} };
  if (!ctx.formValues.passengerRequests) {
    result.errors.passengerRequests = "You must select a CSV source for passengers.";
  }
  if (!ctx.formValues.vehicles) {
    result.errors.vehicles = "You must select a CSV source for vehicles.";
  }
  return result;
};

const changeInputEvent = actionCreator<FormValues>(Events.CHANGE_INPUT);
const changeInputReducer = produce((ctx: Context, ev: ReturnType<typeof changeInputEvent>) => {
  ctx.formValues = { ...ctx.formValues, ...ev.payload };
});

function getMappedResults(ctx: Context) {
  const data = new FormData();
  data.append("passengerRequests", ctx.formValues.passengerRequests as File);
  data.append("vehicles", ctx.formValues.vehicles as File);
  return PlannerApiClient.postCsvToValidate(data);
}

interface Props {
  onChange: (data: Planner.LoadedData) => void;
}

const initialContext = (): Context => ({
  formValues: {},
  errors: {},
});

const machineFactory = (handleChange: Props["onChange"]) =>
  createMachine(
    {
      [States.CLOSED]: state(transition(Events.OPEN_MODAL, States.OPENED)),
      [States.OPENED]: state(
        transition(Events.CLOSE_MODAL, States.CLOSED),
        transition(Events.CHANGE_INPUT, States.OPENED, reduce(changeInputReducer)),
        transition(Events.SUBMIT, States.VALIDATING, reduce(validate))
      ),
      [States.VALIDATING]: state(
        immediate(
          States.SUBMITTING,
          guard((ctx: Context) => Object.keys(ctx.errors).length === 0)
        ),
        immediate(States.HAS_ERRORS)
      ),
      [States.HAS_ERRORS]: state(
        transition(Events.CLOSE_MODAL, States.CLOSED),
        transition(Events.CHANGE_INPUT, States.HAS_ERRORS, reduce(changeInputReducer), reduce(validate)),
        transition(Events.SUBMIT, States.VALIDATING, reduce(validate))
      ),
      [States.SUBMITTING]: invoke(
        getMappedResults,
        transition(
          Events.DONE,
          States.CLOSED,
          action((ctx, ev: { data: AxiosResponse<Planner.LoadedData> }) => {
            handleChange(ev.data.data);
          })
        ),
        transition(Events.ERROR, States.OPENED)
      ) as MachineState,
    },
    initialContext
  );

export function LoadDataModal({ onChange: handleChange }: Props): React.ReactElement {
  const machine = useMemo(() => machineFactory(handleChange), [handleChange]);
  const [current, send] = useMachine(machine);
  const {
    context: { errors, formValues },
  } = current;
  const isModalVisible = current.name !== States.CLOSED;
  const isSubmitting = current.name === States.SUBMITTING;
  const handleClose = () => {
    if (!isSubmitting) {
      send(Events.CLOSE_MODAL);
    }
  };
  const handleFieldChange = (fieldId: keyof FormValues) => (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.currentTarget?.files?.[0]) {
      send(changeInputEvent({ [fieldId]: ev.currentTarget.files[0] }));
    }
  };
  const passengersError = errors.passengerRequests;
  const vehiclesError = errors.vehicles;
  return (
    <>
      <Button variant="primary" data-cy="openLoadDataModalButton" onClick={() => send(Events.OPEN_MODAL)}>
        Load CSV Data
      </Button>
      <Modal show={isModalVisible} onHide={handleClose}>
        <Modal.Header closeButton={!isSubmitting}>
          <Modal.Title>Load data</Modal.Title>
        </Modal.Header>
        <Form
          noValidate
          onSubmit={(ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            send(Events.SUBMIT);
          }}
        >
          <Modal.Body>
            <Form.Group as={Form.Row} controlId={Ids.PASSENGERS}>
              <Form.Label>Passengers</Form.Label>
              <div className="custom-file">
                <input
                  onChange={handleFieldChange("passengerRequests")}
                  type="file"
                  className={classNames("custom-file-input", {
                    "is-invalid": !!passengersError,
                  })}
                  id={Ids.PASSENGERS}
                  disabled={isSubmitting}
                  accept=".csv"
                />
                <label className="custom-file-label" htmlFor="passengers">
                  {formValues.passengerRequests?.name ?? "Please select a file"}
                </label>
                {!!passengersError && (
                  <Form.Control.Feedback type="invalid">{passengersError}</Form.Control.Feedback>
                )}
              </div>
            </Form.Group>
            <Form.Group as={Form.Row} controlId={Ids.VEHICLES}>
              <Form.Label className="mt-1">Vehicles</Form.Label>
              <div className="custom-file">
                <input
                  onChange={handleFieldChange("vehicles")}
                  type="file"
                  className={classNames("custom-file-input", {
                    "is-invalid": !!vehiclesError,
                  })}
                  id={Ids.VEHICLES}
                  disabled={isSubmitting}
                  accept=".csv"
                />
                <label className="custom-file-label" htmlFor="vehicles">
                  {formValues.vehicles?.name ?? "Please select a file"}
                </label>
                {!!vehiclesError && (
                  <Form.Control.Feedback type="invalid">{vehiclesError}</Form.Control.Feedback>
                )}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={isSubmitting} data-cy="loadDataSubmitButton">
              {isSubmitting ? (
                <>
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                  Loading...
                </>
              ) : (
                "Load"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
