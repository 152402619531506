import { TFunction } from "react-i18next";
import { FleetApiClient } from "shared/api";
import { createMachine, assign } from "xstate";
import { Context, Events, InitializationData, VehicleSegmentsData } from "./types";

const REFRESH_INTERVAL_IN_SECONDS = 60;
const segmentRefreshingState = {
  invoke: {
    src: "reloadSegments",
    onDone: {
      target: "ready",
      actions: "assignFreshVehicleSegments",
    },
    onError: {
      target: "ready",
      actions: "logError",
    },
  },
};
export const stateMachineFactory = (t: TFunction<string[]>) =>
  createMachine(
    {
      predictableActionArguments: true,
      tsTypes: {} as import("./stateMachine.typegen").Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Events,
        services: {} as {
          initializeData: {
            data: InitializationData;
          };
          reloadSegments: { data: VehicleSegmentsData };
          autoRefresh: { data: void };
        },
      },
      initial: "initializing",
      context: {
        refreshInterval: REFRESH_INTERVAL_IN_SECONDS,
        previousVehicleSegments: [],
        selectedVehicleSegments: [],
      },
      states: {
        initializing: {
          invoke: {
            id: "initializeData",
            src: "initializeData",
            onDone: {
              target: "ready",
              actions: "assignInitialData",
            },
            onError: {
              target: "initializationFailed",
              actions: "assignError",
            },
          },
        },
        initializationFailed: {
          type: "final",
        },
        ready: {
          invoke: {
            src: "autoRefresh",
          },
          on: {
            REFRESH_SEGMENTS: {
              target: "autoRefreshing",
            },
            SELECT_VEHICLE: {
              actions: "assignSelectedVehicle",
              target: "changingVehicle",
            },
          },
        },
        changingVehicle: segmentRefreshingState,
        autoRefreshing: segmentRefreshingState,
      },
    },
    {
      actions: {
        assignInitialData: assign((ctx, ev) => ({ ...ev.data })),
        assignSelectedVehicle: assign({
          selectedVehicleId: (_context, event) => event.payload.vehicleId,
        }),
        assignFreshVehicleSegments: assign((ctx, ev) => ({ ...ev.data })),
        logError: (_context, event) => {
          console.error(event.data as Error);
        },
        assignError: assign({
          errorMessage: (ctx, ev) => (ev.data as Error).message,
        }),
      },
      services: {
        initializeData: async () => {
          const vehicles = (await FleetApiClient.vehicle.getVehicles()).data;
          if (vehicles.length <= 0) {
            throw new Error(t("driverRides:errors.noVehicles"));
          }
          const selectedVehicle = vehicles[0];
          const selectedVehicleId = selectedVehicle.id;
          const vehicleSegmentsData = (
            await FleetApiClient.vehicle.getNearestVehicleSegments(selectedVehicle.id)
          ).data;
          const now = new Date();
          return {
            vehicles,
            selectedVehicleId,
            previousVehicleSegments: vehicleSegmentsData,
            selectedVehicleSegments: vehicleSegmentsData,
            lastRefreshTimestamp: now,
          };
        },
        reloadSegments: async (ctx) => {
          if (!ctx.selectedVehicleId) {
            console.error("Can't reload segments with no selectedVehicle");
            throw new Error(t("driverRides:errors.refreshError"));
          }
          const selectedVehicleSegments = (
            await FleetApiClient.vehicle.getNearestVehicleSegments(ctx.selectedVehicleId)
          ).data;
          const now = new Date();
          return {
            previousVehicleSegments: ctx.selectedVehicleSegments,
            selectedVehicleSegments: selectedVehicleSegments,
            lastRefreshTimestamp: now,
          };
        },
        autoRefresh: (ctx) => (cb) => {
          const interval = setInterval(() => {
            cb("REFRESH_SEGMENTS");
          }, 1000 * ctx.refreshInterval);

          return () => {
            clearInterval(interval);
          };
        },
      },
    }
  );
