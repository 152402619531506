import { Button, Modal, ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { getStopDisplayName } from "shared/mappers";
import styles from "../Rides.module.css";

interface Props {
  ride?: Fleet.Ride;
  onHide: () => void;
}

export function SuccessModal({ ride, onHide }: Props) {
  const { t } = useTranslation("rides");
  return (
    <Modal show={!!ride} centered onHide={onHide} className={styles.successModal} backdrop="static">
      {ride && (
        <ModalBody className="text-center">
          <h3 data-cy="rideOkTitle" className="text-success mb-4">
            {t("newRide.rideFound")}
          </h3>
          <h4 data-cy="rideDepartureStop">
            {t("form.departure")} — <em>{getStopDisplayName(ride.departureStop)}</em> —{" "}
            {TimestampTransforms.toDate(ride.departureStartTime)}:
          </h4>
          <h5>
            <strong>
              {TimestampTransforms.toShortTime(ride.departureStartTime)} -{" "}
              {TimestampTransforms.toShortTime(ride.departureEndTime)}
            </strong>
          </h5>
          <h4 data-cy="rideArrivalStop">
            {t("form.arrival")} — <em>{getStopDisplayName(ride.arrivalStop)}</em> —{" "}
            {TimestampTransforms.toDate(ride.arrivalStartTime)}:
          </h4>
          <h5>
            <strong>
              {TimestampTransforms.toShortTime(ride.arrivalStartTime)} -{" "}
              {TimestampTransforms.toShortTime(ride.arrivalEndTime)}
            </strong>
          </h5>
          <div className="d-flex justify-content-center mt-4">
            <Button data-cy="rideFindOk" variant="success" onClick={onHide}>
              {t("general:ok")}
            </Button>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
}
