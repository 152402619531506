import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { Board, Vehicle, SegmentsPart } from "..";
import { VehicleMarker } from "../../../common";
import { Fleet } from "shared/types";
import { TimeSpanTransforms, TimestampTransforms } from "shared/mappers";
import { HudSegmentLocationMarker } from "./HudSegmentLocationMarker";
import { useTranslation } from "react-i18next";
import { FiltersState } from "pages/MissionDetails/MissionDetails";

interface Props {
  mission: Fleet.MissionDetailedOverviewItemDto;
  activeSegments: Fleet.Segment[];
  activeIndex: number;
  visibleSegments: Fleet.Segment[];
  visibleIndex: number;
  vehiclePosition?: Fleet.VehiclePosition;
  refresh: () => void;
  filtersState: FiltersState;
  setFiltersState: React.Dispatch<FiltersState>;
}

const SegmentDataCells: { [translationKey: string]: (s: Fleet.Segment) => string } = {
  busStopName: (s) => `${s.stop.name}${s.stop.identifier ? ` ${s.stop.identifier}` : ""}`,
  address: (s) => s.stop.street,
  initialPlannedServiceTime: (s) => TimestampTransforms.toFullDate(s.initialPlannedServiceTime),
  plannedServiceTime: (s) => TimestampTransforms.toFullDate(s.plannedServiceTime),
  reportedArrivalTime: (s) => TimestampTransforms.toFullDate(s.reportedArrivalTime),
  reportedDepartureTime: (s) => TimestampTransforms.toFullDate(s.reportedDepartureTime),
  delay: (s) => TimeSpanTransforms.removeMilliseconds(s.delay),
};

export function Hud({
  mission,
  activeSegments,
  activeIndex,
  visibleSegments,
  visibleIndex,
  vehiclePosition,
  refresh,
  filtersState,
  setFiltersState,
}: Props): ReactElement {
  const { t } = useTranslation(["missionDetails"]);
  return (
    <>
      <Board
        mission={mission}
        refresh={refresh}
        filtersState={filtersState}
        setFiltersState={setFiltersState}
      />
      <Vehicle vehicle={mission.vehicle} />
      <SegmentsPart segments={activeSegments} activeIndex={activeIndex} />

      {!!vehiclePosition && <VehicleMarker {...vehiclePosition} />}
      {visibleSegments.map((segment, index) => (
        <HudSegmentLocationMarker segment={segment} isActive={visibleIndex === index} key={segment.segmentId}>
          <div className="font-size-larger font-weight-bold">
            <Table striped bordered hover className="mb-0" size="sm">
              <tbody>
                {Object.entries(SegmentDataCells).map(([translationKey, valueGetter]) => (
                  <tr key={translationKey}>
                    <td>{t(translationKey)}</td>
                    <td>{valueGetter(segment)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </HudSegmentLocationMarker>
      ))}
    </>
  );
}
