export const reports = {
  header: "Raporty",
  dashboard: "Raport z realizacji przewozów w systemie DRT",
  from: "Od:",
  until: "Do:",
  export: "Pobierz",
  show: "Wyświetl",
  numberOfDaysToGroup: "Liczba dni do grupowania danych:",
  daysInactivityCount: "Liczba dni po upływie której uważamy użytkownika za nieaktywnego:",
  minimumConnectionsCount:
    "Minimalna liczba zrealizowanych tras prezentowana w tabeli najpopularniejszych połączeń:",
  charts: {
    stopsHeatmap: {
      title: "Najczęściej wybierane połączenia",
    },
    rideRequestTime: {
      title: "Wyprzedzenie zamówienia w stosunku do przejazdu [% przejazdów]",
    },
    timelinessOfRides: {
      title: "Procent realizacji usługi przed i po czasie per pasażer",
      seriesName: { departureTime: "Godzina odjazdu", arrivalTime: "Godzina przyjazdu" },
    },
    hoursRides: {
      title: "Liczba osób przewiezionych w danej godzinie",
      seriesName: { departureTime: "Godzina odjazdu" },
    },
    passengersCount: {
      title: "Liczba osób przewiezionych w danym okresie",
      seriesName: {
        passengersCount: "Liczba pasażerów",
        avgPassengersPerRequestCount: "Śr. liczba pasażerów/zamówienie",
      },
    },
    rideRequests: {
      title: "Zamówienia złożone w danym okresie",
      seriesName: {
        passengersRequestRejected: "Odrzucona propozycja",
        timeOutPropositionRides: "Timeout propozycji",
        cancelledRides: "Anulowany",
        deliveredPassengers: "Dostarczony",
        absentPassengers: "Nieobecny",
      },
    },
    drtUsers: {
      title: "Liczba użytkowników składających zamówienia [wszystkie statusy]",
      seriesName: {
        newUsers: "Nowi użytkownicy",
        lostUsers: "Utraceni użytkownicy",
        users: "Użytkownicy",
        activeUsers: "Aktywni użytkownicy",
      },
    },
  },
  fileName: "Dane za okres {{from}} - {{until}}.xlsx",
  validation: {
    required: "Data jest wymagana",
    from: "Data od powinna być wcześniejsza niż data do",
    until: "Data do powinna być późniejsza niż data od",
  },
};
