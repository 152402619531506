import React, { useState, useEffect } from "react";
import { Card, Collapse } from "react-bootstrap";
import classnames from "classnames";

import styles from "./CollapsibleCard.module.css";

interface Props {
  defaultExpanded?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  bodyClassName?: string;
}

export function CollapsibleCard({ headerContent, children, defaultExpanded, bodyClassName, ...rest }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded || true);
  const handleToggle = () => setIsExpanded(!isExpanded);
  useEffect(() => {
    if (typeof defaultExpanded !== "undefined" && defaultExpanded !== isExpanded) {
      setIsExpanded(defaultExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpanded]);

  return (
    <Card {...rest}>
      <Card.Header onClick={handleToggle} className={styles.header}>
        {headerContent}
        <i
          role="button"
          className={classnames("fas", {
            "fa-chevron-up": isExpanded,
            "fa-chevron-down": !isExpanded,
          })}
        />
      </Card.Header>
      <Collapse in={isExpanded}>
        <div>
          <Card.Body className={bodyClassName}>{children}</Card.Body>
        </div>
      </Collapse>
    </Card>
  );
}
