export const AppUrls = {
  Index: "/",
  Realms: "/realms",
  Unauthorized: "/unauthorized",
  Contact: "/contact",
  Stops: "/stops",
  Missions: "/missions",
  Vehicles: "/vehicles",
  VehicleLocations: "/vehicle-locations",
  Rides: "/rides",
  VehicleMission: (vehicleId: string) => `/vehicle/${vehicleId}/mission`,
  VehicleAvailability: (vehicleId: string) => `/vehicle/${vehicleId}/availability`,
  MissionDetails: (missionId: string) => `/mission-details/${missionId}`,
  NotFound: "/not-found",
  Login: "/login",
  LoginCallback: "/login-callback",
  SilentRefresh: "/silent-refresh",
  TimeControl: "/time-control",
  Configuration: "/configuration",
  ForbiddenTransitions: "/forbidden-transitions",
  DriverRides: "/driver-rides",
  Reports: "/reports",
};
