import { general } from "./general";
import { navigation } from "./navigation";
import { configuration } from "./pages/configuration";
import { driverRides } from "./pages/driverRides";
import { forbiddenTransitions } from "./pages/forbiddenTransitions";
import { missionDetails } from "./pages/missionDetails";
import { missions } from "./pages/missions";
import { notFound } from "./pages/notFound";
import { realms } from "./pages/realms";
import { rides } from "./pages/rides";
import { stop } from "./pages/stop";
import { timeControl } from "./pages/timeControl";
import { vehicle } from "./pages/vehicle";
import { vehicleLocations } from "./pages/vehicleLocations";
import { vehicle_availability } from "./pages/vehicle_availability";
import { reports } from "./pages/reports";

const pl = {
  general,
  rides,
  forbiddenTransitions,
  stop,
  vehicle,
  "vehicle-locations": vehicleLocations,
  "vehicle/availability": vehicle_availability,
  realms,
  missions,
  "time-control": timeControl,
  missionDetails,
  configuration,
  driverRides,
  navigation,
  "not-found": notFound,
  reports,
};

export default pl;
