import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "components";
import { useAuthService } from "shared/hooks/useAuthService";
import { useHistory } from "react-router-dom";
import { AppUrls } from "shared/constants";

export function LoginCallback() {
  const authService = useAuthService();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    async function redirectAfterSignIn() {
      await authService.signinRedirectCallback();
      history.push(localStorage.getItem("redirectUri") || AppUrls.Index);
    }
    void redirectAfterSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout.Centered>
      <h4>{t("redirecting")}...</h4>
    </Layout.Centered>
  );
}
