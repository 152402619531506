import { Settings, TimeZone } from "../types";
import { SettingsUrls } from "../constants";
import { ApiClient, notifyOnError } from "./base";
import { AxiosResponse } from "axios";

export class SettingsApiClient extends ApiClient {
  @notifyOnError
  public static async getSettings(): Promise<AxiosResponse<Settings.AppSettings>> {
    return await ApiClient.getWithToken<Settings.AppSettings>(SettingsUrls.Configuration);
  }

  @notifyOnError
  public static async getChangelog(): Promise<AxiosResponse<string>> {
    return await ApiClient.getWithToken<string>(SettingsUrls.Changelog);
  }

  @notifyOnError
  public static async getTimeZones(): Promise<AxiosResponse<TimeZone[]>> {
    return await ApiClient.getWithToken<TimeZone[]>(SettingsUrls.TimeZones);
  }

  @notifyOnError
  public static async getDefaultCancelPushMessage(): Promise<AxiosResponse<string>> {
    return await ApiClient.getWithToken<string>(SettingsUrls.DefaultCancelMessage);
  }

  @notifyOnError
  public static async getSystemTime(): Promise<AxiosResponse<string>> {
    return await ApiClient.getWithToken<string>(SettingsUrls.Time);
  }

  @notifyOnError
  public static async setSystemTime(time: Date): Promise<AxiosResponse<string>> {
    return await ApiClient.postWithToken<string>(SettingsUrls.Time, { time });
  }

  @notifyOnError
  public static async resetSystemTime(): Promise<AxiosResponse<unknown>> {
    return await ApiClient.postWithToken(SettingsUrls.TimeReset);
  }

  @notifyOnError
  public static async getAlgorithmConfig(): Promise<AxiosResponse<Settings.AlgorithmConfiguration>> {
    return await ApiClient.getWithToken<Settings.AlgorithmConfiguration>(SettingsUrls.AlgorithmConfiguration);
  }

  @notifyOnError
  public static async setAlgorithmConfig(
    config: Settings.AlgorithmConfiguration
  ): Promise<AxiosResponse<string>> {
    return await ApiClient.postWithToken<string>(SettingsUrls.AlgorithmConfiguration, config);
  }

  @notifyOnError
  public static async resetAlgorithmConfig(): Promise<AxiosResponse<unknown>> {
    return await ApiClient.postWithToken(SettingsUrls.AlgorithmConfigurationReset, null);
  }
}
