import * as Yup from "yup";
import { TFunction } from "i18next";

export const MAX_VEHICLE_CAPACITY = 100;
export const MIN_VEHICLE_CAPACITY = 1;

export const vehicleSchemaFactory = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(t("general:validation.required")),
    licensePlate: Yup.string().required(t("general:validation.required")),
    capacity: Yup.number()
      .required("general:validation.capacityRequired")
      .min(
        MIN_VEHICLE_CAPACITY,
        t("general:validation.capacityMinLength", { minLength: MIN_VEHICLE_CAPACITY })
      )
      .max(
        MAX_VEHICLE_CAPACITY,
        t("general:validation.capacityMaxLength", { maxLength: MAX_VEHICLE_CAPACITY })
      ),
    startPositionId: Yup.string().required(t("general:validation.required")),
    endPositionId: Yup.string().required(t("general:validation.required")),
  });
