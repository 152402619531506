import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsApiClient } from "shared/api";
import { Settings } from "shared/types";

import { Configuration, Layout } from "../components";

export function ConfigurationPage(): ReactElement {
  const { t } = useTranslation("configuration");
  const [algorithmConfig, setAlgorithmConfig] = useState<Settings.AlgorithmConfiguration>();
  const fetchAlgorithmConfig = useCallback(async () => {
    const config = (await SettingsApiClient.getAlgorithmConfig()).data;
    setAlgorithmConfig(config);
  }, []);
  const saveAlgorithmConfig = useCallback(
    async (values: Settings.AlgorithmConfiguration) => {
      await SettingsApiClient.setAlgorithmConfig(values);
      void fetchAlgorithmConfig();
    },
    [fetchAlgorithmConfig]
  );
  const resetAlgorithmConfig = useCallback(async () => {
    await SettingsApiClient.resetAlgorithmConfig();
    void fetchAlgorithmConfig();
  }, [fetchAlgorithmConfig]);
  useEffect(() => {
    void fetchAlgorithmConfig();
  }, [fetchAlgorithmConfig]);
  return (
    <Layout.Content simpleHeader={t("header")}>
      <Layout.CollapsibleCard headerContent={<h3 className="card-title">{t("algorithm.header")}</h3>}>
        {algorithmConfig && (
          <Configuration.Form
            initialValues={algorithmConfig}
            onConfigSave={saveAlgorithmConfig}
            resetToDefault={resetAlgorithmConfig}
          />
        )}
      </Layout.CollapsibleCard>
    </Layout.Content>
  );
}
