import React, { ReactElement } from "react";

import { Common } from "shared/types";
import { PaginationRow } from "./PaginationRow";
import { TopRow } from "./TopRow";
import { DataRow } from "./DataRow";

interface Props<TEntity extends Record<string, unknown>>
  extends Omit<Common.TableWithPagination<TEntity>, "gotoPage"> {
  gotoPage: (page: number) => void;
  topRowContent?: React.ReactNode;
  isLoading?: boolean;
  responsive?: boolean;
  dataLength?: number;
}

export function Table<TEntity extends Record<string, unknown>>(props: Props<TEntity>): ReactElement {
  const { dataLength, gotoPage, pageCount, data, setPageSize, topRowContent, state } = props;
  const { pageIndex, pageSize } = state;
  const gotoPageWrapper = (newPage: number) => {
    gotoPage(newPage - 1 || 0);
  };

  return (
    <div className="my-3">
      <TopRow currentPageSize={pageSize} onPageSizeChange={setPageSize} totalRows={dataLength ?? data.length}>
        {topRowContent}
      </TopRow>
      <DataRow {...props} />
      <PaginationRow
        onPageChange={gotoPageWrapper}
        numberOfPages={pageCount}
        currentPage={pageIndex + 1}
        totalRows={dataLength ?? data.length}
      />
    </div>
  );
}
