import * as Yup from "yup";
import { TFunction } from "i18next";

export const MAX_MARGIN_IN_MINUTES = 999;
export const MIN_MARGIN_IN_MINUTES = 0;

export const algorithmConfigurationSchemaFactory = (t: TFunction) => {
  const numericDictionary = Yup.object()
    .required(t("general:validation.required"))
    .test("onlyNumericValues", t("configuration:algorithm.form.validation.onlyNumericValues"), (value) =>
      Object.values<number>(value).every((v) => !isNaN(v))
    );
  const numericDictionaryInRange = numericDictionary.test(
    "cannotBeOutOfRange",
    t("configuration:algorithm.form.validation.cannotBeOutOfRange", {
      min: MIN_MARGIN_IN_MINUTES,
      max: MAX_MARGIN_IN_MINUTES,
    }),
    (value) =>
      Object.values<number>(value).every((v) => v >= MIN_MARGIN_IN_MINUTES && v <= MAX_MARGIN_IN_MINUTES)
  );

  return Yup.object().shape({
    evaluatorWeights: numericDictionary.test(
      "notMuchGreaterThan1",
      t("configuration:algorithm.form.validation.sumNotMuchGreaterThan1"),
      (value) => Object.values<number>(value).reduce<number>((prev, curr) => prev + curr, 0) <= 1.1
    ),
    marginsSpans: numericDictionaryInRange,
    promiseSpans: numericDictionaryInRange,
    dynamicPromiseCalculatorConfig: numericDictionaryInRange,
  });
};
