import { Fleet } from "shared/types";

export const missionDetails = {
  debugCancel: "Anulowane dla celów debugownia",
  startTime: "Czas rozpoczęcia",
  endTime: "Czas zakończenia",
  plannedTime: "Planowany",
  reportedTime: "Raportowany",
  initialPlannedTime: "Początkowy",
  start: "Rozpocznij",
  finish: "Zakończ",
  cancel: "Anuluj",
  busStopName: "Nazwa przystanku",
  address: "Adres",
  plannedServiceTime: "Planowany czas obsługi",
  initialPlannedServiceTime: "Początkowy czas obsługi",
  reportedDepartureTime: "Raportowany czas odjazdu",
  reportedArrivalTime: "Raportowany czas przyjazdu",
  reportedServiceTime: "Raportowany czas obsługi",
  filterCancelledSegments: "Filtruj anulowane segmenty",
  filterOnlyActiveSegments: "Tylko aktywne segmenty",
  filterOnlySegments: "Tylko segmenty",
  filterOnlyCancelledSegments: "Tylko anulowane segmenty",
  filterNoneSegments: "Brak",
  hideMe: "Pokaż mnie",
  unhideMe: "Schowaj mnie",
  delay: "Opóźnienie (gdz:min:sek)",
  missionStatuses: {
    [Fleet.MissionStatuses.Pending]: "Oczekuje na rozpoczęcie",
    [Fleet.MissionStatuses.Started]: "Rozpoczęta",
    [Fleet.MissionStatuses.Finished]: "Zakończona",
    [Fleet.MissionStatuses.Cancelled]: "Anulowana",
  },
};
