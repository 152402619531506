import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PaginationControls } from "./PaginationControls";
import styles from "../Table.module.css";

interface Props extends React.ComponentProps<typeof PaginationControls> {
  totalRows: number;
}

export function PaginationRow({
  numberOfPages,
  currentPage,
  onPageChange,
  totalRows,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const multiplePagesExist = numberOfPages > 1;
  return (
    <Container fluid className="mt-1">
      <Row
        className={classNames(styles.paginationRow, {
          "justify-content-end": !multiplePagesExist,
          "justify-content-between": multiplePagesExist,
        })}
      >
        {multiplePagesExist && (
          <>
            <Col xs={6} md={3} className={classNames("d-flex px-0 order-2 order-md-1", styles.jumpToPage)}>
              {t("table.page")}
              <Form.Control
                as="input"
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value) {
                    onPageChange(+e.target.value);
                  }
                }}
                min={1}
                max={numberOfPages}
                size="sm"
                value={currentPage.toString()}
              />
              {t("table.outOf", { numberOfPages: numberOfPages })}
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center order-1 order-md-2 mb-3 mb-lg-0">
              <PaginationControls
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            </Col>
          </>
        )}
        <Col xs={6} md={3} data-cy="numberOfRows" className="text-right order-last">
          {t("table.totalRows", { totalRows: totalRows })}
        </Col>
      </Row>
    </Container>
  );
}
