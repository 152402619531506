import { ChangeEvent, ReactElement, useMemo } from "react";
import { Form as BSForm, Button, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { Settings } from "shared/types";
import { useTranslation } from "react-i18next";

import { algorithmConfigurationSchemaFactory, MAX_MARGIN_IN_MINUTES } from "./schema";
import { EvaluatorsManualInput } from "components/common/Input/EvaluatorsManualInput";
import { TFunction } from "i18next";

const mapObjectToAlgorithmOptions = (t: TFunction, object: Record<string, number>) =>
  Object.keys(object).map((identifier) => ({
    displayLabel: t(`algorithm.form.${identifier}`),
    identifier,
  }));

type NumericAlgorithmFields = Exclude<
  keyof Settings.AlgorithmConfiguration,
  "evaluatorWeights" | "promiseCalculator"
>;

const numericAlgorithmFields: NumericAlgorithmFields[] = [
  "dynamicPromiseCalculatorConfig",
  "marginsSpans",
  "promiseSpans",
];

interface Props {
  initialValues: Settings.AlgorithmConfiguration;
  onConfigSave: (algorithmConfig: Settings.AlgorithmConfiguration) => Promise<void>;
  resetToDefault: () => void;
}

export function Form({ onConfigSave, initialValues, resetToDefault }: Props): ReactElement {
  const { t } = useTranslation("configuration");
  const configSchema = useMemo(() => algorithmConfigurationSchemaFactory(t), [t]);
  const handleResetToDefaults = () => {
    if (window.confirm(t("algorithm.resetConfirmation"))) {
      resetToDefault();
    }
  };
  const promiseCalculatorMap: Record<Settings.PromiseCalculatorType, string> = {
    [Settings.PromiseCalculatorType.STATIC]: t("algorithm.form.staticPromiseCalculator"),
    [Settings.PromiseCalculatorType.DYNAMIC]: t("algorithm.form.dynamicPromiseCalculator"),
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={configSchema}
      onSubmit={async (values) => {
        if (window.confirm(t("algorithm.saveConfirmation"))) {
          await onConfigSave(values);
        }
      }}
    >
      {({
        setFieldValue,
        handleSubmit,
        values,
        handleReset,
        errors,
        touched,
        initialValues,
        isSubmitting,
      }) => (
        <BSForm noValidate onSubmit={handleSubmit}>
          <Row>
            <BSForm.Group as={Col} lg="7" xl="3">
              <BSForm.Label className="d-flex justify-content-between mb-4">
                <div>{t("algorithm.form.promiseCalculator")}:</div>
              </BSForm.Label>
              <BSForm.Control
                as="select"
                value={values.promiseCalculator as Settings.PromiseCalculatorType}
                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("promiseCalculator", ev.target.value as Settings.PromiseCalculatorType)
                }
                className="mb-2"
              >
                {Object.values(Settings.PromiseCalculatorType).map((type: Settings.PromiseCalculatorType) => (
                  <option key={type} value={type}>
                    {promiseCalculatorMap[type]}
                  </option>
                ))}
              </BSForm.Control>
            </BSForm.Group>
          </Row>
          <hr></hr>
          <Row>
            <BSForm.Group as={Col} lg="7" xl="3">
              <BSForm.Label className="d-flex justify-content-between mb-4">
                <div>{t("algorithm.form.evaluators")}:</div>
              </BSForm.Label>
              <EvaluatorsManualInput
                id="evaluatorsInput"
                totalMax={1}
                step={0.05}
                decimalPlacesShown={2}
                options={mapObjectToAlgorithmOptions(t, initialValues.evaluatorWeights)}
                currentValues={values.evaluatorWeights}
                labelColumnSize={9}
                onChange={(values) => {
                  setFieldValue("evaluatorWeights", values);
                }}
                disabled={isSubmitting}
                error={
                  touched.evaluatorWeights && errors.evaluatorWeights
                    ? errors.evaluatorWeights.toString()
                    : undefined
                }
              />
            </BSForm.Group>
            {numericAlgorithmFields.map((identifier) => (
              <BSForm.Group as={Col} lg="7" xl="3" key={identifier}>
                <BSForm.Label className="d-flex justify-content-between mb-4">
                  <div>{t(`algorithm.form.${identifier}`)}:</div>
                </BSForm.Label>
                <EvaluatorsManualInput
                  id={identifier}
                  totalMax={MAX_MARGIN_IN_MINUTES}
                  step={1}
                  decimalPlacesShown={0}
                  options={mapObjectToAlgorithmOptions(t, initialValues[identifier])}
                  currentValues={values[identifier]}
                  labelColumnSize={9}
                  onChange={(values) => {
                    setFieldValue(identifier, values);
                  }}
                  disabled={isSubmitting}
                  error={touched[identifier] && errors[identifier] && errors[identifier]?.toString()}
                />
              </BSForm.Group>
            ))}
          </Row>
          <Row>
            <Col lg="12" xl="7" className="d-flex justify-content-between">
              <Button disabled={isSubmitting} variant="warning" onClick={handleResetToDefaults}>
                {t("algorithm.resetLabel")}
              </Button>
              <div>
                <Button disabled={isSubmitting} variant="secondary" onClick={handleReset}>
                  {t("algorithm.resetFormLabel")}
                </Button>
                <Button className="ml-1" disabled={isSubmitting} type="submit" variant="primary">
                  {t("general:save")}
                </Button>
              </div>
            </Col>
          </Row>
        </BSForm>
      )}
    </Formik>
  );
}
