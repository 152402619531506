import { ReactElement, useState, useEffect } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import { v4 as uuid } from "uuid";
import { LatLngBounds } from "leaflet";

import { Planner, Common } from "shared/types";
import { AddressSearchInput } from "../AddressSearchInput";
import { vehicleValidation, MAX_VEHICLE_CAPACITY, MIN_VEHICLE_CAPACITY } from "./validation";
import { Map } from "../../../common";
import { LocationMarker } from "../LocationMarker";
import styles from "./Form.module.css";

interface Props {
  onClose: () => void;
  saveVehicle: (newVehicleRequestValue: Planner.Vehicle) => void;
  vehicle: Planner.Vehicle;
}

const mapHeight = 300;
const defaultLocation = { latitude: 0, longitude: 0, name: "" };

export function AddVehicleForm({ onClose, vehicle, saveVehicle }: Props): ReactElement {
  const [valueInMemoryStartPosition, setValueInMemoryStartPosition] = useState<{
    previousValue: string;
    currentValue: string;
  }>({ previousValue: "", currentValue: "" });
  const [locationStartPosition, setLocationStartPosition] = useState<Common.Address>();

  useEffect(() => {
    setLocationStartPosition(defaultLocation);
  }, [vehicle]);

  const key = uuid();
  const getMapProps = (address?: Common.Address) => {
    if (address && address.latitude !== 0 && address.longitude !== 0) {
      return {
        key: Object.values(address).join(),
        bounds: new LatLngBounds([[address.latitude, address.longitude]]),
        children: <LocationMarker {...address} color="green" />,
      };
    }
    return {
      key,
    };
  };

  return (
    <Modal show size="lg" onHide={onClose} aria-labelledby="example-modal-sizes-title-sm">
      <Formik
        initialValues={vehicle}
        validate={vehicleValidation}
        onSubmit={(values) => {
          saveVehicle(values);
          onClose();
        }}
      >
        {({ setFieldValue, handleSubmit, values, handleChange, errors, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">Add vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} md="8">
                  <Form.Label>Vehicle number:</Form.Label>
                  <Form.Control
                    key={vehicle.number}
                    type="text"
                    name="number"
                    value={values.number}
                    placeholder="Enter vehicle number"
                    onChange={handleChange}
                    isInvalid={!!errors.number && touched.number}
                  />
                  <Form.Control.Feedback type="invalid">{errors.number}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Capacity:</Form.Label>
                  <Form.Control
                    type="number"
                    min={MIN_VEHICLE_CAPACITY}
                    max={MAX_VEHICLE_CAPACITY}
                    name="capacity"
                    value={values.capacity.toString()}
                    onChange={handleChange}
                    isInvalid={!!errors.capacity && touched.capacity}
                  />
                  <Form.Control.Feedback type="invalid">{errors.capacity}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Map height={mapHeight} {...getMapProps(locationStartPosition)} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Start address:</Form.Label>
                  <div className="align-middle float-right">
                    Coordinates:{" "}
                    <strong id="address-value">
                      (lat:{values.startPosition.latitude}, lng:
                      {values.startPosition.longitude})
                    </strong>
                  </div>
                  <div className={styles.info}>
                    {valueInMemoryStartPosition.previousValue
                      ? `Previous value: ${valueInMemoryStartPosition.previousValue}`
                      : null}
                  </div>
                  <AddressSearchInput
                    currentValue={values.startPosition.name}
                    onChangeAddress={(newAddress) => {
                      setFieldValue("startPosition", newAddress);
                    }}
                    addValueToMemory={(newValue) => {
                      setValueInMemoryStartPosition({
                        previousValue: valueInMemoryStartPosition.currentValue,
                        currentValue: newValue,
                      });
                    }}
                    getLocation={setLocationStartPosition}
                    isInvalid={
                      !!(errors.startPosition && errors.startPosition.name) &&
                      !!(touched.startPosition && touched.startPosition.name)
                    }
                  />
                  <div className={styles.invalid}>
                    {!!(errors.startPosition && errors.startPosition.name) &&
                    !!(touched.startPosition && touched.startPosition.name)
                      ? errors.startPosition && errors.startPosition.name
                      : null}
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="2"></Form.Group>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save changes
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
