import React, { ReactElement } from "react";
import { ProgressBar } from "react-bootstrap";

interface Props {
  durationInSeconds: number;
}

interface State {
  currentSecond: number;
}

export class SimulatedProgressBar extends React.Component<Props, State> {
  public readonly state = {
    currentSecond: 0,
  };
  private timer?: number = undefined;

  public componentDidMount = (): void => {
    this.timer = window.setInterval(this.tick, 1000);
  };

  public componentWillUnmount = (): void => {
    clearInterval(this.timer);
  };

  public render = (): ReactElement | null => {
    if (this.props.durationInSeconds <= 0) {
      return null;
    }
    const currentProgress = (this.state.currentSecond * 100) / this.props.durationInSeconds;
    return (
      <ProgressBar
        animated
        variant="info"
        min={0}
        max={100}
        now={currentProgress}
        className="mt-2"
        label={`${Math.round(currentProgress)}%`}
      />
    );
  };

  private tick = (): void => {
    const nextSecond = this.state.currentSecond + 1;
    if (nextSecond >= this.props.durationInSeconds) {
      clearInterval(this.timer);
    }
    this.setState({ currentSecond: nextSecond });
  };
}
