import React, { ForwardedRef, forwardRef, ReactElement, useEffect, useState } from "react";
import styles from "./NavbarRight.module.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAuthService } from "shared/hooks/useAuthService";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { UserRealm, RealmType } from "shared/authService";
import { useDispatch } from "react-redux";
import { realmChosen } from "shared/state/actions";
import { useHistory } from "react-router-dom";
import { useMountedRef } from "shared/hooks/useMountedRef";

interface CustomToggleProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function RealmToggleButtonRender(
  { children, onClick }: CustomToggleProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      data-cy="changeRealmLink"
      variant="link"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      className={classNames("nav-link text-uppercase", styles.chooseRealmsLink)}
    >
      {children}
    </Button>
  );
}

function UserToggleButtonRender(
  { children, onClick }: CustomToggleProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      ref={ref}
      variant="link"
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      className={classNames("nav-link", styles.logoutButton)}
    >
      {children}
    </Button>
  );
}

const RealmToggleButton = forwardRef<HTMLButtonElement>(RealmToggleButtonRender);
const UserToggleButton = forwardRef<HTMLButtonElement>(UserToggleButtonRender);

export function NavbarRight(): ReactElement {
  const [realms, setRealms] = useState<UserRealm[]>([]);
  const [chosenRealmName, setChosenRealmName] = useState<string>();
  const authService = useAuthService();
  const { t } = useTranslation(["general"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const componentIsMounted = useMountedRef();
  const handleLogout = () => {
    authService.logout();
  };

  const selectRealm = (realmId: string) => {
    const foundRealm = realms.find((r) => r.id === realmId);
    foundRealm && dispatch(realmChosen(foundRealm));
    setChosenRealmName(authService.getChosenRealmName());
    history.replace("/");
  };

  useEffect(() => {
    async function initialLoad() {
      const data = await authService.getRealms();
      if (componentIsMounted.current) {
        setRealms(data);
        setChosenRealmName(authService.getChosenRealmName());
      }
    }
    void initialLoad();
  }, [authService, componentIsMounted]);

  return (
    <ul className="navbar-nav">
      <li className="nav-item d-inline-block">
        <Dropdown>
          <Dropdown.Toggle as={RealmToggleButton}>
            <i className="fas fa-map mr-sm-2 fa-lg" />
            <span className="d-none d-sm-inline">{chosenRealmName}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {realms.map((realm, index) => (
              <Dropdown.Item
                onClick={() => {
                  selectRealm(realm.id);
                }}
                key={index}
              >
                <Row>
                  <Col>{realm.name}</Col>
                  {realm.type === RealmType.Private && (
                    <Col className="text-right">
                      <i className="fas fa-eye-slash"></i>
                    </Col>
                  )}
                </Row>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li className="nav-item d-inline-block">
        <Dropdown>
          <Dropdown.Toggle as={UserToggleButton}>
            <i className="fas fa-user mr-sm-2 fa-lg" />
            <span className="d-none d-sm-inline">{authService.getUserProfile()?.username}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>{t("logout")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </ul>
  );
}
