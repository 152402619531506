import { useMemo } from "react";
import { ReactElement } from "react-markdown";
import { useTranslation } from "react-i18next";
import { CellProps, usePagination, useTable } from "react-table";
import { Button, Card, Dropdown, DropdownButton } from "react-bootstrap";
import { TFunction } from "i18next";

import { Fleet, Common } from "../../../shared/types";
import { RoleRestriction, Table as InnerTable } from "../../common";
import { PaginatedTableState } from "../../../shared/types/Common";
import { Role } from "../../../shared/constants/roles";
import { ForbiddenTransition } from "shared/types/Fleet";

function defaultSortFactory<T extends Fleet.ForbiddenTransition>(idGetter: (entity: T) => string) {
  return (a: T, b: T): number => ("" + idGetter(a)).localeCompare("" + idGetter(b));
}

const createSimpleColumns = (t: TFunction) => [
  { Header: t("table.header.sourceStopId"), accessor: "sourceStopId" },
  { Header: t("table.header.sourceStopName"), accessor: "sourceStopName" },
  { Header: t("table.header.destinationStopId"), accessor: "destinationStopId" },
  { Header: t("table.header.destinationStopName"), accessor: "destinationStopName" },
];

interface Props {
  forbiddenTransitions: Fleet.ForbiddenTransition[];
  isLoading: boolean;
  onRemoveForbiddenTransition: (toRemove: ForbiddenTransition) => void;
  onAddForbiddenTransition: () => void;
  onDuplicateForbiddenTransition: (toDuplicate: ForbiddenTransition) => void;
  isForbiddenTransitionUnique: (forbiddenTransition: ForbiddenTransition) => boolean;
}

export function Table({
  forbiddenTransitions,
  isLoading,
  onRemoveForbiddenTransition,
  onAddForbiddenTransition,
  onDuplicateForbiddenTransition,
  isForbiddenTransitionUnique,
}: Props): ReactElement {
  const { t } = useTranslation("forbiddenTransitions");
  const sortedForbiddenTransitions = useMemo(
    () => forbiddenTransitions.sort(defaultSortFactory((x: Fleet.ForbiddenTransition) => x.sourceStopName)),
    [forbiddenTransitions]
  );
  const forbiddenTransitionsColumns = useMemo(
    () => [
      ...createSimpleColumns(t),
      {
        id: "selection",
        Header: function Header() {
          return <div className="text-center">{t("table.header.actions")}</div>;
        },
        Cell: function Cell({ row }: CellProps<Fleet.ForbiddenTransition>) {
          return (
            <div className="dropdown-actions text-center">
              <DropdownButton
                id="actionsDropdown"
                size="sm"
                title={<i className="fas fa-ellipsis-h" />}
                variant="outline-primary"
              >
                <RoleRestriction requiredRoles={[Role.ADMIN]}>
                  <>
                    {isForbiddenTransitionUnique({
                      ...row.original,
                      sourceStopId: row.original.destinationStopId,
                      destinationStopId: row.original.sourceStopId,
                    }) ? (
                      <Dropdown.Item
                        as={Button}
                        onClick={() => {
                          onDuplicateForbiddenTransition(row.original);
                        }}
                      >
                        <i className="mr-1 fas fa-clone" /> {t("table.actions.duplicate")}
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item
                      as={Button}
                      onClick={() => {
                        if (
                          window.confirm(
                            t("table.actions.deleteConfirm", {
                              sourceName: row.original.sourceStopName,
                              destinationName: row.original.destinationStopName,
                            })
                          )
                        ) {
                          onRemoveForbiddenTransition(row.original);
                        }
                      }}
                    >
                      <i className="mr-1 fas fa-trash" /> {t("table.actions.delete")}
                    </Dropdown.Item>
                  </>
                </RoleRestriction>
              </DropdownButton>
            </div>
          );
        },
      },
    ],
    [isForbiddenTransitionUnique, onRemoveForbiddenTransition, onDuplicateForbiddenTransition, t]
  );

  const table = useTable(
    {
      columns: forbiddenTransitionsColumns,
      data: sortedForbiddenTransitions,
      initialState: { pageIndex: 0, pageSize: 10 } as PaginatedTableState<Fleet.ForbiddenTransition>,
    },
    usePagination
  ) as Common.TableWithPagination<Fleet.ForbiddenTransition>;

  return (
    <Card data-cy="">
      <InnerTable
        {...table}
        isLoading={isLoading}
        topRowContent={
          <RoleRestriction requiredRoles={[Role.ADMIN]}>
            <Button size="sm" variant="success" onClick={onAddForbiddenTransition}>
              <i className="fas fa-plus mr-1"></i> {t("add")}
            </Button>
          </RoleRestriction>
        }
      />
    </Card>
  );
}
