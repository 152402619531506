import React from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  error?: Error;
}

export class ErrorBoundary extends React.Component<Props, State> {
  readonly state: State = {};

  componentDidCatch(error: Error): void {
    this.setState({
      error,
    });
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return <h1>Component failure.</h1>;
    }

    return this.props.children;
  }
}
