import { Action } from "redux";
import { isType } from "typescript-fsa";

import { postProcessingRequest, setProcessingFilters } from "../actions/actionCreators";
import { Planner } from "../../types";
import processingDevState from "../devState/planner";

interface PlannerState extends Planner.Result {
  isFetching: boolean;
  filters: {
    vehicleId?: string;
    stopId?: string;
  };
}

let initialState: PlannerState = {
  isFetching: false,
  filters: {},
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  initialState = processingDevState;
}

export default function planner(state: PlannerState = initialState, action: Action): PlannerState {
  if (isType(action, postProcessingRequest.started)) {
    return { ...state, entities: undefined, fleetPlan: undefined, isFetching: true };
  }

  if (isType(action, postProcessingRequest.done)) {
    return {
      ...state,
      ...action.payload.result.data,
      isFetching: false,
      filters: {},
    };
  }

  if (isType(action, postProcessingRequest.failed)) {
    return { ...state, isFetching: false };
  }

  if (isType(action, setProcessingFilters)) {
    return { ...state, filters: { ...state.filters, ...action.payload } };
  }

  return state;
}
