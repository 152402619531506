import * as Yup from "yup";
import { TFunction } from "i18next";

export const MIN_PUSH_MESSAGE_LENGTH = 1;
export const MAX_PUSH_MESSAGE_LENGTH = 500;

export const cancelMissionSchemaFactory = (t: TFunction) =>
  Yup.object({
    message: Yup.string()
      .required(t("missions:form.validation.messageRequired"))
      .min(MIN_PUSH_MESSAGE_LENGTH)
      .max(MAX_PUSH_MESSAGE_LENGTH),
  });
