import React, { useMemo } from "react";
import { DefaultDateTimePicker } from "components/common/Input";
import { FieldArray, FieldArrayRenderProps, Formik } from "formik";
import { Modal, Form as BSForm, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TimeSpanTransforms, TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { DatesInput } from "./DatesInput";
import { PatternsInput } from "./PatternsInput";
import { FormValues, vehicleAvailabilitySchemaFactory } from "./schema";

interface Props {
  isEdit: boolean;
  isShown: boolean;
  initialValues?: Partial<Fleet.VehicleAvailabilityListItem>;
  onSubmit: (availability: Fleet.VehicleAvailabilityActionDto) => Promise<void>;
  onHide: () => void;
}

function mapAvailabilityToFormValues(
  availability: Partial<Fleet.VehicleAvailabilityListItem>
): Partial<FormValues> {
  return {
    ...availability,
    validFrom: availability.validFrom ? TimestampTransforms.parseISODate(availability.validFrom) : undefined,
    validTo: availability.validTo ? TimestampTransforms.parseISODate(availability.validTo) : undefined,
    name: availability.name,
    pattern: availability.pattern?.map((pattern) => ({
      slots: pattern.slots.map((s) => ({
        duration: TimeSpanTransforms.parseToDate(s.duration),
        timeStart: TimeSpanTransforms.parseToDate(s.timeStart),
      })),
      weekDays: pattern.weekDays,
    })),
    excludedDays: availability.excludedDays?.map((eD) => TimestampTransforms.parseISODate(eD)),
  };
}

function mapFormValuesToAvailability(formValues: FormValues): Fleet.VehicleAvailabilityActionDto {
  return {
    ...formValues,
    pattern:
      formValues.pattern?.map((p) => ({
        ...p,
        slots: p.slots.map((s) => ({
          timeStart: TimeSpanTransforms.stringify(s.timeStart),
          duration: TimeSpanTransforms.stringify(s.duration),
        })),
      })) || [],
  };
}

export function Form({ isShown, isEdit, initialValues, onSubmit, onHide }: Props) {
  const { t } = useTranslation(["vehicle/availability"]);
  const vehicleAvailabilitySchema = useMemo(() => vehicleAvailabilitySchemaFactory(t), [t]);
  return (
    <Modal show={isShown} onHide={onHide} size="lg">
      {initialValues && (
        <Formik
          initialValues={mapAvailabilityToFormValues(initialValues)}
          validationSchema={vehicleAvailabilitySchema}
          onSubmit={async (values) => {
            if (window.confirm(t("form.saveConfirm"))) {
              await onSubmit(mapFormValuesToAvailability(values as FormValues));
            }
          }}
        >
          {({ setFieldValue, handleSubmit, values, errors, isSubmitting, touched }) => (
            <BSForm noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{isEdit ? t("form.editHeader") : t("form.createHeader")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BSForm.Row>
                  <BSForm.Group as={Col} md="12">
                    <BSForm.Label>{t("form.name")}</BSForm.Label>
                    <BSForm.Control
                      isInvalid={touched.name && !!errors.name}
                      className="form-control form-control-sm"
                      name="name"
                      value={values.name ?? ""}
                      placeholder={t("form.nameHint")}
                      onChange={(text) => {
                        setFieldValue("name", text.target.value);
                      }}
                    />
                    <BSForm.Control.Feedback
                      className={classNames({ "d-block": touched.name && !!errors.name })}
                      type="invalid"
                    >
                      {errors.name}
                    </BSForm.Control.Feedback>
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row>
                  <BSForm.Group as={Col} md="3">
                    <BSForm.Label>{t("form.validFrom")}</BSForm.Label>
                    <DefaultDateTimePicker
                      isInvalid={touched.validFrom && !!errors.validFrom}
                      className="form-control form-control-sm"
                      selected={values.validFrom}
                      onChange={(date) => {
                        if (!Array.isArray(date)) {
                          setFieldValue("validFrom", date);
                        }
                      }}
                      useLocalDateTime
                    />
                    <BSForm.Control.Feedback
                      className={classNames({ "d-block": touched.validFrom && !!errors.validFrom })}
                      type="invalid"
                    >
                      {errors.validFrom}
                    </BSForm.Control.Feedback>
                  </BSForm.Group>
                  <BSForm.Group as={Col} md="4">
                    <BSForm.Label>{t("form.validTo")}</BSForm.Label>
                    <DefaultDateTimePicker
                      isInvalid={touched.validTo && !!errors.validTo}
                      className="form-control form-control-sm"
                      selected={values.validTo}
                      onChange={(date) => {
                        if (!Array.isArray(date)) {
                          setFieldValue("validTo", date);
                        }
                      }}
                      useLocalDateTime
                    />
                    <BSForm.Control.Feedback
                      type="invalid"
                      className={classNames({ "d-block": touched.validTo && !!errors.validTo })}
                    >
                      {errors.validTo}
                    </BSForm.Control.Feedback>
                  </BSForm.Group>
                  <BSForm.Group as={Col} md="5">
                    <BSForm.Label>{t("form.excludedDays")}</BSForm.Label>
                    <FieldArray
                      name="excludedDays"
                      component={DatesInput as React.ComponentType<void | FieldArrayRenderProps>}
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row>
                  <BSForm.Group as={Col} md="12">
                    <BSForm.Label>{t("form.pattern")}</BSForm.Label>
                    <FieldArray
                      name="pattern"
                      component={PatternsInput as React.ComponentType<void | FieldArrayRenderProps>}
                    />
                  </BSForm.Group>
                </BSForm.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={isSubmitting} variant="secondary" onClick={onHide}>
                  {t("general:cancel")}
                </Button>
                <Button disabled={isSubmitting} type="submit" variant="primary">
                  {t("general:save")}
                </Button>
              </Modal.Footer>
            </BSForm>
          )}
        </Formik>
      )}
    </Modal>
  );
}
