import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import pl from "date-fns/locale/pl";
import { LatLngTuple, LatLngLiteral } from "leaflet";

import { Fleet, Common } from "./types";
import {
  FullDateTimeFormat,
  ShortTimeFormat,
  InputDateFormat,
  FullTimeFormat,
  FullDateTimeFormatWithSeconds,
  DateFormat,
  APIDateFormat,
  ShortDateFormat,
} from "./constants/dateFormats";
import { addHours, addMilliseconds, addSeconds, addMinutes } from "date-fns";

export const minutesToFormattedHours = (minutes: number): string => {
  if (minutes >= 60) {
    const hoursRemainder = minutes % 60;
    return `${(minutes - hoursRemainder) / 60}h ${hoursRemainder}min`;
  }

  return `${minutes}min`;
};
export const metersToKm = (distanceInMetres: number): string => (distanceInMetres / 1000).toFixed(1);
export const locationToLatLngTuple = (location: Common.Location): LatLngTuple => [location.lat, location.lng];
export const locationToLatLng = (location: Common.Location): LatLngLiteral => ({
  lat: location.lat,
  lng: location.lng,
});
export class TimestampTransforms {
  public static toCustomFormat = (isoString: string, dateFormat: string): string =>
    isoString ? format(parseISO(isoString), dateFormat) : "-";
  public static toFullDate = (isoString: string): string =>
    TimestampTransforms.toCustomFormat(isoString, FullDateTimeFormat);
  public static toShortDate = (isoString: string): string =>
    TimestampTransforms.toCustomFormat(isoString, ShortDateFormat);
  public static toShortTime = (isoString: string): string =>
    TimestampTransforms.toCustomFormat(isoString, ShortTimeFormat);
  public static toDate = (isoString: string): string =>
    TimestampTransforms.toCustomFormat(isoString, DateFormat);
  public static parseISODate = (isoString: string): Date => parseISO(isoString);
}
export class DateTransforms {
  public static toCustomFormat = (date: Date, dateFormat: string): string =>
    format(date, dateFormat, {
      locale: pl,
    });
  public static toInputFormat = (date: Date): string => DateTransforms.toCustomFormat(date, InputDateFormat);
  public static toAPIDateFormat = (date: Date): string => DateTransforms.toCustomFormat(date, APIDateFormat);
  public static toShortTimeFormat = (date: Date): string =>
    DateTransforms.toCustomFormat(date, ShortTimeFormat);
  public static toFullTimeFormat = (date: Date): string =>
    DateTransforms.toCustomFormat(date, FullTimeFormat);
  public static toFullDateTimeFormat = (date: Date): string =>
    DateTransforms.toCustomFormat(date, FullDateTimeFormat);
  public static toFullDateTimeFormatWithSeconds = (date: Date): string =>
    DateTransforms.toCustomFormat(date, FullDateTimeFormatWithSeconds);
  public static localDateToUtcDate = (date: Date): Date => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  };
  public static utcDateToLocalDate = (date: Date): Date => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  };
  public static addTimePortion = (date: Date, timeToAdd: Date): Date => {
    const withMillis = addMilliseconds(date, timeToAdd.getMilliseconds());
    const withSeconds = addSeconds(withMillis, timeToAdd.getSeconds());
    const withMinutes = addMinutes(withSeconds, timeToAdd.getMinutes());
    return addHours(withMinutes, timeToAdd.getHours());
  };
}

export class TimeTransforms {
  public static secondsToMilliseconds = (seconds: number): number => seconds * 1000;
  public static minutesToMilliseconds = (minutes: number): number =>
    TimeTransforms.secondsToMilliseconds(minutes * 60);
  public static hoursToMilliseconds = (hours: number): number =>
    TimeTransforms.minutesToMilliseconds(hours * 60);
  public static getDayPercentageFromMilliseconds = (milliseconds: number): number =>
    (milliseconds * 100) / TimeTransforms.hoursToMilliseconds(24);
}

export class TimeSpanTransforms {
  private static validateTimeSpan = (timeSpan: string) => {
    if (!TimeSpanTransforms.timeSpanFormat.test(timeSpan)) {
      throw new TypeError(`Wrong TimeSpan format: ${timeSpan}`);
    }
  };
  private static timeSpanFormat = new RegExp(
    /^((((?:-?0*\d+\.)?(?:0*)(?:2[0-3]|1[0-9]|[0-9]))(?::0*([0-5]?[0-9]))?(?::0*((?:[0-5]?[0-9])(?:\.\d{0,7})?)))|(\d+))?$/
  );
  private static toZeroBasedString = (value: number) => value.toString().padStart(2, "0");
  public static splitToTimeValues = (timeSpan: string) => {
    TimeSpanTransforms.validateTimeSpan(timeSpan);
    const timeSplit = timeSpan.split(":");
    const secondsMillisecondsSplit = timeSplit[2]?.split(".");
    const milliseconds = secondsMillisecondsSplit[1]
      ? parseFloat(`0.${secondsMillisecondsSplit[1]}`) * 1000
      : 0;
    const seconds = secondsMillisecondsSplit[0] ? parseInt(secondsMillisecondsSplit[0]) : 0;
    const minutes = timeSplit[1] ? parseInt(timeSplit[1]) : 0;
    const daysHoursSplit = timeSplit[0]?.split(".") ?? ["0"];
    let hours = parseInt(daysHoursSplit[0]);
    if (daysHoursSplit.length > 1) {
      hours = parseInt(daysHoursSplit[1]) + parseInt(daysHoursSplit[0]) * 24;
    }
    return {
      hours,
      minutes,
      seconds,
      milliseconds,
    };
  };
  public static parseToDate = (timeSpan: string): Date => {
    const { hours, minutes, seconds, milliseconds } = TimeSpanTransforms.splitToTimeValues(timeSpan);
    return new Date(0, 0, 0, hours, minutes, seconds, milliseconds);
  };
  public static extractTotalMilliseconds = (timeSpan: string): number => {
    const { hours, minutes, seconds, milliseconds } = TimeSpanTransforms.splitToTimeValues(timeSpan);
    return (
      milliseconds +
      TimeTransforms.secondsToMilliseconds(seconds) +
      TimeTransforms.minutesToMilliseconds(minutes) +
      TimeTransforms.hoursToMilliseconds(hours)
    );
  };
  public static stringify = (timeSpan: Date): string => {
    const hoursString = TimeSpanTransforms.toZeroBasedString(timeSpan.getHours());
    const minutesString = TimeSpanTransforms.toZeroBasedString(timeSpan.getMinutes());
    const secondsString = TimeSpanTransforms.toZeroBasedString(timeSpan.getSeconds());
    let result = `${hoursString}:${minutesString}:${secondsString}`;
    if (timeSpan.getMilliseconds() > 0) {
      result += `.${timeSpan.getMilliseconds()}`;
    }
    return result;
  };
  public static removeMilliseconds = (timeSpan: string): string => {
    TimeSpanTransforms.validateTimeSpan(timeSpan);
    const timeSplit = timeSpan.split(":");
    return [timeSplit[0], timeSplit[1], timeSplit[2].split(".")[0]].join(":");
  };
}

export const getStopDisplayName = (stop: Fleet.Stop) =>
  `${stop.name}${stop.identifier ? ` ${stop.identifier}` : ""}`;
