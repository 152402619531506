import { ReactElement } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SidebarMenu } from "../SidebarMenu";
import { siteNavigation } from "siteNavigation";
import styles from "./Sidebar.module.css";

export function Sidebar(): ReactElement {
  const { t } = useTranslation();
  return (
    <aside className={classNames("main-sidebar sidebar-dark-info elevation-4", styles.wrapper)}>
      <Link to="/" className="brand-link navbar-dark">
        <div className={classNames("brand-image", styles.brandLogo)}>
          <i className="fas fa-bus" />
        </div>
        <span className="brand-text font-weight-light">{t("appName")}</span>
      </Link>
      <div className="sidebar">
        <div style={{ height: "100 %", width: "100%" }}>
          <SidebarMenu siteNavigation={siteNavigation} />
        </div>
        {/* Left here to know where it was if we ever fix it
          <SidebarVersionDisplay /> 
        */}
      </div>
    </aside>
  );
}
