import { TFunction } from "react-i18next";
import { i18n } from "i18next";
import {
  Column,
  TableInstance,
  TableState,
  UsePaginationInstanceProps,
  UsePaginationState,
} from "react-table";

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Pick<Partial<T>, K>;

export type TableWithPagination<T extends Record<string, unknown>> = TableInstance<T> &
  UsePaginationInstanceProps<T> & {
    state: TableState<T> & UsePaginationState<T>;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
export type PaginatedTableState<TEntity extends object> = TableState<TEntity> & UsePaginationState<TEntity>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type SortableColumn<TEntity extends object> = Column<TEntity> & {
  sortAccessor?: string;
};

export interface Address {
  name: string;
  latitude: number;
  longitude: number;
}

export interface PaginationParams {
  page: number;
  pageSize: number;
  sort?: string;
}

export interface Paginated<Data> {
  page: number;
  pageSize: number;
  totalCount: number;
  data: Data[];
}

export interface UseTranslationResult {
  t: (...params: Parameters<TFunction>) => string;
  i18n: i18n;
  ready: boolean;
}

// Notifications
export enum SystemNotificationPositions {
  TOP_RIGHT = "top-right",
  TOP_CENTER = "top-center",
}

export interface SystemNotification {
  color: string;
  guid: string;
  message: JSX.Element | string;
  disableAutoHide: boolean;
  position: SystemNotificationPositions;
}

export enum ModalFormStatus {
  closed = "closed",
  create = "create",
  edit = "edit",
  readonly = "readonly",
  duplicate = "duplicate",
}

export enum ModalState {
  SHOWN = "SHOWN",
  HIDDEN = "HIDDEN",
}

export interface MapboxResponse {
  features: [
    {
      place_name: string;
      geometry: {
        coordinates: number[];
      };
    }
  ];
}

export enum WeekDays {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURDSAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export interface Location {
  lat: number;
  lng: number;
}
