import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Form as BSForm, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import { Map } from "../../common";
import { LocationMarker } from "../PlannerForm/LocationMarker";
import { Fleet } from "shared/types";
import { LatLngBounds } from "leaflet";
import styles from "../PlannerForm/EntityForms/Form.module.css";
import { StopSearchInput } from "./StopSearchInput";
import { useTranslation } from "react-i18next";

import { vehicleSchemaFactory, MAX_VEHICLE_CAPACITY, MIN_VEHICLE_CAPACITY } from "./schema";
import { FleetApiClient } from "shared/api";
import { v4 as uuid } from "uuid";
import { StopType } from "shared/types/Fleet";

type FormVehicle = Parameters<typeof FleetApiClient.vehicle.addVehicle>[0];

interface Props {
  vehicleModalState: Fleet.VehicleModalState;
  stops: Fleet.Stop[];
  onClose: () => void;
  onVehicleSave: (newFleetVehicleValue: FormVehicle) => void;
}

const defaultStop: Fleet.Stop = {
  stopId: "",
  name: "",
  city: "",
  identifier: "",
  street: "",
  location: {
    lat: 0,
    lng: 0,
  },
  type: StopType.All,
  imagesUrls: [],
};

const defaultMapProps = {
  key: uuid(),
  height: 200,
};

export function Form({ vehicleModalState, onVehicleSave, onClose, stops }: Props): ReactElement {
  const { t } = useTranslation(["vehicle"]);
  const vehicleSchema = useMemo(() => vehicleSchemaFactory(t), [t]);
  const getMapProps = (stop?: Fleet.Stop) => {
    if (stop && stop.location.lat !== 0 && stop.location.lng !== 0) {
      return {
        ...defaultMapProps,
        key: Object.values(stop.name).join(),
        bounds: new LatLngBounds([[stop.location.lat, stop.location.lng]]),
        children: (
          <LocationMarker
            latitude={stop.location.lat}
            longitude={stop.location.lng}
            name={stop.name}
            color="green"
          />
        ),
      };
    }
    return defaultMapProps;
  };

  const [valueInMemoryStartPosition, setValueInMemoryStartPosition] = useState<{
    previousValue: string;
    currentValue: string;
  }>({ previousValue: "", currentValue: "" });
  const [valueInMemoryEndPosition, setValueInMemoryEndPosition] = useState<{
    previousValue: string;
    currentValue: string;
  }>({ previousValue: "", currentValue: "" });

  const [startStop, setStartStop] = useState<Fleet.Stop>();
  const [endStop, setEndStop] = useState<Fleet.Stop>();

  const getStopNameForId = (id?: string) => {
    const stop = stops.find((s) => s.stopId === id);
    return stop ? stop.name : "";
  };

  const getStopForId = useCallback(
    (id?: string) => stops.find((s) => s.stopId === id) ?? defaultStop,
    [stops]
  );

  useEffect(() => {
    setStartStop(getStopForId(vehicleModalState.initialFormValues.startPositionId));
    setEndStop(getStopForId(vehicleModalState.initialFormValues.endPositionId));
  }, [vehicleModalState.initialFormValues, getStopForId]);

  return (
    <Modal show={vehicleModalState.isShown} size="lg" onHide={onClose}>
      <Formik
        initialValues={vehicleModalState.initialFormValues}
        validationSchema={vehicleSchema}
        onSubmit={(values) => {
          onVehicleSave(values);
          onClose();
        }}
      >
        {({ setFieldValue, handleSubmit, values, handleChange, errors, touched }) => (
          <BSForm noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {vehicleModalState.isEdit ? t("form.editFormTitle") : t("form.addFormTitle")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BSForm.Row>
                <BSForm.Group as={Col} md="7">
                  <BSForm.Label>{t("form.name")}</BSForm.Label>
                  <BSForm.Control
                    key={vehicleModalState.initialFormValues.name}
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder={t("form.placeholderName")}
                    onChange={handleChange}
                    isInvalid={!!errors.name && touched.name}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors.name}</BSForm.Control.Feedback>
                </BSForm.Group>
                <BSForm.Group as={Col} md="3">
                  <BSForm.Label>{t("form.licensePlate")}</BSForm.Label>
                  <BSForm.Control
                    key={vehicleModalState.initialFormValues.licensePlate}
                    type="text"
                    name="licensePlate"
                    value={values.licensePlate}
                    placeholder={t("form.placeholderLicensePlate")}
                    onChange={handleChange}
                    isInvalid={!!errors.licensePlate && touched.licensePlate}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors.licensePlate}</BSForm.Control.Feedback>
                </BSForm.Group>
                <BSForm.Group as={Col} md="2">
                  <BSForm.Label>{t("form.capacity")}</BSForm.Label>
                  <BSForm.Control
                    type="number"
                    min={MIN_VEHICLE_CAPACITY}
                    max={MAX_VEHICLE_CAPACITY}
                    name="capacity"
                    value={values.capacity.toString()}
                    onChange={handleChange}
                    isInvalid={!!errors.capacity && touched.capacity}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors.capacity}</BSForm.Control.Feedback>
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row className="border-top pt-4 px-2 mt-2">
                <BSForm.Group as={Col} md="5">
                  <Map {...getMapProps(startStop)} />
                </BSForm.Group>
                <BSForm.Group as={Col} md="7">
                  <BSForm.Label>{t("form.startStop")}</BSForm.Label>
                  <div className="align-middle float-right">
                    <i id="address-value">
                      (lat: {startStop?.location.lat}, lng: {startStop?.location.lng})
                    </i>
                  </div>
                  <div className={styles.info}>
                    {valueInMemoryStartPosition.previousValue
                      ? `Previous value: ${valueInMemoryStartPosition.previousValue}`
                      : null}
                  </div>
                  <StopSearchInput
                    placeholder={t("form.placeholderStartStop")}
                    currentValue={getStopNameForId(values.startPositionId)}
                    addValueToMemory={(newValue) => {
                      setValueInMemoryStartPosition({
                        previousValue: valueInMemoryStartPosition.currentValue,
                        currentValue: newValue,
                      });
                    }}
                    onStopSelected={(stop: Fleet.Stop) => {
                      setStartStop(stop);
                      setFieldValue("startPositionId", stop.stopId);
                    }}
                    isInvalid={!!errors.startPositionId && !!touched.startPositionId}
                    stops={stops}
                    alwaysRenderSuggestions
                  />
                  <div className={styles.invalid}>
                    {!!errors.startPositionId && !!touched.startPositionId ? errors.startPositionId : null}
                  </div>
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row>
                <hr></hr>
              </BSForm.Row>
              <BSForm.Row className="border-top pt-4 px-2">
                <BSForm.Group as={Col} md="5">
                  <Map {...getMapProps(endStop)} />
                </BSForm.Group>
                <BSForm.Group as={Col} md="7">
                  <BSForm.Label>{t("form.endStop")}</BSForm.Label>
                  <div className="align-middle float-right">
                    <i id="address-value">
                      (lat: {endStop?.location.lat}, lng: {endStop?.location.lng})
                    </i>
                  </div>
                  <div className={styles.info}>
                    {valueInMemoryEndPosition.previousValue
                      ? `Previous value: ${valueInMemoryEndPosition.previousValue}`
                      : null}
                  </div>
                  <StopSearchInput
                    placeholder={t("form.placeholderEndStop")}
                    currentValue={getStopNameForId(values.endPositionId)}
                    addValueToMemory={(newValue) => {
                      setValueInMemoryEndPosition({
                        previousValue: valueInMemoryEndPosition.currentValue,
                        currentValue: newValue,
                      });
                    }}
                    onStopSelected={(stop: Fleet.Stop) => {
                      setEndStop(stop);
                      setFieldValue("endPositionId", stop.stopId);
                    }}
                    isInvalid={!!errors.endPositionId && !!touched.endPositionId}
                    stops={stops}
                    alwaysRenderSuggestions
                  />
                  <div className={styles.invalid}>
                    {!!errors.endPositionId && !!touched.endPositionId ? errors.endPositionId : null}
                  </div>
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row>
                <BSForm.Group as={Col} md="2"></BSForm.Group>
              </BSForm.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                {t("general:save")}
              </Button>
            </Modal.Footer>
          </BSForm>
        )}
      </Formik>
    </Modal>
  );
}
