import { combineReducers } from "redux";
import planner from "./planner";
import global from "./global";

const rootReducer = combineReducers({
  planner,
  global,
});

export default rootReducer;
