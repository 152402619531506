import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from "react";
import { Modal, Form as BSForm, Col, Spinner, Button } from "react-bootstrap";
import { FieldArray, FieldArrayRenderProps, Formik } from "formik";
import { Fleet, Common } from "shared/types";
import { useTranslation } from "react-i18next";

import { FleetApiClient } from "shared/api";
import { StopMap } from "./Map";
import { stopSchemaFactory } from "./schema";
import { Role } from "shared/constants/roles";
import { RoleRestriction } from "components/common/RoleRestriction";
import { StopType } from "shared/types/Fleet";
import { ModalFormStatus } from "shared/types/Common";
import { StopImages } from "./StopImages/StopImages";

type FormStop = Parameters<typeof FleetApiClient.stop.addStop>[0];

interface Props {
  stopModalState: Fleet.StopModalState;
  onClose: () => void;
  onStopSave: (newFleetStopValue: FormStop) => Promise<void>;
}

export function Form({ stopModalState, onStopSave, onClose }: Props): ReactElement {
  const { t } = useTranslation("stop");
  const stopSchema = useMemo(() => stopSchemaFactory(t), [t]);
  const [formLocation, setFormLocation] = useState<Common.Location>(
    stopModalState.initialFormValues.location
  );
  const isReadonly = stopModalState.status === ModalFormStatus.readonly;

  const location = formLocation.lat !== 0 && formLocation.lng !== 0 ? formLocation : undefined;

  useEffect(() => {
    setFormLocation(stopModalState.initialFormValues.location);
  }, [stopModalState.initialFormValues.location]);

  function getTitle(status: ModalFormStatus) {
    switch (status) {
      case ModalFormStatus.create:
        return t("form.add");
      case ModalFormStatus.edit:
        return t("form.edit");
      case ModalFormStatus.readonly:
        return t("form.details");
      default:
        return "";
    }
  }

  return (
    <Modal show={stopModalState.status !== ModalFormStatus.closed} size="lg" onHide={onClose}>
      <Formik
        initialValues={stopModalState.initialFormValues}
        validationSchema={stopSchema}
        onSubmit={async (values, formikBag) => {
          formikBag.setSubmitting(true);
          await onStopSave(values);
          onClose();
        }}
      >
        {({ setFieldValue, handleSubmit, values, handleChange, errors, touched, isSubmitting }) => (
          <BSForm noValidate onSubmit={isReadonly ? undefined : handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{getTitle(stopModalState.status)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <BSForm.Row>
                <BSForm.Group as={Col} md="12">
                  <StopMap
                    key={`${formLocation.lat}${formLocation.lng}`}
                    location={location}
                    setNewLocation={(location: Common.Location) => {
                      if (isReadonly) return;

                      setFormLocation(location);
                      setFieldValue("location.lat", location.lat);
                      setFieldValue("location.lng", location.lng);
                    }}
                  />
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row>
                <BSForm.Group as={Col} md="6">
                  <BSForm.Label>{t("form.name")}</BSForm.Label>
                  <BSForm.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name && touched.name}
                    disabled={isSubmitting || isReadonly}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors?.name}</BSForm.Control.Feedback>
                </BSForm.Group>
                <BSForm.Group as={Col} md="3">
                  <BSForm.Label>{t("form.identifier")}</BSForm.Label>
                  <BSForm.Control
                    type="text"
                    name="identifier"
                    value={values.identifier}
                    onChange={handleChange}
                    isInvalid={!!errors.identifier && touched.identifier}
                    disabled={isSubmitting || isReadonly}
                  />
                </BSForm.Group>
                <BSForm.Group as={Col} md="3">
                  <BSForm.Label>{t("form.type")}</BSForm.Label>
                  <BSForm.Control
                    as="select"
                    value={values.type as number}
                    disabled={isSubmitting || isReadonly}
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => setFieldValue("type", ev.target.value)}
                  >
                    {Object.entries(StopType)
                      .filter(([, value]) => typeof value === "number")
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      .map(([key, value]) => (
                        <option key={key} value={value}>
                          {t(`type.${value}`)}
                        </option>
                      ))}
                  </BSForm.Control>
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row>
                <BSForm.Group as={Col} md="6">
                  <BSForm.Label>{t("form.lat")}</BSForm.Label>
                  <BSForm.Control
                    type="number"
                    min={49}
                    max={55}
                    name="location.lat"
                    step={0.00001}
                    value={values.location.lat}
                    onChange={(event) => {
                      setFieldValue("location.lat", event.currentTarget.value);
                      setFormLocation({ ...formLocation, lat: +event.currentTarget.value });
                    }}
                    isInvalid={!!errors.location?.lat && touched.location?.lat}
                    disabled={isSubmitting || isReadonly}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors?.location?.lat}</BSForm.Control.Feedback>
                </BSForm.Group>
                <BSForm.Group as={Col} md="6">
                  <BSForm.Label>{t("form.lng")}</BSForm.Label>
                  <BSForm.Control
                    type="number"
                    min={14}
                    max={24}
                    name="location.lng"
                    step={0.00001}
                    value={values.location.lng}
                    onChange={(event) => {
                      setFieldValue("location.lng", event.currentTarget.value);
                      setFormLocation({ ...formLocation, lng: +event.currentTarget.value });
                    }}
                    isInvalid={!!errors.location?.lat && touched.location?.lng}
                    disabled={isSubmitting || isReadonly}
                  />
                  <BSForm.Control.Feedback type="invalid">{errors?.location?.lng}</BSForm.Control.Feedback>
                </BSForm.Group>
              </BSForm.Row>
              <BSForm.Row>
                <BSForm.Group as={Col} md="6">
                  <BSForm.Label>{t("form.city")}</BSForm.Label>
                  <BSForm.Control
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city && touched.city}
                    disabled={isSubmitting || isReadonly}
                  />
                </BSForm.Group>
                <BSForm.Group as={Col} md="6">
                  <BSForm.Label>{t("form.street")}</BSForm.Label>
                  <BSForm.Control
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    isInvalid={!!errors.street && touched.street}
                    disabled={isSubmitting || isReadonly}
                  />
                </BSForm.Group>
              </BSForm.Row>
              <FieldArray
                name="imagesUrls"
                render={(fieldArrayProps: FieldArrayRenderProps) => (
                  <StopImages {...fieldArrayProps} stopModalState={stopModalState} />
                )}
              />
            </Modal.Body>
            <Modal.Footer>
              {!isReadonly && (
                <RoleRestriction requiredRoles={[Role.ADMIN]}>
                  <Button variant="primary" disabled={isSubmitting} type="submit">
                    {isSubmitting && (
                      <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    )}
                    {t("form.submit")}
                  </Button>
                </RoleRestriction>
              )}
            </Modal.Footer>
          </BSForm>
        )}
      </Formik>
    </Modal>
  );
}
