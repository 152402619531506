import { getConfiguration, loginSuccess, realmChosen } from "../actions";
import { call, put, cancelled } from "@redux-saga/core/effects";
import { SettingsApiClient } from "shared/api";
import { Effect, select, takeLatest } from "redux-saga/effects";
import { Settings } from "shared/types";
import { AxiosResponse } from "axios";
import { getChosenRealmApiUrl } from "../selectors/global";

export function* configurationGetSaga(): Generator<Effect> {
  yield takeLatest([realmChosen.type, loginSuccess.type], function* (): Generator<Effect> {
    const realmApiUrl = yield select(getChosenRealmApiUrl);
    if (realmApiUrl) {
      yield put(getConfiguration.started());
      try {
        const result = (yield call(() =>
          SettingsApiClient.getSettings()
        )) as AxiosResponse<Settings.AppSettings>;
        yield put(getConfiguration.done({ result }));
      } catch (error) {
        yield put(getConfiguration.failed({ error: { error: error as string } }));
      } finally {
        if (yield cancelled()) {
          yield put(getConfiguration.failed({ error: { error: "Action cancelled" } }));
        }
      }
    }
  });
}
