import ReactApexChart from "react-apexcharts";
import { useMemo } from "react";
import { Fleet } from "shared/types";
import { useTranslation } from "react-i18next";

interface Props {
  dataSource: Fleet.PassengersAndRideDataSource[];
}

export function DRTUsersChart({ dataSource }: Props) {
  const { t } = useTranslation(["reports"]);
  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        height: 400,
        type: "line",
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2, 3],
      },
      stroke: {
        width: [1, 1, 2, 2],
      },
      xaxis: {
        categories: dataSource.map((d) => new Date(d.date).toLocaleDateString()),
        labels: {
          rotateAlways: true,
          rotate: -45,
        },
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    }),
    [dataSource]
  );

  const series = useMemo(
    () => [
      {
        name: t("charts.drtUsers.seriesName.newUsers"),
        type: "column",
        data: dataSource.map((d) => d.newUsersCount),
      },
      {
        name: t("charts.drtUsers.seriesName.lostUsers"),
        type: "column",
        data: dataSource.map((d) => d.lostUsersCount),
      },
      {
        name: t("charts.drtUsers.seriesName.users"),
        type: "line",
        data: dataSource.map((d) => d.usersCount),
      },
      {
        name: t("charts.drtUsers.seriesName.activeUsers"),
        type: "line",
        data: dataSource.map((d) => d.activeUsersCount),
      },
    ],
    [dataSource, t]
  );

  return <ReactApexChart options={options} series={series} type="line" height={400} />;
}
