import React, { useMemo } from "react";
import { Modal as BSModal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormResultValues, FormValues, operatorRideRequestSchemaFactory } from "./schema";
import { CreateForm } from "./CreateForm";
import styles from "../../Rides.module.css";
import { OperatorRideRequest } from "shared/types/Fleet";

interface Props {
  onHide: () => void;
  isShown: boolean;
  handleCreate: (request: OperatorRideRequest) => Promise<void> | void;
}

const initalValues: FormValues = {
  phone: "",
  name: "",
  passengerCount: 1,
  departureStopId: "",
  arrivalStopId: "",
  time: null,
  usingDepartureTime: true,
};

const mapFormValuesToRequest = (values: FormResultValues): OperatorRideRequest => {
  const { usingDepartureTime, time, ...rest } = values;
  return {
    ...rest,
    departureTime: usingDepartureTime ? time : undefined,
    arrivalTime: !usingDepartureTime ? time : undefined,
  };
};

export function Modal({ onHide, isShown, handleCreate }: Props): React.ReactElement {
  const { t } = useTranslation("rides");
  const schema = useMemo(() => operatorRideRequestSchemaFactory(t), [t]);

  return (
    <BSModal size="lg" show={isShown} onHide={onHide} className={styles.rideRequestModal}>
      <Formik
        initialValues={initalValues}
        validationSchema={schema}
        onSubmit={async (values) => {
          await handleCreate(mapFormValuesToRequest(values as FormResultValues));
        }}
        component={CreateForm}
      />
    </BSModal>
  );
}
