import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { MapContainer, ScaleControl, TileLayer, useMap, ZoomControl } from "react-leaflet";

import { StoreShape } from "shared/state";
import { globalSelectors } from "shared/state/selectors";
import styles from "./Map.module.css";
import "./leaflet-setup";
import { ErrorBoundary } from "../ErrorBoundary";
import { LatLngBoundsExpression, LatLngTuple } from "leaflet";

interface StateProps {
  mapTilingServiceAddress: ReturnType<typeof globalSelectors.getMapTilingServiceAddress>;
  mapboxAccessToken: ReturnType<typeof globalSelectors.getMapboxAccessToken>;
  mapboxProximity: ReturnType<typeof globalSelectors.getMapboxProximity>;
}

interface OwnProps extends Omit<React.ComponentProps<typeof MapContainer>, "children"> {
  height?: number | string;
  children?: React.ReactNode;
}

type Props = StateProps & OwnProps;

function MapView({
  mapboxAccessToken,
  mapboxProximity,
  mapTilingServiceAddress,
  children,
  height = 600,
  style,
  bounds,
  ...rest
}: Props): React.ReactElement {
  const defaultMapProps: Partial<React.ComponentProps<typeof MapContainer>> = {
    zoom: 10,
    maxZoom: 18,
    zoomControl: false,
    bounds,
  };

  const getCenterPoint = useCallback((): LatLngTuple => {
    if (mapboxProximity) {
      const coordinates = mapboxProximity.split(",");
      if (coordinates.length === 2) {
        return [+coordinates[1], +coordinates[0]];
      }
      console.error("The 'MapboxProximity' property is set to the wrong value");
      return [50.2136513, 18.9371535];
    }
    console.error("Property 'mapboxProximity' is undefined");
    return [50.2136513, 18.9371535];
  }, [mapboxProximity]);

  if (mapTilingServiceAddress == null) {
    return (
      <div className={classNames("d-flex align-items-center justify-content-center", styles.warning)}>
        <div className="alert alert-danger alert-dismissible p-4">
          <h4 className="text-center">
            <i className="icon fa fa-ban"></i> Alert!
          </h4>
          <p className="text-center">Render service URL was not provided.</p>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <MapContainer {...defaultMapProps} center={getCenterPoint()} style={{ ...style, height }} {...rest}>
        <MapBoundsUpdater bounds={bounds} />
        <TileLayer
          url={`${mapTilingServiceAddress}{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`}
          attribution={
            '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
          }
          maxZoom={18}
          id="mapbox/streets-v11"
          accessToken={mapboxAccessToken}
          tileSize={512}
          zoomOffset={-1}
        />
        <ScaleControl position="bottomright" imperial={false} maxWidth={200} />
        <ZoomControl position="bottomright" />
        {children}
      </MapContainer>
    </ErrorBoundary>
  );
}

const mapStateToProps = (state: StoreShape): StateProps => {
  return {
    mapTilingServiceAddress: globalSelectors.getMapTilingServiceAddress(state),
    mapboxAccessToken: globalSelectors.getMapboxAccessToken(state),
    mapboxProximity: globalSelectors.getMapboxProximity(state),
  };
};

export const Map =
  connect<StateProps, Record<string, unknown>, Record<string, unknown>, StoreShape>(mapStateToProps)(MapView);

function MapBoundsUpdater({ bounds }: { bounds?: LatLngBoundsExpression }) {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { padding: [30, 30] });
    }
  }, [bounds, map]);
  return null;
}
