import { ModalFormStatus, Location } from "./Common";
import { Vehicle, VehiclePosition } from "./fleet/Vehicle";

export * from "./fleet/Vehicle";

export interface CancelMission {
  message: string;
  missionId: string;
  vehicleId: string;
}

export interface Schedule {
  scheduleId: string;
  regionId: string;
  type: number;
  stopId: string;
  stopTimes: string[];
  deadlineMinutes: number;
  vehicles: string[];
  timeZoneId: string;
}

export interface ApiEnum<Enum> {
  code: Enum;
  description: string;
}

export interface RideInfo {
  rideId: string;
  passengerName: string;
  passengerCounter: number;
  phone: string;
  status: ApiEnum<RideStatuses>;
}

export enum PickupStatuses {
  Pending = 0,
  PickedUp = 1,
  NotPickedUp = 2,
}

export interface PickupRideInfo extends RideInfo {
  pickupStatus: ApiEnum<PickupStatuses>;
  pickupStatusUpdated: string;
}

export enum DropoffStatuses {
  Pending = 0,
  DroppedOff = 1,
  NotPickedUp = 2,
}

export interface DropoffRideInfo extends RideInfo {
  dropoffStatus: ApiEnum<DropoffStatuses>;
  dropoffStatusUpdated: string;
}

export enum SegmentStatuses {
  Pending = 0,
  Confirmed = 1,
  Cancelled = 2,
  Completed = 3,
  Visited = 4,
}

export interface Segment {
  segmentId: string;
  stop: Stop;
  initialPlannedServiceTime: string;
  plannedServiceTime: string;
  reportedDepartureTime: string;
  reportedArrivalTime: string;
  segmentStatus: ApiEnum<SegmentStatuses>;
  segmentStatusUpdate: string;
  pickUps: PickupRideInfo[];
  dropOffs: DropoffRideInfo[];
  delay: string;
}

export interface RouteSegment {
  routeSegment: Location[];
}

export enum MissionStatuses {
  Pending = 0,
  Started = 1,
  Finished = 2,
  Cancelled = 3,
}

export interface MissionState {
  missionId: string;
  missionStatus: ApiEnum<MissionStatuses>;
  plan: Segment[];
  route: RouteSegment[];
}

export enum StopType {
  Disabled = 0,
  Pickup = 1,
  Dropoff = 2,
  All = 3,
}

export interface Stop extends Record<string, unknown> {
  stopId: string;
  name: string;
  city: string;
  street: string;
  location: Location;
  identifier: string;
  type: StopType;
  imagesUrls: string[];
}

export interface ForbiddenTransition extends Record<string, unknown> {
  sourceStopId: string;
  sourceStopName: string;
  destinationStopId: string;
  destinationStopName: string;
}

export interface MissionPlan {
  segments: Segment[];
}

export interface PagesInfo<T> {
  allItems: number;
  pages: number;
  size: number;
  items: T[];
}

export interface MissionOverviewListItemDto {
  id: string;
  vehicleId: string;
  vehicleName: string;
  plannedStartTime: string;
  plannedEndTime: string;
  reportedStartTime: string;
  reportedEndTime: string;
  initialPlannedStartTime: string;
  initialPlannedEndTime: string;
  status: MissionStatuses;
  statusDescription: string;
}

export interface MissionDetailedOverviewItemDto extends Omit<MissionOverviewListItemDto, "status"> {
  vehicle: Vehicle;
  plan: MissionPlan;
  vehiclePositions: VehiclePosition[];
  status: ApiEnum<MissionStatuses>;
}

export enum RideStatuses {
  Registered = 0,
  InProgress = 1,
  Absent = 2,
  Cancelled = 3,
  Delivered = 4,
  DeliveryConfirmed = 5,
  AbsentConfirmed = 6,
  CancelledConfirmed = 7,
  Unconfirmed = 8,
}

export enum RideArrivalStatuses {
  Pending,
  DroppedOff,
  NotPickedUp,
}

export enum RideDepartureStatuses {
  Pending,
  PickedUp,
  NotPickedUp,
}

export interface Mission {
  missionId: string;
  missionStatus: ApiEnum<MissionStatuses>;
  plan: Segment[];
  route: RouteSegment[];
  plannedStartTime: string;
  plannedEndTime: string;
  reportedStartTime: string;
  reportedEndTime: string;
  initialPlannedStartTime: string;
  initialPlannedEndTime: string;
}

export interface Passenger {
  subjectId: string;
  username: string;
}

export interface RideOverviewListItemDto {
  id: string;
  status: RideStatuses;
  arrivalStatus: RideArrivalStatuses;
  arrivalStatusUpdatedAt: string;
  departureStatus: RideDepartureStatuses;
  departureStatusUpdatedAt: string;
  statusUpdatedAt: string;
  statusDescription: string;
  createdAt: string;
  arrivalStopName: string;
  arrivalStopNumber: number | null;
  departureStopName: string;
  departureStopNumber: number | null;
  reportedArrival: string;
  plannedArrival: string;
  initialPlannedArrival: string;
  reportedDeparture: string;
  plannedDeparture: string;
  initialPlannedDeparture: string;
  passengerUsername: string | null;
  passengerName: string;
  passengerPhone: string;
  numberOfPassengers: number;
  source: RideRequestSource;
  vehicleName: string;
}

export enum RideRequestSource {
  NoData = 0,
  PassengerMobile = 1,
  Operator = 2,
}

enum RideRequestStatus {
  Registered = 0,
  Accepted = 1,
  Discarded = 2,
}

export interface RideRequestDto {
  id: string;
  userId: string;
  passengerCount: number;
  departureStopId: string;
  arrivalStopId: string;
  DepartureTime: string;
  arrivalTime: string;
  RegisteredAt: string;
  status: ApiEnum<RideRequestStatus>;
}

export interface RideOverviewItemDto {
  id: string;
  mission: Mission;
  passenger: Passenger;
  rideStatus: ApiEnum<RideStatuses>;
  createdAt: string;
  arrivalSegment: Segment;
  departureSegment: Segment;
  rideRequest: RideRequestDto;
  departureStatus: ApiEnum<PickupStatuses>;
  arrivalStatus: ApiEnum<DropoffStatuses>;
}

export interface OperatorRideRequest {
  passengerCount: number;
  departureStopId?: string;
  arrivalStopId?: string;
  departureTime?: Date;
  arrivalTime?: Date;
  phone: string;
  name: string;
}

export interface Ride {
  arrivalStartTime: string;
  arrivalEndTime: string;
  departureStartTime: string;
  departureEndTime: string;
  rideId: string;
  departureStop: Stop;
  arrivalStop: Stop;
}

export interface RideRequestResponse {
  success: boolean;
  errorMessage: string | null;
  ride?: Ride;
}

export interface VehicleModalState {
  initialFormValues: Vehicle;
  isShown: boolean;
  isEdit: boolean;
}

export interface CancelMissionModalState {
  initialFormValues: CancelMission;
  isShown: boolean;
}

export interface StopModalState {
  initialFormValues: Stop;
  status: ModalFormStatus;
}

export interface DashboardRequest {
  from: string;
  until: string;
  numberOfDaysToGroup: number;
  minimumConnectionsCount: number;
  daysInactivityCount: number;
}

export interface PassengersAndRideDataSource {
  date: string;
  passengersCount: number;
  deliveredPassengersCount: number;
  orderedRidesCount: number;
  cancelledRidesCount: number;
  absentPassengersCount: number;
  passengersRequestRejectedCount: number;
  timeOutPropositionRidesCount: number;
  allRideRequestsCount: number;
  avgPassengersPerRequestCount: number;
  newUsersCount: number;
  lostUsersCount: number;
  usersCount: number;
  activeUsersCount: number;
}

export interface TravelHoursDataSource
{
    hour: string;
    passengersCount: number;
}

export interface ServiceTimeDataSource
{
    intervalName: string;
    departurePercent: number;
    arrivalPercent: number;
    departureCount: number;
    arrivalCount: number;
}

export interface RequestTimeDataSource
{
    intervalName: string;
    requestsCount: number;
}

export interface Series {
  name: string;
  data: number[];
}

export interface StopsHeatmapDataSource
{
    headerRow: string[];
    series: Series[];
}

export interface DashboardResponse {
  passengersAndRideDataSources: PassengersAndRideDataSource[];
  travelHoursDataSources: TravelHoursDataSource[];     
  serviceTimeDataSources: ServiceTimeDataSource[]; 
  requestTimeDataSources: RequestTimeDataSource[];
  stopsHeatmapDataSources: StopsHeatmapDataSource;
}

export type DataSerie = {
  primary: string;
  secondary: number;
  radius?: number;
};
