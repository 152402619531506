export const missions = {
  filters: {
    id: "Id misji",
    vehicleName: "Nazwa pojazdu",
    status: "Status",
    startsBefore: "Rozpoczyna się przed",
    startsAfter: "Rozpoczyna się po",
    showDeleted: "Pokaż usunięte",
  },
  table: {
    header: {
      startTime: "Czas rozpoczęcia",
      endTime: "Czas zakończenia",
      plannedStartTime: "Planowany",
      plannedEndTime: "Planowany",
      reportedStartTime: "Raportowany",
      reportedEndTime: "Raportowany",
      initialPlannedStartTime: "Początkowy",
      initialPlannedEndTime: "Początkowy",
      status: "Status",
      actions: "Akcje",
      vehicleName: "Pojazd",
    },
    actions: {
      details: "Podgląd",
      cancel: "Anuluj",
      cancelDescription: "Misja zostanie anulowana wraz z przypisanymi do niej niezrealizowanymi przejazdami. Nowa misja nie zostanie już utworzona w tym samym przedziale czasowym.",
      deleteDescription: "Misja zostanie anulowana, a następnie usunięta (status Deleted). Niezrealizowane przejazdy zostaną anulowane, a pasażerowie otrzymają w aplikacji wiadomość z prośbą o ponowne zgłoszenie. Nowa misja może powstać w tym samym przedziale czasowym po przelogowaniu się kierowcy, lub po złożeniu zamówienia przez dowolnego użytkownika.",
      fixWithRecalculate: "Resetuj z przeliczeniem",
      fixWithRecalculateDescription: "Aktualna misja zostanie usunięta (status Deleted). W jej miejsce zostanie utworzona nowa misja w tym samym przedziale czasowym. Przeniesione zostaną wszystkie zrealizowane i niezrealizowane przejazdy, a następnie przeliczone przez algorytm planowania trasy.",
      fixWithCanceledRides: "Resetuj z anulowaniem",
      fixWithCanceledRidesDescription: "Aktualna misja zostanie usunięta (status Deleted). W jej miejsce zostanie utworzona nowa misja w tym samym przedziale czasowym. Przeniesione zostaną wszystkie zrealizowane przejazdy. Niezrealizowane przejazdy zostaną anulowane, a pasażerowie otrzymają w aplikacji wiadomość z prośbą o ponowne zgłoszenie.",
      message: {
        fixWithRecalculateMessage: "Czy na pewno chcesz podjąć próbę naprawy misji z funkcją rekalkulacji?",
        fixWithCanceledRidesMessage:
          "Czy na pewno chcesz podjąć próbę naprawy misji z anulowaniem przejazdów?",
        deleteConfirmationMessage: "Czy na pewno chcesz usunąć misję wraz z jej wszystkimi danymi?",
      },
    },
  },
  form: {
    cancelConfirm: "Czy na pewno chcesz anulować misje?",
    cancelMission: "Anulowanie misji",
    cancelInfo:
      "Anulowanie misji spowoduje natychmiastowe zatrzymanie wszystkich niezrealizowanych przejazdów. \nWszyscy pasażerowie, których przejazdy zostaną wstrzymane, otrzymają napisaną przez Ciebie wiadomość.",
    detailsHeader: "Szczegóły anulowanej misji",
    vehicleNameLabel: "Aktualnie przypisany pojazd",
    statusLabel: "Aktualny status",
    pushMessageLabel: "Powiadomienie dla pasażerów z anulowanych przejazdów",
    pushMessagePlaceholder: "Twoja wiadomość",
    howManyCharactersLeft: "Dostępne znaki: {{current}}/{{max}}",
    save: "Anuluj misję i wyślij powiadomienia",
    validation: {
      messageRequired: "Należy wprowadzić wiadomość",
    },
  },
};
