import { LatLngBounds } from "leaflet";
import { Row } from "react-bootstrap";
import classNames from "classnames";

import { Fleet } from "shared/types";
import { Map, StopLocationMarker } from "../../common";
import styles from "./Stop.module.css";

function getBounds(stops: Fleet.Stop[]): LatLngBounds | undefined {
  if (stops.length >= 1) {
    return new LatLngBounds(stops.map(({ location }) => [location.lat, location.lng]));
  }
  return undefined;
}
const tooltipContent = (stop: Fleet.Stop) => (
  <div>
    <Row className="mx-2 font-weight-bolder">{`${stop.name}${
      stop.identifier ? ` ${stop.identifier}` : ""
    }`}</Row>
    <Row className="mx-2">{`lat: ${stop.location.lat}, lng: ${stop.location.lng}`}</Row>
  </div>
);

interface Props {
  stops: Fleet.Stop[];
}

export function FormMap({ stops }: Props) {
  return (
    <Map height={700} bounds={getBounds([...stops])}>
      {stops.map((el) => (
        <StopLocationMarker
          key={`from${el.location.lat}${el.location.lng}`}
          location={el.location}
          tooltipContent={tooltipContent(el)}
        >
          <i className={classNames("fas fa-bus text-black", styles.icon)}></i>
        </StopLocationMarker>
      ))}
    </Map>
  );
}
