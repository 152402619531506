import { useReducer, useMemo, useCallback } from "react";
import actionCreatorFactory, { Action, isType } from "typescript-fsa";

interface VisibilityState {
  [key: string]: boolean;
}

type HookResult<ItemType> = {
  isExpanded: (item: ItemType) => boolean;
  toggleExpand: (item: ItemType) => void;
  expandAll: () => void;
  collapseAll: () => void;
  allItemsExpanded: boolean;
};

// General state idea:
// If [Identifier]: true
//  item is expanded
// Anything else:
//  item is collapsed
function prepareReducer<ItemType>(idGetter: (item: ItemType) => string) {
  const actionCreator = actionCreatorFactory();
  const collapseAllAction = actionCreator("COLLAPSE_ALL");
  const expandAllAction = actionCreator<ItemType[]>("EXPAND_ALL");
  const toggleVisibilityAction = actionCreator<ItemType>("TOGGLE_VISIBILITY");

  return {
    reducer: (state: VisibilityState, action: Action<unknown>): VisibilityState => {
      if (isType(action, toggleVisibilityAction)) {
        const id = idGetter(action.payload);
        return { ...state, [id]: !state[id] };
      }
      if (isType(action, collapseAllAction)) {
        return {};
      }
      if (isType(action, expandAllAction)) {
        return action.payload.reduce((object: VisibilityState, item) => {
          object[idGetter(item)] = true;
          return object;
        }, {});
      }
      throw new Error("Unknown action type in ResultDetailsItem, this really shouldn't have happened.");
    },
    collapseAllAction,
    expandAllAction,
    toggleVisibilityAction,
  };
}

export function useAccordion<ItemType>(
  items: ItemType[],
  idGetter: (item: ItemType) => string
): HookResult<ItemType> {
  const { reducer, toggleVisibilityAction, expandAllAction, collapseAllAction } = useMemo(
    () => prepareReducer(idGetter),
    [idGetter]
  );
  const [visibilityState, dispatch] = useReducer(reducer, {});
  const allItemsExpanded = useMemo(
    () => items.every((item) => visibilityState[idGetter(item)]),
    [items, visibilityState, idGetter]
  );
  const isExpanded = useCallback(
    (item: ItemType) => !!visibilityState[idGetter(item)],
    [visibilityState, idGetter]
  );
  const toggleExpand = useCallback(
    (item: ItemType) => {
      dispatch(toggleVisibilityAction(item));
    },
    [toggleVisibilityAction]
  );
  const expandAll = useCallback(() => {
    dispatch(expandAllAction(items));
  }, [expandAllAction, items]);
  const collapseAll = useCallback(() => {
    dispatch(collapseAllAction());
  }, [collapseAllAction]);
  return {
    isExpanded,
    toggleExpand,
    expandAll,
    collapseAll,
    allItemsExpanded,
  };
}
