import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.TravelHoursDataSource[];
}

export function HoursRidesChart({ dataSource }: Props) {
  const { t } = useTranslation(["reports"]);
  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 400,
        stacked: false,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: dataSource.map((d) => d.hour),
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      fill: {
        opacity: 1,
      },
    }),
    [dataSource]
  );

  const series = useMemo(
    () => [
      {
        name: t("charts.hoursRides.seriesName.departureTime"),
        data: dataSource.map((d) => d.passengersCount),
      },
    ],
    [dataSource, t]
  );

  return <ReactApexChart options={options} series={series} type="bar" height={400} />;
}
