import { FormikProps } from "formik";
import { FieldArrayRenderProps } from "formik/dist/FieldArray";
import React, { useCallback, useReducer } from "react";
import { Modal, Form as BSForm, Col, Spinner, Button, ButtonGroup } from "react-bootstrap";
import styles from "./StopImages.module.css";
import { useTranslation } from "react-i18next";

import { Fleet } from "../../../../shared/types";
import { ModalFormStatus } from "../../../../shared/types/Common";
import { stopImagesReducer, initialState, StopImagesStateActions } from "./reducer";

interface StopImagesProps extends FieldArrayRenderProps {
  stopModalState: Fleet.StopModalState;
}

const isUniqueUrl = (url: string, existingUrls: string[]) => {
  if (!url) {
    return true;
  }

  return existingUrls.filter((x) => x === url).length === 0;
};

const isValidImageUrl = (url: string) => {
  const imageUrlRegex = /^(https?:\/\/.*\.(?:png|jpg))$/;

  return imageUrlRegex.test(url);
};

export function StopImages({ form, stopModalState, push, remove }: StopImagesProps) {
  const formProp = form as FormikProps<Fleet.Stop>;
  const { t } = useTranslation("stop");

  const [stopImagesState, dispatch] = useReducer(stopImagesReducer, initialState);

  const isReadonly = stopModalState.status === ModalFormStatus.readonly;

  const handleAddUrl = useCallback(
    async (url: string) => {
      dispatch(StopImagesStateActions.checkingDraftImageStarted());
      let res: Response;

      try {
        res = await fetch(url, { method: "HEAD" });
      } catch {
        dispatch(StopImagesStateActions.draftImageFetchErrorOccured());
        return;
      }

      if (!res.ok) {
        dispatch(StopImagesStateActions.draftImageNotFound());
      } else {
        push(url);
        dispatch(StopImagesStateActions.clearDraftImageUrl());
      }
      dispatch(StopImagesStateActions.checkingDraftImageCompleted());
    },
    [push]
  );

  const validateDraftUrl = useCallback((): boolean => {
    if (!stopImagesState.draftImageUrl) {
      return true;
    }

    if (
      !isValidImageUrl(stopImagesState.draftImageUrl) ||
      !isUniqueUrl(stopImagesState.draftImageUrl, formProp.values.imagesUrls) ||
      stopImagesState.draftImageNotFound ||
      stopImagesState.draftImageFetchErrorOccured
    ) {
      return false;
    }

    return true;
  }, [
    stopImagesState.draftImageUrl,
    stopImagesState.draftImageNotFound,
    stopImagesState.draftImageFetchErrorOccured,
    formProp.values.imagesUrls,
  ]);

  function getValidationUrlError(): string | null {
    if (!stopImagesState.draftImageUrl) {
      return null;
    }

    if (!isValidImageUrl(stopImagesState.draftImageUrl)) {
      return t("stop:validation.imageUrl");
    }

    if (!isUniqueUrl(stopImagesState.draftImageUrl, formProp.values.imagesUrls)) {
      return t("stop:validation.uniqueUrl");
    }

    if (stopImagesState.draftImageNotFound) {
      return t("stop:validation.notFound");
    }

    if (stopImagesState.draftImageFetchErrorOccured) {
      return t("stop:validation.fetchError");
    }

    return null;
  }

  return (
    <>
      <BSForm.Row>
        <BSForm.Group as={Col} md="12">
          <BSForm.Label>{t("form.imageUrl")}</BSForm.Label>
          <BSForm.Group
            as={Col}
            md="12"
            className="d-flex justify-content-between align-items-baseline p-0 m-0"
          >
            <BSForm.Group as={Col} className="p-0 m-0">
              <BSForm.Control
                className="p-2"
                type="text"
                name="imagesUrls"
                placeholder="Url"
                value={stopImagesState.draftImageUrl}
                onChange={(value) => {
                  dispatch(StopImagesStateActions.draftImageUrlChanged({ imageUrl: value.target.value }));
                }}
                isInvalid={!validateDraftUrl()}
                disabled={form.isSubmitting || isReadonly || stopImagesState.checkingDraftImage}
              />
              <BSForm.Control.Feedback type="invalid">
                {!validateDraftUrl() ? getValidationUrlError() : null}
              </BSForm.Control.Feedback>
            </BSForm.Group>
            <Button
              variant="primary"
              className="p-2 ml-2 text-center"
              size="sm"
              onClick={() => {
                void handleAddUrl(stopImagesState.draftImageUrl);
              }}
              disabled={
                stopImagesState.draftImageUrl === "" ||
                !isValidImageUrl(stopImagesState.draftImageUrl) ||
                !isUniqueUrl(stopImagesState.draftImageUrl, formProp.values.imagesUrls) ||
                stopImagesState.checkingDraftImage ||
                stopImagesState.draftImageNotFound ||
                stopImagesState.draftImageFetchErrorOccured
              }
            >
              {stopImagesState.checkingDraftImage ? (
                <>
                  <span>{t("general:add")}</span>&nbsp;
                  <Spinner animation="border" variant="primary" size="sm" />
                </>
              ) : (
                t("general:add")
              )}
            </Button>
          </BSForm.Group>
        </BSForm.Group>
      </BSForm.Row>
      <BSForm.Row className="justify-content-start">
        {formProp.values.imagesUrls.map((url, urlIndex) => (
          <React.Fragment key={urlIndex}>
            <div
              className={`d-flex flex-column justify-content-end align-items-center mb-4 ml-4 ${styles.imageBox}`}
              style={{ backgroundImage: `url(${url})` }}
            >
              <ButtonGroup className="mb-2">
                <Button
                  variant="dark"
                  className="p-2 mb-2"
                  size="sm"
                  onClick={() => {
                    remove(urlIndex);
                  }}
                  disabled={form.isSubmitting || isReadonly}
                >
                  <i className="fas fa-trash"></i>&nbsp;
                  {t("general:delete")}
                </Button>
                <Button
                  variant="dark"
                  className="p-2 mb-2"
                  size="sm"
                  onClick={() => {
                    dispatch(StopImagesStateActions.showImageInPreview({ imageUrl: url }));
                  }}
                >
                  <i className="fas fa-eye"></i>&nbsp;
                  {t("general:preview")}
                </Button>
              </ButtonGroup>
            </div>
          </React.Fragment>
        ))}
      </BSForm.Row>
      <Modal
        show={stopImagesState.showImageInPreview}
        size="lg"
        onHide={() => dispatch(StopImagesStateActions.imagePreviewClosed())}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <img className="w-100 h-100" src={stopImagesState.imageToPreview} alt="" />
        </Modal.Body>
      </Modal>
    </>
  );
}
