import { useTranslation } from "react-i18next";
import { Layout } from "components";
import { useAuthService } from "shared/hooks/useAuthService";

export function SilentRenew() {
  const authService = useAuthService();
  const { t } = useTranslation();
  authService.signinSilentCallback();
  return (
    <Layout.Centered>
      <h4>{t("redirecting")}...</h4>
    </Layout.Centered>
  );
}
