import { Fleet } from "shared/types";

export function ComputeActiveSegments(
  mission: Fleet.MissionDetailedOverviewItemDto,
  currentSegmentIndex: number
): [Fleet.Segment[], number] {
  const newArray = [];
  let activeIndex = 0;
  if (currentSegmentIndex === 0) {
    for (let i = 0; i < mission.plan.segments.length && i < 3; ++i) {
      newArray.push(mission.plan.segments[i]);
    }
    activeIndex = 0;
  } else if (currentSegmentIndex === mission.plan.segments.length - 1) {
    let i =
      mission.plan.segments.length > 2 ? mission.plan.segments.length - 3 : mission.plan.segments.length - 2;
    for (; i < mission.plan.segments.length; ++i) {
      newArray.push(mission.plan.segments[i]);
    }
    activeIndex = newArray.length - 1;
  } else {
    newArray.push(mission.plan.segments[currentSegmentIndex - 1]);
    newArray.push(mission.plan.segments[currentSegmentIndex]);
    newArray.push(mission.plan.segments[currentSegmentIndex + 1]);
    activeIndex = 1;
  }

  return [newArray, activeIndex];
}
