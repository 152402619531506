import { Common } from "shared/types";

export const general = {
  appName: "Panel TnŻ",
  insufficientPrivileges: "Konto nie posiada wymaganych uprawnień",
  register: "Zarejestruj się",
  today: "Dzisiaj",
  login: "Zaloguj się",
  logout: "Wyloguj",
  changeRealm: "Zmień wdrożenie",
  save: "Zapisz",
  cancel: "Anuluj",
  publish: "Opublikuj",
  duplicate: "Duplikuj",
  edit: "Edytuj",
  create: "Stwórz",
  delete: "Usuń",
  preview: "Podgląd",
  add: "Dodaj",
  archive: "Archiwizuj",
  close: "Zamknij",
  details: "Szczegóły",
  back: "Cofnij",
  yes: "Tak",
  no: "Nie",
  clear: "Wyczyść",
  refresh: "Odśwież",
  loading: "Ładowanie",
  download: "Pobierz",
  search: "Filtruj...",
  format: "Format: {{format}}",
  empty: "Brak",
  ok: "Ok",
  sessionExpired: "Sesja wygasła",
  confirmationQuestion: "Potwierdź akcję",
  redirecting: "Przekierowuję",
  unauthorized: "Nie posiadasz wymaganych uprawnień",
  dispatcherHeader: "Jesteś zalogowany na konto DYSPOZYTORA",
  adminHeader: "Jesteś zalogowany na konto ADMINISTRATORA",
  driverHeader: "Jesteś zalogowany na konto KIEROWCY",
  dispatcherHeaderShort: "DYSPOZYTOR",
  adminHeaderShort: "ADMINISTRATOR",
  driverHeaderShort: "KIEROWCA",
  validation: {
    emailFormat: "Należy podać poprawny adres email",
    emailMaxLength: "Adres email nie może być dłuższy niż {{maxLength}} znaków",
    emailRequired: "Należy podać swój adres email",
    passwordMinLength: "Hasło nie może być krótsze niż {{minLength}} znaków",
    passwordMaxLength: "Hasło nie może być dłuższe niż {{maxLength}} znaków",
    capacityMinLength: "Pojemność pojazdu nie może być mniejsza niż {{minLength}}",
    capacityMaxLength: "Pojemność pojazdu nie może być większa niż {{maxLength}}",
    capacityRequired: "Należy podać pojemność pojazdu",
    passwordRequired: "Należy podać hasło",
    required: "Pole jest wymagane",
    locationRequired: "Należy wybrać adres",
    phoneNumberFormat: "Wprowadź poprawny numer telefonu",
    dateIsAfterToday: "Data nie może wybiegać w przyszłość",
    dateIsInDistantPast: "Data nie może być wcześniejsza niż {{date}}",
    maxLength: "Długość pola nie powinna przekraczać {{length}} znaków",
  },
  inputNames: {
    email: "Adres email",
    password: "Hasło",
    name: "Imię",
    surname: "Nazwisko",
    homeAddress: "Adres zamieszkania",
    caretakerName: "Imię i nazwisko opiekuna",
    caretakerPhoneNumber: "Numer telefonu kontaktowego",
    alternativePhoneNumber: "Alternatywny numer telefonu",
    rideDirection: "Kierunek przejazdu",
    wheelchairAssistanceRequired: "Czy pasażer posiada wózek inwalidzki?",
    remarks: "Uwagi",
    datePickerTimeInputName: "Godzina:",
  },
  table: {
    page: "Strona",
    outOf: "z {{numberOfPages}}",
    totalRows: "Liczba wierszy: {{totalRows}}",
    all: "wszystkie",
    show: "Pokaż",
    showRows_0: "wiersz",
    showRows_1: "wiersze",
    showRows_2: "wierszy",
  },
  notifications: {
    userMessage: "Wiadomość dla użytkownika",
    requestFailed: "Błąd żądania",
    invalidResponse: "Niepoprawna odpowiedź serwera.",
    correlationId: "Identyfikator błędu",
    defaultUserMessage: "Skontaktuj się z nami podając identyfikator błędu.",
  },
  map: {
    error: "Błąd",
    errorMessage: "Wczytywanie mapy nie powiodło się.",
  },
  weekDayNamesShort: {
    [Common.WeekDays.MONDAY]: "Pn",
    [Common.WeekDays.TUESDAY]: "Wt",
    [Common.WeekDays.WEDNESDAY]: "Śr",
    [Common.WeekDays.THURDSAY]: "Cz",
    [Common.WeekDays.FRIDAY]: "Pt",
    [Common.WeekDays.SATURDAY]: "Sb",
    [Common.WeekDays.SUNDAY]: "Nd",
  },
  arrived: "Przyjechał",
  departured: "Odjechał",
};
