import { CellProps } from "react-table";
import { Fleet } from "shared/types";
import { RideStatuses } from "shared/types/Fleet";
import { UseTranslationResult } from "shared/types/Common";
import { useTranslation } from "react-i18next";
import { RoleRestriction } from "components/common";
import { Role } from "shared/constants";

const rideCanBeCancelled = (ride: Fleet.RideOverviewListItemDto) =>
  ride.status === RideStatuses.Registered || ride.status === RideStatuses.Unconfirmed;

interface OwnProps {
  showDetails: (id: string) => void;
  handleCancel: (rideId: string) => void;
}

export function Actions({
  row,
  handleCancel,
  showDetails,
}: CellProps<Fleet.RideOverviewListItemDto> & OwnProps) {
  const { t } = useTranslation("rides") as UseTranslationResult;
  return (
    <div className="text-center text-nowrap">
      {rideCanBeCancelled(row.original) && (
        <i
          data-cy="cancelRideButton"
          title={t("general:cancel")}
          role="button"
          className="text-danger mr-2 fas fa-trash-alt cursor-pointer font-larger"
          onClick={() => handleCancel(row.original.id)}
        />
      )}
      <RoleRestriction requiredRoles={[Role.ADMIN]}>
        <i
          title={t("table.header.details")}
          role="button"
          className="fas fa-file-alt cursor-pointer font-larger"
          onClick={() => showDetails(row.original.id)}
        />
      </RoleRestriction>
    </div>
  );
}
