import { LatLngTuple } from "leaflet";
import { DirectionalPolylineSegment } from "../../../common/DirectionalPolyline";
import { SegmentStatus } from "../PlanSegmentsPanel/SegmentRow";
import {
  StatusColorsNameMap,
  StatusOpacityNameMap,
  SegmentsStatusSequence,
} from "./DirectionalPolylineProperties";

const createDirectionalPolylineSegment = (
  status: SegmentStatus,
  positions: LatLngTuple[][],
  arrowSegmentSize: number,
  routeSegmentsStatus: SegmentStatus[]
) =>
  positions
    .filter((_, index) => routeSegmentsStatus[index] === status)
    .map((positions, index) => (
      <DirectionalPolylineSegment
        key={`${status}${index}`}
        positions={positions}
        arrowSegmentSize={arrowSegmentSize}
        color={StatusColorsNameMap[status]}
        opacity={StatusOpacityNameMap[status]}
      />
    ));

interface Props {
  positions?: LatLngTuple[][];
  arrowSegmentSize?: number;
  routeSegmentsStatus: SegmentStatus[];
}

export function DirectionalPolyline({ positions = [], arrowSegmentSize = 9, routeSegmentsStatus }: Props) {
  return (
    <>
      {SegmentsStatusSequence.map((status) =>
        createDirectionalPolylineSegment(status, positions, arrowSegmentSize, routeSegmentsStatus)
      )}
    </>
  );
}
