import React, { ReactElement } from "react";
import { Fleet } from "shared/types";
import { SegmentLocationMarker } from "../../../common";

interface Props {
  children?: string | React.ReactNode;
  segment: Fleet.Segment;
  isActive?: boolean;
}

export function HudSegmentLocationMarker({ children, segment, isActive }: Props): ReactElement {
  return (
    <SegmentLocationMarker
      location={segment.stop.location}
      iconStyles={{ fontSize: "18px", marginLeft: "2px", marginTop: "1px" }}
      isActive={isActive}
    >
      {children}
    </SegmentLocationMarker>
  );
}
