import { LatLngBoundsLiteral } from "leaflet";
import { createSelector } from "reselect";

import { StoreShape } from "../store";

export const getFetching = (state: StoreShape) => state.planner.isFetching;
export const getFilters = (state: StoreShape) => state.planner.filters;
export const getFleetPlan = (state: StoreShape) => state.planner.fleetPlan;
export const getEntities = (state: StoreShape) => state.planner.entities;

export const getVehicleEntities = createSelector(getEntities, (entities) => entities?.vehicles ?? {});
export const getStopEntities = createSelector(getEntities, (entities) => entities?.stops ?? {});

export const getFleetPlanVehicle = (state: StoreShape, props: { vehicleId: string }) =>
  state.planner.entities?.vehicles[props.vehicleId];
export const getFleetPlanStop = (state: StoreShape, props: { stopId: string }) =>
  state.planner.entities?.stops[props.stopId];

export const getFleetPlanVehicleRoute = createSelector(
  getFleetPlanVehicle,
  (vehicle) => vehicle?.route ?? []
);
export const getFleetPlanVehicleIds = createSelector(
  getFleetPlan,
  (fleetPlan) => fleetPlan?.vehicleIds ?? []
);
export const getFleetPlanStopIds = createSelector(getFleetPlan, (fleetPlan) => fleetPlan?.stopIds ?? []);
export const getFilteredVehicle = createSelector(getFilters, getVehicleEntities, (filters, vehicles) =>
  filters.vehicleId && vehicles ? vehicles[filters.vehicleId] : null
);

export const getFleetPlanBounds = createSelector(
  getFleetPlanStopIds,
  getStopEntities,
  getFleetPlanVehicleIds,
  getVehicleEntities,
  (stopIds, stops, vehicleIds, vehicles) => {
    const stopBounds = stopIds.map((id) => stops[id].latLng);
    const vehicleBounds = vehicleIds.flatMap((id) => vehicles[id].route);
    return stopBounds.concat(vehicleBounds) as LatLngBoundsLiteral;
  }
);
