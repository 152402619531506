import { Route } from "react-router-dom";

import { LogoutCallback } from "./Authentication/LogoutCallback";
import { AppUrls } from "shared/constants";
import { LoginCallback } from "./Authentication/LoginCallback";
import { SilentRenew } from "./Authentication/SilentRenew";
import { Unauthorized } from "./Authentication/Unauthorized";

interface PublicPage extends React.ComponentProps<typeof Route> {
  path: string;
}

export const publicPages: PublicPage[] = [
  { path: AppUrls.Login, component: LogoutCallback },
  { path: AppUrls.Unauthorized, component: Unauthorized },
  { path: AppUrls.LoginCallback, component: LoginCallback, exact: true },
  { path: AppUrls.SilentRefresh, component: SilentRenew, exact: true },
];
