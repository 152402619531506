import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { hideNotification } from "shared/state/actions";
import { globalSelectors } from "shared/state/selectors";
import { StoreShape } from "shared/state";
import styles from "./NotificationsArea.module.css";
import { Dispatch } from "redux";
import { SystemNotificationPositions, SystemNotification } from "shared/types/Common";
import classNames from "classnames";

interface StoreProps {
  notificationQueue: ReturnType<typeof globalSelectors.getNotificationsQueue>;
  notificationShowDuration: ReturnType<typeof globalSelectors.getNotificationShowDuration>;
}

interface DispatchProps {
  dismissNotification(guid: string): void;
}

type Props = StoreProps & DispatchProps;

class NotificationsAreaView extends React.Component<Props> {
  private timers: { [key: string]: number } = {};
  public render = (): ReactElement => {
    const { notificationQueue, notificationShowDuration } = this.props;
    const notificationsMap: Record<SystemNotificationPositions, SystemNotification[]> = {
      [SystemNotificationPositions.TOP_RIGHT]: [],
      [SystemNotificationPositions.TOP_CENTER]: [],
    };
    for (let i = 0; i < notificationQueue.length; i++) {
      const notification = notificationQueue[i];
      notificationsMap[notification.position].push(notification);
    }
    return (
      <>
        <TransitionGroup
          className={classNames(
            "position-fixed d-flex flex-column",
            styles.notificationContainer,
            styles.topRight
          )}
        >
          {this.mapNotifications(
            notificationsMap[SystemNotificationPositions.TOP_RIGHT],
            notificationShowDuration
          )}
        </TransitionGroup>
        <TransitionGroup
          className={classNames(
            "position-fixed d-flex flex-column",
            styles.notificationContainer,
            styles.topCenter
          )}
        >
          {this.mapNotifications(
            notificationsMap[SystemNotificationPositions.TOP_CENTER],
            notificationShowDuration
          )}
        </TransitionGroup>
      </>
    );
  };
  private dismissMessage = (guid: string) => {
    return () => {
      clearTimeout(this.timers[this.getTimerName(guid)]);
      this.props.dismissNotification(guid);
    };
  };
  private startSelfDestructTimer = (guid: string): (() => void) => {
    const { dismissNotification, notificationShowDuration } = this.props;
    return () => {
      this.timers[this.getTimerName(guid)] = window.setTimeout(() => {
        dismissNotification(guid);
      }, notificationShowDuration);
    };
  };
  private getTimerName = (guid: string): string => `${guid}Timer`;
  private mapNotifications = (notifications: SystemNotification[], notificationShowDuration?: number) =>
    notifications.map(({ guid, message, color, disableAutoHide }) => (
      <CSSTransition
        data-test-id="CSSTransitionNotification"
        key={guid}
        timeout={500}
        classNames={{
          enter: styles.notificationEnter,
          enterActive: styles.notificationEnterActive,
          exit: styles.notificationExit,
          exitActive: styles.notificationExitActive,
        }}
        onEntered={
          notificationShowDuration && !disableAutoHide ? this.startSelfDestructTimer(guid) : undefined
        }
      >
        <div className={`alert alert-${color} alert-dismissible fade show`} role="alert">
          {message}
          <button type="button" className="close" onClick={this.dismissMessage(guid)} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </CSSTransition>
    ));
}

const mapStateToProps = (state: StoreShape): StoreProps => ({
  notificationQueue: globalSelectors.getNotificationsQueue(state) || [],
  notificationShowDuration: globalSelectors.getNotificationShowDuration(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dismissNotification: (guid: string) => dispatch(hideNotification({ guid })),
});

export const NotificationsArea = connect(mapStateToProps, mapDispatchToProps)(NotificationsAreaView);
