import { Dispatch } from "redux";
import { connect } from "react-redux";

import { ResultsFilter, DispatchProps, StateProps } from "./ResultsFilter";
import { StoreShape } from "shared/state";
import { plannerSelectors } from "shared/state/selectors";
import { setProcessingFilters } from "shared/state/actions";

const mapStateToProps = (state: StoreShape): StateProps => ({
  label: "Vehicle",
  itemIds: plannerSelectors.getFleetPlanVehicleIds(state),
  itemEntities: plannerSelectors.getVehicleEntities(state),
  filteredItemId: plannerSelectors.getFilters(state).vehicleId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setFilter: (newValue?: string) => dispatch(setProcessingFilters({ vehicleId: newValue })),
});

export const ResultsVehicleFilter = connect<StateProps, DispatchProps, Record<string, unknown>, StoreShape>(
  mapStateToProps,
  mapDispatchToProps
)(ResultsFilter);
