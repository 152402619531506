import { User } from "../types";
import axios, { AxiosResponse } from "axios";
import { UserUrls } from "../constants";
import { ApiClient } from "./base";
import { store } from "shared/state";
import { getChosenRealmApiUrl } from "shared/state/selectors/global";

export class UserApiClient extends ApiClient {
  public static async login(request: User.LoginRequest): Promise<AxiosResponse<User.LoginResponse>> {
    return await axios.post<User.LoginRequest, AxiosResponse<User.LoginResponse>>(UserUrls.Login, request);
  }

  public static async register(request: User.RegisterRequest): Promise<AxiosResponse<User.LoginResponse>> {
    return await axios.post<User.RegisterRequest, AxiosResponse<User.LoginResponse>>(
      UserUrls.Register,
      request,
      {
        baseURL: getChosenRealmApiUrl(store.getState()),
      }
    );
  }

  public static async refreshUserInfo(token: string): Promise<AxiosResponse<void>> {
    return await axios.post<void, AxiosResponse>(
      UserUrls.UpdateUserData,
      null,
      this.addTokenWithProvider(() => token)
    );
  }
}
