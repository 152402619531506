import { Fleet } from "shared/types";

export function ComputeVisibleSegments(
  activeSegments: Fleet.Segment[],
  activeIndex: number
): [Fleet.Segment[], number] {
  let filteredSegments: Fleet.Segment[] = activeSegments;
  let filteredIndex: number = activeIndex;

  if (filteredSegments.length < 2) {
    return [filteredSegments, filteredIndex];
  }

  if (filteredSegments.length === 2) {
    const areTwoStopsTheSame = activeSegments[0].stop.stopId === activeSegments[1].stop.stopId;
    if (activeIndex === 0) {
      if (areTwoStopsTheSame) {
        return [[activeSegments[0]], 0];
      }
    } else {
      if (areTwoStopsTheSame) {
        return [[activeSegments[1]], 0];
      }
    }
  }

  //if the first segment is selected
  if (activeIndex === 0) {
    //and first and second segment are the same segments
    if (activeSegments[0].stop.stopId === activeSegments[1].stop.stopId) {
      //we're showing first segment and third as the second will be overwritten by the first one
      filteredSegments = [activeSegments[0], activeSegments[activeSegments.length - 1]];
      filteredIndex = 0;
    }
  }

  //if the second segment is selected
  if (activeIndex === 1) {
    //and first and second segment are the same segments
    if (activeSegments[0].stop.stopId === activeSegments[1].stop.stopId) {
      //we're showing second segment and third as the first will be overwritten by the second one
      filteredSegments = [activeSegments[1], activeSegments[activeSegments.length - 1]];
      filteredIndex = 0;
    }
  }

  //if the middle segment is selected
  if (activeIndex === activeSegments.length - 2) {
    //and the middle and the last segment are the same segments
    if (
      activeSegments[activeSegments.length - 2].stop.stopId ===
      activeSegments[activeSegments.length - 1].stop.stopId
    ) {
      //we're showing first segment and middle as the third will be overwritten by the middle one
      filteredSegments = [
        activeSegments[activeSegments.length - 3],
        activeSegments[activeSegments.length - 2],
      ];
      filteredIndex = 1;
    }
  }

  //if the last segment is selected
  if (activeIndex === activeSegments.length - 1) {
    //and the middle and the last segment are the same segments
    if (
      activeSegments[activeSegments.length - 2].stop.stopId ===
      activeSegments[activeSegments.length - 1].stop.stopId
    ) {
      //we're showing first segment and last as the middle will be overwritten by the last one
      filteredSegments = [
        activeSegments[activeSegments.length - 3],
        activeSegments[activeSegments.length - 1],
      ];
      filteredIndex = 1;
    }
  }

  return [filteredSegments, filteredIndex];
}
