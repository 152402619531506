import { useEffect, useState, useMemo, useCallback } from "react";
import { Formik } from "formik";
import { Modal, Form as BSForm, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LatLngBounds } from "leaflet";
import { v4 as uuid } from "uuid";

import { forbiddenTransitionSchemaFactory } from "./schema";
import { ForbiddenTransition, StopType } from "../../../shared/types/Fleet";
import { Fleet } from "shared/types";
import { StopSearchInput } from "../Vehicles/StopSearchInput/StopSearchInput";
import styles from "../PlannerForm/EntityForms/Form.module.css";
import { Map } from "../../common";
import { LocationMarker } from "../PlannerForm/LocationMarker";

interface Props {
  initialForbiddenTransition: ForbiddenTransition;
  stops: Fleet.Stop[];
  onForbiddenTransitionSave: (newForbiddenTransition: ForbiddenTransition) => Promise<void>;
  isForbiddenTransitionUnique: (newForbiddenTransition: ForbiddenTransition) => boolean;
}

const defaultStop: Fleet.Stop = {
  stopId: "",
  name: "",
  city: "",
  identifier: "",
  street: "",
  location: {
    lat: 0,
    lng: 0,
  },
  type: StopType.All,
  imagesUrls: [],
};

const defaultMapProps = {
  key: uuid(),
  height: 200,
};

function getMapProps(stop?: Fleet.Stop) {
  if (stop && stop.location.lat !== 0 && stop.location.lng !== 0) {
    return {
      ...defaultMapProps,
      key: Object.values(stop.name).join(),
      bounds: new LatLngBounds([[stop.location.lat, stop.location.lng]]),
      children: (
        <LocationMarker
          latitude={stop.location.lat}
          longitude={stop.location.lng}
          name={stop.name}
          color="green"
        />
      ),
    };
  }
  return defaultMapProps;
}

export function Form({
  initialForbiddenTransition,
  onForbiddenTransitionSave,
  stops,
  isForbiddenTransitionUnique,
}: Props) {
  const { t } = useTranslation("forbiddenTransitions");
  const forbiddenTransitionSchema = useMemo(() => forbiddenTransitionSchemaFactory(t), [t]);
  const [sourceStop, setSourceStop] = useState<Fleet.Stop>();
  const [destinationStop, setDestinationStop] = useState<Fleet.Stop>();

  const [valueInMemorySourceStop, setValueInMemorySourceStop] = useState<{
    previousValue: string;
    currentValue: string;
  }>({ previousValue: "", currentValue: "" });
  const [valueInMemoryDestinationStop, setValueInMemoryDestinationStop] = useState<{
    previousValue: string;
    currentValue: string;
  }>({ previousValue: "", currentValue: "" });

  const getStopById = useCallback(
    (stopId: string) => stops.find((s) => s.stopId === stopId) ?? defaultStop,
    [stops]
  );

  const getStopName = useCallback(
    (stopId: string) => stops.find((s) => s.stopId === stopId)?.name ?? "",
    [stops]
  );

  useEffect(() => {
    setSourceStop(getStopById(initialForbiddenTransition.sourceStopId));
    setDestinationStop(getStopById(initialForbiddenTransition.destinationStopId));
  }, [initialForbiddenTransition.destinationStopId, initialForbiddenTransition.sourceStopId, getStopById]);

  return (
    <Formik
      initialValues={initialForbiddenTransition}
      validationSchema={forbiddenTransitionSchema}
      onSubmit={async (values, formikBag) => {
        formikBag.setSubmitting(true);
        await onForbiddenTransitionSave(values);
      }}
    >
      {({ setFieldValue, handleSubmit, setFieldTouched, values, errors, touched, isSubmitting }) => (
        <BSForm noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("form.add")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <BSForm.Row>
              <BSForm.Group as={Col} md="5">
                <Map {...getMapProps(sourceStop)} />
              </BSForm.Group>
              <BSForm.Group as={Col} md="7">
                <BSForm.Label>{t("form.sourceStop")}</BSForm.Label>
                <div className="float-right">
                  <i>
                    (lat: {sourceStop?.location.lat}, lng: {sourceStop?.location.lng})
                  </i>
                </div>
                <div className={styles.info}>
                  {valueInMemorySourceStop.previousValue
                    ? `${t("previousValue")}: ${valueInMemorySourceStop.previousValue}`
                    : null}
                </div>
                <StopSearchInput
                  placeholder={t("form.placeholderSourceStop")}
                  currentValue={getStopName(values.sourceStopId)}
                  addValueToMemory={(newValue) => {
                    setValueInMemorySourceStop({
                      previousValue: valueInMemorySourceStop.currentValue,
                      currentValue: newValue,
                    });
                  }}
                  onStopSelected={(stop: Fleet.Stop) => {
                    setSourceStop(stop);
                    setFieldValue("sourceStopId", stop.stopId, true);
                    setFieldTouched("sourceStopId", true, false);
                  }}
                  isInvalid={!!errors?.sourceStopId && !!touched.sourceStopId}
                  stops={stops}
                  alwaysRenderSuggestions
                />
                <BSForm.Control.Feedback type="invalid" className="d-block">
                  {touched.sourceStopId ? errors?.sourceStopId : null}
                </BSForm.Control.Feedback>
              </BSForm.Group>
            </BSForm.Row>
            <hr></hr>
            <BSForm.Row>
              <BSForm.Group as={Col} md="5">
                <Map {...getMapProps(destinationStop)} />
              </BSForm.Group>
              <BSForm.Group as={Col} md="7">
                <BSForm.Label>{t("form.destinationStop")}</BSForm.Label>
                <div className="float-right">
                  <i>
                    (lat: {destinationStop?.location.lat}, lng: {destinationStop?.location.lng})
                  </i>
                </div>
                <div className={styles.info}>
                  {valueInMemoryDestinationStop.previousValue
                    ? `${t("previousValue")}: ${valueInMemoryDestinationStop.previousValue}`
                    : null}
                </div>
                <StopSearchInput
                  placeholder={t("form.placeholderDestinationStop")}
                  currentValue={getStopName(values.destinationStopId)}
                  addValueToMemory={(newValue) => {
                    setValueInMemoryDestinationStop({
                      previousValue: valueInMemoryDestinationStop.currentValue,
                      currentValue: newValue,
                    });
                  }}
                  onStopSelected={(stop: Fleet.Stop) => {
                    setDestinationStop(stop);
                    setFieldValue("destinationStopId", stop.stopId, true);
                    setFieldTouched("destinationStopId", true, false);
                  }}
                  isInvalid={!!errors?.destinationStopId && touched.destinationStopId}
                  stops={stops}
                  alwaysRenderSuggestions
                />
                <BSForm.Control.Feedback type="invalid" className="d-block">
                  {touched.destinationStopId ? errors?.destinationStopId : null}
                </BSForm.Control.Feedback>
              </BSForm.Group>
            </BSForm.Row>
            {!isForbiddenTransitionUnique(values) ? (
              <>
                <hr></hr>
                <BSForm.Row>
                  <BSForm.Group as={Col} md="12">
                    <div className={styles.invalid}>{t("validation.uniqueForbiddenTransition")}</div>
                  </BSForm.Group>
                </BSForm.Row>
              </>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={!isForbiddenTransitionUnique(values) || isSubmitting}>
              {t("form.submit")}
            </Button>
          </Modal.Footer>
        </BSForm>
      )}
    </Formik>
  );
}
