import { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import { AppUrls, Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";

export function HomePage(): ReactElement | null {
  const authService = useAuthService();
  return authService.userHasRole(Role.ADMIN) ? (
    <Redirect to={AppUrls.Missions} />
  ) : authService.userHasRole(Role.DISPATCHER) ? (
    <Redirect to={AppUrls.Rides} />
  ) : authService.userHasRole(Role.DRIVER) ? (
    <Redirect to={AppUrls.DriverRides} />
  ) : null;
}
