import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";

interface Props {
  toggleSidebar(): void;
}

export function NavbarLeft({ toggleSidebar }: Props): ReactElement {
  const { t } = useTranslation();
  const authService = useAuthService();
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <div data-cy="sidebarToggle" role="button" className="nav-link" onClick={toggleSidebar}>
          <i className="fas fa-bars" />
        </div>
      </li>
      <li className="nav-item d-inline-block">
        {authService.userHasRole(Role.ADMIN) ? (
          <>
            <div className="nav-link d-none d-md-inline-block disabled text-white">{t("adminHeader")}</div>
            <div className="nav-link d-inline-block d-md-none disabled text-white">
              {t("adminHeaderShort")}
            </div>
          </>
        ) : authService.userHasRole(Role.DISPATCHER) ? (
          <>
            <div className="nav-link d-none d-md-inline-block disabled text-white">
              {t("dispatcherHeader")}
            </div>
            <div className="nav-link d-inline-block d-md-none disabled text-white">
              {t("dispatcherHeaderShort")}
            </div>
          </>
        ) : authService.userHasRole(Role.DRIVER) ? (
          <>
            <div className="nav-link d-none d-md-inline-block disabled text-white">{t("driverHeader")}</div>
            <div className="nav-link d-inline-block d-md-none disabled text-white">
              {t("driverHeaderShort")}
            </div>
          </>
        ) : null}
      </li>
    </ul>
  );
}
