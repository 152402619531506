import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.PassengersAndRideDataSource[];
}

export function RideRequestsChart({ dataSource }: Props) {
  const { t } = useTranslation(["reports"]);
  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
      },
      xaxis: {
        categories: dataSource.map((d) => new Date(d.date).toLocaleDateString()),
        labels: {
          rotateAlways: true,
          rotate: -45,
        },
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        labels: {
          useSeriesColors: false,
        },
        markers: {
          fillColors: ["#6f42c1ff", "#008ffb", "#00e396ff", "#ffc107ff", "#dc3545ff"],
        },
      },
      fill: {
        opacity: 1,
        colors: ["#6f42c1ff", "#008ffb", "#00e396ff", "#ffc107ff", "#dc3545ff"],
      },
    }),
    [dataSource]
  );

  const series = useMemo(
    () => [
      {
        name: t("charts.rideRequests.seriesName.passengersRequestRejected"),
        data: dataSource.map((d) => d.passengersRequestRejectedCount),
      },
      {
        name: t("charts.rideRequests.seriesName.timeOutPropositionRides"),
        data: dataSource.map((d) => d.timeOutPropositionRidesCount),
      },
      {
        name: t("charts.rideRequests.seriesName.cancelledRides"),
        data: dataSource.map((d) => d.cancelledRidesCount),
      },
      {
        name: t("charts.rideRequests.seriesName.deliveredPassengers"),
        data: dataSource.map((d) => d.deliveredPassengersCount),
      },
      {
        name: t("charts.rideRequests.seriesName.absentPassengers"),
        data: dataSource.map((d) => d.absentPassengersCount),
      },
    ],
    [dataSource, t]
  );

  return <ReactApexChart options={options} series={series} type="bar" height={400} />;
}
