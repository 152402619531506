import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Col, Form, ListGroup, Row, Table } from "react-bootstrap";
import { FieldArrayRenderProps, FormikErrors, FormikProps } from "formik";
import { DateTransforms } from "shared/mappers";
import { FormValues } from "./schema";
import { WeekdaysInput } from "./WeekdaysInput";
import styles from "../VehicleAvailability.module.css";
import { useTranslation } from "react-i18next";

interface FormPatternSlot {
  duration: Date;
  timeStart: Date;
}

const createSlotId = (slot: FormPatternSlot, index: number) =>
  `${slot.timeStart.toString()}.${slot.duration.toString()}.${index}`;

export function PatternsInput({ form, replace }: FieldArrayRenderProps): React.ReactElement {
  const formProp = form as FormikProps<FormValues>;
  const { t } = useTranslation("vehicle/availability");
  const [newSlot, setNewSlot] = useState<Partial<FormPatternSlot>>();
  return (
    <div className="mt-2">
      {formProp.values.pattern.map((pattern, patternIndex) => (
        <div className="position-relative" key={patternIndex}>
          <h6 className={styles.patternIdentifier}>
            {t("form.patternsInput.patternHeader", { index: patternIndex + 1 })}
          </h6>
          <ListGroup className="w-100">
            <ListGroup.Item className="pt-3">
              <Row>
                <Col sm="8">
                  <h6>{t("form.patternsInput.slotsHeader")}</h6>
                  <Table striped bordered size="sm" className="mb-2">
                    <thead>
                      <tr>
                        <th>{t("form.patternsInput.slotsStart")}</th>
                        <th>{t("form.patternsInput.slotsEnd")}</th>
                        <th colSpan={2}>{t("form.patternsInput.slotsDuration")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pattern.slots.length <= 0 && (
                        <tr>
                          <td colSpan={4} className="text-center">
                            {t("form.patternsInput.slotsNoData")}
                          </td>
                        </tr>
                      )}
                      {pattern.slots.map((slot, slotIndex) => (
                        <tr key={createSlotId(slot, slotIndex)}>
                          <td>{DateTransforms.toFullTimeFormat(slot.timeStart)}</td>
                          <td>
                            {DateTransforms.toFullTimeFormat(
                              DateTransforms.addTimePortion(slot.timeStart, slot.duration)
                            )}
                          </td>
                          <td className="border-right-0">{DateTransforms.toFullTimeFormat(slot.duration)}</td>
                          <td className="text-right border-left-0 pr-2">
                            <Button
                              variant="outline-danger"
                              size={"xs" as "sm"}
                              onClick={() => {
                                replace(patternIndex, {
                                  ...pattern,
                                  slots: pattern.slots.filter(
                                    (s, currSlotIndex) =>
                                      createSlotId(s, currSlotIndex) !== createSlotId(slot, slotIndex)
                                  ),
                                });
                              }}
                            >
                              <i className="mr-1 fas fa-minus" /> {t("general:delete")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Form.Control.Feedback type="invalid" className="d-block mb-1">
                    {formProp.touched.pattern &&
                      Array.isArray(formProp.errors.pattern) &&
                      typeof formProp.errors.pattern[patternIndex] !== "string" &&
                      (
                        formProp.errors.pattern[patternIndex] as FormikErrors<{
                          slots: Record<string, unknown>[];
                        }>
                      ).slots}
                  </Form.Control.Feedback>
                  <div className={styles.patternInputRow}>
                    <div className="d-flex">
                      <DatePicker
                        className="form-control form-control-sm"
                        placeholderText="Czas rozpoczęcia"
                        selected={newSlot?.timeStart}
                        onChange={(value) => {
                          if (!Array.isArray(value)) {
                            if (value) {
                              value.setMilliseconds(0);
                            }
                            setNewSlot((slot) => ({ ...slot, timeStart: value ?? undefined }));
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption=""
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        openToDate={new Date(0, 0, 0, 0, 0, 0)}
                      />
                      <DatePicker
                        className="ml-1 form-control form-control-sm"
                        placeholderText="Czas trwania"
                        selected={newSlot?.duration}
                        onChange={(value) => {
                          if (!Array.isArray(value)) {
                            if (value) {
                              value.setMilliseconds(0);
                            }
                            setNewSlot((slot) => ({ ...slot, duration: value ?? undefined }));
                          }
                        }}
                        timeCaption=""
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        openToDate={new Date(0, 0, 0, 0, 0, 0)}
                      />
                    </div>
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() => {
                        if (newSlot && newSlot.duration && newSlot.timeStart) {
                          replace(patternIndex, {
                            ...pattern,
                            slots: [...pattern.slots, newSlot as FormPatternSlot],
                          });
                          setNewSlot(undefined);
                        }
                      }}
                    >
                      <i className="mr-1 fas fa-plus" /> {t("general:add")}
                    </Button>
                  </div>
                </Col>
                <Col sm="4">
                  <h6>{t("form.patternsInput.weekDaysHeader")}</h6>
                  <WeekdaysInput
                    value={pattern.weekDays}
                    onChange={(weekDays) => {
                      replace(patternIndex, {
                        ...pattern,
                        weekDays,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block mb-1">
                    {formProp.touched.pattern &&
                      Array.isArray(formProp.errors.pattern) &&
                      typeof formProp.errors.pattern[patternIndex] !== "string" &&
                      (
                        formProp.errors.pattern[patternIndex] as FormikErrors<{
                          weekDays: Record<string, unknown>[];
                        }>
                      ).weekDays}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div>
      ))}
      <Form.Control.Feedback type="invalid" className="d-block">
        {formProp.touched.pattern && !Array.isArray(formProp.errors.pattern) && formProp.errors.pattern}
      </Form.Control.Feedback>
    </div>
  );
}
