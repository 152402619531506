import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import React from "react";
import { ENV } from "./constants/env";
import { Role, AppUrls } from "./constants";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { store } from "./state";
import { loginSuccess, logout } from "./state/actions";
import { LoginResponse } from "./types/User";
import {
  getChosenRealmApiUrl,
  getChosenRealmId,
  getIdToken,
  getUserData,
  getChosenRealmName,
} from "./state/selectors/global";

interface UserProfile {
  s_hash: string;
  sid: string;
  sub: string;
  auth_time: number;
  idp: string;
  amr: string[];
  name: string;
  username: string;
  access_roles: {
    global: Role[];
    realms: {
      [id: string]: Role[];
    };
  };
  email: string;
}

interface StoredState {
  id_token: string;
  session_state: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
  scope: string;
  profile: UserProfile;
  expires_at: number;
  stored_realm: string;
}

export interface UserRealm {
  apiUrl: string;
  description: string;
  id: string;
  name: string;
  roles: string[];
  type: RealmType;
}

export enum RealmType {
  Public = 1,
  Private = 2,
}

interface SmartDriveResponse<T> {
  correlationId: string;
  data: T;
  errors: string[];
}

const REDIRECT_URI_KEY = "redirectUri";
const GLOBALLY_ALLOWED_ROLES = [Role.ADMIN, Role.DISPATCHER, Role.DRIVER];

class AuthServiceProvider {
  private UserManager: UserManager;
  private identityConfig: UserManagerSettings;
  private authIssuer: string;

  constructor() {
    const publicUrl = window.location.origin;
    const hostname = window.location.hostname;
    const authIssuer = ENV.AUTH_ISSUER
      .replace("<<HOST_ADDRESS>>", publicUrl)
      .replace("<<HOSTNAME>>", hostname);
    this.authIssuer = authIssuer;
    this.identityConfig = {
      authority: authIssuer,
      client_id: "UI-Dispatchers-Panel",
      redirect_uri: publicUrl + ENV.BASE_PATH + AppUrls.LoginCallback,
      silent_redirect_uri: publicUrl + ENV.BASE_PATH + AppUrls.SilentRefresh,
      post_logout_redirect_uri: publicUrl + ENV.BASE_PATH + AppUrls.Login,
      response_type: "code",
      response_mode: "query",
      automaticSilentRenew: true,
      loadUserInfo: true,
      scope: "openid profile email offline_access username user_roles fleet_manager_api sso_api",
    };

    this.UserManager = new UserManager({
      ...this.identityConfig,
      stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });
    this.UserManager.events.addUserLoaded((user) => {
      store.dispatch(loginSuccess(user as unknown as LoginResponse));
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.warn("silent renew error", e.message, new Date());
    });
    this.UserManager.events.addAccessTokenExpired(() => {
      console.warn("token expired", new Date());
    });
  }

  signinRedirectCallback = () => {
    return this.UserManager.signinRedirectCallback().catch(exc => {
      console.error(exc);
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  getUserProfile = () => {
    const storedState = this.getStoredState();
    return storedState?.profile;
  };

  async getRealms(): Promise<UserRealm[]> {
    const token = this.getToken() ?? "";
    const response = await axios.get<SmartDriveResponse<UserRealm[]>>(
      `${this.authIssuer}/user/realms?roles=${Role.ADMIN}&roles=${Role.DISPATCHER}&roles=${Role.DRIVER}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return new Promise<UserRealm[]>((resolve) => {
      resolve(response.data.data);
    });
  }

  getToken = () => {
    const storedState = this.getStoredState();
    return storedState?.access_token;
  };

  getChosenRealmId = () => {
    return getChosenRealmId(store.getState());
  };

  getChosenRealmApiUrl = () => {
    return getChosenRealmApiUrl(store.getState());
  };

  getChosenRealmName = () => {
    return getChosenRealmName(store.getState());
  };

  signinRedirect = () => {
    localStorage.setItem(REDIRECT_URI_KEY, window.location.pathname + window.location.search);
    void this.UserManager.signinRedirect({});
  };

  getPageBeforeRedirect = () => {
    return (localStorage.getItem(REDIRECT_URI_KEY) as string) ?? "/";
  };

  isAuthenticated = () => {
    const storedState = this.getStoredState();
    return !!storedState && !!storedState.access_token;
  };

  hasChosenRealmId = () => {
    return !!this.getChosenRealmId();
  };

  userCanUseApp = () => {
    return this.userHasOneOfRoles(GLOBALLY_ALLOWED_ROLES);
  };

  userHasRole = (role: Role) => {
    return this.userHasOneOfRoles([role]);
  };

  saveRedirectUri() {
    localStorage.setItem(REDIRECT_URI_KEY, window.location.pathname + window.location.search);
  }

  userHasOneOfRoles = (requiredRoles: Role[] = []) => {
    const storedState = this.getStoredState();
    const token: {
      access_roles: {
        global: Role[];
        realms: {
          [id: string]: Role[];
        };
      };
    } = jwt_decode(storedState?.access_token ?? "");
    const realm = getChosenRealmId(store.getState()) ?? "";

    if (!realm) {
      return false;
    }

    return (
      this.isAuthenticated() &&
      (requiredRoles.length <= 0 ||
        token.access_roles.global.some((role) => requiredRoles.includes(role)) ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (token.access_roles.realms[realm] &&
          token.access_roles.realms[realm].some((role) => requiredRoles.includes(role))))
    );
  };

  signinSilentCallback = () => {
    void this.UserManager.signinSilentCallback();
  };

  logout = () => {
    const idToken = getIdToken(store.getState());
    store.dispatch(logout());
    void this.UserManager.signoutRedirect({
      id_token_hint: idToken,
    });
    void this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    void this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
    });
    void this.UserManager.clearStaleState();
  };

  private getStoredState(): StoredState | undefined {
    return getUserData(store.getState()) as unknown as StoredState;
  }
}

export const AuthContext = React.createContext<typeof AuthService | null>(null);
export const { Consumer: AuthConsumer } = AuthContext;
export const AuthService = new AuthServiceProvider();
