import { renderToStaticMarkup } from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { divIcon } from "leaflet";

import { Common } from "shared/types";
import styles from "./LocationMarker.module.css";

interface Props extends Common.Address {
  color?: string;
}

export const LocationMarker = ({ latitude, longitude, name, color }: Props) => (
  <Marker
    icon={divIcon({
      html: renderToStaticMarkup(<div className={styles.marker} style={{ backgroundColor: color }} />),
      iconSize: [13, 13],
    })}
    position={[latitude, longitude]}
  >
    <Tooltip>{name}</Tooltip>
  </Marker>
);
