import React, { useMemo, useCallback } from "react";
import { createMachine, state, transition, reduce } from "robot3";
import { useMachine } from "react-robot";
import { v4 as uuid } from "uuid";

import { Planner } from "shared/types";
import { EditPassengerForm } from "./EntityForms/EditPassengerForm";
import { AddPassengerForm } from "./EntityForms/AddPassengerForm";
import { EditVehicleForm } from "./EntityForms/EditVehicleForm";
import { AddVehicleForm } from "./EntityForms/AddVehicleForm";

const defaultPassengerRequestValue: Planner.PassengerRequest = {
  id: uuid(),
  name: "",
  phone: "",
  from: {
    name: "",
    latitude: 0,
    longitude: 0,
  },
  to: {
    name: "",
    latitude: 0,
    longitude: 0,
  },
  departureLeftMargin: 0,
  departureOptimalTime: "",
  departureRightMargin: 0,

  arrivalLeftMargin: 0,
  arrivalOptimalTime: "",
  arrivalRightMargin: 0,
  errors: {},
};

const defaultVehicle: Planner.Vehicle = {
  id: uuid(),
  number: "",
  startPosition: {
    name: "",
    latitude: 0,
    longitude: 0,
  },
  capacity: 1,
  errors: {},
};

interface Context {
  id?: string;
}

enum States {
  CLOSED = "closed",
  EDITING_PASSENGER = "editing-passenger",
  ADDING_PASSENGER = "adding-passenger",
  EDITING_VEHICLE = "editing-vehicle",
  ADDING_VEHICLE = "adding-vehicle",
}

enum Events {
  EDIT_PASSENGER = "edit-passenger",
  ADD_PASSENGER = "add-passenger",
  EDIT_VEHICLE = "edit-vehicle",
  ADD_VEHICLE = "add-vehicle",
  CLOSE = "close",
}

interface EditEvent {
  type: string;
  id: string;
}

const machine = createMachine(
  {
    [States.CLOSED]: state(
      transition(Events.ADD_PASSENGER, States.ADDING_PASSENGER),
      transition(
        Events.EDIT_PASSENGER,
        States.EDITING_PASSENGER,
        reduce((ctx: Context, ev: EditEvent) => ({ id: ev.id }))
      ),
      transition(Events.ADD_VEHICLE, States.ADDING_VEHICLE),
      transition(
        Events.EDIT_VEHICLE,
        States.EDITING_VEHICLE,
        reduce((ctx: Context, ev: EditEvent) => ({ id: ev.id }))
      )
    ),
    [States.ADDING_PASSENGER]: state(transition(Events.CLOSE, States.CLOSED)),
    [States.EDITING_PASSENGER]: state(transition(Events.CLOSE, States.CLOSED)),
    [States.ADDING_VEHICLE]: state(transition(Events.CLOSE, States.CLOSED)),
    [States.EDITING_VEHICLE]: state(transition(Events.CLOSE, States.CLOSED)),
  },
  (): Context => ({})
);

interface ModalHandlers {
  openPassengerEdit: (id: string) => void;
  openPassengerAdd: () => void;
  openVehicleEdit: (id: string) => void;
  openVehicleAdd: () => void;
}

interface Props {
  children: (handlers: ModalHandlers) => React.ReactNode;
  passengerRequests: Record<string, Planner.PassengerRequest>;
  vehicles: Record<string, Planner.Vehicle>;
  onPassengerUpsert: (newPassengerRequest: Planner.PassengerRequest) => void;
  onVehicleUpsert: (vehicle: Planner.Vehicle) => void;
}

export function TableModals({
  passengerRequests,
  vehicles,
  children,
  onPassengerUpsert,
  onVehicleUpsert,
}: Props) {
  const [current, send] = useMachine(machine);
  const handlers: ModalHandlers = useMemo(
    () => ({
      openPassengerEdit: (id) => {
        send({ type: Events.EDIT_PASSENGER, id });
      },
      openPassengerAdd: () => {
        send(Events.ADD_PASSENGER);
      },
      openVehicleEdit: (id) => {
        send({ type: Events.EDIT_VEHICLE, id });
      },
      openVehicleAdd: () => {
        send(Events.ADD_VEHICLE);
      },
    }),
    [send]
  );
  const handleClose = useCallback(() => {
    send(Events.CLOSE);
  }, [send]);
  switch (current.name) {
    case States.CLOSED:
      return <>{children(handlers)}</>;
    case States.EDITING_PASSENGER:
      if (!current.context.id) {
        throw new ReferenceError("Missing passenger id");
      }
      return (
        <>
          {children(handlers)}
          <EditPassengerForm
            onClose={handleClose}
            passengerRequest={passengerRequests[current.context.id]}
            saveFormData={onPassengerUpsert}
          />
        </>
      );
    case States.ADDING_PASSENGER:
      return (
        <>
          {children(handlers)}
          <AddPassengerForm
            onClose={handleClose}
            passengerRequest={{ ...defaultPassengerRequestValue, id: uuid() }}
            saveFormData={onPassengerUpsert}
          />
        </>
      );
    case States.EDITING_VEHICLE:
      if (!current.context.id) {
        throw new ReferenceError("Missing vehicle id");
      }
      return (
        <>
          {children(handlers)}
          <EditVehicleForm
            onClose={handleClose}
            vehicle={vehicles[current.context.id]}
            saveVehicle={onVehicleUpsert}
          />
        </>
      );
    case States.ADDING_VEHICLE:
      return (
        <>
          {children(handlers)}
          <AddVehicleForm
            onClose={handleClose}
            vehicle={{ ...defaultVehicle, id: uuid() }}
            saveVehicle={onVehicleUpsert}
          />
        </>
      );
    default:
      return null;
  }
}
