import { put, takeEvery, SimpleEffect, ForkEffect } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import { showNotification } from "../actions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatErrorNotification(mainError: any): string | JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!mainError?.response?.data) {
    return <p className="mb-0">Received invalid response from the server</p>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const { correlationId, type, userMessage, systemMessage, stackTrace } = mainError.response.data;

  if (process.env.NODE_ENV === "production") {
    return (
      <>
        <p className="mb-0">
          <strong>Correlation Id:</strong> {correlationId}
        </p>
        <p className="mb-0" style={{ maxWidth: "600px" }}>
          {userMessage || "Please contact us and provide Correlation Id."}
        </p>
      </>
    );
  }

  return (
    <>
      <p className="mb-0">
        <strong>CorrelationId:</strong> {correlationId}
      </p>
      <p className="mb-0" style={{ maxWidth: "600px" }}>
        <strong>User message:</strong> {userMessage || "Please contact us and provide Correlation Id."}
      </p>
      <p className="mb-0">
        <strong>{type}:</strong> {systemMessage}
      </p>
      <pre className="mb-0" style={{ maxWidth: "600px", maxHeight: "200px" }}>
        <code>{stackTrace}</code>
      </pre>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* errorNotificationSaga(action: Action<any>): Iterator<SimpleEffect<any, any>> {
  yield put(
    showNotification({
      color: "danger",
      message: (
        <div>
          <strong>Request failed</strong>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {formatErrorNotification(action.payload.error)}
        </div>
      ),
      disableAutoHide: true,
    })
  );
}

export function* errorWatcherSaga(): Iterator<ForkEffect> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  yield takeEvery((action: any) => action.error, errorNotificationSaga);
}
