import React, { useState } from "react";
import classNames from "classnames";

import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import styles from "./Wrapper.module.css";
import { NotificationsArea } from "../NotificationsArea";

interface OwnProps {
  children: React.ReactNode;
}

type Props = OwnProps;

function WrapperView({ children }: Props) {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const freshLoad = isSidebarOpen == null;
  const toggleSidebar = () => setSidebarOpen(!freshLoad && !isSidebarOpen);

  return (
    <div
      className={classNames("sidebar-mini layout-fixed", styles.wrapper, {
        "sidebar-open": !freshLoad && isSidebarOpen,
        "sidebar-collapse": !freshLoad && !isSidebarOpen,
      })}
    >
      <div className="wrapper">
        <Navbar toggleSidebar={toggleSidebar} />
        <Sidebar />
        {children}
        <div onClick={toggleSidebar} id="sidebar-overlay" />
        <NotificationsArea />
      </div>
    </div>
  );
}

export const Wrapper = WrapperView;
