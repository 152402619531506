import { ReactElement } from "react";
import { Fleet } from "shared/types";
import styles from "./Vehicle.module.css";

interface Props {
  vehicle: Fleet.Vehicle;
}

export function Vehicle({ vehicle }: Props): ReactElement {
  let title = vehicle.name;
  if (vehicle.licensePlate && vehicle.licensePlate.length > 0) {
    title += `(${vehicle.licensePlate})`;
  }
  return <div className={styles.vehicle}>{title}</div>;
}
