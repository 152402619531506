import classNames from "classnames";
import { CellProps } from "react-table";
import { Fleet } from "shared/types";
import { RideRequestSource } from "shared/types/Fleet";
import { UseTranslationResult } from "shared/types/Common";
import { useTranslation } from "react-i18next";
import styles from "../Rides.module.css";

const iconSourceClassMap = {
  [RideRequestSource.NoData]: "fa-question text-danger",
  [RideRequestSource.PassengerMobile]: "fa-mobile-alt text-success",
  [RideRequestSource.Operator]: "fa-desktop text-primary",
};

export function SourceIcon({ row }: CellProps<Fleet.RideOverviewListItemDto>) {
  const { t } = useTranslation("rides") as UseTranslationResult;
  return (
    <div className="text-center">
      <i
        title={t(`source.${row.original.source}`)}
        className={classNames("fas font-larger", styles.sourceIcon, iconSourceClassMap[row.original.source])}
      />
    </div>
  );
}
