import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "components";
import { Button } from "react-bootstrap";

export function NotFoundPage() {
  const { t } = useTranslation(["not-found"]);
  const history = useHistory();

  return (
    <Layout.Centered>
      <h4>{t("not-found:header")}</h4>
      <div className="mt-4 d-flex justify-content-center">
        <Button
          onClick={() => {
            history.push("/");
          }}
          variant="info"
        >
          {t("not-found:backButton")}
        </Button>
      </div>
    </Layout.Centered>
  );
}
