import { MapColors, MapOpacity } from "../../../common/DirectionalPolyline/mapColors";
import { SegmentStatus } from "../PlanSegmentsPanel/SegmentRow";

export const StatusColorsNameMap = {
  [SegmentStatus.UPCOMING]: MapColors.INACTIVE,
  [SegmentStatus.CURRENT]: MapColors.ACTIVE,
  [SegmentStatus.COMPLETED]: MapColors.ACTIVE,
  [SegmentStatus.CANCELLED]: MapColors.CANCELLED,
};

export const StatusOpacityNameMap = {
  [SegmentStatus.UPCOMING]: MapOpacity.ACTIVE,
  [SegmentStatus.CURRENT]: MapOpacity.ACTIVE,
  [SegmentStatus.COMPLETED]: MapOpacity.INACTIVE,
  [SegmentStatus.CANCELLED]: MapOpacity.ACTIVE,
};

export const SegmentsStatusSequence = [
  SegmentStatus.COMPLETED,
  SegmentStatus.UPCOMING,
  SegmentStatus.CURRENT,
  SegmentStatus.CANCELLED,
];
