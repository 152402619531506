import { createStore, applyMiddleware, compose, StoreEnhancer, PreloadedState } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootSaga from "./sagas";
import { User } from "shared/types";
import { Realm } from "./reducers/global/user";

export type StoreShape = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (...args: any) => {};
    Cypress?: Record<string, unknown>;
  }
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) as (
  ...params: unknown[]
) => StoreEnhancer<unknown, StoreShape>;
const middleware = applyMiddleware(sagaMiddleware);
let storeInstance = createStore(rootReducer, composeEnhancers(middleware));

if (window.Cypress) {
  const storedUserData = window.sessionStorage.getItem("oidc.loggedIn");
  const userData = JSON.parse(storedUserData ?? "{}") as User.LoginResponse;
  const storeRealm = window.sessionStorage.getItem("realm");
  const realm = JSON.parse(storeRealm ?? "{}") as Realm;
  const preloadedState = {
    global: {
      user: {
        userData,
        realm,
      },
    },
  } as PreloadedState<StoreShape>;
  storeInstance = createStore(rootReducer, preloadedState, composeEnhancers(middleware));
}

export const store = storeInstance;
export const storePersistor = persistStore(store);

sagaMiddleware.run(rootSaga);
