import { useMemo, ReactElement } from "react";
import { useTable, usePagination, CellProps } from "react-table";
import { Button, Card, Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Fleet, Common } from "shared/types";
import { Table as InnerTable } from "../../common";
import { Link } from "react-router-dom";
import { TFunction } from "i18next";
import { AppUrls } from "shared/constants";
import { PaginatedTableState } from "shared/types/Common";

function defaultSortFactory<T extends Fleet.Vehicle>(idGetter: (entity: T) => string) {
  return (a: T, b: T): number => ("" + idGetter(a)).localeCompare("" + idGetter(b));
}

const createSimpleColumns = (t: TFunction) => [
  { Header: "Id", accessor: "id" },
  { Header: t("table.header.name"), accessor: "name" },
  { Header: t("table.header.licensePlate"), accessor: "licensePlate" },
  { Header: t("table.header.capacity"), accessor: "capacity" },
];

interface Props {
  vehicles: Fleet.Vehicle[];
  onVehicleRemove: (vehicleId: string) => void;
  openVehicleEdit: (id: string) => void;
  openVehicleAdd: () => void;
  isLoading: boolean;
  stops: Fleet.Stop[];
}

export function Table({
  vehicles,
  onVehicleRemove,
  openVehicleEdit,
  openVehicleAdd,
  isLoading,
  stops,
}: Props): ReactElement {
  const { t } = useTranslation(["vehicle"]);

  const sortedVehicles = useMemo(
    () => vehicles.sort(defaultSortFactory((x: Fleet.Vehicle) => x.name)),
    [vehicles]
  );
  const vehicleColumns = useMemo(
    () => [
      ...createSimpleColumns(t),
      {
        id: "startStop",
        Header: t("table.header.startStop"),
        Cell: function Cell({ row }: CellProps<Fleet.Vehicle>) {
          return <div>{stops.find((s) => s.stopId === row.original.startPositionId)?.name}</div>;
        },
      },
      {
        id: "endStop",
        Header: t("table.header.endStop"),
        Cell: function Cell({ row }: CellProps<Fleet.Vehicle>) {
          return <div>{stops.find((s) => s.stopId === row.original.endPositionId)?.name}</div>;
        },
      },
      {
        id: "selection",
        Header: function Header() {
          return <div className="text-center">{t("table.header.actions")}</div>;
        },
        Cell: function Cell({ row }: CellProps<Fleet.Vehicle>) {
          return (
            <div className="dropdown-actions text-center">
              <DropdownButton
                id="actionsDropdown"
                size="sm"
                title={<i className="fas fa-ellipsis-h" />}
                variant="outline-primary"
              >
                <Dropdown.Item
                  as={Button}
                  title={t("general:edit")}
                  onClick={() => {
                    openVehicleEdit(row.original.id);
                  }}
                >
                  <i className="mr-1 fas fa-edit" /> {t("general:edit")}
                </Dropdown.Item>
                <Dropdown.Item
                  as={Button}
                  title={t("general:delete")}
                  onClick={() => {
                    if (window.confirm(`Czy na pewno chcesz usunąć pojazd ${row.original.name}?`)) {
                      onVehicleRemove(row.original.id);
                    }
                  }}
                >
                  <i className="mr-1 fas fa-trash" /> {t("general:delete")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={AppUrls.VehicleMission(row.original.id)}>
                  <i className="mr-1 fas fa-route" /> {t("table.actions.mission")}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={AppUrls.VehicleAvailability(row.original.id)}>
                  <i className="mr-1 fas fa-clock" /> {t("table.actions.availability")}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        },
      },
    ],
    [onVehicleRemove, openVehicleEdit, stops, t]
  );
  const table = useTable(
    {
      columns: vehicleColumns,
      data: sortedVehicles,
      initialState: { pageIndex: 0, pageSize: 10 } as PaginatedTableState<Fleet.Vehicle>,
    },
    usePagination
  ) as Common.TableWithPagination<Fleet.Vehicle>;
  return (
    <Card data-cy="viewFleetVehicleListCard">
      <InnerTable
        {...table}
        isLoading={isLoading}
        topRowContent={
          <Button size="sm" variant="success" onClick={openVehicleAdd}>
            <i className="fas fa-plus mr-1"></i> {t("table.actions.addVehicle")}
          </Button>
        }
      />
    </Card>
  );
}
