import { ReactElement, useState, useEffect, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Vehicles } from "components";
import { Fleet } from "shared/types";
import { FleetApiClient } from "shared/api";
import { v4 as uuid } from "uuid";
import actionCreatorFactory, { Action, isType } from "typescript-fsa";

const actionCreator = actionCreatorFactory();
export const VehicleModalActions = {
  showEdit: actionCreator<{ vehicle: Fleet.Vehicle }>("SHOW_EDIT"),
  showAdd: actionCreator<{ vehicle: Fleet.Vehicle }>("SHOW_ADD"),
  close: actionCreator<void>("CLOSE"),
};

type FormVehicle = Parameters<typeof FleetApiClient.vehicle.addVehicle>[0];

const defaultVehicle: Fleet.Vehicle = {
  name: "",
  licensePlate: "",
  capacity: 1,
  startPositionId: "",
  endPositionId: "",
  id: "",
};

function reducer(state: Fleet.VehicleModalState, action: Action<unknown>): Fleet.VehicleModalState {
  if (isType(action, VehicleModalActions.showAdd)) {
    return { ...state, isShown: true, initialFormValues: action.payload.vehicle };
  }
  if (isType(action, VehicleModalActions.showEdit)) {
    return { ...state, isShown: true, isEdit: true, initialFormValues: action.payload.vehicle };
  }
  if (isType(action, VehicleModalActions.close)) {
    return {
      ...state,
      isShown: false,
      isEdit: false,
      initialFormValues: defaultVehicle,
    };
  }
  return state;
}

export function VehiclesPage(): ReactElement {
  const { t } = useTranslation("vehicle");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [fleetVehicles, setFleetVehicles] = useState<Fleet.Vehicle[]>([]);
  const [stops, setStops] = useState<Fleet.Stop[]>([]);
  const [vehicleModalState, dispatch] = useReducer(reducer, {
    isShown: false,
    isEdit: false,
    initialFormValues: defaultVehicle,
  });

  const closeModal = useCallback(() => dispatch(VehicleModalActions.close()), []);

  // API Calls
  const getStops = useCallback(async () => {
    setStops((await FleetApiClient.stop.getStops()).data);
  }, [setStops]);
  const getVehicles = useCallback(async () => {
    try {
      setIsFetching(true);
      setFleetVehicles((await FleetApiClient.vehicle.getVehicles()).data);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsFetching(false);
    }
  }, [setFleetVehicles]);
  const postVehicle = useCallback(
    async (vehicleToSave: FormVehicle) => {
      try {
        await FleetApiClient.vehicle.addVehicle(vehicleToSave);
        await getVehicles();
      } catch (err) {
        console.warn(err);
      }
    },
    [getVehicles]
  );
  const deleteVehicle = useCallback(
    async (id: string) => {
      try {
        await FleetApiClient.vehicle.deleteVehicle(id);
        await getVehicles();
      } catch (err) {
        console.warn(err);
      }
    },
    [getVehicles]
  );

  const onOpenVehicleAdd = useCallback(
    () => dispatch(VehicleModalActions.showAdd({ vehicle: { ...defaultVehicle, id: uuid() } })),
    []
  );
  const onOpenVehicleEdit = useCallback(
    (id: string) =>
      dispatch(
        VehicleModalActions.showEdit({
          vehicle: fleetVehicles.find((e) => e.id === id) ?? { ...defaultVehicle, id: uuid() },
        })
      ),
    [fleetVehicles]
  );

  useEffect(() => {
    void getStops();
    void getVehicles();
  }, [getStops, getVehicles]);

  return (
    <Layout.Content simpleHeader={t("side.title")}>
      <Vehicles.Table
        isLoading={isFetching}
        vehicles={fleetVehicles}
        onVehicleRemove={deleteVehicle}
        openVehicleEdit={onOpenVehicleEdit}
        openVehicleAdd={onOpenVehicleAdd}
        stops={stops}
      />
      <Vehicles.Form
        vehicleModalState={vehicleModalState}
        onClose={closeModal}
        onVehicleSave={postVehicle}
        stops={stops}
      />
    </Layout.Content>
  );
}
