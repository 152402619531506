import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function CenteredSpinner() {
  const { t } = useTranslation("general");
  return (
    <div className="w-100 d-flex justify-content-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">{t("loading")}...</span>
      </Spinner>
    </div>
  );
}
