export const driverRides = {
  vehicleLabel: "Pojazd",
  noRides: "Brak nadchodzących przejazdów do wyświetlenia.",
  table: {
    date: "Dzień",
    time: "Czas obsługi",
    stop: "Przystanek",
    pickups: "Wsiada",
    dropoffs: "Wysiada",
  },
  lastUpdate: "Ostatnia aktualizacja",
  errors: {
    noVehicles: "Brak pojazdów dostępnych w systemie.",
    refreshError: "Błąd wczytywania przejazdów, spróbuj odświeżyć stronę.",
  },
};
