import classNames from "classnames";
import { Common } from "components";
import { RoleRestriction } from "components/common";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";
import { RideRequestSource, RideStatuses } from "shared/types/Fleet";

export interface FilterState {
  username?: string;
  createdAfter?: Date | null;
  createdBefore?: Date | null;
  id?: string;
  status?: string;
  source?: RideRequestSource;
  onlyActiveMissions?: boolean;
}

interface Props {
  filterState: FilterState;
  updateFilter: <StateKey extends keyof FilterState>(key: StateKey, value: FilterState[StateKey]) => void;
}

const textColorMap = {
  [RideRequestSource.NoData]: "text-danger",
  [RideRequestSource.PassengerMobile]: "text-success",
  [RideRequestSource.Operator]: "text-primary",
};

export function Filters({ filterState, updateFilter }: Props) {
  const { t } = useTranslation("rides");
  const authService = useAuthService();
  const isAdmin = authService.userHasRole(Role.ADMIN);
  const updateNativeInputFilter =
    (input: "username" | "id" | "status" | "source" | "onlyActiveMissions") =>
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      updateFilter(input, ev.currentTarget.value);
    };
  const updateDateInputFilter =
    (input: "createdAfter" | "createdBefore") => (date: Date | [Date, Date] | null) => {
      if (!Array.isArray(date)) {
        updateFilter(input, date);
      }
    };
  return (
    <Form className="px-3 pt-3 pb-0">
      <Form.Row>
        <Form.Group as={Col} xs={3} xl={isAdmin ? 1 : 2} controlId="formGridSource">
          <Form.Label>{t("filters.source")}</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={filterState.source || ""}
            onChange={updateNativeInputFilter("source")}
          >
            <option value="">-</option>
            {Object.entries(RideRequestSource)
              .filter(([, value]) => value !== RideRequestSource.NoData && typeof value === "number")
              .map(([key, value]) => (
                <option
                  className={classNames("font-weight-bold", textColorMap[value as RideRequestSource])}
                  key={key}
                  value={value}
                >
                  {t(`source.${value}`)}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={4} xl={isAdmin ? 1 : 2} controlId="formGridStatus">
          <Form.Label>{t("filters.status")}</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={filterState.status || ""}
            onChange={updateNativeInputFilter("status")}
          >
            <option value="">-</option>
            {Object.entries(RideStatuses)
              .filter(([, value]) => typeof value === "number")
              .map(([key, value]) => (
                <option key={key} value={value}>
                  {t(`status.${value}`)}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={5} xl={2} controlId="formGridUsername">
          <Form.Label>{t("filters.username")}</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            value={filterState.username || ""}
            onChange={updateNativeInputFilter("username")}
          />
        </Form.Group>
        <RoleRestriction requiredRoles={[Role.ADMIN]}>
          <Form.Group as={Col} xs={4} xl={2} controlId="formGridId">
            <Form.Label>{t("filters.id")}</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value={filterState.id || ""}
              onChange={updateNativeInputFilter("id")}
            />
          </Form.Group>
        </RoleRestriction>
        <Form.Group as={Col} xs={isAdmin ? 4 : 6} md={4} xl={2} controlId="formGridCreatedAfter">
          <Form.Label>{t("filters.createdAfter")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridCreatedAfter"
            className="form-control form-control-sm"
            selected={filterState.createdAfter}
            onChange={updateDateInputFilter("createdAfter")}
          />
        </Form.Group>
        <Form.Group as={Col} xs={isAdmin ? 4 : 6} md={4} xl={2} controlId="formGridCreatedBefore">
          <Form.Label>{t("filters.createdBefore")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridCreatedBefore"
            className="form-control form-control-sm"
            selected={filterState.createdBefore}
            onChange={updateDateInputFilter("createdBefore")}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} md={4} xl={2} controlId="formGridShowOnlyActiveMissions">
          <Form.Label>{t("filters.onlyActiveMissions")}</Form.Label>
          <Form.Check
            className="mr-3"
            type="switch"
            checked={filterState.onlyActiveMissions ?? false}
            onChange={() => updateFilter("onlyActiveMissions", !filterState.onlyActiveMissions)}
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
