import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import subMinutes from "date-fns/subMinutes";

import { FleetApiClient, SettingsApiClient } from "shared/api";
import { TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { MissionStatuses, SegmentStatuses } from "shared/types/Fleet";
import styles from "./Board.module.css";
import { FiltersState } from "pages/MissionDetails/MissionDetails";
import classNames from "classnames";

interface Props {
  mission: Fleet.MissionDetailedOverviewItemDto;
  refresh: () => void;
  filtersState: FiltersState;
  setFiltersState: React.Dispatch<FiltersState>;
}

export function Board({ mission, refresh, filtersState, setFiltersState }: Props): ReactElement {
  const { t } = useTranslation(["missionDetails"]);
  const [currentSystemTime, setCurrentSystemTime] = useState<Date>();

  const refreshSystemTime = useCallback(async () => {
    const result = await SettingsApiClient.getSystemTime();
    setCurrentSystemTime(parseISO(result.data));
  }, []);

  useEffect(() => {
    void refreshSystemTime();
  }, [refreshSystemTime]);

  const [isShown, setIsShown] = useState(false);

  const cancelMission = async () => {
    await FleetApiClient.missions.cancelMissionByOperator({
      missionId: mission.id,
      vehicleId: mission.vehicle.id,
      message: t("debugCancel"),
    });
    refresh();
  };

  const startMission = async () => {
    await FleetApiClient.missions.startMission(mission.vehicle.id, mission.id);
    refresh();
  };

  const finishMission = async () => {
    await FleetApiClient.missions.finishMission(mission.vehicle.id, mission.id);
    refresh();
  };

  const filterCancelledSegmentsChanged = () => {
    setFiltersState({
      cancelledFiltered: true,
      activeOnly: false,
      cancelledOnly: false,
      withoutVehicles: false,
      none: false,
    });
  };

  const filterOnlyActiveSegmentsChanged = () => {
    setFiltersState({
      cancelledFiltered: false,
      activeOnly: true,
      cancelledOnly: false,
      withoutVehicles: false,
      none: false,
    });
  };

  const filterOnlyCancelledSegmentsChanged = () => {
    setFiltersState({
      cancelledFiltered: false,
      activeOnly: false,
      cancelledOnly: true,
      withoutVehicles: false,
      none: false,
    });
  };

  const filterOnlySegmentsChanged = () => {
    setFiltersState({
      cancelledFiltered: false,
      activeOnly: false,
      cancelledOnly: false,
      withoutVehicles: true,
      none: false,
    });
  };

  const filterNoneSegmentsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltersState({
      cancelledFiltered: false,
      activeOnly: false,
      cancelledOnly: false,
      withoutVehicles: false,
      none: true,
    });
  };

  const isMissionCancelDisabled =
    mission.status.code === MissionStatuses.Cancelled || mission.status.code === MissionStatuses.Finished;
  const isMissionStartDisabled =
    !currentSystemTime ||
    mission.status.code !== MissionStatuses.Pending ||
    isBefore(currentSystemTime, subMinutes(parseISO(mission.plannedStartTime), 30));
  const isMissionFinishDisabled =
    !currentSystemTime ||
    mission.status.code === MissionStatuses.Pending ||
    mission.status.code === MissionStatuses.Finished ||
    mission.status.code === MissionStatuses.Cancelled ||
    isBefore(currentSystemTime, parseISO(mission.plannedEndTime)) ||
    mission.plan.segments.filter(
      (segment) =>
        segment.segmentStatus.code === SegmentStatuses.Confirmed ||
        segment.segmentStatus.code === SegmentStatuses.Pending ||
        segment.segmentStatus.code === SegmentStatuses.Visited
    ).length > 1;

  return (
    <div className={styles.missionInfo}>
      <div className={isShown ? "d-none" : ""}>
        <Row className="mb-1">
          <Col className="text-center font-weight-bolder">{t("startTime")}</Col>
          <Col className="text-center font-weight-bolder">{t("endTime")}</Col>
        </Row>
        <hr></hr>
        <Row className="my-1">
          <Col className="text-center font-italic">{t("initialPlannedTime")}</Col>
        </Row>
        <Row className="my-1">
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.initialPlannedStartTime)}
          </Col>
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.initialPlannedEndTime)}
          </Col>
        </Row>
        <hr></hr>
        <Row className="my-1">
          <Col className="text-center font-italic">{t("plannedTime")}</Col>
        </Row>
        <Row className="my-1">
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.plannedStartTime)}
          </Col>
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.plannedEndTime)}
          </Col>
        </Row>
        <hr></hr>
        <Row className="my-1">
          <Col className="text-center font-italic">{t("reportedTime")}</Col>
        </Row>
        <Row className="my-1">
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.reportedStartTime)}
          </Col>
          <Col className="text-center font-weight-bold">
            {TimestampTransforms.toFullDate(mission.reportedEndTime)}
          </Col>
        </Row>
        <hr></hr>
        <Row className="mb-3 mt-2">
          <Col>
            Status:
            <span
              className={classNames(
                [styles.fontSizeLarger],
                "ml-1 font-weight-bold",
                { "text-success": mission.status.code === MissionStatuses.Started },
                {
                  "text-danger":
                    mission.status.code === MissionStatuses.Cancelled ||
                    mission.status.code === MissionStatuses.Finished,
                }
              )}
            >
              {t(`missionStatuses.${mission.status.code}`)}
            </span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Button
            className="mr-1"
            variant="success"
            size="sm"
            disabled={isMissionStartDisabled}
            onClick={startMission}
          >
            {t("start")}
          </Button>
          <Button
            className="mr-1"
            variant="primary"
            size="sm"
            disabled={isMissionFinishDisabled}
            onClick={finishMission}
          >
            {t("finish")}
          </Button>
          <Button variant="danger" size="sm" disabled={isMissionCancelDisabled} onClick={cancelMission}>
            {t("cancel")}
          </Button>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mt-1">
              <Form.Check
                className={styles.fontSizeLarger}
                type="radio"
                label={t("filterCancelledSegments")}
                onChange={filterCancelledSegmentsChanged}
                checked={filtersState.cancelledFiltered}
              />
              <Form.Check
                className={styles.fontSizeLarger}
                type="radio"
                label={t("filterOnlyActiveSegments")}
                onChange={filterOnlyActiveSegmentsChanged}
                checked={filtersState.activeOnly}
              />
              <Form.Check
                className={styles.fontSizeLarger}
                type="radio"
                label={t("filterOnlyCancelledSegments")}
                onChange={filterOnlyCancelledSegmentsChanged}
                checked={filtersState.cancelledOnly}
              />
              <Form.Check
                className={styles.fontSizeLarger}
                type="radio"
                label={t("filterOnlySegments")}
                onChange={filterOnlySegmentsChanged}
                checked={filtersState.withoutVehicles}
              />
              <Form.Check
                className={styles.fontSizeLarger}
                type="radio"
                label={t("filterNoneSegments")}
                onChange={filterNoneSegmentsChanged}
                checked={filtersState.none}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Row className="mb-1">
        <Col className="text-center w-100 h-100">
          <Button onClick={() => setIsShown((curr) => !curr)} className="w-100">
            {t(isShown ? "hideMe" : "unhideMe")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
