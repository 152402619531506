import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FleetApiClient } from "shared/api";
import { Fleet } from "shared/types";
import { UseTranslationResult } from "shared/types/Common";
import { ForbiddenTransition } from "shared/types/Fleet";
import { Layout, ForbiddenTransitions } from "../components";
import { Modal } from "react-bootstrap";

const defaultForbiddenTransition: ForbiddenTransition = {
  sourceStopId: "",
  sourceStopName: "",
  destinationStopId: "",
  destinationStopName: "",
};

export function ForbiddenTransitionsPage(): ReactElement {
  const { t } = useTranslation("forbiddenTransitions") as UseTranslationResult;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [forbiddenTransitions, setForbiddenTransitions] = useState<Fleet.ForbiddenTransition[]>([]);
  const [stops, setStops] = useState<Fleet.Stop[]>([]);
  const [selectedForbiddenTransition, setSelectedForbiddenTransition] =
    useState<ForbiddenTransition | undefined>(undefined);

  const getData = useCallback(async () => {
    try {
      setIsFetching(true);
      setStops((await FleetApiClient.stop.getStops()).data);
      setForbiddenTransitions((await FleetApiClient.stop.getForbiddenTransitions()).data);
      setIsFetching(false);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  const onRemoveForbiddenTransition = useCallback(
    async (toRemove: ForbiddenTransition) => {
      setIsFetching(true);
      await FleetApiClient.stop.deleteForbiddenTransition(toRemove);
      await getData();
    },
    [getData]
  );

  const onSaveForbiddenTransition = useCallback(
    async (forbiddenTransition: ForbiddenTransition) => {
      try {
        setIsFetching(true);
        await FleetApiClient.stop.addForbiddenTransition(forbiddenTransition);
        setSelectedForbiddenTransition(undefined);
        await getData();
      } catch (err) {
        console.warn(err);
      }
    },
    [getData]
  );

  const onAddForbiddenTransition = useCallback(() => {
    setSelectedForbiddenTransition(defaultForbiddenTransition);
  }, []);

  const onDuplicateForbiddenTransition = useCallback((toDuplicate: ForbiddenTransition) => {
    setSelectedForbiddenTransition({
      ...toDuplicate,
      sourceStopId: toDuplicate.destinationStopId,
      destinationStopId: toDuplicate.sourceStopId,
    });
  }, []);

  const isForbiddenTransitionUnique = useCallback(
    (forbiddenTransition: ForbiddenTransition): boolean => {
      return !forbiddenTransitions.find(
        (ft) =>
          ft.sourceStopId === forbiddenTransition.sourceStopId &&
          ft.destinationStopId === forbiddenTransition.destinationStopId
      );
    },
    [forbiddenTransitions]
  );

  const onCloseModal = useCallback(() => {
    setSelectedForbiddenTransition(undefined);
  }, []);

  useEffect(() => {
    void getData();
  }, [getData]);

  return (
    <Layout.Content simpleHeader={t("side.title")}>
      <Layout.CollapsibleCard
        className="mt-2"
        data-cy="stops"
        headerContent={<h3 className="card-title">{t("list")}</h3>}
      >
        <ForbiddenTransitions.Table
          forbiddenTransitions={forbiddenTransitions}
          isLoading={isFetching}
          onRemoveForbiddenTransition={onRemoveForbiddenTransition}
          onAddForbiddenTransition={onAddForbiddenTransition}
          onDuplicateForbiddenTransition={onDuplicateForbiddenTransition}
          isForbiddenTransitionUnique={isForbiddenTransitionUnique}
        />
        <Modal show={selectedForbiddenTransition !== undefined} size="lg" onHide={onCloseModal}>
          {selectedForbiddenTransition && (
            <ForbiddenTransitions.Form
              initialForbiddenTransition={selectedForbiddenTransition}
              stops={stops}
              onForbiddenTransitionSave={onSaveForbiddenTransition}
              isForbiddenTransitionUnique={isForbiddenTransitionUnique}
            />
          )}
        </Modal>
      </Layout.CollapsibleCard>
    </Layout.Content>
  );
}
