import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { divIcon } from "leaflet";
import classNames from "classnames";

import { Common } from "shared/types";
import markerStyles from "./Markers.module.css";

export enum StopLocationMarkerVariants {
  REGULAR = "regular",
  DEPARTURE = "departure",
  ARRIVAL = "arrival",
}

interface Props {
  location: Common.Location;
  children?: string | React.ReactNode;
  isActive?: boolean;
  variant?: StopLocationMarkerVariants;
  tooltipContent?: JSX.Element | string;
  onClick?: () => void;
}

const markerTheme: Record<
  StopLocationMarkerVariants,
  {
    bgColor: string;
    iconClass: string;
  }
> = {
  [StopLocationMarkerVariants.REGULAR]: { bgColor: "bg-light", iconClass: markerStyles.regularMarkerIcon },
  [StopLocationMarkerVariants.DEPARTURE]: {
    bgColor: "bg-success",
    iconClass: markerStyles.departureMarkerIcon,
  },
  [StopLocationMarkerVariants.ARRIVAL]: { bgColor: "bg-danger", iconClass: markerStyles.arrivalMarkerIcon },
};

export const StopLocationMarker = ({
  location,
  children,
  isActive,
  onClick,
  tooltipContent,
  variant = StopLocationMarkerVariants.REGULAR,
}: Props): React.ReactElement => {
  const theme = markerTheme[variant];
  return (
    <Marker
      icon={divIcon({
        html: renderToStaticMarkup(
          <div className={classNames(markerStyles.marker, markerStyles.outlined)}>
            <div className={markerStyles.shadow} />
            <div
              className={classNames(
                theme.bgColor,
                markerStyles.shape,
                isActive ? markerStyles.activeBorder : ""
              )}
            />
            <div className={classNames(theme.bgColor, markerStyles.pointer)} />
            <div
              className={classNames(
                theme.bgColor,
                markerStyles.background,
                markerStyles.stopLocationTextWrap,
                theme.iconClass
              )}
            >
              {children}
            </div>
          </div>
        ),
        iconSize: [30, 30],
      })}
      position={[location.lat, location.lng]}
      eventHandlers={{
        click: onClick,
      }}
    >
      {tooltipContent && <Tooltip>{tooltipContent}</Tooltip>}
    </Marker>
  );
};
