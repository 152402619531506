import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CellProps } from "react-table";
import { Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";
import { Fleet } from "shared/types";
import styles from "../Rides.module.css";

export function Username({ row }: CellProps<Fleet.RideOverviewListItemDto>) {
  const authService = useAuthService();
  const passengerTableName = `${row.original.passengerName}${
    row.original.passengerPhone ? ` (${row.original.passengerPhone})` : ""
  }`;
  if (authService.userHasRole(Role.ADMIN) && row.original.passengerUsername) {
    return (
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <Tooltip id={`username-tooltip-${passengerTableName}`}>{row.original.passengerUsername}</Tooltip>
        }
      >
        <div className={classNames("cursor-pointer", styles.username)} role="button">
          {passengerTableName}
        </div>
      </OverlayTrigger>
    );
  }
  return passengerTableName;
}
