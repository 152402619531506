import classNames from "classnames";
import { TimeTransforms } from "shared/mappers";
import styles from "../VehicleAvailability.module.css";

const createStepLabel = (hours: number) => (hours < 10 ? `0${hours}:00` : `${hours}:00`);

export function HourlyScale({ hourlyScaleStep }: { hourlyScaleStep: number }) {
  if (24 % hourlyScaleStep !== 0) {
    hourlyScaleStep = 4;
  }
  const stepWidth = `${TimeTransforms.getDayPercentageFromMilliseconds(
    TimeTransforms.hoursToMilliseconds(hourlyScaleStep)
  )}%`;
  const regularSteps = [];
  for (let i = hourlyScaleStep * 2; i <= 24; i += hourlyScaleStep) {
    regularSteps.push(
      <div key={i} className={classNames(styles.scaleStep, "border-left-0")} style={{ width: stepWidth }}>
        <div className={classNames(styles.scaleLabel, styles.right)}>{createStepLabel(i)}</div>
      </div>
    );
  }
  return (
    <div className={classNames("d-flex px-3", styles.scaleWrapper)}>
      <div className={styles.scaleStep} style={{ width: stepWidth }}>
        <div className={classNames(styles.scaleLabel, styles.left)}>{createStepLabel(0)}</div>
        <div className={classNames(styles.scaleLabel, styles.right)}>{createStepLabel(hourlyScaleStep)}</div>
      </div>
      {regularSteps}
    </div>
  );
}
