import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.ServiceTimeDataSource[];
}

export function TimelinessOfRidesChart({ dataSource }: Props) {
  const { t } = useTranslation(["reports"]);
  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
        zoom: {
          enabled: true,
        },
      },
      tooltip: {
        y: {
          formatter(val, opts) {
            return val.toFixed(1) + "%";
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: dataSource.map((d) => d.intervalName),
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
        labels: {
          formatter: function (val, index) {
            return val.toFixed(0) + "%";
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      fill: {
        opacity: 1,
      },
    }),
    [dataSource]
  );

  const series = useMemo(
    () => [
      {
        name: t("charts.timelinessOfRides.seriesName.departureTime"),
        data: dataSource.map((d) => d.departurePercent * 100),
      },
      {
        name: t("charts.timelinessOfRides.seriesName.arrivalTime"),
        data: dataSource.map((d) => d.arrivalPercent * 100),
      },
    ],
    [dataSource, t]
  );

  return <ReactApexChart options={options} series={series} type="bar" height={400} />;
}
