import { useCallback, useEffect, useState } from "react";
import { Layout, Common } from "components";
import { FleetApiClient } from "shared/api";
import { ActiveVehiclePosition } from "shared/types/Fleet";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LatLngTuple } from "leaflet";
import { TimestampTransforms } from "shared/mappers";
import { TFunction, useTranslation } from "react-i18next";

function getBounds(positions: ActiveVehiclePosition[]) {
  const newBounds = positions.map((p) => [p.position.location.lat, p.position.location.lng]) as LatLngTuple[];
  return newBounds.length >= 2 ? newBounds : undefined;
}

function MarkerTooltip({
  t,
  position,
  vehicleName,
}: ActiveVehiclePosition & { t: TFunction<"vehicle-locations"> }) {
  return (
    <div>
      <h5>{vehicleName}</h5>
      <p className="mb-0">
        {t("lastUpdate")}: <strong>{TimestampTransforms.toFullDate(position.timestamp)}</strong>
      </p>
      <p className="mb-0">
        {t("mission")}: {position.missionId}
      </p>
    </div>
  );
}

export function VehicleLocationsPage() {
  const { t } = useTranslation("vehicle-locations");
  const [isFetching, setIsFatching] = useState<boolean>(false);
  const [activeVehiclePositions, setActiveVehiclePositions] = useState<ActiveVehiclePosition[]>([]);
  const refetchActivePositions = useCallback(async () => {
    try {
      setIsFatching(true);
      const positions = (await FleetApiClient.vehicle.getActivePositions()).data;
      setActiveVehiclePositions(positions);
    } finally {
      setIsFatching(false);
    }
  }, []);
  useEffect(() => {
    void refetchActivePositions();
  }, [refetchActivePositions]);
  const header = (
    <section className="content-header">
      <Container fluid>
        <Row className="mb-2">
          <Col xs="8">
            <h1 data-cy="pageContentHeader" className="m-0 text-dark">
              {t("header")}
            </h1>
          </Col>
          <Col xs="4" className="d-flex justify-content-end">
            <Button onClick={refetchActivePositions}>{t("general:refresh")}</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
  return (
    <div>
      <Layout.Content headerComponent={header}>
        <Common.Map height="calc(100vh - 140px)" bounds={getBounds(activeVehiclePositions)}>
          <Common.MapLoadingOverlay isShown={isFetching} />
          {activeVehiclePositions.map((avp) => (
            <Common.VehicleMarker
              key={avp.position.missionId + avp.position.vehicleId}
              {...avp.position}
              tooltip={<MarkerTooltip {...avp} t={t} />}
            />
          ))}
        </Common.Map>
      </Layout.Content>
    </div>
  );
}
