import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { SidebarMenuDropdownItem } from "./SidebarMenuDropdownItem";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { Navigation } from "shared/types";
import { useAuthService } from "shared/hooks/useAuthService";
import { AuthService } from "shared/authService";

function buildMenuFromNavigation(
  t: TFunction,
  authService: typeof AuthService,
  navigationShape: Navigation.Shape
): ReactElement[] {
  return Object.keys(navigationShape).reduce<ReactElement[]>((result, key) => {
    const navigationItem = navigationShape[key];
    if (Navigation.isCategory(navigationItem)) {
      const categoryItems = buildMenuFromNavigation(
        t,
        authService,
        navigationItem[Navigation.Properties.CATEGORY_ITEMS]
      );
      if (categoryItems.length > 0) {
        result.push(
          <React.Fragment key={`header${key}`}>
            <li className="nav-header">{t(key)}</li>
            {categoryItems}
          </React.Fragment>
        );
      }
    } else if (Navigation.isDropdown(navigationItem)) {
      result.push(
        <SidebarMenuDropdownItem key={`dropdown${key}`} translationKey={key} {...navigationItem} />
      );
    } else {
      if (authService.userHasOneOfRoles(navigationItem.visibleToRoles)) {
        result.push(<SidebarMenuItem key={`item${key}`} translationKey={key} {...navigationItem} />);
      }
    }
    return result;
  }, []);
}

interface Props {
  siteNavigation: Navigation.Shape;
}

export function SidebarMenu({ siteNavigation }: Props): ReactElement | null {
  const authService = useAuthService();
  const { t } = useTranslation("navigation");
  return (
    <nav className="mt-2">
      <ul
        className="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        {buildMenuFromNavigation(t, authService, siteNavigation)}
      </ul>
    </nav>
  );
}
