import React from "react";

interface Props {
  onClick: () => void;
  headerRender: React.ReactNode;
  isCurrentlySorted: boolean;
  sort?: string;
  sortAscending: boolean;
}

export function SortableHeader({
  headerRender,
  onClick,
  sort,
  sortAscending,
  isCurrentlySorted,
}: Props): React.ReactElement {
  const iconProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = {
    key: "icon",
    className: "ml-1 fas",
  };
  const currentSortIsAscending = sortAscending;
  const isAnyFieldSorted = !!sort;
  if (isCurrentlySorted) {
    if (currentSortIsAscending) {
      iconProps.className += " fa-sort-up";
    } else {
      iconProps.className += " fa-sort-down";
    }
  } else {
    iconProps.className += " fa-sort";
    if (isAnyFieldSorted) {
      iconProps.className += " invisible";
    }
  }
  return (
    <div onClick={onClick} className="cursor-pointer text-nowrap">
      {headerRender}
      <i {...iconProps} />
    </div>
  );
}
