/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.StopsHeatmapDataSource;
}
const colors = ["#ffd899", "#f7a955", "#ef852e", "#d65724", "#a9341a", "#7b1010"];

export function StopsHeatmap({ dataSource }: Props) {
  const calculateHeigth = useCallback(() => {
    const height = dataSource.headerRow.length * 25;
    return height > 400 ? height : 400;
  }, [dataSource.headerRow.length]);

  const colorScaleRange = useCallback(() => {
    let max = 0;
    const colorScale = [
      {
        from: 0,
        to: 0,
        color: "#fff1d6",
        foreColor: "#fff",
      },
    ];
    dataSource.series.forEach((s) =>
      s.data.forEach((value) => {
        max = value > max ? value : max;
      })
    );
    const colorsCount = colors.length;
    const range = parseInt((max / colorsCount).toFixed(0));
    for (let i = 0; i < colorsCount; i++) {
      const toValue = range * (i + 1);
      colorScale.push({
        from: range * i + 1,
        to: i < colorsCount - 1 ? toValue : toValue < max ? max : toValue,
        color: colors[i],
        foreColor: "#fff",
      });
    }
    return colorScale;
  }, [dataSource.series]);

  const { series } = useMemo(() => dataSource, [dataSource]);

  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        height: calculateHeigth(),
        type: "heatmap",
      },
      legend: {
        show: true,
      },
      plotOptions: {
        heatmap: {
          enableShades: false,
          colorScale: {
            ranges: colorScaleRange(),
          },
        },
      },
      dataLabels: {
        enabled: true,
        distributed: false,
        style: {
          colors: ["#fff"],
        },
      },
      xaxis: {
        type: "category",
        categories: dataSource.headerRow,
        labels: {
          trim: false,
          hideOverlappingLabels: false,
          show: true,
          rotate: -45,
          rotateAlways: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            `<div class="card m-0">
              <div class="card-header bg-secondary">${w.globals.initialSeries[seriesIndex].name} -> ${w.globals.labels[dataPointIndex]}</div>
              <div class="card-body text-secondary">
                <div class="card-title">Liczba zrealizowanych połączeń: <b>${series[seriesIndex][dataPointIndex]}</b></div>
              </div>
            </div>`);
        },
      },
    }),
    [calculateHeigth, colorScaleRange, dataSource.headerRow]
  );

  return <ReactApexChart options={options} series={series} type="heatmap" height={calculateHeigth()} />;
}
