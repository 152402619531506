import React, { useMemo, ReactElement } from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames";
import styles from "./PlanSegmentsPanel.module.css";
import { TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";

export enum SegmentStatus {
  UPCOMING = "upcoming",
  CURRENT = "current",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

const StatusClassNameMap = {
  [SegmentStatus.UPCOMING]: styles.upcoming,
  [SegmentStatus.CURRENT]: styles.current,
  [SegmentStatus.COMPLETED]: styles.completed,
  [SegmentStatus.CANCELLED]: styles.cancelled,
};

const wasCollected = (pickup: Fleet.PickupRideInfo) =>
  pickup.pickupStatus.code === Fleet.PickupStatuses.PickedUp;
const preparePassengerSummary = (segment: Fleet.Segment, isCompleted: boolean) => {
  const passengersNotCollected = segment.pickUps.filter((x) => !wasCollected(x)).length;
  return (
    <>
      <strong>
        <i className="fas fa-lg fa-long-arrow-alt-up text-success" title="Passengers picked up" />
        {isCompleted ? segment.pickUps.length - passengersNotCollected : segment.pickUps.length}
      </strong>
      {isCompleted && passengersNotCollected > 0 && (
        <strong>
          <i
            className={classNames("fas fa-lg fa-exclamation", styles.textOrange)}
            title="Passengers not picked up"
          />
          {passengersNotCollected}
        </strong>
      )}
      <strong>
        <i className="fas fa-lg fa-long-arrow-alt-down text-danger" title="Passengers dropped off" />
        {segment.dropOffs.length}
      </strong>
    </>
  );
};

const preparePassengerPickupRow =
  (isCompleted: boolean) =>
  (rideInfo: Fleet.PickupRideInfo): ReactElement => {
    const rowClasses = classNames(
      styles.passengersRow,
      isCompleted && !wasCollected(rideInfo) && styles.notCollected
    );
    const iconClasses = "fas mr-3 fa-user-plus text-success";
    return preparePassengerInfoRow(rowClasses, iconClasses, rideInfo);
  };
const preparePassengerDropoffRow = (rideInfo: Fleet.DropoffRideInfo) =>
  preparePassengerInfoRow(styles.passengersRow, "fas mr-3 fa-user-minus text-danger", rideInfo);
const preparePassengerInfoRow = (
  rowClasses: string,
  iconClasses: string,
  { rideId, passengerName, phone }: Fleet.RideInfo
) => (
  <li key={rideId} className={rowClasses}>
    <div>
      <i className={iconClasses} />
      <strong>{passengerName}</strong>
    </div>
    <div className="text-monospace">
      <i className="fas mr-2 fa-phone-alt text-muted" />
      {phone}
    </div>
  </li>
);

interface Props {
  segment: Fleet.Segment;
  isExpanded: boolean;
  isHighlighted: boolean;
  status: SegmentStatus;
  handleGoTo: (segment: Fleet.Segment) => () => void;
  handleVisibility: (segment: Fleet.Segment) => () => void;
  handleHighlight: (segment?: Fleet.Segment) => () => void;
}

export function SegmentRow({
  segment,
  status,
  isExpanded,
  handleGoTo,
  handleVisibility,
  isHighlighted,
  handleHighlight,
}: Props): React.ReactElement {
  const isCompleted = status === SegmentStatus.COMPLETED;
  const pickUps = useMemo(
    () => segment.pickUps.map(preparePassengerPickupRow(isCompleted)),
    [isCompleted, segment.pickUps]
  );
  const dropOffs = useMemo(() => segment.dropOffs.map(preparePassengerDropoffRow), [segment.dropOffs]);
  const canBeToggled = segment.pickUps.length + segment.dropOffs.length > 0;
  const label = (
    <>
      <strong className="mr-1">{TimestampTransforms.toShortTime(segment.plannedServiceTime)}</strong>
      <em>
        {segment.stop.name}
        {segment.stop.identifier && `(${segment.stop.identifier})`}
      </em>
    </>
  );
  const locateButton = (
    <div className={styles.locateButton}>
      <i role="button" className="fas fa-lg fa-crosshairs" onClick={handleGoTo(segment)} />
    </div>
  );
  const rowLabel = (
    <div className={styles.rowLabel}>
      <p>{label}</p>
    </div>
  );
  const baseClasses = classNames(
    styles.listItem,
    isHighlighted && styles.highlighted,
    StatusClassNameMap[status]
  );
  if (!canBeToggled) {
    return (
      <>
        <div onMouseEnter={handleHighlight(segment)} onMouseLeave={handleHighlight()} className={baseClasses}>
          {locateButton}
          <div className={styles.rowInfo}>{rowLabel}</div>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        onMouseEnter={handleHighlight(segment)}
        onMouseLeave={handleHighlight()}
        className={classNames(baseClasses, styles.togglable, isExpanded && styles.expanded)}
      >
        {locateButton}
        <div onClick={handleVisibility(segment)} className={styles.rowInfo}>
          {rowLabel}
          <div className={styles.passengersInfo}>{preparePassengerSummary(segment, isCompleted)}</div>
          <div className={styles.rowToggler}>
            <i className={classNames("fas text-muted", isExpanded ? "fa-caret-up" : "fa-caret-down")} />
          </div>
        </div>
      </div>
      <Collapse in={isExpanded}>
        <ul>
          {pickUps}
          {dropOffs}
        </ul>
      </Collapse>
    </>
  );
}
