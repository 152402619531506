import { useMemo, ReactElement } from "react";
import { useTable, usePagination, Column } from "react-table";
import { parseISO, format } from "date-fns";
import classNames from "classnames";

import { PassengerTable } from "./PassengerTable";
import { VehicleTable } from "./VehicleTable";
import { Common as CommonTypes, Planner } from "shared/types";
import { Common, Layout } from "components";
import { FormMap } from "../FormMap";
import styles from "./Tables.module.css";
import { PaginatedTableState } from "shared/types/Common";

const stringToFormattedDate = (isoString: string) =>
  isoString ? format(parseISO(isoString), "dd.MM.yyyy HH:mm") : "-";
const simplePassengerColumns: Column<Planner.PassengerRequest>[] = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Contact",
    accessor: "phone",
  },
  {
    Header: "From",
    accessor: "from.name",
  },
  {
    Header: "To",
    accessor: "to.name",
  },
  {
    Header: "Left Margin",
    accessor: (row) => (row.departureOptimalTime ? row.departureLeftMargin : "-"),
    id: "departureLeftMargin",
  },
  {
    Header: "Departure Time",
    accessor: (row) => stringToFormattedDate(row.departureOptimalTime),
    id: "departureOptimal",
  },
  {
    Header: "Right Margin",
    accessor: (row) => (row.departureOptimalTime ? row.departureRightMargin : "-"),
    id: "departureRightMargin",
  },
  {
    Header: "Left Margin",
    accessor: (row) => (row.arrivalOptimalTime ? row.arrivalLeftMargin : "-"),
    id: "arrivalLeftMargin",
  },
  {
    Header: "Arrival Time",
    accessor: (row) => stringToFormattedDate(row.arrivalOptimalTime),
    id: "arrivalOptimalTime",
  },
  {
    Header: "Right Margin",
    accessor: (row) => (row.arrivalOptimalTime ? row.arrivalRightMargin : "-"),
    id: "arrivalRightMargin",
  },
];

const simpleVehicleColumns = [
  {
    Header: "Number",
    accessor: "number",
  },
  {
    Header: "Start Position",
    accessor: "startPosition.name",
  },
  {
    Header: "Capacity",
    accessor: "capacity",
  },
];

function defaultSortFactory<T extends Planner.PassengerRequest | Planner.Vehicle>(
  idGetter: (entity: T) => string
) {
  return (a: T, b: T): number => {
    const aHasErrors = Object.keys(a.errors).length > 0;
    const bHasErrors = Object.keys(b.errors).length > 0;
    if (aHasErrors && !bHasErrors) {
      return -1;
    }
    if (bHasErrors && !aHasErrors) {
      return 1;
    }
    if (aHasErrors && bHasErrors) {
      return 0;
    }
    return ("" + idGetter(a)).localeCompare("" + idGetter(b));
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
const useInvalidRows = (hooks: any) => {
  hooks.getRowProps.push((row: any, table: any) => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      className: classNames({ [styles.invalid]: Object.keys(table.row.original.errors).length > 0 }),
      ...row,
    } as Record<string, unknown>;
  });
};
useInvalidRows.pluginName = "useInvalidRows";

interface Props {
  passengerRequests: Planner.PassengerRequest[];
  vehicles: Planner.Vehicle[];
  passengerRequestsError?: string;
  vehiclesError?: string;
  onVehicleRemove: (vehicleId: string) => void;
  onPassengerRemove: (passengerId: string) => void;
  openPassengerEdit: (id: string) => void;
  openPassengerAdd: () => void;
  openVehicleEdit: (id: string) => void;
  openVehicleAdd: () => void;
}

export function RequestsTables({
  passengerRequests,
  vehicles,
  onPassengerRemove,
  onVehicleRemove,
  passengerRequestsError,
  vehiclesError,
  openPassengerEdit,
  openVehicleEdit,
  openPassengerAdd,
  openVehicleAdd,
}: Props): ReactElement {
  const sortedPassengerRequests = useMemo(
    () => passengerRequests.sort(defaultSortFactory((x: Planner.PassengerRequest) => x.name)),
    [passengerRequests]
  );
  const sortedVehicles = useMemo(
    () => vehicles.sort(defaultSortFactory((x: Planner.Vehicle) => x.number)),
    [vehicles]
  );
  const passengerColumns = useMemo(
    () => [
      ...simplePassengerColumns,
      {
        id: "selection",
        Header: "Actions",
        Cell: Common.ActionCellFactory<Planner.PassengerRequest>(
          openPassengerEdit,
          onPassengerRemove,
          (pr) => pr.id
        ),
        width: 145,
      },
    ],
    [onPassengerRemove, openPassengerEdit]
  );
  const vehicleColumns = useMemo(
    () => [
      ...simpleVehicleColumns,
      {
        id: "selection",
        Header: "Actions",
        Cell: Common.ActionCellFactory<Planner.Vehicle>(openVehicleEdit, onVehicleRemove, (v) => v.id),
        width: 145,
      },
    ],
    [onVehicleRemove, openVehicleEdit]
  );

  const passengersTable = useTable(
    {
      columns: passengerColumns,
      data: sortedPassengerRequests,
      initialState: { pageIndex: 0, pageSize: 10 } as PaginatedTableState<Planner.PassengerRequest>,
    },
    usePagination,
    useInvalidRows
  ) as CommonTypes.TableWithPagination<Planner.PassengerRequest>;
  const vehiclesTable = useTable(
    {
      columns: vehicleColumns,
      data: sortedVehicles,
      initialState: { pageIndex: 0, pageSize: 10 } as PaginatedTableState<Planner.Vehicle>,
    },
    usePagination,
    useInvalidRows
  ) as CommonTypes.TableWithPagination<Planner.Vehicle>;

  return (
    <>
      <Layout.CollapsibleCard
        headerContent={<h3 className="card-title">Map view</h3>}
        bodyClassName="p-0 position-relative"
        data-cy="mapViewCard"
      >
        <FormMap passengersPage={passengersTable.page} vehiclesPage={vehiclesTable.page} />
      </Layout.CollapsibleCard>
      <PassengerTable table={passengersTable} addNewRow={openPassengerAdd} error={passengerRequestsError} />
      <VehicleTable table={vehiclesTable} addNewRow={openVehicleAdd} error={vehiclesError} />
    </>
  );
}
