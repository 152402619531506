import { useState } from "react";

export function useItemSelector<ItemType>(enabled: boolean, idGetter: (item: ItemType) => string) {
  const [highlightedStopId, setHighlightedStop] = useState<string>();
  let setItemSelected = (stop?: ItemType) =>
    stop ? setHighlightedStop(idGetter(stop)) : setHighlightedStop(undefined);
  let isItemSelected = (stop: ItemType) => highlightedStopId === idGetter(stop);
  if (!enabled) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItemSelected = () => {};
    isItemSelected = () => false;
  }
  return { isItemSelected, setItemSelected };
}
