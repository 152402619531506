import { hideNotification, showNotification, loginSuccess } from "../../actions";
import { SystemNotification, SystemNotificationPositions } from "../../../types/Common";
import { Reducer } from "redux";
import { isType } from "typescript-fsa";
import { v4 as uuid } from "uuid";

interface NotificationsReducerState {
  notificationShowDuration?: number;
  notificationQueue: SystemNotification[];
}

const initialState = {
  notificationShowDuration: 2500,
  notificationQueue: [],
};
const notificationLimit = 5;

const reducer: Reducer<NotificationsReducerState> = (state = initialState, action) => {
  if (isType(action, showNotification)) {
    const {
      guid = uuid(),
      message,
      color,
      disableAutoHide = false,
      position = SystemNotificationPositions.TOP_RIGHT,
    } = action.payload;
    const newNotification = {
      color,
      guid,
      message,
      disableAutoHide,
      position,
    };
    if (state.notificationQueue.length > notificationLimit) {
      const arrCopy = state.notificationQueue.slice();
      arrCopy.shift();
      arrCopy.push(newNotification);
      return {
        ...state,
        notificationQueue: arrCopy,
      };
    }
    return {
      ...state,
      notificationQueue: [...state.notificationQueue, newNotification],
    };
  }
  if (isType(action, hideNotification)) {
    return {
      ...state,
      notificationQueue: state.notificationQueue.filter(
        (notification) => notification.guid !== action.payload.guid
      ),
    };
  }
  if (isType(action, loginSuccess)) {
    return initialState;
  }
  return state;
};

export default reducer;
