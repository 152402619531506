import React, { ReactElement, useCallback, useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import { divIcon, DivIconOptions } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import classNames from "classnames";

import styles from "./ResultsMapMarker.module.css";
import { MapColors } from "../ResultsMap/mapColors";

type MarkerTypes = "start" | "finish" | "passenger";

interface Props {
  children?: React.ReactNode;
  vehicles: string[];
  position: [number, number];
  onVehicleSelect: (vehicleId: string) => void;
  active: boolean;
  opacity: number;
  type: MarkerTypes;
}

interface IconProperties extends DivIconOptions {
  iconClass: string;
  iconId: string;
}

const iconPropertiesMap: { [key in MarkerTypes]: IconProperties } = {
  start: {
    iconId: "leafletStartMarker",
    iconClass: "fas fa-bus",
    iconSize: [30, 24],
    popupAnchor: [0, -10],
  },
  finish: {
    iconId: "leafletFinishMarker",
    iconClass: "fa-flag-checkered",
    iconSize: [24, 24],
    iconAnchor: [2, 24],
  },
  passenger: {
    iconId: "leafletPassengerMarker",
    iconClass: "fas fa-male",
    iconSize: [24, 24],
    iconAnchor: [2, 24],
  },
};

export function ResultsMapMarkerWithCounter({
  children,
  vehicles,
  position,
  onVehicleSelect,
  active,
  opacity,
  type,
}: Props): ReactElement {
  const multipleVehicles = vehicles.length > 1;
  const handleVehicleSelect = useCallback(
    (vehicleId: string) => () => {
      onVehicleSelect(vehicleId);
    },
    [onVehicleSelect]
  );
  const { iconClass, iconId, ...otherIconProps } = iconPropertiesMap[type];
  const iconColor = active ? MapColors.ACTIVE : MapColors.INACTIVE;
  const markerEventHandlers = useMemo(
    () => ({
      click: !multipleVehicles ? handleVehicleSelect(vehicles[0]) : undefined,
    }),
    [multipleVehicles, handleVehicleSelect, vehicles]
  );

  return (
    <Marker
      position={position}
      eventHandlers={markerEventHandlers}
      opacity={opacity}
      icon={divIcon({
        html: renderToStaticMarkup(
          <div data-cy={iconId} className={styles.wrapper}>
            {multipleVehicles && (
              <div className={styles.counter} style={{ borderColor: iconColor }}>
                {vehicles.length}
              </div>
            )}
            <i className={classNames("fa fa-2x", iconClass)} style={{ color: iconColor }} />
          </div>
        ),
        ...otherIconProps,
      })}
    >
      {multipleVehicles && (
        <Popup className={styles.markerPopup}>
          <ul>
            {vehicles.map((vehicleId, index) => (
              <li
                key={vehicleId}
                onClick={handleVehicleSelect(vehicleId)}
              >{`${index}. Vehicle ${vehicleId}`}</li>
            ))}
          </ul>
        </Popup>
      )}
      {children}
    </Marker>
  );
}
