import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "components";
import { Button } from "react-bootstrap";
import { useAuthService } from "shared/hooks/useAuthService";
import { AppUrls } from "shared/constants";

export function LogoutCallback() {
  const authService = useAuthService();
  const { t } = useTranslation();
  authService.signoutRedirectCallback();

  return (
    <Layout.Centered>
      <div className="d-flex justify-content-center">
        <Link to={AppUrls.Index}>
          <Button className="btn-main">{t("login")}</Button>
        </Link>
      </div>
    </Layout.Centered>
  );
}
