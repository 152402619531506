import { ReactElement, useState, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Layout, VehicleAvailability } from "components";
import { Common, Fleet } from "shared/types";
import { FleetApiClient } from "shared/api";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { ModalFormStatus } from "shared/types/Common";
import actionCreatorFactory, { Action, isType } from "typescript-fsa";

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

interface VehicleAvailabilityState {
  type: ModalFormStatus;
  id: string;
  initialValues: Partial<Fleet.VehicleAvailabilityListItem>;
}

const initialState: VehicleAvailabilityState = {
  type: ModalFormStatus.closed,
  id: EMPTY_GUID,
  initialValues: {
    pattern: [{ slots: [], weekDays: [] }],
    excludedDays: [],
  },
};

const actionCreator = actionCreatorFactory();
export const VehicleAvailabilityModalActions = {
  showCreate: actionCreator("CREATE"),
  showEdit: actionCreator<Partial<Fleet.VehicleAvailabilityListItem>>("EDIT"),
  showDuplicate: actionCreator<Partial<Fleet.VehicleAvailabilityListItem>>("DUPLICATE"),
  hide: actionCreator("HIDE"),
};

function reducer(
  state: VehicleAvailabilityState = initialState,
  action: Action<unknown>
): VehicleAvailabilityState {
  if (isType(action, VehicleAvailabilityModalActions.showCreate)) {
    return { ...state, type: ModalFormStatus.create };
  }

  if (isType(action, VehicleAvailabilityModalActions.showEdit)) {
    return {
      initialValues: { ...action.payload, id: undefined },
      id: action.payload.id ?? EMPTY_GUID,
      type: ModalFormStatus.edit,
    };
  }

  if (isType(action, VehicleAvailabilityModalActions.showDuplicate)) {
    const now = new Date();

    if (action.payload.validFrom && action.payload.validTo) {
      const initial = new Date(action.payload.validFrom);
      const final = new Date(action.payload.validTo);

      initial.setDate(
        now > initial && now <= final
          ? now.getDate() + 1
          : now < initial
          ? final.getDate() + 1
          : now.getDate()
      );

      return {
        initialValues: { ...action.payload, validFrom: initial.toISOString(), validTo: undefined },
        id: EMPTY_GUID,
        type: ModalFormStatus.duplicate,
      };
    }

    return state;
  }

  if (isType(action, VehicleAvailabilityModalActions.hide)) {
    return { ...state, type: ModalFormStatus.closed };
  }

  return state;
}

const defaultAvailability: Partial<Fleet.VehicleAvailabilityListItem> = {
  pattern: [{ slots: [], weekDays: [] }],
  excludedDays: [],
};

interface Params {
  vehicleId: string;
}

type DataState = Common.Paginated<Fleet.VehicleAvailabilityListItem>;

export function VehicleAvailabilityPage(): ReactElement {
  const { t } = useTranslation("vehicle/availability");
  const { vehicleId } = useParams<Params>();
  const [vehicleInfo, setVehicleInfo] = useState<Fleet.Vehicle>();
  const [filterState, setFilterState] = useState<VehicleAvailability.FilterState>({});
  const [dataState, setDataState] = useState<DataState>({ data: [], totalCount: 0, page: 1, pageSize: 5 });
  const [isFetchingAvailabilities, setIsFetchingAvailabilities] = useState(false);
  const [vehicleAvailabilityModal, dispatch] = useReducer(reducer, initialState);
  const closeForm = useCallback(() => {
    dispatch(VehicleAvailabilityModalActions.hide());
  }, []);
  const openNewForm = useCallback(() => {
    dispatch(VehicleAvailabilityModalActions.showCreate());
  }, []);
  const openEditForm = useCallback(
    (id: string) => {
      const availability = dataState.data.find((ap) => ap.id === id) || defaultAvailability;
      dispatch(VehicleAvailabilityModalActions.showEdit(availability));
    },
    [dataState.data]
  );
  const openDuplicateForm = useCallback(
    (id: string) => {
      const availability = dataState.data.find((ap) => ap.id === id) || defaultAvailability;
      dispatch(VehicleAvailabilityModalActions.showDuplicate(availability));
    },
    [dataState.data]
  );
  const updateFilter = useCallback(
    function updateFilter<FilterName extends keyof VehicleAvailability.FilterState>(
      filterName: FilterName,
      value: VehicleAvailability.FilterState[FilterName]
    ) {
      const newState = { ...filterState, [filterName]: value };
      setFilterState(newState);
    },
    [filterState]
  );
  const reloadAvailabilites = useCallback(
    async (newParams?: Common.PaginationParams) => {
      if (!newParams) {
        newParams = { page: dataState.page, pageSize: dataState.pageSize };
      }
      setIsFetchingAvailabilities(true);
      try {
        const result = await FleetApiClient.vehicle.getAvailabilities(vehicleId, {
          ...filterState,
          ...newParams,
        });
        setDataState(result.data);
      } finally {
        setIsFetchingAvailabilities(false);
      }
    },
    [dataState.page, dataState.pageSize, filterState, vehicleId]
  );
  const onSubmit = useCallback(
    async (newAvailabilityState: Fleet.VehicleAvailabilityActionDto) => {
      try {
        if (
          vehicleAvailabilityModal.type === ModalFormStatus.create ||
          vehicleAvailabilityModal.type === ModalFormStatus.duplicate
        ) {
          await FleetApiClient.vehicle.createAvailability(vehicleId, newAvailabilityState);
        } else {
          await FleetApiClient.vehicle.updateAvailability(
            vehicleId,
            vehicleAvailabilityModal.id,
            newAvailabilityState
          );
        }
        closeForm();
        await reloadAvailabilites();
      } catch (err) {
        console.warn(err);
      }
    },
    [closeForm, reloadAvailabilites, vehicleAvailabilityModal.id, vehicleAvailabilityModal.type, vehicleId]
  );
  const getVehicleInfo = useCallback(async () => {
    setVehicleInfo((await FleetApiClient.vehicle.getVehicle(vehicleId)).data);
  }, [vehicleId]);

  useEffect(() => {
    void getVehicleInfo();
  }, [getVehicleInfo]);

  let headerText = t("header");
  if (vehicleInfo) {
    headerText += ` ${vehicleInfo.name} (${vehicleInfo.licensePlate})`;
  }

  return (
    <Layout.Content headerComponent={<VehicleAvailability.Header text={headerText} />}>
      <Card className="pb-2" data-cy="viewVehicleAvailabilityListCard">
        <VehicleAvailability.Form
          onHide={closeForm}
          isEdit={vehicleAvailabilityModal.type === ModalFormStatus.edit}
          isShown={vehicleAvailabilityModal.type !== ModalFormStatus.closed}
          initialValues={vehicleAvailabilityModal.initialValues}
          onSubmit={onSubmit}
        />
        <VehicleAvailability.Filters filterState={filterState} updateFilter={updateFilter} />
        <VehicleAvailability.Table
          openNewForm={openNewForm}
          openEditForm={openEditForm}
          openDuplicateForm={openDuplicateForm}
          dataState={dataState}
          reloadAvailabilites={reloadAvailabilites}
          isFetching={isFetchingAvailabilities}
        />
      </Card>
    </Layout.Content>
  );
}
