import React from "react";
import { LatLngTuple, LatLngLiteral } from "leaflet";
import { Polyline } from "react-leaflet";
import getCenter from "geolib/es/getCenter";
import getRhumbLineBearing from "geolib/es/getRhumbLineBearing";
import { DirectionalArrowMarker } from "./DirectionalArrowMarker";

const latLngTupleToGeoLib = (latLng: LatLngTuple) => ({ lat: latLng[0], lng: latLng[1] });
const geoLibToLatLng = ({ latitude, longitude }: { longitude: number; latitude: number }): LatLngLiteral => ({
  lat: latitude,
  lng: longitude,
});
const createDirectionalMarker = (
  startPoint: LatLngTuple,
  destinationPoint: LatLngTuple,
  color: string,
  opacity: number
) => {
  const geoStartPoint = latLngTupleToGeoLib(startPoint);
  const geoDestinationPoint = latLngTupleToGeoLib(destinationPoint);
  const lineCenter = getCenter([geoStartPoint, geoDestinationPoint]);
  const arrowBearing = getRhumbLineBearing(geoStartPoint, geoDestinationPoint);
  if (lineCenter) {
    return (
      <DirectionalArrowMarker
        key={lineCenter.latitude + lineCenter.longitude + arrowBearing}
        position={geoLibToLatLng(lineCenter)}
        bearing={arrowBearing}
        color={color}
        opacity={opacity}
      />
    );
  }
  return null;
};
const getHalfPoint = (toCenter: number) => (toCenter <= 2 ? 0 : Math.floor(toCenter / 2));
const generateDirectionalArrows = (
  positions: LatLngTuple[],
  arrowSegmentSize: number,
  color: string,
  opacity: number
) => {
  const result: (React.ReactElement | null)[] = [];
  if (arrowSegmentSize === 0 || positions.length <= 1) {
    return result;
  }
  if (positions.length < arrowSegmentSize) {
    const startOffset = getHalfPoint(positions.length);
    result.push(createDirectionalMarker(positions[startOffset], positions[startOffset + 1], color, opacity));

    return result;
  }

  const startOffset = getHalfPoint(arrowSegmentSize);
  for (
    let segmentStart = startOffset;
    segmentStart < positions.length - (startOffset + 1);
    segmentStart += arrowSegmentSize
  ) {
    result.push(
      createDirectionalMarker(
        positions[segmentStart + startOffset],
        positions[segmentStart + startOffset + 1],
        color,
        opacity
      )
    );
  }

  return result;
};

interface Props {
  positions: LatLngTuple[];
  arrowSegmentSize: number;
  color: string;
  opacity: number;
}

export function DirectionalPolylineSegment({
  positions,
  arrowSegmentSize,
  color,
  opacity,
}: Props): React.ReactElement {
  return (
    <>
      <Polyline color={color} opacity={opacity} positions={positions} />
      {generateDirectionalArrows(positions, arrowSegmentSize, color, opacity)}
    </>
  );
}
