import React from "react";
import { Pagination } from "react-bootstrap";

interface Props {
  currentPage: number;
  numberOfPages: number;
  pagePadding?: number;
  onPageChange: (newPage: number) => void;
}

export function PaginationControls({
  currentPage,
  numberOfPages,
  onPageChange,
  pagePadding = 1,
}: Props): React.ReactElement {
  const handlePageChange = (newPage: number) => () => {
    onPageChange(newPage);
  };
  const frontMargin = currentPage - pagePadding - 1;
  const backMargin = numberOfPages - (currentPage + pagePadding);
  let paddingPagesStart = Math.min(numberOfPages - (pagePadding * 2 + 2), currentPage - pagePadding);
  if (paddingPagesStart < 2) {
    paddingPagesStart = 2;
  }
  let paddingPagesEnd = Math.max(pagePadding * 2 + 3, currentPage + pagePadding);
  if (paddingPagesEnd > numberOfPages - 1) {
    paddingPagesEnd = numberOfPages - 1;
  }
  const buttons = [];

  buttons.push(
    <Pagination.Prev key="Prev" disabled={currentPage - 1 <= 0} onClick={handlePageChange(currentPage - 1)} />
  );
  buttons.push(
    <Pagination.Item key="First" onClick={handlePageChange(1)} active={currentPage === 1}>
      {1}
    </Pagination.Item>
  );
  if (frontMargin > 1 && paddingPagesStart > 2) {
    buttons.push(<Pagination.Ellipsis key="EllipsisFront" disabled />);
  }

  for (let page = paddingPagesStart; page <= paddingPagesEnd; ++page) {
    buttons.push(
      <Pagination.Item onClick={handlePageChange(page)} key={page} active={page === currentPage}>
        {page}
      </Pagination.Item>
    );
  }

  if (backMargin > 1 && paddingPagesEnd < numberOfPages - 1) {
    buttons.push(<Pagination.Ellipsis key="EllipsisBack" disabled />);
  }
  if (numberOfPages > 1) {
    buttons.push(
      <Pagination.Item
        key="Last"
        onClick={handlePageChange(numberOfPages)}
        active={currentPage === numberOfPages}
      >
        {numberOfPages}
      </Pagination.Item>
    );
  }
  buttons.push(
    <Pagination.Next
      key="Next"
      disabled={numberOfPages - currentPage <= 0}
      onClick={handlePageChange(currentPage + 1)}
    />
  );
  return (
    <>
      <Pagination className="d-flex d-md-none">{buttons}</Pagination>
      <Pagination className="d-none d-md-flex" size="sm">
        {buttons}
      </Pagination>
    </>
  );
}
