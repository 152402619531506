import { Button, ButtonToolbar, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fleet } from "shared/types";
import { useAuthService } from "shared/hooks/useAuthService";
import { AppUrls, Role } from "shared/constants";
import { store } from "shared/state";
import { getPaymentsSettings } from "shared/state/selectors/global";
import { ChargeStrategy } from "shared/types/Settings";

interface Props {
  id: string;
  status: Fleet.MissionStatuses;
  openCancelModal: () => void;
  onRemoveMission: (missionId: string) => void;
  onFixMission: (missionId: string, method: string) => void;
}

export function ActionsCell({ id, openCancelModal, onRemoveMission, onFixMission, status }: Props) {
  const { t } = useTranslation("missions");

  const authService = useAuthService();
  const paymentsSettings = getPaymentsSettings(store.getState());
  const isFreeChargeStrategy = paymentsSettings.chargeStrategy === ChargeStrategy.Free;
  const isMissionFixingDisabled =
    status === Fleet.MissionStatuses.Cancelled ||
    status === Fleet.MissionStatuses.Finished ||
    !isFreeChargeStrategy;

  return authService.userHasOneOfRoles([Role.ADMIN]) ? (
    <div className="dropdown-actions dropdown-action-longer-texts text-center">
      <DropdownButton
        id="actionsDropdown"
        size="sm"
        title={<i className="fas fa-ellipsis-h" />}
        variant="outline-primary"
        className="dropdown-with-long-texts"
      >
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-right`}>
              {t("table.actions.cancelDescription")}
            </Tooltip>
          }
        >
          <Dropdown.Item
            as={ButtonToolbar}
            onClick={openCancelModal}
            disabled={status === Fleet.MissionStatuses.Cancelled || status === Fleet.MissionStatuses.Finished}
          >
            <i className="mr-2 mt-1 fas fa-users-slash" /> {t("table.actions.cancel")}
          </Dropdown.Item>
        </OverlayTrigger>
        <Dropdown.Item as={Link} to={AppUrls.MissionDetails(id)}>
          <i className="fas fa-search-location mr-1" />
          {t("table.actions.details")}
        </Dropdown.Item>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-right`}>
              {t("table.actions.fixWithRecalculateDescription")}
            </Tooltip>
          }
        >
          <Dropdown.Item
            as={ButtonToolbar}
            onClick={() => {
              if (window.confirm(t("table.actions.message.fixWithRecalculateMessage"))) {
                onFixMission(id, "recalculation");
              }
            }}
            disabled={isMissionFixingDisabled}
          >
            <i className="mr-2 mt-1 fas fa-sync-alt" /> {t("table.actions.fixWithRecalculate")}
          </Dropdown.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-right`}>
              {t("table.actions.fixWithCanceledRidesDescription")}
            </Tooltip>
          }
        >
          <Dropdown.Item
            as={ButtonToolbar}
            onClick={() => {
              if (window.confirm(t("table.actions.message.fixWithCanceledRidesMessage"))) {
                onFixMission(id, "cancellation");
              }
            }}
            disabled={isMissionFixingDisabled}
          >
            <i className="mr-2 mt-1 fas fa-sync-alt" /> {t("table.actions.fixWithCanceledRides")}
          </Dropdown.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-right`}>
              {t("table.actions.deleteDescription")}
            </Tooltip>
          }
        >
          <Dropdown.Item
            as={ButtonToolbar}
            onClick={() => {
              if (window.confirm(t("table.actions.message.deleteConfirmationMessage"))) {
                onRemoveMission(id);
              }
            }}
            disabled={status === Fleet.MissionStatuses.Cancelled || status === Fleet.MissionStatuses.Finished}
          >
            <i className="mr-2 mt-1 fas fa-trash" /> {t("general:delete")}
          </Dropdown.Item>
        </OverlayTrigger>
      </DropdownButton>
    </div>
  ) : authService.userHasOneOfRoles([Role.DISPATCHER]) ? (
    <div className="d-flex justify-content-center">
      <Button
        variant="outline-danger"
        size="sm"
        className="btn-xs mr-2"
        disabled={status === Fleet.MissionStatuses.Cancelled || status === Fleet.MissionStatuses.Finished}
        onClick={openCancelModal}
      >
        <i className="fas fa-ban mr-1" /> {t("table.actions.cancel")}
      </Button>
    </div>
  ) : null;
}
