import React, { ChangeEvent } from "react";
import { Form, Col, Row } from "react-bootstrap";

interface AlgorithmOption {
  identifier: string;
  displayLabel: string;
}

interface Props {
  totalMax: number;
  id: string;
  options: AlgorithmOption[];
  currentValues: Record<string, number>;
  error?: string;
  disabled?: boolean;
  onChange: (values: Record<string, number>) => void;
  decimalPlacesShown?: number;
  step?: number;
  labelColumnSize?: number;
}

export function EvaluatorsManualInput({
  options,
  currentValues,
  onChange,
  totalMax,
  disabled,
  error,
  step,
  decimalPlacesShown = 1,
  labelColumnSize = 8,
}: Props): React.ReactElement {
  const getInputProps = (costFunctionId: string) => ({
    onChange: (ev: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...currentValues,
        [costFunctionId]: parseFloat(ev.currentTarget.value),
      });
    },
    value: (currentValues[costFunctionId] || 0).toFixed(decimalPlacesShown),
  });
  return (
    <>
      {options.map(({ identifier, displayLabel }) => {
        return (
          <Form.Group key={identifier} className="mb-1" as={Row} controlId={`${identifier}FormInput`}>
            <Form.Label column md={labelColumnSize}>
              {displayLabel}
            </Form.Label>
            <Col className="pt-1">
              <input
                className="form-control"
                disabled={disabled}
                min={0}
                max={totalMax}
                step={step ? step : totalMax * 0.001}
                type="number"
                {...getInputProps(identifier)}
              />
            </Col>
          </Form.Group>
        );
      })}
      {error && <div className="text-danger">{error}</div>}
    </>
  );
}
