export const vehicle = {
  side: {
    title: "Pojazdy",
  },
  table: {
    header: {
      name: "Nazwa",
      licensePlate: "Numer rejestracyjny",
      capacity: "Pojemność",
      startStop: "Przystanek startowy",
      endStop: "Przystanek końcowy",
      actions: "Akcje",
    },
    actions: {
      mission: "Misja",
      addVehicle: "Dodaj pojazd",
      availability: "Dostępność",
    },
  },
  form: {
    addFormTitle: "Dodaj pojazd",
    editFormTitle: "Edytuj pojazd",
    name: "Nazwa pojazdu:",
    licensePlate: "Numer rejestracyjny:",
    capacity: "Pojemność:",
    startStop: "Przystanek startowy:",
    endStop: "Przystanek końcowy:",
    placeholderName: "Podaj nazwę pojazdu:",
    placeholderLicensePlate: "Podaj nr rejestracyjny:",
    placeholderStartStop: "Wybierz przystanek startowy:",
    placeholderEndStop: "Wybierz przystanek końcowy:",
  },
};
