import classNames from "classnames";
import { Common, Layout } from "components";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SettingsApiClient } from "shared/api";

const refreshTimeInMs = 30000;

export function TimeControlPage(): React.ReactElement {
  const { t } = useTranslation(["navigation", "time-control"]);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [timeInputValue, setTimeInputValue] = useState<Date>();
  const [currentSystemTime, setCurrentSystemTime] = useState<Date>();

  const resetTimeInput = useCallback(() => {
    setTimeInputValue(undefined);
    setValidationMessage(undefined);
  }, [setValidationMessage, setTimeInputValue]);
  const refreshSystemTime = useCallback(async () => {
    const result = await SettingsApiClient.getSystemTime();
    setCurrentSystemTime(parseISO(result.data));
  }, []);
  const setSystemTime = useCallback(async () => {
    if (timeInputValue) {
      await SettingsApiClient.setSystemTime(timeInputValue);
      resetTimeInput();
      void refreshSystemTime();
    } else {
      setValidationMessage(t("time-control:dateEmptyValidation"));
    }
  }, [refreshSystemTime, resetTimeInput, t, timeInputValue]);
  const resetSystemTime = useCallback(async () => {
    await SettingsApiClient.resetSystemTime();
    resetTimeInput();
    void refreshSystemTime();
  }, [refreshSystemTime, resetTimeInput]);

  useEffect(() => {
    void refreshSystemTime();

    const interval = setInterval(() => {
      void refreshSystemTime();
    }, refreshTimeInMs);

    return () => clearInterval(interval);
  }, [refreshSystemTime]);

  return (
    <Layout.Content simpleHeader={t("navigation:timeControl")}>
      <Card className="px-1 py-2" data-cy="viewTimeControlCard">
        {currentSystemTime && (
          <h5 className="ml-2">
            <p className="mb-1">{t("time-control:timeHeader")}:</p>
            <i>
              <Common.Clock initialDate={currentSystemTime} showDate />
            </i>
          </h5>
        )}
        <Form inline className="px-2 pb-0">
          <Form.Group className="position-relative">
            <Form.Label className="mr-2" htmlFor="formGridSystemTimeInput">
              {t("time-control:timeInputLabel")}
            </Form.Label>
            <Common.Input.DefaultDateTimePicker
              useLocalDateTime
              id="formGridSystemTimeInput"
              className={classNames("form-control form-control-sm", {
                "is-invalid": validationMessage,
              })}
              selected={timeInputValue}
              onChange={(date) => {
                if (!Array.isArray(date)) {
                  setTimeInputValue(date ?? undefined);
                  if (date) {
                    setValidationMessage(undefined);
                  }
                }
              }}
            />
          </Form.Group>
          <Form.Group>
            <Button className="mx-2" size="sm" onClick={setSystemTime}>
              {t("time-control:set")}
            </Button>
          </Form.Group>
          <span className="d-none d-sm-block">|</span>
          <Form.Group>
            <Button variant="secondary" className="ml-2" size="sm" onClick={resetSystemTime}>
              {t("time-control:reset")}
            </Button>
          </Form.Group>
        </Form>
        <Form.Control.Feedback type="invalid" className="ml-2 d-block">
          {validationMessage}
        </Form.Control.Feedback>
      </Card>
    </Layout.Content>
  );
}
