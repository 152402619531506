import React from "react";
import styles from "./Centered.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  children?: React.ReactNode;
  useChildrenAsMainBox?: boolean;
}

export function Centered({ children }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.centeredPageStyle}>
      <div className={styles.centeredBoxWrapper}>
        <h1>{t("appName")}</h1>
        {<div className={styles.contentBox}>{children}</div>}
      </div>
    </div>
  );
}
