import { TransitionGroup } from "react-transition-group";
import { Fleet } from "shared/types";
import { Table as BSTable } from "react-bootstrap";
import { TableRow } from "./TableRow";
import { useTranslation } from "react-i18next";

interface Props {
  previousVehicleSegments: Fleet.VehicleDriverSegment[];
  selectedVehicleSegments: Fleet.VehicleDriverSegment[];
}

export function Table({ previousVehicleSegments, selectedVehicleSegments }: Props) {
  const { t } = useTranslation("driverRides");
  const noSegments = selectedVehicleSegments.length <= 0;

  return (
    <BSTable responsive striped bordered>
      <thead>
        <tr>
          <th>{t("table.date")}</th>
          <th>{t("table.time")}</th>
          <th>{t("table.stop")}</th>
          <th>{t("table.pickups")}</th>
          <th>{t("table.dropoffs")}</th>
        </tr>
      </thead>
      {noSegments && (
        <tbody>
          <tr>
            <td colSpan={5} className="text-center font-italic">
              {t("noRides")}
            </td>
          </tr>
        </tbody>
      )}
      <TransitionGroup component="tbody">
        {selectedVehicleSegments.map((segment) => (
          <TableRow
            key={segment.id}
            segment={segment}
            previousSegment={previousVehicleSegments.find((s) => s.id === segment.id)}
          />
        ))}
      </TransitionGroup>
    </BSTable>
  );
}
