import React from "react";
import { AuthService, AuthContext } from "shared/authService";

interface Props {
  children?: React.ReactNode;
}

export function AuthProvider({ children }: Props): React.ReactElement {
  return <AuthContext.Provider value={AuthService}>{children}</AuthContext.Provider>;
}
