import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { divIcon } from "leaflet";
import classNames from "classnames";

import { Common } from "shared/types";
import styles from "./Markers.module.css";

interface Props {
  location: Common.Location;
  children?: string | React.ReactNode;
  iconStyles?: React.CSSProperties;
  isActive?: boolean;
}

export const SegmentLocationMarker = ({
  location,
  children,
  iconStyles,
  isActive,
}: Props): React.ReactElement => {
  const color: string = isActive ? "bg-black" : "bg-white";
  const oppositeColor: string = isActive ? "white" : "black";
  return (
    <Marker
      icon={divIcon({
        html: renderToStaticMarkup(
          <div className={classNames(styles.marker, styles.outlined)}>
            <div className={styles.shadow} />
            <div className={classNames(color, styles.shape, isActive ? styles.activeBorder : "")} />
            <div className={classNames(color, styles.pointer)} />
            <div className={classNames(color, styles.background)}>
              <i
                className={classNames(`fas fa-building text-${oppositeColor}`, styles.icon)}
                style={iconStyles}
              ></i>
            </div>
          </div>
        ),
        iconSize: [30, 30],
      })}
      position={[location.lat, location.lng]}
    >
      {children && <Tooltip>{children}</Tooltip>}
    </Marker>
  );
};
