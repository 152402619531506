import { ReactElement, useState, useCallback, useEffect, useReducer } from "react";
import { FleetApiClient } from "shared/api";
import { Fleet } from "shared/types";
import { v4 as uuid } from "uuid";
import { Layout, Stops } from "../components";

import actionCreatorFactory, { Action, isType } from "typescript-fsa";
import { useTranslation } from "react-i18next";
import { ModalFormStatus, UseTranslationResult } from "shared/types/Common";

const actionCreator = actionCreatorFactory();
export const StopModalActions = {
  showEdit: actionCreator<{ stop: Fleet.Stop }>("SHOW_EDIT"),
  showAdd: actionCreator<{ stop: Fleet.Stop }>("SHOW_ADD"),
  showDetails: actionCreator<{ stop: Fleet.Stop }>("SHOW_DETAILS"),
  close: actionCreator<void>("CLOSE"),
};

type FormStop = Parameters<typeof FleetApiClient.stop.addStop>[0];

const defaultStop: Fleet.Stop = {
  stopId: "",
  name: "",
  city: "",
  street: "",
  identifier: "",
  location: {
    lat: 0,
    lng: 0,
  },
  type: Fleet.StopType.All,
  imagesUrls: [],
};

function reducer(state: Fleet.StopModalState, action: Action<unknown>): Fleet.StopModalState {
  if (isType(action, StopModalActions.showAdd)) {
    return { ...state, status: ModalFormStatus.create, initialFormValues: action.payload.stop };
  }
  if (isType(action, StopModalActions.showEdit)) {
    return { ...state, status: ModalFormStatus.edit, initialFormValues: action.payload.stop };
  }
  if (isType(action, StopModalActions.showDetails)) {
    return { ...state, status: ModalFormStatus.readonly, initialFormValues: action.payload.stop };
  }
  if (isType(action, StopModalActions.close)) {
    return {
      ...state,
      status: ModalFormStatus.closed,
      initialFormValues: defaultStop,
    };
  }
  return state;
}

export function StopsPage(): ReactElement {
  const { t } = useTranslation("stop") as UseTranslationResult;
  const [stops, setStops] = useState<Fleet.Stop[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [stopModalState, dispatch] = useReducer(reducer, {
    status: ModalFormStatus.closed,
    initialFormValues: defaultStop,
  });

  const getStops = useCallback(async () => {
    setIsFetching(true);
    setStops((await FleetApiClient.stop.getStops()).data);
    setIsFetching(false);
  }, [setStops]);

  const deleteStop = useCallback(
    async (stopId: string) => {
      try {
        setIsFetching(true);
        await FleetApiClient.stop.deleteStop(stopId);
        await getStops();
      } catch (err) {
        console.warn(err);
      }
    },
    [getStops]
  );

  const closeModal = useCallback(() => dispatch(StopModalActions.close()), []);

  const onOpenStopAdd = useCallback(
    () => dispatch(StopModalActions.showAdd({ stop: { ...defaultStop, id: uuid(), imagesUrls: [] } })),
    []
  );
  const onOpenStopEdit = useCallback(
    (id: string) =>
      dispatch(
        StopModalActions.showEdit({
          stop: stops.find((e) => e.stopId === id) ?? { ...defaultStop, id: uuid() },
        })
      ),
    [stops]
  );
  const onOpenStopDetails = useCallback(
    (id: string) =>
      dispatch(
        StopModalActions.showDetails({
          stop: stops.find((e) => e.stopId === id) ?? { ...defaultStop, id: uuid() },
        })
      ),
    [stops]
  );
  const postStop = useCallback(
    async (stopToSave: FormStop) => {
      try {
        await FleetApiClient.stop.addStop(stopToSave);
        await getStops();
      } catch (err) {
        console.warn(err);
      }
    },
    [getStops]
  );

  useEffect(() => {
    void getStops();
  }, [getStops]);

  return (
    <Layout.Content simpleHeader={t("side.title")}>
      <Layout.CollapsibleCard
        className="mt-2"
        data-cy="mapStops"
        headerContent={<h3 className="card-title">{t("map")}</h3>}
        defaultExpanded={false}
      >
        <Stops.FormMap stops={stops} />
      </Layout.CollapsibleCard>
      <Layout.CollapsibleCard
        className="mt-2"
        data-cy="stops"
        headerContent={<h3 className="card-title">{t("list")}</h3>}
      >
        <Stops.Table
          stops={stops}
          onStopRemove={deleteStop}
          openStopAdd={onOpenStopAdd}
          openStopEdit={onOpenStopEdit}
          openStopDetails={onOpenStopDetails}
          isLoading={isFetching}
        />
        <Stops.Form stopModalState={stopModalState} onStopSave={postStop} onClose={closeModal}></Stops.Form>
      </Layout.CollapsibleCard>
    </Layout.Content>
  );
}
