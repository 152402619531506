import { ReactElement } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./shared/state";
import { AppUrls, ENV } from "shared/constants";
import { AuthConsumer } from "shared/authService";
import { Layout, Common } from "components";
import { privatePages, privateAfterLoginPages, publicPages, NotFoundPage } from "./pages";

function generatePagesInfo<Page extends { path: string }>(
  Component: typeof Route | typeof Common.PrivateRoute,
  pages: Page[]
): [string[], JSX.Element[]] {
  const paths = [];
  const pageRoutes = [];
  for (const page of pages) {
    paths.push(page.path);
    pageRoutes.push(<Component key={page.path} {...page} />);
  }
  return [paths, pageRoutes];
}
const [publicPaths, publicPageRoutes] = generatePagesInfo(Route, publicPages);
const [privatePaths, privatePageRoutes] = generatePagesInfo(Common.PrivateRoute, privatePages);
const [privateAfterLoginPaths, privateAfterLoginPageRoutes] = generatePagesInfo(
  Common.PrivateRouteAfterLogin,
  privateAfterLoginPages
);

export function App(): ReactElement {
  return (
    <Common.AuthProvider>
      <Provider store={store}>
        <Router basename={`${ENV.BASE_PATH}/`}>
          <Switch>
            <Route exact path={publicPaths}>
              <Switch>{publicPageRoutes}</Switch>
            </Route>
            <Route exact path={privatePaths}>
              <AuthConsumer>
                {(authService) =>
                  authService?.isAuthenticated() ? (
                    <Layout.Wrapper>
                      <Switch>{privatePageRoutes}</Switch>
                    </Layout.Wrapper>
                  ) : (
                    <>{authService?.signinRedirect()}</>
                  )
                }
              </AuthConsumer>
            </Route>
            <Route exact path={privateAfterLoginPaths}>
              <AuthConsumer>
                {(authService) =>
                  authService?.isAuthenticated() ? (
                    <Layout.Centered>
                      <Switch>{privateAfterLoginPageRoutes}</Switch>
                    </Layout.Centered>
                  ) : (
                    <>{authService?.signinRedirect()}</>
                  )
                }
              </AuthConsumer>
            </Route>
            <Route exact path={AppUrls.NotFound}>
              <NotFoundPage />
            </Route>
            <Route>
              <Redirect to={AppUrls.NotFound} />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </Common.AuthProvider>
  );
}
