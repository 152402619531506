import React from "react";
import { Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";

interface Props {
  children: React.ReactElement;
  requiredRoles: Role[];
}

export function RoleRestriction({ requiredRoles, children }: Props): React.ReactElement | null {
  const authService = useAuthService();
  if (authService.userHasOneOfRoles(requiredRoles)) {
    return children;
  } else {
    return null;
  }
}
