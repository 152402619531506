import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { WeekDays } from "shared/types/Common";

interface WeekdaysInputProps {
  value: WeekDays[];
  onChange?: (values: WeekDays[]) => void;
  readOnly?: boolean;
}

export function WeekdaysInput({ value = [], readOnly = false, onChange }: WeekdaysInputProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex">
      {Object.values(WeekDays)
        .filter((v) => typeof v === "number")
        .map((weekDay) => {
          const weekDayValue = weekDay as WeekDays;
          const isChecked = value.includes(weekDayValue);
          return (
            <div key={weekDay} className="d-flex flex-column justify-content-center align-items-center mr-1">
              <div style={{ width: "fit-content" }} className="mr-1">
                {t(`weekDayNamesShort.${weekDayValue}`)}
              </div>
              <Form.Check
                inline
                readOnly={readOnly}
                type="checkbox"
                checked={isChecked}
                className="mr-0"
                onChange={() => {
                  if (onChange) {
                    onChange(isChecked ? value.filter((wd) => wd !== weekDay) : [...value, weekDayValue]);
                  }
                }}
              />
            </div>
          );
        })}
    </div>
  );
}
