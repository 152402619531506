import actionCreatorFactory from "typescript-fsa";

import { Planner, Settings, User, Common } from "../../types";
import {
  PROCESSING_REQUEST_POST,
  PROCESSING_FILTERS_SET,
  NOTIFICATION_HIDE,
  NOTIFICATION_SHOW,
  API_ERROR_SHOW,
  FLEET_CONFIGURATION_GET,
} from "shared/constants/actionTypes";
import { UserRealm } from "shared/authService";

const actionCreator = actionCreatorFactory();

export const postProcessingRequest =
  actionCreator.async<FormData, { data: Planner.Result }, { error: string }>(PROCESSING_REQUEST_POST);

export const getConfiguration =
  actionCreator.async<void, { data: Settings.AppSettings }, { error: string }>(FLEET_CONFIGURATION_GET);

export const setProcessingFilters =
  actionCreator<{
    vehicleId?: string;
    stopId?: string;
  }>(PROCESSING_FILTERS_SET);

export const showNotification =
  actionCreator<{
    message: JSX.Element | string;
    color: string;
    disableAutoHide?: boolean;
    guid?: string;
    position?: Common.SystemNotificationPositions;
  }>(NOTIFICATION_SHOW);

export const hideNotification =
  actionCreator<{
    guid: string;
  }>(NOTIFICATION_HIDE);

export const apiCallErrorMesage = actionCreator<Record<string, unknown>>(API_ERROR_SHOW, null, true);

export const loginSuccess = actionCreator<User.LoginResponse>("LOGIN_SUCCESS");
export const logout = actionCreator("LOGOUT");
export const realmChosen = actionCreator<UserRealm>("REALM_CHOSEN");
