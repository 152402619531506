import { Navigation } from "./shared/types";
import { Role, AppUrls } from "shared/constants";

export const siteNavigation: Navigation.Shape = {
  Flota: {
    categoryItems: {
      missions: {
        link: AppUrls.Missions,
        iconClass: "nav-icon fas fa-route",
        visibleToRoles: [Role.ADMIN, Role.DISPATCHER],
      },
      stops: {
        link: AppUrls.Stops,
        iconClass: "nav-icon fas fa-map-marker-alt",
        visibleToRoles: [Role.ADMIN, Role.DISPATCHER],
      },
      forbiddenTransitions: {
        link: AppUrls.ForbiddenTransitions,
        iconClass: "nav-icon fas fa-times-circle",
        visibleToRoles: [Role.ADMIN],
      },
      vehicles: {
        link: AppUrls.Vehicles,
        iconClass: "nav-icon fas fa-car-side",
        visibleToRoles: [Role.ADMIN],
      },
      vehicleLocations: {
        link: AppUrls.VehicleLocations,
        iconClass: "nav-icon fas fa-map-marked-alt",
        visibleToRoles: [Role.ADMIN, Role.DISPATCHER],
      },
      rides: {
        link: AppUrls.Rides,
        iconClass: "nav-icon fas fa-user-friends",
        visibleToRoles: [Role.ADMIN, Role.DISPATCHER],
      },
      timeControl: {
        link: AppUrls.TimeControl,
        iconClass: "nav-icon fas fa-hourglass-half",
        visibleToRoles: [Role.ADMIN],
      },
      configuration: {
        link: AppUrls.Configuration,
        iconClass: "nav-icon fas fa-cogs",
        visibleToRoles: [Role.ADMIN],
      },
      reports: {
        link: AppUrls.Reports,
        iconClass: "nav-icon fas fa-chart-line",
        visibleToRoles: [Role.ADMIN, Role.DISPATCHER],
      },
    },
  },
  Kierowca: {
    categoryItems: {
      driverRides: {
        link: AppUrls.DriverRides,
        iconClass: "nav-icon fas fa-list-ul",
        visibleToRoles: [Role.ADMIN, Role.DRIVER],
      },
    },
  },
};
