import { Action } from "redux";
import { isType } from "typescript-fsa";
import { loginSuccess, logout, realmChosen } from "../../actions/actionCreators";

import { User } from "shared/types";
import jwtDecode from "jwt-decode";

interface AccessTokenData {
  access_roles: {
    global: string[];
    realms: { [realm: string]: string[] };
  };
  username: string;
  is_support_account: boolean;
}

export interface Realm {
  apiUrl?: string;
  id?: string;
  name?: string;
}

interface UserState {
  userData?: User.LoginResponse;
  accessTokenData?: AccessTokenData;
  realm?: Realm;
}

const initialState: UserState = {};

export default function user(state: UserState = initialState, action: Action): UserState {
  if (isType(action, loginSuccess)) {
    const decodedAccessToken: AccessTokenData = jwtDecode(action.payload.access_token);
    if (
      (decodedAccessToken.is_support_account && decodedAccessToken.access_roles.global.length > 0) ||
      (state.realm && state.realm.id && decodedAccessToken.access_roles.realms[state.realm.id]?.length > 0)
    ) {
      return { ...state, userData: action.payload, accessTokenData: decodedAccessToken };
    }
    return { userData: action.payload, accessTokenData: decodedAccessToken };
  }

  if (isType(action, realmChosen)) {
    return {
      ...state,
      realm: { ...action.payload },
    };
  }

  if (isType(action, logout)) {
    return {};
  }

  return state;
}
