import { Form } from "react-bootstrap";

import styles from "./MissionDashboard.module.css";

interface Props {
  highlightsEnabled: boolean;
  toggleHighlights: () => void;
}

export function SettingsControls({ highlightsEnabled, toggleHighlights }: Props) {
  return (
    <div className="leaflet-bottom leaflet-left">
      <div className="leaflet-control leaflet-bar">
        <div className={styles.settingsControls}>
          <h5>Settings</h5>
          <Form>
            <Form.Check
              type="switch"
              id="highlights-switch"
              label="Enable location highlights"
              checked={highlightsEnabled}
              onChange={toggleHighlights}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
