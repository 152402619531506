import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { Role, AppUrls } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";

interface Props extends RouteProps {
  allowedRoles?: Role[];
}

export function PrivateRoute({ component: Component, allowedRoles, ...rest }: Props): React.ReactElement {
  const authService = useAuthService();
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!authService.hasChosenRealmId()) {
          return <Redirect to={AppUrls.Realms} />;
        } else if (!authService.userCanUseApp()) {
          return <Redirect to={AppUrls.Unauthorized} />;
        } else if (Component && authService.userHasOneOfRoles(allowedRoles)) {
          return <Component {...routeProps} />;
        } else {
          return <Redirect to={AppUrls.NotFound} />;
        }
      }}
    />
  );
}

export function PrivateRouteAfterLogin({
  component: Component,
  allowedRoles,
  ...rest
}: Props): React.ReactElement {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (Component) {
          return <Component {...routeProps} />;
        } else {
          return <Redirect to={AppUrls.NotFound} />;
        }
      }}
    />
  );
}
