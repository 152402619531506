export class SettingsUrls {
  protected static Prefix = "/api/settings/";
  public static Changelog = SettingsUrls.Prefix + "changelog";
  public static Configuration = SettingsUrls.Prefix + "configuration";
  public static TimeZones = SettingsUrls.Prefix + "time-zones";
  public static DefaultCancelMessage = SettingsUrls.Prefix + "default-cancel-push-message";
  public static Time = SettingsUrls.Prefix + "time";
  public static TimeReset = SettingsUrls.Time + "/reset";
  public static AlgorithmConfiguration = SettingsUrls.Prefix + "algorithm-config";
  public static AlgorithmConfigurationReset = SettingsUrls.AlgorithmConfiguration + "/reset";
}

export class PlannerUrls {
  protected static Prefix = "/api/planner/";
  public static Process = PlannerUrls.Prefix + "process";
  public static Options = PlannerUrls.Prefix + "options";
  public static Validate = PlannerUrls.Prefix + "validate";
}

export class FleetUrls {
  protected static Prefix = "/api/fleet/";
  // Vehicles
  public static Vehicle = FleetUrls.Prefix + "vehicle";
  public static VehicleAvailability = (vehicleId: string) =>
    `${FleetUrls.SpecificVehicle(vehicleId)}/availability`;
  public static SpecificVehicleAvailability = (vehicleId: string, availabilityId: string) =>
    `${FleetUrls.VehicleAvailability(vehicleId)}/${availabilityId}`;
  public static SpecificVehicle = (vehicleId: string) => `${FleetUrls.Vehicle}/${vehicleId}`;
  public static VehiclePosition = (vehicleId: string) => `${FleetUrls.SpecificVehicle(vehicleId)}/position`;
  public static VehicleMission = (vehicleId: string) => `${FleetUrls.SpecificVehicle(vehicleId)}/mission`;
  public static NearestVehicleSegments = (vehicleId: string) =>
    `${FleetUrls.SpecificVehicle(vehicleId)}/segments/nearest`;
  public static VehicleActivePositions = `${FleetUrls.Vehicle}/active-positions`;
  // Missions
  public static Missions = FleetUrls.Prefix + "mission";
  public static MissionOverviewList = FleetUrls.Missions + "/overview";
  public static MissionOverview = (id: string) => `${FleetUrls.MissionOverviewList}/${id}`;
  public static SpecificMission = (vehicleId: string, missionId: string) =>
    `${FleetUrls.Vehicle}/${vehicleId}/mission/${missionId}`;
  public static CancelMissionOperator = (vehicleId: string, missionId: string) =>
    `${FleetUrls.SpecificMission(vehicleId, missionId)}/cancel-by-operator`;
  public static StartMission = (vehicleId: string, missionId: string) =>
    `${FleetUrls.SpecificMission(vehicleId, missionId)}/start`;
  public static FinishMission = (vehicleId: string, missionId: string) =>
    `${FleetUrls.SpecificMission(vehicleId, missionId)}/finish`;
  public static RemoveMission = (missionId: string) => `${FleetUrls.Missions}/${missionId}`;
  public static FixMission = (missionId: string) => `${FleetUrls.Missions}/${missionId}/fix`;
  // Rides
  public static Rides = FleetUrls.Prefix + "ride";
  public static RideOverviewList = FleetUrls.Rides + "/overview";
  public static RideOverview = (rideId: string) => `${FleetUrls.Rides}/${rideId}/overview`;
  public static RideRegisterByOperator = `${FleetUrls.Rides}/register-by-operator`;
  public static RideCancelByOperator = (rideId: string) => `${FleetUrls.Rides}/${rideId}/cancel`;
  // Stops
  public static Stops = FleetUrls.Prefix + "stop";
  public static StopSearch = FleetUrls.Stops + "/search";
  public static StopDelete = (stopId: string) => `${FleetUrls.Stops}/${stopId}`;
  public static ForbiddenTransitions = FleetUrls.Stops + "/forbidden-transition";
  // Reports
  public static Reports = "/api/reports/";
  public static DashboardFile = FleetUrls.Reports + "dashboard-file";
  public static Dashboard = FleetUrls.Reports + "dashboard"
}

export class UserUrls {
  protected static Prefix = "/api/user/";
  public static Login = UserUrls.Prefix + "login";
  public static Register = UserUrls.Prefix + "register";
  public static UpdateUserData = UserUrls.Prefix + "update-user-info";
}
