import { Common } from "../..";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface FilterState {
  id?: string;
  validFrom?: Date | null;
  validTo?: Date | null;
}

interface Props {
  filterState: FilterState;
  updateFilter: <StateKey extends keyof FilterState>(key: StateKey, value: FilterState[StateKey]) => void;
}

export function Filters({ filterState, updateFilter }: Props) {
  const { t } = useTranslation("vehicle/availability");
  const updateNativeInputFilter = (input: "id") => (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter(input, ev.currentTarget.value);
  };
  const updateDateInputFilter = (input: "validFrom" | "validTo") => (date: Date | [Date, Date] | null) => {
    if (!Array.isArray(date)) {
      updateFilter(input, date);
    }
  };
  return (
    <Form className="px-3 pt-3 pb-0">
      <Form.Row>
        <Form.Group as={Col} controlId="formGridId">
          <Form.Label>{t("filters.id")}</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            value={filterState.id || ""}
            onChange={updateNativeInputFilter("id")}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridValidFrom" className="pl-0">
          <Form.Label>{t("filters.validFrom")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridValidFrom"
            className="form-control form-control-sm"
            selected={filterState.validFrom}
            onChange={updateDateInputFilter("validFrom")}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridValidTo">
          <Form.Label>{t("filters.validTo")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridValidTo"
            className="form-control form-control-sm"
            selected={filterState.validTo}
            onChange={updateDateInputFilter("validTo")}
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
