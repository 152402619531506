export const navigation = {
  home: "Strona główna",
  planner: "Planer",
  missions: "Misje",
  vehicles: "Pojazdy",
  vehicleLocations: "Lokalizacja pojazdów",
  stops: "Przystanki",
  rides: "Przejazdy",
  timeControl: "Kontrola czasu",
  configuration: "Konfiguracja",
  start: "Start",
  forbiddenTransitions: "Zabronione przejazdy",
  driverRides: "Nadchodzące przejazdy",
  reports: "Raporty",
};
