import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.RequestTimeDataSource[];
}

export function RideRequestTimeChart({ dataSource }: Props) {
  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        width: 400,
        type: "donut",
        toolbar: {
          show: true,
        },
      },
      labels: dataSource.map((d) => d.intervalName),
      fill: {
        type: "gradient",
      },
    }),
    [dataSource]
  );

  const series = useMemo(() => dataSource.map((d) => d.requestsCount), [dataSource]);

  return <ReactApexChart options={options} series={series} type="donut" height={412} />;
}
