import produce from "immer";
import actionCreatorFactory, { AnyAction, isType } from "typescript-fsa";
import { Common, Fleet } from "shared/types";
import { Rides } from "components";

type DataState = Common.Paginated<Fleet.RideOverviewListItemDto>;

interface State {
  rides: {
    isFetching: boolean;
    state: DataState;
  };
  filters: Rides.FilterState;
  modals: {
    success: {
      ride?: Fleet.Ride;
    };
    create: {
      shown: boolean;
    };
  };
}
const actionCreator = actionCreatorFactory();

export const ridesPageActions = {
  startFetching: actionCreator("START_FETCHING"),
  finishFetching: actionCreator("FINISH_FETCHING"),
  showCreateModal: actionCreator("SHOW_CREATE_MODAL"),
  hideCreateModal: actionCreator("HIDE_CREATE_MODAL"),
  showSuccessModal: actionCreator<Fleet.Ride>("SHOW_SUCCESS_MODAL"),
  hideSuccessModal: actionCreator("HIDE_SUCCESS_MODAL"),
  setDataState: actionCreator<DataState>("SET_DATA_STATE"),
  updateFilterValue:
    actionCreator<{
      filterName: keyof Rides.FilterState;
      value: Rides.FilterState[keyof Rides.FilterState];
    }>("UPDATE_FILTER_VALUE"),
};

export const initialState: State = {
  rides: {
    isFetching: false,
    state: { data: [], totalCount: 0, page: 1, pageSize: 5 },
  },
  filters: {},
  modals: {
    create: { shown: false },
    success: {},
  },
};

export const ridesReducer = produce((draft: State, action: AnyAction) => {
  if (isType(action, ridesPageActions.startFetching)) {
    draft.rides.isFetching = true;
  } else if (isType(action, ridesPageActions.finishFetching)) {
    draft.rides.isFetching = false;
  } else if (isType(action, ridesPageActions.showCreateModal)) {
    delete draft.modals.success.ride;
    draft.modals.create.shown = true;
  } else if (isType(action, ridesPageActions.hideCreateModal)) {
    draft.modals.create.shown = false;
  } else if (isType(action, ridesPageActions.showSuccessModal)) {
    draft.modals.create.shown = false;
    draft.modals.success.ride = action.payload;
  } else if (isType(action, ridesPageActions.hideSuccessModal)) {
    delete draft.modals.success.ride;
  } else if (isType(action, ridesPageActions.setDataState)) {
    draft.rides.state = action.payload;
  } else if (isType(action, ridesPageActions.updateFilterValue)) {
    const value = action.payload.value;
    if (typeof value === "string" && value.length <= 0) {
      delete draft.filters[action.payload.filterName];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      draft.filters[action.payload.filterName] = value as any;
    }
  }
});
