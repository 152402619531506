import { Role, AppUrls } from "../shared/constants";
import { HomePage } from "./Home";
import { RealmsPage } from "./Realms/Realms";
import { ContactPage } from "./Contact";
import { StopsPage } from "./Stops";
import { VehiclesPage } from "./Vehicles";
import { MissionDashboardPage } from "./MissionDashboard";
import { MissionsPage } from "./Missions";
import { MissionDetailsPage } from "./MissionDetails/MissionDetails";
import { RidesPage } from "./Rides";
import { TimeControlPage } from "./TimeControl";
import { ConfigurationPage } from "./Configuration";
import { Common } from "components";
import { VehicleAvailabilityPage } from "./VehicleAvailability";
import { VehicleLocationsPage } from "./VehicleLocations";
import { ForbiddenTransitionsPage } from "./ForbiddenTransitions";
import { DriverRidesPage } from "./DriverRides";
import { ReportsPage } from "./Reports";

interface PrivatePage extends React.ComponentProps<typeof Common.PrivateRoute> {
  path: string;
}

export const privatePages: PrivatePage[] = [
  {
    path: AppUrls.Index,
    component: HomePage,
    exact: true,
  },
  {
    path: AppUrls.Contact,
    component: ContactPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.Missions,
    component: MissionsPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
  {
    path: AppUrls.Stops,
    component: StopsPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
  {
    path: AppUrls.ForbiddenTransitions,
    component: ForbiddenTransitionsPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.Vehicles,
    component: VehiclesPage,
    allowedRoles: [Role.ADMIN],
    exact: true,
  },
  {
    path: AppUrls.VehicleLocations,
    component: VehicleLocationsPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
  {
    path: AppUrls.VehicleMission(":vehicleId"),
    component: MissionDashboardPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.VehicleAvailability(":vehicleId"),
    component: VehicleAvailabilityPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.Rides,
    component: RidesPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
  {
    path: AppUrls.TimeControl,
    component: TimeControlPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.MissionDetails(":missionId"),
    component: MissionDetailsPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.Configuration,
    component: ConfigurationPage,
    allowedRoles: [Role.ADMIN],
  },
  {
    path: AppUrls.DriverRides,
    component: DriverRidesPage,
    allowedRoles: [Role.ADMIN, Role.DRIVER],
  },
  {
    path: AppUrls.Reports,
    component: ReportsPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
];

export const privateAfterLoginPages: PrivatePage[] = [
  {
    path: AppUrls.Realms,
    component: RealmsPage,
    allowedRoles: [Role.ADMIN, Role.DISPATCHER],
  },
];
