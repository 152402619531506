import { ReactElement, useCallback, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FleetApiClient } from "shared/api";
import { Common, Fleet } from "shared/types";

import { Layout, Missions } from "../components";

type DataState = Common.Paginated<Fleet.MissionOverviewListItemDto>;

export function MissionsPage(): ReactElement {
  const { t } = useTranslation("navigation");
  const [isFetching, setIsFetching] = useState(false);
  const [filterState, setFilterState] = useState<Missions.FilterState>({});
  const [dataState, setDataState] = useState<DataState>({ data: [], totalCount: 0, page: 1, pageSize: 5 });
  const updateFilter = useCallback(
    function updateFilter<FilterName extends keyof Missions.FilterState>(
      filterName: FilterName,
      value: Missions.FilterState[FilterName]
    ) {
      if (typeof value === "string" && value.length <= 0) {
        value = undefined;
      }
      const newState = { ...filterState, [filterName]: value };
      setFilterState(newState);
    },
    [filterState]
  );
  const reloadMissions = useCallback(
    async (newParams?: Common.PaginationParams) => {
      if (!newParams) {
        newParams = { page: dataState.page, pageSize: dataState.pageSize };
      }
      setIsFetching(true);
      try {
        const result = await FleetApiClient.missions.getMissions({ ...filterState, ...newParams });
        setDataState(result.data);
      } finally {
        setIsFetching(false);
      }
    },
    [dataState.page, dataState.pageSize, filterState]
  );

  return (
    <Layout.Content simpleHeader={t("missions")}>
      <Card className="pb-2" data-cy="viewMissionsListCard">
        <Missions.Filters filterState={filterState} updateFilter={updateFilter} />
        <Missions.Table dataState={dataState} isFetching={isFetching} reloadMissions={reloadMissions} />
      </Card>
    </Layout.Content>
  );
}
