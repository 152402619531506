import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import actionCreatorFactory, { isType } from "typescript-fsa";
import produce from "immer";
import { Column, ColumnInstance, useTable } from "react-table";
import { Button, Table } from "react-bootstrap";
import { Common } from "shared/types";
import { PaginationRow } from "./PaginationRow";
import { TopRow } from "./TopRow";
import { SortableHeader } from "./SortableHeader";
import { useTranslation } from "react-i18next";
import { AnyAction } from "redux";

const actionCreator = actionCreatorFactory();

const TableStateActions = {
  changePageSize: actionCreator<{ pageSize: number }>("CHANGE_PAGE_SIZE"),
  toggleSortBy: actionCreator<{ sortBy: string }>("TOGGLE_SORT_BY"),
};

interface TableState extends Omit<Common.PaginationParams, "page"> {
  sortAscending: boolean;
}

const defaultState: TableState = {
  pageSize: 5,
  sortAscending: false,
};

const tableReducer = produce((draft: TableState, action: AnyAction) => {
  if (isType(action, TableStateActions.changePageSize)) {
    draft.pageSize = action.payload.pageSize;
  }
  if (isType(action, TableStateActions.toggleSortBy)) {
    if (draft.sort === action.payload.sortBy) {
      if (draft.sortAscending) {
        delete draft.sort;
        draft.sortAscending = false;
      } else {
        draft.sortAscending = true;
      }
    } else {
      draft.sort = action.payload.sortBy;
      draft.sortAscending = false;
    }
  }
}, defaultState);

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<Row extends object> {
  isFetching?: boolean;
  columns: Column<Row>[];
  data: Row[];
  page: number;
  totalCount: number;
  fetchData: (params: Common.PaginationParams) => Promise<void>;
  initialState?: TableState;
  additionalButtons?: React.ReactNode;
  responsive?: boolean;
  autoRefreshInterval?: number;
}

const mapStateToFetchParams = (state: TableState): Omit<Common.PaginationParams, "page"> => {
  const { sortAscending, ...rest } = state;
  let sort = state.sort;
  if (sort) {
    sort += sortAscending ? " asc" : " desc";
  }
  return { ...rest, sort };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function ControlledTable<Row extends object>({
  fetchData,
  columns,
  data,
  page,
  totalCount,
  additionalButtons,
  isFetching = false,
  initialState = defaultState,
  responsive = false,
  autoRefreshInterval,
}: Props<Row>): React.ReactElement {
  const { t } = useTranslation();
  const [tableState, dispatch] = useReducer(tableReducer, initialState);
  useEffect(() => {
    void fetchData({ ...mapStateToFetchParams(tableState), page });
  }, [fetchData, page, tableState]);
  const table = useTable({
    columns,
    data,
  });
  const refresh = useCallback(() => {
    void fetchData({ ...mapStateToFetchParams(tableState), page });
  }, [fetchData, page, tableState]);
  useEffect(() => {
    if (autoRefreshInterval === undefined || autoRefreshInterval === 0) {
      return;
    }

    const id = setInterval(() => {
      refresh();
    }, autoRefreshInterval);

    return () => clearInterval(id);
  }, [autoRefreshInterval, fetchData, page, refresh, tableState]);
  const onPageChange = useCallback(
    (page: number) => {
      void fetchData({ ...mapStateToFetchParams(tableState), page });
    },
    [fetchData, tableState]
  );
  const onPageSizeChange = useCallback(
    (pageSize: number) => {
      dispatch(TableStateActions.changePageSize({ pageSize }));
    },
    [dispatch]
  );
  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / tableState.pageSize);
  }, [tableState.pageSize, totalCount]);
  return (
    <>
      <TopRow
        totalRows={totalCount}
        currentPageSize={tableState.pageSize}
        onPageSizeChange={onPageSizeChange}
      >
        {additionalButtons}
        <Button disabled={isFetching} size="sm" onClick={refresh}>
          {t("refresh")} <i className="ml-1 fas fa-sync" data-cy="refreshButton" />
        </Button>
      </TopRow>
      <div className="position-relative my-2">
        <Table striped responsive={responsive} className="border-bottom mb-0" {...table.getTableProps()}>
          <thead>
            {table.headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header) => {
                  const { getHeaderProps, width, sortAccessor, render } = header as ColumnInstance<Row> & {
                    sortAccessor?: string;
                  };
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      {...getHeaderProps({
                        style: width && width !== 150 ? { width: `${width}px` } : undefined,
                      })}
                    >
                      {typeof sortAccessor !== "undefined" ? (
                        <SortableHeader
                          onClick={() => {
                            dispatch(TableStateActions.toggleSortBy({ sortBy: sortAccessor }));
                          }}
                          isCurrentlySorted={tableState.sort === sortAccessor}
                          headerRender={render("Header")}
                          {...tableState}
                        />
                      ) : (
                        render("Header")
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody data-cy="rideView" {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr data-cy="rideViewRow" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <PaginationRow
        totalRows={totalCount}
        currentPage={page}
        onPageChange={onPageChange}
        numberOfPages={totalPages}
      />
    </>
  );
}
