import { ReactElement, useMemo, useCallback } from "react";
import { format, parseISO } from "date-fns";
import classNames from "classnames";
import { Collapse } from "react-bootstrap";

import { Planner } from "shared/types";
import { useAccordion } from "shared/hooks/useAccordion";
import { metersToKm } from "shared/mappers";
import styles from "./ResultsDetailsItem.module.css";

interface Props {
  vehicleName: string;
  vehicleDistance: number;
  visibleStops: Planner.ResultVehiclePlanStop[];
  getPassengerName(id: string): string;
  getStopName(id: string): string;
}

const getStopLabel = (timestamp: string, distance: string, name: string): string =>
  `${format(parseISO(timestamp), "HH:mm")} - ${name} - ${distance}km`;
const getStopIdentifier = (stop: Planner.ResultVehiclePlanStop) => stop.stopId + stop.timestamp;
const mapPassenger = (passengerId: string, name: string, isPickup = false): ReactElement => (
  <li key={passengerId}>
    <span data-cy={isPickup ? "passengerPickup" : "passengerDropoff"}>
      <i
        className={classNames("fas", {
          "fa-user-plus mr-1 text-success": isPickup,
          "fa-user-minus mr-1 text-danger": !isPickup,
        })}
      />
      {name}
    </span>
  </li>
);

export function ResultDetailsItem({
  vehicleName,
  vehicleDistance,
  visibleStops,
  getStopName,
  getPassengerName,
}: Props) {
  const { isExpanded, toggleExpand, expandAll, collapseAll, allItemsExpanded } = useAccordion(
    visibleStops,
    getStopIdentifier
  );
  const allItemsToggle = allItemsExpanded ? (
    <span className={styles.passengersToggle} onClick={collapseAll}>
      Collapse all
    </span>
  ) : (
    <span className={styles.passengersToggle} onClick={expandAll}>
      Expand all
    </span>
  );
  const handleStopToggle = useCallback(
    (stop: Planner.ResultVehiclePlanStop) => () => {
      toggleExpand(stop);
    },
    [toggleExpand]
  );
  const stopList = useMemo(
    () =>
      visibleStops.map((stop) => {
        const stopIdentifier = getStopIdentifier(stop);
        const { pickupPassengerIds, dropoffPassengerIds, stopId, timestamp, distance } = stop;
        const togglable = pickupPassengerIds.length + dropoffPassengerIds.length > 0;
        const stopLabel = getStopLabel(timestamp, metersToKm(distance), getStopName(stopId));
        if (!togglable) {
          return (
            <li key={stopIdentifier}>
              <span data-cy="detailsTreeStopHeader">
                <i className="fas fa-clock mr-1" />
                {stopLabel}
              </span>
            </li>
          );
        }
        const isStopExpanded = isExpanded(stop);
        return (
          <li key={stopIdentifier}>
            <span
              data-cy="detailsTreeStopHeader"
              className={styles.stopToggle}
              onClick={handleStopToggle(stop)}
            >
              <i className="fas fa-clock mr-1" />
              {stopLabel}
              <i className={classNames("ml-2 fas", isStopExpanded ? "fa-minus" : "fa-plus")} />
            </span>
            <Collapse in={isStopExpanded}>
              <ul>
                {pickupPassengerIds.map((passengerId) =>
                  mapPassenger(passengerId, getPassengerName(passengerId), true)
                )}
                {dropoffPassengerIds.map((passengerId) =>
                  mapPassenger(passengerId, getPassengerName(passengerId))
                )}
              </ul>
            </Collapse>
          </li>
        );
      }),
    [getPassengerName, getStopName, handleStopToggle, isExpanded, visibleStops]
  );

  return (
    <div className={styles.tree}>
      <ul>
        <li>
          <span data-cy="detailsTreeVehicleHeader">
            <i className="fa fa-car-side mr-1" />
            {vehicleName} - {metersToKm(vehicleDistance)}km
          </span>
          {allItemsToggle}
          <ul>{stopList}</ul>
        </li>
      </ul>
    </div>
  );
}
