import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  link: string;
  translationKey: string;
  iconClass?: string;
}

export function SidebarMenuItem({ link, translationKey, iconClass }: Props): ReactElement {
  const { t } = useTranslation("navigation");
  return (
    <li data-cy="sidebarRegularLink" className="nav-item">
      <NavLink to={link} className="nav-link">
        {iconClass && <i className={iconClass} />}
        <p>{t(translationKey)}</p>
      </NavLink>
    </li>
  );
}
