import React, { ReactElement, useMemo } from "react";
import { Button, Col, Modal, Table } from "react-bootstrap";
import { Form as BSForm } from "react-bootstrap";
import { Formik } from "formik";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { FleetApiClient } from "shared/api";
import { Fleet } from "shared/types";
import { cancelMissionSchemaFactory, MAX_PUSH_MESSAGE_LENGTH } from "./schema";
import styles from "./CancelModal.module.css";
import { TimestampTransforms } from "shared/mappers";

type FormCancelMission = Parameters<typeof FleetApiClient.missions.cancelMissionByOperator>[0];
type SeedData = Fleet.MissionOverviewListItemDto & { message: string };

interface Props {
  seedData?: SeedData;
  onClose: () => void;
  onSave: (cancelMission: FormCancelMission) => Promise<void>;
}

const detailsMap: { translationKey: string; accessor: (seedData: SeedData) => string }[] = [
  {
    translationKey: "missions:form.vehicleNameLabel",
    accessor: (seedData) => seedData.vehicleName,
  },
  {
    translationKey: "missions:form.statusLabel",
    accessor: (seedData) => seedData.statusDescription,
  },
  {
    translationKey: "missions:table.header.plannedStartTime",
    accessor: (seedData) => TimestampTransforms.toFullDate(seedData.plannedStartTime),
  },
  {
    translationKey: "missions:table.header.plannedEndTime",
    accessor: (seedData) => TimestampTransforms.toFullDate(seedData.plannedEndTime),
  },
];

export function CancelModal({ seedData, onClose, onSave }: Props): ReactElement {
  const { t } = useTranslation(["general", "missions"]);
  const cancelMissionSchema = useMemo(() => cancelMissionSchemaFactory(t), [t]);
  return (
    <Modal show={!!seedData} onHide={onClose}>
      {seedData && (
        <Formik
          initialValues={{ message: seedData.message, vehicleId: seedData.vehicleId, missionId: seedData.id }}
          validationSchema={cancelMissionSchema}
          onSubmit={onSave}
        >
          {({ handleSubmit, values, handleChange, errors, touched, isSubmitting }) => (
            <BSForm
              noValidate
              onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
                ev.preventDefault();
                if (window.confirm(t("missions:form.cancelConfirm"))) {
                  handleSubmit();
                }
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("missions:form.cancelMission")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BSForm.Row className="d-flex flex-nowrap text-warning">
                  <div className="mr-4 pt-1 pl-1">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    <strong>{t("missions:form.cancelInfo")}</strong>
                  </p>
                </BSForm.Row>
                <BSForm.Row>
                  <BSForm.Group as={Col}>
                    <BSForm.Label>{t("missions:form.pushMessageLabel")}:</BSForm.Label>
                    <BSForm.Control
                      as="textarea"
                      name="message"
                      maxLength={500}
                      value={values.message}
                      placeholder={t("missions:form.pushMessagePlaceholder")}
                      onChange={handleChange}
                      isInvalid={!!errors.message && touched.message}
                      className={styles.pushMessage}
                    />
                    <div className="d-flex justify-content-between">
                      <BSForm.Control.Feedback className="d-block" type="invalid">
                        {errors.message}
                      </BSForm.Control.Feedback>
                      <i className={classNames("d-block", styles.characterCount)}>
                        {t("missions:form.howManyCharactersLeft", {
                          current: (MAX_PUSH_MESSAGE_LENGTH - values.message.length).toString(),
                          max: MAX_PUSH_MESSAGE_LENGTH,
                        })}
                      </i>
                    </div>
                  </BSForm.Group>
                </BSForm.Row>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th colSpan={2}>{t("missions:form.detailsHeader")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsMap.map(({ translationKey, accessor }) => (
                      <tr key={translationKey}>
                        <td>{t(translationKey)}</td>
                        <td>{accessor(seedData)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" disabled={isSubmitting} onClick={onClose} variant="secondary">
                  {t("general:back")}
                </Button>
                <Button size="sm" disabled={isSubmitting} type="submit" variant="danger">
                  {t("missions:form.save")}
                </Button>
              </Modal.Footer>
            </BSForm>
          )}
        </Formik>
      )}
    </Modal>
  );
}
