import { ReactElement } from "react";
import { connect } from "react-redux";

import { StoreShape } from "shared/state";
import { plannerSelectors } from "shared/state/selectors";
import styles from "./ResultsDetails.module.css";
import { ResultDetailsItem } from "../ResultsDetailsItem";

interface StateProps {
  entities?: ReturnType<typeof plannerSelectors.getEntities>;
  filters: ReturnType<typeof plannerSelectors.getFilters>;
  vehicleIds?: ReturnType<typeof plannerSelectors.getFleetPlanVehicleIds>;
}

type Props = StateProps;

function ResultsDetailsView({ entities, vehicleIds, filters }: Props): ReactElement | null {
  if (!entities || !vehicleIds) {
    return null;
  }

  const getStopName = (stopId: string) => entities.stops[stopId].name;
  const getPassengerName = (passengerId: string) => entities.passengers[passengerId].name;
  const unfilteredVehicleIds = vehicleIds.filter((x) => !filters.vehicleId || x === filters.vehicleId);
  const visibleStopItems: JSX.Element[] = [];

  unfilteredVehicleIds.forEach((vehicleId) => {
    const vehicleEntity = entities.vehicles[vehicleId];
    const unfilteredStops = vehicleEntity.plan.filter((x) => !filters.stopId || x.stopId === filters.stopId);
    if (unfilteredStops.length > 0) {
      visibleStopItems.push(
        <ResultDetailsItem
          key={vehicleId}
          vehicleName={vehicleEntity.name}
          vehicleDistance={vehicleEntity.distanceInMeters}
          getStopName={getStopName}
          getPassengerName={getPassengerName}
          visibleStops={unfilteredStops}
        />
      );
    }
  });

  return (
    <div className={styles.wrapper}>
      {visibleStopItems.length <= 0 ? (
        <div className={styles.noItemsInfo}>No unfiltered items to display.</div>
      ) : (
        visibleStopItems
      )}
    </div>
  );
}

const mapStateToProps = (state: StoreShape): StateProps => ({
  entities: plannerSelectors.getEntities(state),
  filters: plannerSelectors.getFilters(state),
  vehicleIds: plannerSelectors.getFleetPlanVehicleIds(state),
});

export const ResultsDetails =
  connect<StateProps, Record<string, unknown>, Record<string, unknown>, StoreShape>(mapStateToProps)(
    ResultsDetailsView
  );
