import { Col, Row } from "react-bootstrap";
import { Fleet } from "shared/types";
import {
  TimelinessOfRidesChart,
  HoursRidesChart,
  PassengersCountChart,
  StopsHeatmap,
  RideRequestTimeChart,
  DRTUsersChart,
  RideRequestsChart,
  ChartCard,
} from "./Charts";
import { useTranslation } from "react-i18next";

interface Props {
  reportData: Fleet.DashboardResponse;
}

export function Dashboard({ reportData }: Props) {
  const { t } = useTranslation(["reports"]);
  return (
    <>
      <Row>
        <Col md="6">
          <ChartCard title={t("charts.passengersCount.title")}>
            <PassengersCountChart dataSource={reportData.passengersAndRideDataSources} />
          </ChartCard>
        </Col>
        <Col md="6">
          <ChartCard title={t("charts.rideRequests.title")}>
            <RideRequestsChart dataSource={reportData.passengersAndRideDataSources} />
          </ChartCard>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <ChartCard title={t("charts.drtUsers.title")}>
            <DRTUsersChart dataSource={reportData.passengersAndRideDataSources} />
          </ChartCard>
        </Col>
        <Col md="6">
          <ChartCard title={t("charts.hoursRides.title")}>
            <HoursRidesChart dataSource={reportData.travelHoursDataSources} />
          </ChartCard>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <ChartCard title={t("charts.timelinessOfRides.title")}>
            <TimelinessOfRidesChart dataSource={reportData.serviceTimeDataSources} />
          </ChartCard>
        </Col>
        <Col md="6">
          <ChartCard title={t("charts.rideRequestTime.title")}>
            <RideRequestTimeChart dataSource={reportData.requestTimeDataSources} />
          </ChartCard>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ChartCard title={t("charts.stopsHeatmap.title")}>
            <StopsHeatmap dataSource={reportData.stopsHeatmapDataSources} />
          </ChartCard>
        </Col>
      </Row>
    </>
  );
}
