import React, { useState, ReactNode, ReactElement, useEffect } from "react";
import Autosuggest, {
  ChangeEvent,
  InputProps,
  Theme,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import styles from "./StopSearchInput.module.css";
import { Fleet } from "shared/types";
import classNames from "classnames";

interface Props {
  isValid?: boolean;
  isInvalid?: boolean;
  currentValue: string;
  alwaysRenderSuggestions?: boolean;
  stops: Fleet.Stop[];
  placeholder: string;
  onStopSelected: (stop: Fleet.Stop) => void;
  disabled?: boolean;
}

export function StopSearchInput({
  currentValue,
  onStopSelected,
  stops,
  placeholder,
  isValid = false,
  isInvalid = false,
  alwaysRenderSuggestions = false,
  disabled = false,
}: Props): ReactElement {
  const [value, setValue] = useState<string>(currentValue);
  const [suggestions, setSuggestions] = useState<Fleet.Stop[]>([]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const filterSuggestions = (searchValue: string) =>
    stops.filter((s) =>
      s.name.concat(" ", s.city).concat(" ", s.street).toUpperCase().includes(searchValue.toUpperCase())
    );

  const getStops = (searchValue: string) => setSuggestions(filterSuggestions(searchValue));
  const onChange = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent): void => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams): void => {
    getStops(value);
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const displayValue = (suggestion: Fleet.Stop): string =>
    `${suggestion.name}${suggestion.identifier ? ` ${suggestion.identifier}` : ""}`;

  function onSuggestionSelected(event: React.FormEvent<Record<string, unknown>>) {
    event.preventDefault();
  }

  const getSuggestionValue = (suggestion: Fleet.Stop): string => {
    onStopSelected({
      stopId: suggestion.stopId,
      name: suggestion.name,
      city: suggestion.city,
      identifier: suggestion.identifier,
      street: suggestion.street,
      location: suggestion.location,
      type: suggestion.type,
      imagesUrls: suggestion.imagesUrls,
    });
    return displayValue(suggestion);
  };

  const renderSuggestion = (suggestion: Fleet.Stop): ReactNode => (
    <div>
      <i className="fas fa-map-marker-alt mx-2 text-secondary" />
      <span className="font-weight-bolder text-dark">{displayValue(suggestion)}</span>
    </div>
  );

  const inputProps: InputProps<Fleet.Stop> = {
    placeholder,
    value,
    onChange,
    type: "text",
    disabled,
  };

  const theme: Theme = {
    ...styles,
    input: classNames("form-control", { "is-valid": isValid, "is-invalid": isInvalid }),
    suggestionsContainer: classNames("card card-default", styles.suggestionsContainer),
  };

  return (
    <Autosuggest
      theme={theme}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      alwaysRenderSuggestions={alwaysRenderSuggestions}
    />
  );
}
