import { Fleet } from "shared/types";

export enum CombinedSegmentDataType {
  Segment,
  Vehicle,
}

export interface CombinedSegmentVehiclePositionData {
  type: CombinedSegmentDataType;
  vehiclePosition?: Fleet.VehiclePosition;
  segment: Fleet.Segment;
}

export function RecombineData(segments: Fleet.Segment[], vehiclePositions: Fleet.VehiclePosition[]) {
  //I'm going to assume that vehicle positions and segments are already sorted by the backend api and segments does not overlaps.
  const ranges: {
    startServiceTime: Date;
    stopServiceTime: Date | null;
    segment: Fleet.Segment;
  }[] = [];

  if (segments.length > 0) {
    let i = 0;
    for (; i < segments.length - 1; i += 1) {
      const segment = segments[i];
      const nextSegment = segments[i + 1];
      ranges.push({
        startServiceTime: new Date(segment.plannedServiceTime),
        stopServiceTime: new Date(nextSegment.plannedServiceTime),
        segment: segment,
      });
    }
    ranges.push({
      startServiceTime: new Date(segments[i].plannedServiceTime),
      stopServiceTime: null,
      segment: segments[i],
    });
  }

  const arr: CombinedSegmentVehiclePositionData[] = [];
  let i = 0;
  while (i < vehiclePositions.length) {
    if (new Date(vehiclePositions[i].timestamp) > ranges[0].startServiceTime) {
      break;
    }

    arr.push({
      segment: ranges[0].segment,
      type: CombinedSegmentDataType.Vehicle,
      vehiclePosition: vehiclePositions[i],
    });
    i += 1;
  }

  for (const range of ranges) {
    arr.push({
      segment: range.segment,
      type: CombinedSegmentDataType.Segment,
    });
    while (i < vehiclePositions.length) {
      const vehiclePosition = vehiclePositions[i];
      const timestampDate = new Date(vehiclePosition.timestamp);
      if (
        !(
          timestampDate >= range.startServiceTime &&
          ((range.stopServiceTime !== null && timestampDate < range.stopServiceTime) ||
            range.stopServiceTime == null)
        )
      ) {
        break;
      }
      arr.push({
        segment: range.segment,
        type: CombinedSegmentDataType.Vehicle,
        vehiclePosition: vehiclePosition,
      });
      i += 1;
    }
  }

  while (i < vehiclePositions.length) {
    const range = ranges[ranges.length - 1];

    if (range == null || range.stopServiceTime == null) {
      break;
    }

    if (new Date(vehiclePositions[i].timestamp) < range.stopServiceTime) {
      break;
    }

    arr.push({
      segment: ranges[0].segment,
      type: CombinedSegmentDataType.Vehicle,
      vehiclePosition: vehiclePositions[i],
    });
    i += 1;
  }

  return arr;
}
