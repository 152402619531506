import React from "react";
import { Marker, MarkerProps } from "react-leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "./DirectionalArrowMarker.module.css";

interface Props extends MarkerProps {
  bearing: number;
  color: string;
  opacity: number;
}

export function DirectionalArrowMarker({ bearing, color, opacity, ...rest }: Props): React.ReactElement {
  return (
    <Marker
      {...rest}
      icon={divIcon({
        html: renderToStaticMarkup(
          <i
            className={styles.marker}
            style={{
              transform: `rotate(${bearing}deg)`,
              borderColor: `transparent transparent ${color} transparent`,
              opacity: `${opacity}`,
            }}
          />
        ),
        iconSize: [10, 14],
        iconAnchor: [5, 7],
      })}
    />
  );
}
