/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../state";
import { apiCallErrorMesage } from "../state/actions";
import { AuthService } from "../authService";
import { getChosenRealmApiUrl } from "shared/state/selectors/global";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyPromise = (...args: any[]) => Promise<any | undefined>;

export function notifyOnError(
  _target: unknown,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<AnyPromise>
): TypedPropertyDescriptor<AnyPromise> {
  const originalMethod = descriptor.value;
  if (originalMethod) {
    descriptor.value = async function (...params) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
        return await originalMethod.call(this, ...params);
      } catch (err) {
        store.dispatch(apiCallErrorMesage({ error: err as Error }));
        return Promise.reject(err);
      }
    };
  }
  return descriptor;
}

export abstract class ApiClient {
  protected static addToken(config?: AxiosRequestConfig): AxiosRequestConfig {
    return ApiClient.addTokenWithProvider(() => AuthService.getToken(), config);
  }

  protected static addTokenWithProvider(
    getToken: () => string | undefined,
    config?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const token = getToken();
    if (!token) {
      return config || {};
    }
    return {
      ...config,
      baseURL: getChosenRealmApiUrl(store.getState()),
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      } as Record<string, string>,
    };
  }

  protected static deleteWithToken<TResultType>(
    ...params: Parameters<typeof axios.delete>
  ): Promise<AxiosResponse<TResultType>> {
    return axios.delete<TResultType>(params[0], ApiClient.addToken(params[1]));
  }

  protected static getWithToken<TResultType>(
    ...params: Parameters<typeof axios.get>
  ): Promise<AxiosResponse<TResultType>> {
    return axios.get<TResultType>(params[0], ApiClient.addToken(params[1]));
  }

  protected static postWithToken<TResultType>(
    ...params: Parameters<typeof axios.post>
  ): Promise<AxiosResponse<TResultType>> {
    return axios.post<TResultType>(params[0], params[1], ApiClient.addToken(params[2]));
  }
}
