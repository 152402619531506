import FileDownload from "js-file-download";

import { Planner } from "../types";
import { PlannerUrls } from "../constants";
import { ApiClient, notifyOnError } from "./base";

export class PlannerApiClient extends ApiClient {
  @notifyOnError
  public static async postProcessRequest(formValues: Planner.FormValues) {
    return await ApiClient.postWithToken<Planner.Result>(PlannerUrls.Process, formValues);
  }

  @notifyOnError
  public static async getAlgorithmOptions(algorithmType: Planner.AlgorithmType) {
    return await ApiClient.getWithToken<Planner.Result>(PlannerUrls.Options, {
      params: {
        algorithmType: algorithmType,
      },
    });
  }

  @notifyOnError
  public static async postCsvToValidate(formData: FormData) {
    return await ApiClient.postWithToken<Planner.LoadedData>(PlannerUrls.Validate, formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  @notifyOnError
  public static async downloadSolution(url: string, filename: string) {
    const result = await ApiClient.getWithToken<Blob>(url);
    FileDownload(result.data, filename, "text/csv;charset=utf-8", "\uFEFF");
  }
}
