import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./Table.module.css";

interface Props {
  currentPageSize: number;
  children?: React.ReactNode;
  totalRows: number;
  onPageSizeChange: (newPageSize: number) => void;
}

const pageSizes = [2, 5, 10, 20, 30, 40, 50];

export function TopRow({
  totalRows,
  currentPageSize,
  onPageSizeChange,
  children,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const pageSizeOptions = pageSizes.map((pageSize) => (
    <option key={pageSize} value={pageSize}>
      {pageSize}
    </option>
  ));
  return (
    <Container fluid>
      <Row className={classNames("d-flex justify-content-between", styles.paginationRow)}>
        <Col className="d-flex px-0">{children}</Col>
        <Col className={classNames("d-flex justify-content-end pl-0", styles.pageSizeSelect)}>
          {t("table.show")}
          <Form.Control
            data-cy="pageSize"
            as="select"
            size="sm"
            value={currentPageSize.toString()}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onPageSizeChange(Number(e.target.value));
            }}
            className="mx-2"
          >
            {pageSizeOptions}
          </Form.Control>
          {t("table.showRows", { count: currentPageSize })}
        </Col>
      </Row>
    </Container>
  );
}
