import React, { useState, useCallback, ReactNode, ReactElement, FormEvent } from "react";
import Autosuggest, {
  SuggestionSelectedEventData,
  ChangeEvent,
  InputProps,
  Theme,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import axios from "axios";
import styles from "./AddressSearchInput.module.css";
import { Common } from "shared/types";
import classNames from "classnames";
import { StoreShape } from "shared/state/store";
import { connect } from "react-redux";
import { globalSelectors } from "shared/state/selectors";

// eslint-disable-next-line @typescript-eslint/unbound-method
Autosuggest.prototype.UNSAFE_componentWillReceiveProps = Autosuggest.prototype.componentWillReceiveProps;
// eslint-disable-next-line @typescript-eslint/unbound-method
delete Autosuggest.prototype.componentWillReceiveProps;

const suggestionIconMap: { [key: string]: string } = {
  R: "fa-map-marker-alt",
  H: "fa-hospital",
  U: "fa-university",
  I: "fa-industry",
  S: "fa-subway",
  B: "fa-bus",
  E: "fa-utensils",
  O: "fa-building",
  K: "fa-baby-carriage",
  T: "fa-school",
  P: "fa-parking",
};

const mapMapboxResponseToMapPlace = (name: string): string => {
  const placeNameElements = name.split(",");
  const mainLocationName = `${placeNameElements[1].substring(7)}, ${placeNameElements[0]}`;
  return `R@${mainLocationName}&${placeNameElements[2]}`;
};

interface StateProps {
  geocodingServiceAddress: ReturnType<typeof globalSelectors.getGeocodingServiceAddress>;
  mapboxAccessToken: ReturnType<typeof globalSelectors.getMapboxAccessToken>;
  mapboxProximity: ReturnType<typeof globalSelectors.getMapboxProximity>;
  mapboxBoundingBox: ReturnType<typeof globalSelectors.getMapboxBoundingBox>;
}

interface OwnProps {
  isValid?: boolean;
  isInvalid?: boolean;
  currentValue: string;
  valueToRemember?: string;
  onChangeAddress: (newAddress: Common.Address) => void;
  getLocation: (location: Common.Address) => void;
  addValueToMemory: (queryValue: string) => void;
}

type Props = StateProps & OwnProps;

function AddressSearchInputComponent({
  mapboxAccessToken,
  geocodingServiceAddress,
  mapboxProximity,
  mapboxBoundingBox,
  currentValue,
  onChangeAddress,
  getLocation,
  addValueToMemory,
  valueToRemember = "",
  isValid = false,
  isInvalid = false,
}: Props): ReactElement {
  const [value, setValue] = useState<string>(currentValue);
  const [suggestions, setSuggestions] = useState<Common.Address[]>([]);
  const [valueInMemory, setValueInMemory] = useState<string>(valueToRemember);
  const getAddresses = useCallback(
    async (address: string) => {
      if (geocodingServiceAddress) {
        const data = await axios.get<Common.MapboxResponse>(`${geocodingServiceAddress}${address}.json`, {
          params: {
            access_token: mapboxAccessToken,
            autocomplete: true,
            country: "pl",
            types: "address",
            limit: 6,
            language: "pl",
            proximity: mapboxProximity,
            bbox: mapboxBoundingBox,
          },
        });
        setSuggestions(
          data.data.features.map(
            (f) =>
              ({
                name: mapMapboxResponseToMapPlace(f.place_name),
                latitude: f.geometry.coordinates[1],
                longitude: f.geometry.coordinates[0],
              } as Common.Address)
          )
        );
      }
    },
    [geocodingServiceAddress, mapboxAccessToken, mapboxBoundingBox, mapboxProximity]
  );

  const onChange = (event: FormEvent<HTMLElement>, { newValue }: ChangeEvent): void => {
    setValue(newValue);
    onChangeAddress({
      name: newValue,
      latitude: 0,
      longitude: 0,
    });
    if (valueInMemory) {
      addValueToMemory(valueInMemory);
    }
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams): void => {
    void getAddresses(value);
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const displayValue = (suggestion: Common.Address): string =>
    suggestion.name.substr(2, suggestion.name.indexOf("&") - 2).trimEnd();

  function onSuggestionSelected(
    event: React.FormEvent<React.ReactElement>,
    { suggestion }: SuggestionSelectedEventData<Common.Address>
  ) {
    event.preventDefault();
    addValueToMemory(displayValue(suggestion));
    onChangeAddress({
      name: displayValue(suggestion),
      latitude: suggestion.latitude,
      longitude: suggestion.longitude,
    });
    setValueInMemory(displayValue(suggestion));
  }

  const getSuggestionValue = (suggestion: Common.Address): string => {
    getLocation({
      name: displayValue(suggestion),
      latitude: suggestion.latitude,
      longitude: suggestion.longitude,
    });
    return displayValue(suggestion);
  };

  const renderSuggestion = (suggestion: Common.Address): ReactNode => {
    return (
      <div>
        <i
          className={classNames(
            "fas mx-2 text-secondary",
            suggestionIconMap[suggestion.name.substr(0, 1)]
              ? suggestionIconMap[suggestion.name.substr(0, 1)]
              : "fa-city"
          )}
        />
        <span className="font-weight-bolder text-dark">{displayValue(suggestion)}</span>
        <span className="font-italic text-black-50 ml-2">
          {suggestion.name.substr(1 + suggestion.name.indexOf("&"))}
        </span>
      </div>
    );
  };

  const inputProps: InputProps<Common.Address> = {
    placeholder: "Write an address here",
    value,
    onChange: onChange,
    type: "text",
  };

  const theme: Theme = {
    container: styles.container,
    input: classNames("form-control", { "is-valid": isValid, "is-invalid": isInvalid }),
    suggestionsContainer: classNames("card card-default", styles.suggestionsContainer),
    suggestionsList: styles.suggestionsList,
    suggestion: styles.suggestion,
    suggestionHighlighted: styles.suggestionHighlighted,
  };

  return (
    <Autosuggest
      theme={theme}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
    />
  );
}
const mapStateToProps = (state: StoreShape): StateProps => {
  return {
    geocodingServiceAddress: globalSelectors.getGeocodingServiceAddress(state),
    mapboxAccessToken: globalSelectors.getMapboxAccessToken(state),
    mapboxBoundingBox: globalSelectors.getMapboxBoundingBox(state),
    mapboxProximity: globalSelectors.getMapboxProximity(state),
  };
};

export const AddressSearchInput =
  connect<StateProps, Record<string, unknown>, Record<string, unknown>, StoreShape>(mapStateToProps)(
    AddressSearchInputComponent
  );
