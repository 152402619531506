const en = {
  general: {
    insufficientPrivileges: "Insufficient privileges",
    register: "Register",
    login: "Login",
    validation: {
      emailFormat: "Please enter a valid email address",
      emailMaxLength: "Your email cannot be more than {{maxLength}} characters long",
      emailRequired: "Please enter your email",
      passwordMinLength: "Your password must be at least {{minLength}} characters long",
      passwordMaxLength: "Your password cannot be more than {{maxLength}} characters long",
      capacityMinLength: "Capacity cannot be lower than {{minLength}}",
      capacityMaxLength: "Capacity cannot exceed {{maxLength}}",
      capacityRequired: "Must input vehicle capacity",
      passwordRequired: "Please enter your password",
      required: "Field is required",
    },
    inputNames: {
      email: "Email",
      password: "Password",
    },
  },
  vehicle: {
    side: {
      title: "Vehicles",
    },
    table: {
      header: {
        name: "Name",
        licensePlate: "License Plate",
        capacity: "Capacity",
        startStop: "Start stop",
        endStop: "End stop",
        actions: "Actions",
      },
      actions: {
        mission: "Mission",
        addVehicle: "Add vehicle",
      },
    },
    form: {
      addFormTitle: "Add vehicle",
      editFormTitle: "Edit vehicle",
      name: "Vehicle name:",
      licensePlate: "Vehicle license plate:",
      capacity: "Capacity:",
      startStop: "Start stop:",
      endStop: "End stop:",
      placeholderName: "Enter vehicle name:",
      placeholderLicensePlate: "Enter license plate:",
      placeholderStartStop: "Select start stop:",
      placeholderEndStop: "Select end stop:",
    },
  },
  navigation: {
    planner: "Planner",
    missions: "Missions",
    vehicles: "Vehicles",
    stops: "Stops",
    rides: "Rides",
  },
};

export default en;
