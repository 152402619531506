import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import styles from "./Forms.module.css";
import { Spinner, Button } from "react-bootstrap";

const LoginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .max(50, "Your email cannot be more than 50 characters long.")
    .required("Please enter your email"),
  password: Yup.string()
    .min(4, "Your password must be at least 4 characters long.")
    .max(50, "Your password cannot be more than 50 characters long.")
    .required("Please enter your password"),
});

type LoginShape = Yup.InferType<typeof LoginSchema>;

interface Props {
  onSubmit: (loginData: LoginShape) => Promise<void>;
}

const initialValues: LoginShape = {
  email: "",
  password: "",
};

export function LoginForm({ onSubmit }: Props): React.ReactElement {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={async (values, formikBag) => {
        formikBag.setSubmitting(true);
        await onSubmit(values);
      }}
    >
      {({ handleSubmit, values, handleChange, errors, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <input
            data-cy="emailField"
            type="text"
            placeholder="Email"
            title="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            className={classNames({ [styles.invalid]: !!touched.email && !!errors.email })}
          />
          {!!touched.email && <span>{errors.email}</span>}
          <input
            data-cy="passwordField"
            type="password"
            placeholder="Password"
            title="Password"
            name="password"
            onChange={handleChange}
            value={values.password}
            className={classNames({ [styles.invalid]: !!touched.password && !!errors.password })}
          />
          {!!touched.password && <span>{errors.password}</span>}
          <Button variant="info" disabled={isSubmitting} type="submit" title="Login">
            {isSubmitting && (
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
            )}
            Login
          </Button>
        </form>
      )}
    </Formik>
  );
}
