import { ReactElement, useCallback, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Layout, DriverRides, Common } from "../../components";
import { stateMachineFactory } from "./stateMachine";

export function DriverRidesPage(): ReactElement {
  const { t } = useTranslation(["navigation", "driverRides"]);
  const stateMachine = useMemo(() => stateMachineFactory(t), [t]);
  const [current, send] = useMachine(stateMachine);
  const context = current.context;
  const handleVehicleChange = useCallback(
    (vehicleId: string) => {
      send("SELECT_VEHICLE", { payload: { vehicleId } });
    },
    [send]
  );
  const defaultContent = (
    <>
      <DriverRides.VehicleSelector handleVehicleChange={handleVehicleChange} {...context} />
      {current.matches("changingVehicle") ? <Common.CenteredSpinner /> : <DriverRides.Table {...context} />}
    </>
  );
  let content = defaultContent;
  if (current.matches("initializationFailed")) {
    content = <span>{context.errorMessage}</span>;
  } else if (current.matches("initializing")) {
    content = <Common.CenteredSpinner />;
  }
  return (
    <Layout.Content simpleHeader={t("navigation:driverRides")}>
      <Card className="p-2" data-cy="viewMissionsListCard">
        {content}
      </Card>
    </Layout.Content>
  );
}
