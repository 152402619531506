import { Actions } from "./Actions";
import { SourceIcon } from "./SourceIcon";
import { TimeDestination } from "./TimeDestination";
import { Username } from "./Username";

export const TableCells = {
  Actions,
  SourceIcon,
  TimeDestination,
  Username,
};
