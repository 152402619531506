/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
declare global {
  interface Window {
    env?: {
      REACT_APP_BASE_PATH?: string;
      REACT_APP_AUTH_ISSUER?: string;
    };
  }
}

export const ENV = {
  BASE_PATH: window.env?.REACT_APP_BASE_PATH ?? "",
  AUTH_ISSUER: window.env?.REACT_APP_AUTH_ISSUER ?? "https://<<HOSTNAME>>/is",
};
