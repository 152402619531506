import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Header({ text }: { text: string }) {
  const { t } = useTranslation(["navigation", "vehicle/availability"]);
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 data-cy="pageContentHeader">{text}</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <Link to="/">{t("navigation:start")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/vehicles">{t("navigation:vehicles")}</Link>
              </li>
              <li className="breadcrumb-item active">{t("vehicle/availability:breadCrumbName")}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}
