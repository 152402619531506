import ReactDOM from "react-dom";
import axios from "axios";
import "admin-lte/plugins/fontawesome-free/css/all.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { App } from "./App";
import { ENV } from "shared/constants";

registerLocale("pl", pl);
setDefaultLocale("pl");

// set default base url for axios
axios.defaults.baseURL = `${window.location.origin}${ENV.BASE_PATH}`;
axios.interceptors.request.use(
  (request) => {
    request.headers = Object.assign((request.headers as Record<string, string> | undefined) ?? {}, {
      "ClientInfo-AppIdentifier": "OperatorPanel",
      "ClientInfo-BrowserUserAgent": window.navigator.userAgent,
    });

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
