import { Map, StopLocationMarker } from "../../common";
import { Common } from "shared/types";
import styles from "./Stop.module.css";

import { useMapEvents } from "react-leaflet";
import { LatLngBounds } from "leaflet";
import { v4 as uuid } from "uuid";
import classNames from "classnames";

interface LocationMarkerProps {
  setNewLocation: (location: Common.Location) => void;
  location?: Common.Location;
}

interface Props {
  setNewLocation: (location: Common.Location) => void;
  location?: Common.Location;
}

const defaultMapProps = {
  key: uuid(),
  height: 300,
};

function LocationMarker({ setNewLocation, location }: LocationMarkerProps) {
  useMapEvents({
    click(e) {
      setNewLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
    },
  });

  return location ? (
    <StopLocationMarker location={{ lat: location.lat, lng: location.lng }}>
      <i className={classNames("fas fa-bus text-black", styles.icon)}></i>
    </StopLocationMarker>
  ) : null;
}

export function StopMap({ location, setNewLocation }: Props) {
  const getMapProps = (location?: Common.Location) =>
    location
      ? {
          ...defaultMapProps,
          bounds: new LatLngBounds([[location.lat, location.lng]]),
        }
      : defaultMapProps;

  return (
    <Map {...getMapProps(location)}>
      <LocationMarker setNewLocation={setNewLocation} location={location} />
    </Map>
  );
}
