import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./DriverRides.module.css";

interface Props {
  hasChanged: boolean;
}

export function TableRowCell({ children, hasChanged }: PropsWithChildren<Props>) {
  return <td className={classNames({ [styles.itemUpdateActive]: hasChanged })}>{children}</td>;
}
