import { Dispatch } from "redux";
import { connect } from "react-redux";

import { ResultsFilter, DispatchProps, StateProps } from "./ResultsFilter";
import { StoreShape } from "shared/state";
import { plannerSelectors } from "shared/state/selectors";
import { setProcessingFilters } from "shared/state/actions";

const mapStateToProps = (state: StoreShape): StateProps => ({
  label: "Stop",
  itemIds: plannerSelectors.getFleetPlanStopIds(state),
  itemEntities: plannerSelectors.getStopEntities(state),
  filteredItemId: plannerSelectors.getFilters(state).stopId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setFilter: (newValue?: string) => dispatch(setProcessingFilters({ stopId: newValue })),
});

export const ResultsStopFilter = connect<StateProps, DispatchProps, Record<string, unknown>, StoreShape>(
  mapStateToProps,
  mapDispatchToProps
)(ResultsFilter);
