import classNames from "classnames";
import React, { forwardRef, ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserApiClient } from "shared/api";
import { UserRealm, RealmType } from "shared/authService";
import { AppUrls } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";
import { realmChosen } from "shared/state/actions";
import { getChosenRealmApiUrl, getAuthToken, getChosenRealmName } from "shared/state/selectors/global";
import styles from "./Realms.module.css";

interface CustomToggleProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const RealmToggleButton = forwardRef<HTMLButtonElement>(function realmToggleButton(
  // eslint-disable-next-line react/prop-types
  { children, onClick }: CustomToggleProps,
  ref
) {
  return (
    <Button
      data-cy="changeRealmLink"
      variant="link"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      className={classNames("nav-link text-uppercase w-100 text-left p-3", styles.chooseRealmsLink)}
    >
      {children}
    </Button>
  );
});

export function RealmsPage(): ReactElement {
  const { t } = useTranslation("realms");
  const [realms, setRealms] = useState<UserRealm[]>([]);
  const authService = useAuthService();
  const history = useHistory();
  const apiUrl = useSelector(getChosenRealmApiUrl);
  const token = useSelector(getAuthToken) ?? "";
  const dispatch = useDispatch();
  const realmName = useSelector(getChosenRealmName);
  const fetchRealms = useCallback(async () => {
    const realms = await authService.getRealms();
    setRealms(realms ?? []);
    return realms;
  }, [authService, setRealms]);

  const selectRealm = (realmId: string) => {
    const foundRealm = realms.find((r) => r.id === realmId);

    if (foundRealm === undefined) {
      return;
    }

    dispatch(realmChosen(foundRealm));
  };

  useEffect(() => {
    async function loadData() {
      const realms = await fetchRealms();

      if (realms.length === 0) {
        history.push(AppUrls.Unauthorized);
      }

      if (realms.length > 0) {
        dispatch(realmChosen(realms[0]));
      }
    }
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = useCallback(() => {
    if (!apiUrl) {
      return;
    }

    UserApiClient.refreshUserInfo(token).catch((err) => {
      console.warn(err);
    });

    const beforeRedirect = authService.getPageBeforeRedirect();
    if (beforeRedirect.toLocaleLowerCase() === "/realms") {
      history.push(beforeRedirect);
    }
    history.push("/", {});
  }, [apiUrl, authService, history, token]);

  return (
    <form>
      <div className={`pl-4 pr-4`}>
        <div className="mb-3">
          <h1 className={classNames(`mt-0`, styles.chooseRealm)}>{t("label")}</h1>
        </div>
        <div className="mb-3">
          <h2 className={styles.chooseRealmDesc}>{t("desc")}</h2>
        </div>
        <div>
          <div className="input-group">
            <Dropdown className="w-100">
              <Dropdown.Toggle as={RealmToggleButton}>
                <i className="fas fa-map mr-2" />
                {realmName}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {realms.map((realm, index) => (
                  <Dropdown.Item
                    onClick={(e) => {
                      selectRealm(realm.id);
                    }}
                    key={index}
                  >
                    <Row>
                      <Col>{realm.name}</Col>
                      {realm.type === RealmType.Private && (
                        <Col className="text-right">
                          <i className="fas fa-eye-slash"></i>
                        </Col>
                      )}
                    </Row>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row text-center mb-4 mt-4">
          <div className="col">
            <Button
              onClick={onClick}
              data-cy="confirmSelectedRealm"
              className={classNames("btn btn-primary px-4", styles.confirm, styles.btnConfirm)}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
