import React, { useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { DateTransforms } from "shared/mappers";
import { InputDateFormat } from "shared/constants/dateFormats";
import { ErrorBoundary } from "../ErrorBoundary";
import classNames from "classnames";

type SelectedValue = React.ComponentProps<typeof DatePicker>["selected"];
type OnChange = React.ComponentProps<typeof DatePicker>["onChange"];
type SelectedValueTransform = (value: SelectedValue) => SelectedValue;

interface Props extends React.ComponentProps<typeof DatePicker> {
  isInvalid?: boolean;
  todayButton?: string;
  dateFormat?: string;
  useLocalDateTime?: boolean;
}

export function DefaultDatePicker(props: Props): React.ReactElement {
  const { t } = useTranslation();
  const defaultProps: Partial<React.ComponentProps<typeof DatePicker>> & { dateFormat: string } = {
    isClearable: true,
    autoComplete: "off",
    todayButton: t("today"),
    timeInputLabel: t("inputNames.datePickerTimeInputName"),
    dateFormat: InputDateFormat,
    minDate: new Date("1900-01-01"),
    maxDate: new Date("2300-12-31"),
    dropdownMode: "select",
    showMonthDropdown: true,
    showYearDropdown: true,
  };
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { selected, onChange, className, isInvalid, ...restProps } = props;
  let finalClassName = className;
  if (className) {
    finalClassName = classNames(finalClassName, { "is-invalid": isInvalid });
  }
  const [selectedTransform, onChangeWrapped] = useMemo(() => {
    let selectedTransform: SelectedValueTransform = (value) => value;
    let onChangeWrapped: OnChange = onChange;
    if (!props.useLocalDateTime) {
      selectedTransform = (value) => (value ? DateTransforms.utcDateToLocalDate(value) : value);
      onChangeWrapped = (value, ev) => {
        if (Array.isArray(value)) {
          onChange(value.map(DateTransforms.localDateToUtcDate) as [Date, Date], ev);
        } else if (value) {
          onChange(DateTransforms.localDateToUtcDate(value), ev);
        } else {
          onChange(value, ev);
        }
      };
    }
    return [selectedTransform, onChangeWrapped];
  }, [onChange, props.useLocalDateTime]);
  defaultProps.placeholderText = t("format", {
    format: DateTransforms.toCustomFormat(
      new Date(),
      props.dateFormat ? props.dateFormat : defaultProps.dateFormat
    ),
  });
  return (
    <ErrorBoundary>
      <DatePicker
        {...defaultProps}
        {...restProps}
        className={finalClassName}
        selected={selectedTransform(selected)}
        onChange={onChangeWrapped}
      />
    </ErrorBoundary>
  );
}
