import { ReactElement, useState } from "react";
import { Layout } from "../components";
import { useTranslation } from "react-i18next";
import { Card, Form, Col, Button, Row, Spinner } from "react-bootstrap";
import { Formik, FormikErrors } from "formik";
import { isValid, parseISO, isAfter } from "date-fns";
import { Fleet } from "shared/types";
import { FleetApiClient } from "shared/api";
import { useAsyncCall } from "shared/hooks/useAsyncCall";
import format from "date-fns/format";
import { DashboardResponse } from "shared/types/Fleet";
import { CenteredSpinner } from "components/common";
import { Dashboard } from "components/page-specific/Reports";

const reportRequest = {
  from: "",
  until: "",
  numberOfDaysToGroup: 7,
  minimumConnectionsCount: 1,
  daysInactivityCount: 21,
};

const dateToInputFormat = (date: Date) => format(date, "yyyy-MM-dd");

export function ReportsPage(): ReactElement {
  const [reportData, setReportData] = useState<DashboardResponse>();
  const [isExport, setIsExport] = useState<boolean>(false);
  const { t } = useTranslation(["reports"]);

  const [handleSubmit] = useAsyncCall(async (reportRequest: Fleet.DashboardRequest) => {
    setReportData(
      (
        await FleetApiClient.reports.getRaportData(
          reportRequest.from,
          reportRequest.until,
          reportRequest.numberOfDaysToGroup,
          reportRequest.daysInactivityCount,
          reportRequest.minimumConnectionsCount
        )
      ).data
    );
  });

  const [exportDashboard] = useAsyncCall(async (reportRequest: Fleet.DashboardRequest) => {
    await FleetApiClient.reports.downloadRaportFile(
      reportRequest.from,
      reportRequest.until,
      reportRequest.numberOfDaysToGroup,
      reportRequest.daysInactivityCount,
      reportRequest.minimumConnectionsCount,
      t("fileName", {
        from: reportRequest.from.substr(0, 7).replace("-", "."),
        until: reportRequest.until.substr(0, 7).replace("-", "."),
      })
    );
    setIsExport(false);
  });

  const reportValidation = (values: Fleet.DashboardRequest) => {
    const errors: FormikErrors<Fleet.DashboardRequest> = {};
    if (!values.from) {
      errors.from = t("validation.required");
    }
    if (!values.until) {
      errors.until = t("validation.required");
    }
    if (!!values.from && !!values.until) {
      if (isAfter(parseISO(values.from), parseISO(values.until))) {
        errors.from = t("validation.from");
        errors.until = t("validation.until");
      }
    }

    return errors;
  };

  return (
    <Layout.Content simpleHeader={t("header")}>
      <Formik initialValues={reportRequest} validate={reportValidation} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit, touched, isSubmitting }) => (
          <>
            <Row>
              <Col md={12}>
                <Card className="py-2" data-cy="reportCard">
                  <Card.Header>
                    <Card.Title>{t("dashboard")}</Card.Title>
                  </Card.Header>
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body>
                      <Form.Row>
                        <Col md="6">
                          <Form.Row>
                            <Form.Group as={Col} md="6">
                              <Form.Label>{t("from")}</Form.Label>
                              <Form.Control
                                type="date"
                                name="from"
                                value={
                                  isValid(new Date(values.from))
                                    ? dateToInputFormat(new Date(values.from))
                                    : ""
                                }
                                onChange={handleChange}
                                isInvalid={!!errors.from && touched.from}
                              />
                              <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label>{t("until")}</Form.Label>
                              <Form.Control
                                type="date"
                                name="until"
                                value={
                                  isValid(new Date(values.until))
                                    ? dateToInputFormat(new Date(values.until))
                                    : ""
                                }
                                onChange={handleChange}
                                isInvalid={!!errors.until && touched.until}
                              />
                              <Form.Control.Feedback type="invalid">{errors.until}</Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Group as={Col} md="12" className="mb-4">
                            <Row>
                              <Col md="6">
                                <Form.Label className="pt-2">{t("numberOfDaysToGroup")}</Form.Label>
                              </Col>
                              <Col md="2">
                                <Form.Control
                                  type="number"
                                  min={1}
                                  max={30}
                                  name="numberOfDaysToGroup"
                                  value={values.numberOfDaysToGroup.toString()}
                                  onChange={handleChange}
                                  isInvalid={!!errors.numberOfDaysToGroup && touched.numberOfDaysToGroup}
                                />
                              </Col>
                            </Row>
                            <Form.Control.Feedback type="invalid">
                              {errors.numberOfDaysToGroup}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group as={Col} md="12" className="mb-4 ml-4 mt-2">
                            <Row>
                              <Col md="7">
                                <Form.Label>{t("daysInactivityCount")}</Form.Label>
                              </Col>
                              <Col md="2">
                                <Form.Control
                                  type="number"
                                  min={1}
                                  max={30}
                                  name="daysInactivityCount"
                                  value={values.daysInactivityCount.toString()}
                                  onChange={handleChange}
                                  isInvalid={!!errors.daysInactivityCount && touched.daysInactivityCount}
                                />
                              </Col>
                            </Row>
                            <Form.Control.Feedback type="invalid">
                              {errors.daysInactivityCount}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md="12" className="my-4 ml-4">
                            <Row>
                              <Col md="7">
                                <Form.Label>{t("minimumConnectionsCount")}</Form.Label>
                              </Col>
                              <Col md="2">
                                <Form.Control
                                  type="number"
                                  min={1}
                                  max={30}
                                  name="minimumConnectionsCount"
                                  value={values.minimumConnectionsCount.toString()}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!errors.minimumConnectionsCount && touched.minimumConnectionsCount
                                  }
                                />
                              </Col>
                            </Row>
                            <Form.Control.Feedback type="invalid">
                              {errors.minimumConnectionsCount}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row className="justify-content-between">
                        <Button
                          variant="success"
                          type="submit"
                          disabled={isSubmitting}
                          className="float-right mt-3"
                        >
                          {t("show")}
                        </Button>
                        {!!reportData && (
                          <Button
                            variant="primary"
                            className="float-right mt-3 mx-2"
                            onClick={() => {
                              setIsExport(true);
                              void exportDashboard(values);
                            }}
                            disabled={isExport}
                          >
                            {isExport ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2 mb-1"
                              />
                            ) : (
                              <i className=" mr-2 far fa-file-excel"></i>
                            )}
                            {t("export")}
                          </Button>
                        )}
                      </Form.Row>
                    </Card.Body>
                  </Form>
                </Card>
              </Col>
            </Row>
            {isSubmitting ? (
              <div style={{ height: "400px" }} className="d-flex align-items-center">
                <CenteredSpinner />
              </div>
            ) : (
              reportData && <Dashboard reportData={reportData} />
            )}
          </>
        )}
      </Formik>
    </Layout.Content>
  );
}
