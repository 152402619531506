import { Common } from "components";
import { Hud } from "components/page-specific/MissionDetails";
import { LatLngBounds } from "leaflet";
import { HTMLProps, ReactElement, RefCallback, useCallback, useEffect, useMemo, useState } from "react";
import { FleetApiClient } from "shared/api";
import { Fleet, Common as CommonTypes } from "shared/types";
import styles from "./MissionDetails.module.css";
import ReactSlider from "react-slider";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { RecombineData, CombinedSegmentDataType } from "./RecombineData";
import { ComputeActiveSegments } from "./ComputeActiveSegments";
import { ComputeVisibleSegments } from "./ComputeVisibleSegments";
import { SegmentStatuses } from "shared/types/Fleet";

interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
  ref: RefCallback<T>;
}

const RefreshRate = 5000;

export function calculateBounds(locations: CommonTypes.Location[]): LatLngBounds | undefined {
  return locations.length >= 1
    ? new LatLngBounds(locations.map((location) => [location.lat, location.lng]))
    : undefined;
}

function filterSegments(filtersState: FiltersState, filteredSegments: Fleet.Segment[]) {
  if (filtersState.cancelledFiltered) {
    filteredSegments = filteredSegments.filter(
      (segment, index) =>
        index === 0 ||
        index === filteredSegments.length - 1 ||
        segment.segmentStatus.code !== SegmentStatuses.Cancelled
    );
  }

  if (filtersState.activeOnly) {
    filteredSegments = filteredSegments.filter(
      (segment, index) =>
        index === 0 ||
        index === filteredSegments.length - 1 ||
        segment.segmentStatus.code === SegmentStatuses.Pending ||
        segment.segmentStatus.code === SegmentStatuses.Confirmed
    );
  }

  if (filtersState.cancelledOnly) {
    filteredSegments = filteredSegments.filter(
      (segment, index) =>
        index === 0 ||
        index === filteredSegments.length - 1 ||
        segment.segmentStatus.code === SegmentStatuses.Cancelled
    );
  }
  return filteredSegments;
}

const defaultMission: Fleet.MissionDetailedOverviewItemDto = {
  id: "",
  vehicleName: "",
  statusDescription: "",
  vehicleId: "",
  plan: {
    segments: [],
  },
  plannedStartTime: "",
  plannedEndTime: "",
  reportedEndTime: "",
  reportedStartTime: "",
  initialPlannedStartTime: "",
  initialPlannedEndTime: "",
  status: {
    code: Fleet.MissionStatuses.Pending,
    description: "",
  },
  vehicle: {
    id: "",
    capacity: 0,
    licensePlate: "",
    name: "No mission data",
    startPositionId: "",
    endPositionId: "",
  },
  vehiclePositions: [],
};

interface DefaultState {
  currentVehiclePosition: Fleet.VehiclePosition | undefined;
  pointInTime: number;
  currentSegmentIndex: number;
}

const defaultState: DefaultState = {
  currentVehiclePosition: undefined,
  pointInTime: 1,
  currentSegmentIndex: 0,
};

export interface FiltersState {
  activeOnly: boolean;
  cancelledFiltered: boolean;
  cancelledOnly: boolean;
  withoutVehicles: boolean;
  none: boolean;
}

const defaultFiltersState: FiltersState = {
  activeOnly: false,
  cancelledFiltered: false,
  cancelledOnly: false,
  withoutVehicles: false,
  none: true,
};

export function MissionDetailsPage(): ReactElement {
  const { missionId } = useParams<{ missionId: string }>();
  const [state, setState] = useState<DefaultState>(defaultState);
  const [filtersState, setFiltersState] = useState(defaultFiltersState);
  const [mission, setMission] = useState<Fleet.MissionDetailedOverviewItemDto>(defaultMission);

  const getMissionDetails = useCallback(async () => {
    const response = await FleetApiClient.missions.getMission(missionId);

    if (filtersState.withoutVehicles) {
      response.data.vehiclePositions = [];
    }

    setMission(response.data);
  }, [filtersState.withoutVehicles, missionId]);

  useEffect(() => {
    const id = setInterval(() => {
      if (
        mission.status.code === Fleet.MissionStatuses.Started ||
        mission.status.code === Fleet.MissionStatuses.Pending
      ) {
        void getMissionDetails();
        return;
      }
      clearInterval(id);
    }, RefreshRate);

    void getMissionDetails();

    return () => clearInterval(id);
  }, [getMissionDetails, mission.status.code]);

  const filteredMission = useMemo<Fleet.MissionDetailedOverviewItemDto>(() => {
    let filteredSegments = mission.plan.segments;

    filteredSegments = filterSegments(filtersState, filteredSegments);

    return {
      ...mission,
      plan: {
        ...mission.plan,
        segments: filteredSegments,
      },
    };
  }, [filtersState, mission]);

  const recombinedData = useMemo(
    () => RecombineData(filteredMission.plan.segments, filteredMission.vehiclePositions),
    [filteredMission.plan.segments, filteredMission.vehiclePositions]
  );

  let min = 1;
  let max = 1;
  if (filteredMission.plan.segments.length > 0) {
    min = 1;
    max = recombinedData.length;
  }

  const [activeSegments, activeIndex] = useMemo<[Fleet.Segment[], number]>(() => {
    return ComputeActiveSegments(
      filteredMission,
      Math.max(Math.min(state.currentSegmentIndex, filteredMission.plan.segments.length - 1), 0)
    );
  }, [filteredMission, state.currentSegmentIndex]);

  const [visibleSegments, visibleIndex] = useMemo<[Fleet.Segment[], number]>(
    () => ComputeVisibleSegments(activeSegments, activeIndex),
    [activeSegments, activeIndex]
  );

  const onChange = useCallback(
    (value: number | number[] | null | undefined) => {
      if (recombinedData.length === 0) return;
      const newValue = value as number;
      const data = recombinedData[newValue - 1];
      const segmentIndex = filteredMission.plan.segments.indexOf(data.segment);
      setState((prevState) => ({
        ...prevState,
        pointInTime: newValue,
        currentVehiclePosition: data.vehiclePosition,
        currentSegmentIndex: segmentIndex >= 0 ? segmentIndex : prevState.currentSegmentIndex,
      }));
    },
    [filteredMission.plan.segments, recombinedData]
  );

  const recomputeMark = useCallback(
    (props: HTMLPropsWithRefCallback<HTMLSpanElement>, prop: "left") => {
      const key = props.key as number;
      const result = { ...props, style: props.style ? { ...props.style } : {} };
      if (props.key === 0) {
        result.style[prop] = "0%";
      } else {
        result.style[prop] = `${(100 / (recombinedData.length - 1)) * key}%`;
      }
      return result;
    },
    [recombinedData.length]
  );

  const recomputeThumb = useCallback(
    (props: HTMLPropsWithRefCallback<HTMLDivElement>, value, prop: "left") => {
      const result = { ...props, style: props.style ? { ...props.style } : {} };
      if (props.key === 0) {
        result.style[prop] = "0%";
      } else if (props.key === recombinedData.length - 1) {
        result.style[prop] = "100%";
      } else {
        result.style[prop] = `${(100 / (recombinedData.length - 1)) * (value - 1)}%`;
      }
      return result;
    },
    [recombinedData.length]
  );

  const additionalProps = {
    marks: true,
    markClassName: classNames(styles.horizontalMark),
    renderMark: function RenderMark(props: HTMLPropsWithRefCallback<HTMLSpanElement>) {
      const key = props.key as number;
      return (
        <span
          {...recomputeMark(props, "left")}
          className={classNames([
            styles.horizontalMark,
            recombinedData.length > 0 && recombinedData[key].type === CombinedSegmentDataType.Vehicle
              ? styles.shortMark
              : "",
          ])}
        >
          {recombinedData.length > 0 && recombinedData[key].type !== CombinedSegmentDataType.Vehicle && (
            <div className={classNames(styles.markDesc)}>
              {recombinedData[key].segment.stop.identifier
                ? `${recombinedData[key].segment.stop.name} ${recombinedData[key].segment.stop.identifier}`
                : `${recombinedData[key].segment.stop.name}`}
            </div>
          )}
        </span>
      );
    },
  };

  const boundsLocations = [...visibleSegments.map((s) => s.stop.location)];
  if (state.currentVehiclePosition) {
    boundsLocations.push(state.currentVehiclePosition.location);
  }

  return (
    <div className={classNames(["content-wrapper", styles.contentWrapper])}>
      <section className={classNames(["content m-0 p-0 h-100", styles.content])}>
        <div className="container-fluid h-100 p-0">
          <Common.Map bounds={calculateBounds(boundsLocations)} height="calc(100% - 150px)">
            <Hud
              mission={filteredMission}
              activeSegments={activeSegments}
              activeIndex={activeIndex}
              visibleSegments={visibleSegments}
              visibleIndex={visibleIndex}
              vehiclePosition={state.currentVehiclePosition}
              refresh={() => getMissionDetails()}
              filtersState={filtersState}
              setFiltersState={setFiltersState}
            />
          </Common.Map>
          <div className="w-100">
            <div className={styles.slider}>
              <ReactSlider
                {...additionalProps}
                className={classNames([styles.horizontalSlider])}
                withTracks={true}
                min={min}
                max={max}
                value={state.pointInTime}
                thumbClassName={styles.horizontalThumb}
                trackClassName={styles.horizontalTrack}
                onChange={onChange}
                renderThumb={(props, state) => (
                  <div
                    {...recomputeThumb(
                      props as HTMLPropsWithRefCallback<HTMLDivElement>,
                      state.valueNow,
                      "left"
                    )}
                  ></div>
                )}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
