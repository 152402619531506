import classNames from "classnames";
import { Spinner } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useMap } from "react-leaflet";
import styles from "./MapLoadingOverlay.module.css";

interface Props {
  isShown?: boolean;
}

export function MapLoadingOverlay({ isShown }: Props) {
  const map = useMap();
  if (isShown) {
    map.dragging.disable();
    map.doubleClickZoom.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) {
      map.tap.disable();
    }
  } else {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) {
      map.tap.enable();
    }
  }
  return ReactDOM.createPortal(
    <div
      onClickCapture={(e) => {
        e.stopPropagation();
      }}
      className={classNames(styles.loadingOverlay, { [styles.visible]: !!isShown })}
    >
      <Spinner animation="border" variant="primary" />
    </div>,
    map.getContainer()
  );
}
