import * as Yup from "yup";
import { TFunction } from "i18next";
import { addMinutes } from "date-fns";

export type FormResultValues = Yup.Asserts<ReturnType<typeof operatorRideRequestSchemaFactory>>;
export type FormValues = Yup.TypeOf<ReturnType<typeof operatorRideRequestSchemaFactory>>;

export const operatorRideRequestSchemaFactory = (t: TFunction) => {
  const now = new Date();
  const minDate = addMinutes(now, -1);
  return Yup.object({
    phone: Yup.string().required(t("rides:form.validation.phoneRequired")),
    name: Yup.string().required(t("rides:form.validation.nameRequired")),
    passengerCount: Yup.number().min(1).required(),
    departureStopId: Yup.string().required(t("rides:form.validation.departureStopRequired")),
    arrivalStopId: Yup.string().required(t("rides:form.validation.arrivalStopRequired")),
    time: Yup.date()
      .nullable()
      .required(t("rides:form.validation.timeRequired"))
      .min(minDate, t("rides:form.validation.timeCannotBeInPast")),
    usingDepartureTime: Yup.bool(),
  });
};
