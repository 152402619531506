import { useState, useMemo, ReactElement, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { matchPath, RouteComponentProps } from "react-router-dom";
import classNames from "classnames";

import { SidebarMenuDropdownSubmenuItem } from "../SidebarMenuDropdownSubmenuItem";
import styles from "./SidebarMenuDropdownItem.module.css";
import { Navigation } from "shared/types";
import { useTranslation } from "react-i18next";
import { AuthService } from "shared/authService";
import { useAuthService } from "shared/hooks/useAuthService";

interface Props extends RouteComponentProps {
  translationKey: string;
  iconClass?: string;
  dropdownItems: DropdownItems;
}

type DropdownItems = {
  [key: string]: Navigation.Item;
};

const buildSubItems = (authService: typeof AuthService, dropdownItems: DropdownItems): ReactElement[] => {
  return Object.keys(dropdownItems).reduce<ReactElement[]>((result, key) => {
    const subItemShape = dropdownItems[key];
    if (authService.userHasOneOfRoles(subItemShape.visibleToRoles)) {
      result.push(<SidebarMenuDropdownSubmenuItem key={key} translationKey={key} {...subItemShape} />);
    }
    return result;
  }, []);
};

const checkIfLinkActive = (currentLocation: string, path: string): boolean => {
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
  return !!matchPath(currentLocation, {
    path: escapedPath,
    exact: false,
    strict: false,
  });
};

function SidebarMenuDropdownItemView({
  translationKey,
  iconClass,
  dropdownItems,
  location,
}: Props): ReactElement | null {
  const authService = useAuthService();
  const { t } = useTranslation("navigation");
  // First section establishes whether the dropdown should be displayed as active
  const { pathname: currentLocation } = location;
  const isDropdownActive = Object.keys(dropdownItems)
    .map((key) => dropdownItems[key].link)
    .some((childPath: string) => checkIfLinkActive(currentLocation, childPath));
  const [isOpen, setOpen] = useState<boolean>(isDropdownActive);
  useEffect(() => {
    setOpen(isDropdownActive);
  }, [isDropdownActive]);

  // Build the submenu items
  const submenuItems = useMemo(() => buildSubItems(authService, dropdownItems), [authService, dropdownItems]);
  if (submenuItems.length <= 0) {
    return null;
  }
  return (
    <li
      data-cy="sidebarDropdownMenu"
      className={classNames("nav-item has-treeview", {
        "menu-open": isOpen,
      })}
    >
      <span
        className={classNames("nav-link", styles.dropdownHeader, {
          active: isDropdownActive,
        })}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {iconClass && <i className={iconClass} />}
        <p>
          {t(translationKey)}
          <i className="right fas fa-angle-left" />
        </p>
      </span>
      <ul className="nav nav-treeview">{submenuItems}</ul>
    </li>
  );
}

export const SidebarMenuDropdownItem = withRouter(SidebarMenuDropdownItemView);
