import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Fleet } from "shared/types";
import { DateTransforms } from "shared/mappers";

interface Props {
  selectedVehicleId?: string;
  vehicles?: Fleet.Vehicle[];
  lastRefreshTimestamp?: Date;
  handleVehicleChange: (vehicleId: string) => void;
}

export function VehicleSelector({
  selectedVehicleId,
  handleVehicleChange,
  vehicles,
  lastRefreshTimestamp,
}: Props) {
  const { t } = useTranslation("driverRides");
  const shouldShowSelector = vehicles && vehicles.length > 1;
  return (
    <Row>
      {shouldShowSelector && (
        <Col xs={12} lg={5} xl={3}>
          <Form>
            <Form.Group className="mb-1 mb-lg-3">
              <Form.Label>{t("vehicleLabel")}</Form.Label>
              <Form.Control
                as="select"
                value={selectedVehicleId}
                onChange={(ev) => {
                  handleVehicleChange(ev.currentTarget.value);
                }}
              >
                {vehicles?.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      )}
      {lastRefreshTimestamp && (
        <Col
          xs={12}
          lg={shouldShowSelector ? 7 : 12}
          xl={shouldShowSelector ? 9 : 12}
          className={classNames("d-flex justify-content-center align-items-end", {
            "justify-content-lg-end": shouldShowSelector,
          })}
        >
          <div className="mb-1 mb-lg-3 font-italic">
            {t("driverRides:lastUpdate")}: {DateTransforms.toFullDateTimeFormat(lastRefreshTimestamp)}
          </div>
        </Col>
      )}
    </Row>
  );
}
