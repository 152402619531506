import * as Yup from "yup";
import { TFunction } from "i18next";

export const forbiddenTransitionSchemaFactory = (t: TFunction) =>
  Yup.object({
    sourceStopId: Yup.string()
      .required(t("general:validation.required"))
      .test(
        "source-different-then-destination",
        t("validation.sourceMustBeDifferentThenDestination"),
        function (value: string | undefined) {
          const destinationStopIdRef = Yup.ref("destinationStopId");
          return value !== this.resolve(destinationStopIdRef);
        }
      ),
    destinationStopId: Yup.string()
      .required(t("general:validation.required"))
      .test(
        "destination-different-then-source",
        t("validation.destinationMustBeDifferentThenSource"),
        function (value: string | undefined) {
          const sourceStopIdRef = Yup.ref("sourceStopId");
          return value !== this.resolve(sourceStopIdRef);
        }
      ),
  });
