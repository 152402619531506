import { ReactElement } from "react";
import { Form, FormControlProps } from "react-bootstrap";

export interface StateProps {
  label: string;
  itemEntities?: Record<string, { name: string }>;
  filteredItemId?: string;
  itemIds: string[];
}

export interface DispatchProps {
  setFilter: (newValue?: string) => void;
}

type Props = StateProps & DispatchProps;

export function ResultsFilter({
  itemEntities,
  itemIds,
  filteredItemId,
  setFilter,
  label,
}: Props): ReactElement | null {
  if (!itemEntities) {
    return null;
  }
  const handleChange: FormControlProps["onChange"] = (event) => {
    const newFilter = event.currentTarget.value === "" ? undefined : event.currentTarget.value;
    setFilter(newFilter);
  };
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        value={filteredItemId}
        onChange={handleChange}
        data-cy={`${label}FilterSelect`}
      >
        <option value="">No {label.toLowerCase()} selected</option>
        {itemIds.map((itemId) => (
          <option key={itemId} value={itemId}>
            {itemEntities[itemId].name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
