import { Fleet, Common } from "../types";
import { FleetUrls } from "../constants";
import { notifyOnError, ApiClient } from "./base";
import { ActiveVehiclePosition, DashboardResponse, ForbiddenTransition } from "shared/types/Fleet";
import FileDownload from "js-file-download";

class VehicleApiClient extends ApiClient {
  @notifyOnError
  public async getVehicles() {
    return await ApiClient.getWithToken<Fleet.Vehicle[]>(FleetUrls.Vehicle);
  }

  @notifyOnError
  public async getVehicle(vehicleId: string) {
    return await ApiClient.getWithToken<Fleet.Vehicle>(FleetUrls.SpecificVehicle(vehicleId));
  }

  @notifyOnError
  public async deleteVehicle(vehicleId: string) {
    return await ApiClient.deleteWithToken<Fleet.Vehicle[]>(FleetUrls.SpecificVehicle(vehicleId));
  }

  @notifyOnError
  public async addVehicle(vehicle: Common.MakeOptional<Fleet.Vehicle, "id">) {
    return await ApiClient.postWithToken(FleetUrls.Vehicle, vehicle);
  }

  @notifyOnError
  public async getMission(vehicleId: string) {
    return await ApiClient.getWithToken<Fleet.MissionState>(FleetUrls.VehicleMission(vehicleId));
  }

  @notifyOnError
  public async getPosition(vehicleId: string) {
    return await ApiClient.getWithToken<Fleet.VehiclePosition>(FleetUrls.VehiclePosition(vehicleId));
  }

  @notifyOnError
  public async getNearestVehicleSegments(vehicleId: string) {
    return await ApiClient.getWithToken<Fleet.VehicleDriverSegment[]>(
      FleetUrls.NearestVehicleSegments(vehicleId)
    );
  }

  @notifyOnError
  public async getAvailabilities(
    vehicleId: string,
    params: {
      id?: string;
      validFrom?: Date | null;
      validTo?: Date | null;
    } & Common.PaginationParams
  ) {
    return await ApiClient.getWithToken<Common.Paginated<Fleet.VehicleAvailabilityListItem>>(
      FleetUrls.VehicleAvailability(vehicleId),
      {
        params,
      }
    );
  }

  @notifyOnError
  public async createAvailability(vehicleId: string, availability: Fleet.VehicleAvailabilityActionDto) {
    return await ApiClient.postWithToken<Common.Paginated<Fleet.VehicleAvailabilityActionDto>>(
      FleetUrls.VehicleAvailability(vehicleId),
      availability
    );
  }

  @notifyOnError
  public async updateAvailability(
    vehicleId: string,
    availabilityId: string,
    availability: Fleet.VehicleAvailabilityActionDto
  ) {
    return await ApiClient.postWithToken(
      FleetUrls.SpecificVehicleAvailability(vehicleId, availabilityId),
      availability
    );
  }

  @notifyOnError
  public async getActivePositions() {
    return await ApiClient.getWithToken<ActiveVehiclePosition[]>(FleetUrls.VehicleActivePositions);
  }
}

class MissionsApiClient extends ApiClient {
  @notifyOnError
  public async getMissions(
    params: {
      id?: string;
      vehicleName?: string;
      status?: string;
      startsAfter?: Date | null;
      startsBefore?: Date | null;
    } & Common.PaginationParams
  ) {
    return await ApiClient.getWithToken<Common.Paginated<Fleet.MissionOverviewListItemDto>>(
      FleetUrls.MissionOverviewList,
      {
        params,
      }
    );
  }

  @notifyOnError
  public async getMission(id: string) {
    return await ApiClient.getWithToken<Fleet.MissionDetailedOverviewItemDto>(FleetUrls.MissionOverview(id));
  }

  @notifyOnError
  public async cancelMissionByOperator(cancelMission: Fleet.CancelMission) {
    return await ApiClient.postWithToken<unknown>(
      FleetUrls.CancelMissionOperator(cancelMission.vehicleId, cancelMission.missionId),
      cancelMission
    );
  }

  @notifyOnError
  public async startMission(vehicleId: string, missionId: string) {
    return await ApiClient.postWithToken<unknown>(FleetUrls.StartMission(vehicleId, missionId));
  }

  @notifyOnError
  public async finishMission(vehicleId: string, missionId: string) {
    return await ApiClient.postWithToken<unknown>(FleetUrls.FinishMission(vehicleId, missionId));
  }

  @notifyOnError
  public async removeMission(missionId: string) {
    return await ApiClient.deleteWithToken<unknown>(FleetUrls.RemoveMission(missionId));
  }

  @notifyOnError
  public async fixMission(missionId: string, method: string) {
    return await ApiClient.postWithToken<unknown>(FleetUrls.FixMission(missionId), {
      method: method,
    });
  }
}

class StopApiClient extends ApiClient {
  @notifyOnError
  public async getStops() {
    return await ApiClient.getWithToken<Fleet.Stop[]>(FleetUrls.Stops);
  }

  @notifyOnError
  public async stopsSearch(params: { startStop?: string; query?: string }) {
    return await ApiClient.getWithToken<Fleet.Stop[]>(FleetUrls.StopSearch, {
      params,
    });
  }

  @notifyOnError
  public async addStop(stop: Common.MakeOptional<Fleet.Stop, "stopId">) {
    return await ApiClient.postWithToken(FleetUrls.Stops, stop);
  }

  @notifyOnError
  public async deleteStop(stopId: string) {
    return await ApiClient.deleteWithToken(FleetUrls.StopDelete(stopId));
  }

  @notifyOnError
  public async getForbiddenTransitions() {
    return await ApiClient.getWithToken<Fleet.ForbiddenTransition[]>(FleetUrls.ForbiddenTransitions);
  }

  @notifyOnError
  public async deleteForbiddenTransition(forbiddenTransition: ForbiddenTransition) {
    return await ApiClient.deleteWithToken(FleetUrls.ForbiddenTransitions, {
      data: forbiddenTransition,
    });
  }

  @notifyOnError
  public async addForbiddenTransition(forbiddenTransition: ForbiddenTransition) {
    return await ApiClient.postWithToken(FleetUrls.ForbiddenTransitions, forbiddenTransition);
  }
}

class RidesApiClient extends ApiClient {
  @notifyOnError
  public async cancelByOperator(rideId: string) {
    return await ApiClient.postWithToken<void>(FleetUrls.RideCancelByOperator(rideId));
  }

  @notifyOnError
  public async registerByOperator(newRideRequest: Fleet.OperatorRideRequest) {
    return await ApiClient.postWithToken<Fleet.RideRequestResponse>(
      FleetUrls.RideRegisterByOperator,
      newRideRequest
    );
  }

  @notifyOnError
  public async getRides(
    params: {
      id?: string;
      status?: string;
      username?: string;
      createdAfter?: Date | null;
      createdBefore?: Date | null;
      onlyActiveMissions?: boolean | null;
    } & Common.PaginationParams
  ) {
    return await ApiClient.getWithToken<Common.Paginated<Fleet.RideOverviewListItemDto>>(
      FleetUrls.RideOverviewList,
      {
        params,
      }
    );
  }

  @notifyOnError
  public async getRideDetails(rideId: string) {
    return await ApiClient.getWithToken<Fleet.RideOverviewItemDto>(FleetUrls.RideOverview(rideId));
  }
}

class ReportsApiClient extends ApiClient {
  @notifyOnError
  public async downloadRaportFile(
    from: string,
    until: string,
    numberOfDaysToGroup: number,
    daysInactivityCount: number,
    minimumConnectionsCount: number,
    filename: string
  ) {
    const dataType = "file";
    FileDownload(
      (
        await ApiClient.getWithToken<Blob>(FleetUrls.Dashboard, {
          params: {
            dataType,
            from,
            until,
            numberOfDaysToGroup,
            daysInactivityCount,
            minimumConnectionsCount,
          },
          responseType: "blob",
        })
      ).data,
      filename,
      "text/xlsx;charset=utf-8"
    );
  }

  @notifyOnError
  public async getRaportData(
    from: string,
    until: string,
    numberOfDaysToGroup: number,
    daysInactivityCount: number,
    minimumConnectionsCount: number
  ) {
    const dataType = "json";
    return await ApiClient.getWithToken<DashboardResponse>(FleetUrls.Dashboard, {
      params: { dataType, from, until, numberOfDaysToGroup, daysInactivityCount, minimumConnectionsCount },
    });
  }
}

export class FleetApiClient {
  public static readonly vehicle = Object.freeze(new VehicleApiClient());
  public static readonly missions = Object.freeze(new MissionsApiClient());
  public static readonly rides = Object.freeze(new RidesApiClient());
  public static readonly stop = Object.freeze(new StopApiClient());
  public static readonly reports = Object.freeze(new ReportsApiClient());
}
