import { Fleet } from "shared/types";

export const stop = {
  side: {
    title: "Wirtualne przystanki",
  },
  map: "Mapa przystanków",
  list: "Lista przystanków",
  add: "Dodaj przystanek",
  validation: {
    minLat: "Minimalna szerokość geograficzna to: {{minLat}}",
    maxLat: "Maksymalna szerokość geograficzna to: {{maxLat}}",
    minLng: "Minimalna długość geograficzna to: {{minLng}}",
    maxLng: "Maksymalna długość geograficzna to: {{maxLng}}",
    imageUrl: "Wprowadzono nieprawidłowo sformatowany url",
    uniqueUrl: "Wprowadzony URL został już dodany",
    notFound: "Wprowadzony URL nie istnieje",
    fetchError: "Wystąpił błąd przy pobraniu zdjęcia ze wskazanego URLa",
  },
  table: {
    header: {
      id: "Id",
      name: "Nazwa",
      city: "Miasto",
      street: "Ulica",
      identifier: "Identyfikator",
      type: "Typ",
      location: "Geolokacja",
      actions: "Akcje",
    },
    actions: {
      edit: "Edytuj przystanek",
      delete: "Usuń przystanek",
      details: "Szczegóły przystanku",
      deleteConfirm: "Czy na pewno chcesz usunąć przystanek '{{name}}'?",
    },
  },
  form: {
    identifier: "Identyfikator",
    name: "Nazwa",
    city: "Miasto",
    street: "Ulica",
    type: "Typ",
    submit: "Zapisz",
    lat: "Szerokość geograficzna (lat)",
    lng: "Długość geograficzna (lng)",
    add: "Dodaj przystanek",
    edit: "Edytuj przystanek",
    details: "Szczegóły przystanku",
    imageUrl: "Zdjęcia przystanku",
  },
  type: {
    [Fleet.StopType.All]: "Dwukierunkowy",
    [Fleet.StopType.Pickup]: "Do wsiadania",
    [Fleet.StopType.Dropoff]: "Do wysiadania",
    [Fleet.StopType.Disabled]: "Nieaktywny",
  },
};
