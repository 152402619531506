import React from "react";
import { FullDateTimeFormat } from "shared/constants/dateFormats";
import { DefaultDatePicker } from "./DefaultDatePicker";

export function DefaultDateTimePicker(
  props: React.ComponentProps<typeof DefaultDatePicker>
): React.ReactElement {
  return (
    <DefaultDatePicker dateFormat={FullDateTimeFormat} shouldCloseOnSelect={false} showTimeInput {...props} />
  );
}
