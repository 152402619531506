import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  translationKey: string;
  link: string;
  iconClass?: string;
}

export function SidebarMenuDropdownSubmenuItem({
  link,
  translationKey,
  iconClass = "far fa-circle nav-icon",
}: Props): ReactElement {
  const { t } = useTranslation("navigation");
  return (
    <li className="nav-item">
      <NavLink to={link} className="nav-link">
        <i className={iconClass} />
        <p>{t(translationKey)}</p>
      </NavLink>
    </li>
  );
}
