import { ReactElement, useCallback, useReducer } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { FleetApiClient } from "shared/api";
import { StoreShape } from "shared/state";
import { showNotification } from "shared/state/actions";
import { Common, Fleet } from "shared/types";
import { Layout, Rides } from "components";
import { ridesReducer, initialState, ridesPageActions } from "./reducer";

interface DispatchProps {
  showErrorNotification: (message: JSX.Element) => void;
}
type Props = DispatchProps;

function RidesPageView({ showErrorNotification }: Props): ReactElement {
  const { t } = useTranslation(["navigation", "rides"]);
  const [state, dispatch] = useReducer(ridesReducer, initialState);
  const updateFilter = useCallback(
    function updateFilter<FilterName extends keyof Rides.FilterState>(
      filterName: FilterName,
      value: Rides.FilterState[FilterName]
    ) {
      dispatch(ridesPageActions.updateFilterValue({ filterName, value }));
    },
    [dispatch]
  );
  const loadRides = useCallback(
    async (newParams: Common.PaginationParams) => {
      dispatch(ridesPageActions.startFetching());
      try {
        const result = await FleetApiClient.rides.getRides({ ...state.filters, ...newParams });
        dispatch(ridesPageActions.setDataState(result.data));
      } finally {
        dispatch(ridesPageActions.finishFetching());
      }
    },
    [state.filters]
  );
  const refreshRides = useCallback(async () => {
    await loadRides(state.rides.state);
  }, [loadRides, state.rides.state]);
  const handleCreate = async (request: Fleet.OperatorRideRequest) => {
    const response = await FleetApiClient.rides.registerByOperator(request);
    if (response.data.success && response.data.ride) {
      dispatch(ridesPageActions.showSuccessModal(response.data.ride));
      await refreshRides();
    } else {
      showErrorNotification(<Rides.Create.ErrorNotification message={response.data.errorMessage} />);
    }
  };
  const handleCancel = useCallback(
    async (rideId: string) => {
      if (window.confirm(t("rides:table.cancelConfirm"))) {
        await FleetApiClient.rides.cancelByOperator(rideId);
        void refreshRides();
      }
    },
    [refreshRides, t]
  );
  const handleCreateModalShow = () => dispatch(ridesPageActions.showCreateModal());
  const handleCreateModalHide = () => dispatch(ridesPageActions.hideCreateModal());
  return (
    <Layout.Content simpleHeader={t("navigation:rides")}>
      <Card className="pb-2" data-cy="viewRidesListCard">
        <Rides.Create.Form.Modal
          handleCreate={handleCreate}
          isShown={state.modals.create.shown}
          onHide={handleCreateModalHide}
        />
        <Rides.Create.SuccessModal
          ride={state.modals.success.ride}
          onHide={() => dispatch(ridesPageActions.hideSuccessModal())}
        />
        <Rides.Filters updateFilter={updateFilter} filterState={state.filters} />
        <Rides.Table
          handleCancel={handleCancel}
          showCreateModal={handleCreateModalShow}
          loadRides={loadRides}
          isFetching={state.rides.isFetching}
          dataState={state.rides.state}
        />
      </Card>
    </Layout.Content>
  );
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  showErrorNotification: (message: JSX.Element) =>
    dispatch(showNotification({ message, color: "danger", disableAutoHide: true })),
});

const PlannerResultsConnected = connect<null, DispatchProps, Record<string, unknown>, StoreShape>(
  null,
  mapDispatchToProps
)(RidesPageView);

export const RidesPage = PlannerResultsConnected;
