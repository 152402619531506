import { useTranslation } from "react-i18next";
import { Layout } from "components";
import { Button } from "react-bootstrap";
import { useAuthService } from "shared/hooks/useAuthService";

export function Unauthorized() {
  const authService = useAuthService();
  const { t } = useTranslation();
  const handleLogout = () => {
    authService.logout();
  };
  return (
    <Layout.Centered>
      <h4>{t("unauthorized")}</h4>
      <div className="d-flex justify-content-center">
        <Button className="btn-main mt-2" onClick={handleLogout}>
          {t("logout")}
        </Button>
      </div>
    </Layout.Centered>
  );
}
