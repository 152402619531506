import { Fleet } from "shared/types";

export const rides = {
  source: {
    [Fleet.RideRequestSource.Operator]: "Panel operatora",
    [Fleet.RideRequestSource.PassengerMobile]: "Aplikacja mobilna",
  },
  status: {
    [Fleet.RideStatuses.Registered]: "Przyjęty",
    [Fleet.RideStatuses.InProgress]: "Trwający",
    [Fleet.RideStatuses.Absent]: "Nieobecny",
    [Fleet.RideStatuses.AbsentConfirmed]: "Nieobecny ✓",
    [Fleet.RideStatuses.Delivered]: "Zakończony",
    [Fleet.RideStatuses.DeliveryConfirmed]: "Zakończony ✓",
    [Fleet.RideStatuses.Cancelled]: "Anulowany",
    [Fleet.RideStatuses.CancelledConfirmed]: "Anulowany ✓",
    [Fleet.RideStatuses.Unconfirmed]: "Niepotwierdzony",
  },
  departureStatus: {
    [Fleet.PickupStatuses.Pending]: "Oczekuje",
    [Fleet.PickupStatuses.PickedUp]: "Odebrany",
    [Fleet.PickupStatuses.NotPickedUp]: "Nie odebrany",
  },
  arrivalStatus: {
    [Fleet.DropoffStatuses.Pending]: "Oczekuje",
    [Fleet.DropoffStatuses.DroppedOff]: "Dowieziony",
    [Fleet.DropoffStatuses.NotPickedUp]: "Nie odebrany",
  },
  filters: {
    id: "Id przejazdu",
    username: "Nazwa użytkownika",
    status: "Status",
    createdAfter: "Utworzone po",
    createdBefore: "Utworzone przed",
    source: "Źródło",
    onlyActiveMissions: "Tylko aktywne misje",
  },
  form: {
    create: "Zgłoś nowy",
    arrival: "Przyjazd",
    departure: "Odjazd",
    time: "Czas przejazdu",
    passenger: "Pasażer",
    ride: "Przejazd",
    departureStop: "Skąd",
    arrivalStop: "Dokąd",
    phone: "Numer telefonu",
    name: "Nazwa użytkownika",
    passengerCount: "Liczba pasażerów",
    header: "Zgłoś przejazd",
    validation: {
      nameRequired: "Należy podać imię",
      phoneRequired: "Należy podać numer telefonu",
      arrivalStopRequired: "Należy wybrać przystanek końcowy",
      departureStopRequired: "Należy wybrać przystanek początkowy",
      timeRequired: "Należy wybrać godzinę",
      timeCannotBeInPast: "Wybrana godzina nie może być w przeszłości",
    },
  },
  table: {
    cancelConfirm: "Czy na pewno anulować ten przejazd?",
    header: {
      status: "Status",
      username: "Użytkownik",
      numberOfPassengers: "Liczba pasażerów",
      from: "Skąd",
      to: "Dokąd",
      departure: "Odjazd",
      arrival: "Przyjazd",
      vehicleName: "Pojazd",
      created: "Utworzony",
      details: "Szczegóły",
      actions: "Akcje",
      source: "Źródło",
    },
    cells: {
      initialTime: "Proponowany",
      plannedTime: "Planowany",
      reportedTime: {
        completed: "Zrealizowany",
        cancelled: "Anulowany",
        absent: "Nieobecny"
      },
    },
  },
  details: {
    entity: "Typ encji",
    id: "Identyfikator",
    header: "Szczegóły przejazdu",
    relations: "Relacje",
    copy: "Skopiuj",
    goToMission: "Przejdź do misji",
    general: "Ogólne",
    passenger: "Pasażer",
    mission: "Misja",
    departureSegment: "Segment wyjazdu",
    arrivalSegment: "Segment przyjazdu",
    rideRequest: "Przyjęte zgłoszenie",
    departureStatus: "Status odjazdu",
    arrivalStatus: "Status przyjazdu",
  },
  newRide: {
    rideFound: "Przejazd znaleziony!",
    rideNotFound: "Nie udało się znaleźć przejazdu.",
  },
};
