import React, { ReactElement } from "react";
import { Col } from "react-bootstrap";

interface Props {
  simpleHeader?: string;
  headerComponent?: React.ReactNode;
  children?: React.ReactNode;
}

export function Content({ children, simpleHeader, headerComponent }: Props): ReactElement {
  let header = null;
  if (simpleHeader) {
    header = (
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <Col xs={12}>
              <h1 data-cy="pageContentHeader" className="m-0 text-dark">
                {simpleHeader}
              </h1>
            </Col>
          </div>
        </div>
      </section>
    );
  }
  if (headerComponent) {
    header = headerComponent;
  }
  return (
    <div className="content-wrapper">
      {header}
      <section className="content">
        <div className="container-fluid">{children}</div>
      </section>
    </div>
  );
}
