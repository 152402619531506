import produce from "immer";
import actionCreatorFactory, { AnyAction, isType } from "typescript-fsa";

interface StopImagesState {
  draftImageUrl: string;
  draftImageNotFound: boolean;
  checkingDraftImage: boolean;
  imageToPreview: string;
  showImageInPreview: boolean;
  draftImageFetchErrorOccured: boolean;
}

const actionCreator = actionCreatorFactory();

export const StopImagesStateActions = {
  checkingDraftImageStarted: actionCreator("CHECKING_DRAFT_IMAGE_STARTED"),
  checkingDraftImageCompleted: actionCreator("CHECKING_DRAFT_IMAGE_COMPLETED"),
  draftImageFetchErrorOccured: actionCreator("FETCH_ERROR_OCCURED"),
  draftImageNotFound: actionCreator("DRAFT_IMAGE_FOUND"),
  clearDraftImageUrl: actionCreator("CLEAR_IMAGE_URL"),
  draftImageUrlChanged: actionCreator<{ imageUrl: string }>("DRAFT_IMAGE_URL_CHANGED"),
  showImageInPreview: actionCreator<{ imageUrl: string }>("SHOW_IMAGE_IN_PREVIEW"),
  imagePreviewClosed: actionCreator("IMAGE_PREVIEW_CLOSED"),
};

export const initialState: StopImagesState = {
  draftImageUrl: "",
  draftImageNotFound: false,
  checkingDraftImage: false,
  imageToPreview: "",
  showImageInPreview: false,
  draftImageFetchErrorOccured: false,
};

export const stopImagesReducer = produce((draft: StopImagesState, action: AnyAction) => {
  if (isType(action, StopImagesStateActions.checkingDraftImageStarted)) {
    draft.draftImageNotFound = false;
    draft.checkingDraftImage = true;
    draft.draftImageFetchErrorOccured = false;
  }

  if (isType(action, StopImagesStateActions.checkingDraftImageCompleted)) {
    draft.checkingDraftImage = false;
  }

  if (isType(action, StopImagesStateActions.draftImageFetchErrorOccured)) {
    draft.draftImageFetchErrorOccured = true;
    draft.checkingDraftImage = false;
  }

  if (isType(action, StopImagesStateActions.draftImageNotFound)) {
    draft.draftImageNotFound = true;
  }

  if (isType(action, StopImagesStateActions.clearDraftImageUrl)) {
    draft.draftImageUrl = "";
  }

  if (isType(action, StopImagesStateActions.draftImageUrlChanged)) {
    draft.draftImageUrl = action.payload.imageUrl;
    draft.draftImageNotFound = false;
    draft.draftImageFetchErrorOccured = false;
  }

  if (isType(action, StopImagesStateActions.showImageInPreview)) {
    draft.showImageInPreview = true;
    draft.imageToPreview = action.payload.imageUrl;
  }

  if (isType(action, StopImagesStateActions.imagePreviewClosed)) {
    draft.showImageInPreview = false;
  }
});
