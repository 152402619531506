import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Fleet } from "shared/types";

interface Props {
  dataSource: Fleet.PassengersAndRideDataSource[];
}

export function PassengersCountChart({ dataSource }: Props) {
  const { t } = useTranslation(["reports"]);
  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        height: 400,
        type: "line",
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true,
            customIcons: [],
          },
        },
      },
      stroke: {
        width: [0, 2],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return (value as number).toFixed(2);
        },
      },

      xaxis: {
        categories: dataSource.map((d) => new Date(d.date).toLocaleDateString()),
      },
      yaxis: [
        {
          title: {
            text: t("charts.passengersCount.seriesName.passengersCount"),
          },
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0);
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          opposite: true,
          title: {
            text: t("charts.passengersCount.seriesName.avgPassengersPerRequestCount"),
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#00E396",
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
      ],
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    }),
    [dataSource, t]
  );

  const series = useMemo(
    () => [
      {
        name: t("charts.passengersCount.seriesName.passengersCount"),
        type: "column",
        data: dataSource.map((d) => d.passengersCount),
      },
      {
        name: t("charts.passengersCount.seriesName.avgPassengersPerRequestCount"),
        type: "line",
        data: dataSource.map((d) => d.avgPassengersPerRequestCount),
      },
    ],
    [dataSource, t]
  );

  return <ReactApexChart options={options} series={series} type="line" height={400} />;
}
