import { StoreShape } from "../store";
import { createSelector } from "reselect";

export const getConfigurationItems = (state: StoreShape) => state.global.configuration.items;
export const getNotificationsQueue = (state: StoreShape) => state.global.notifications.notificationQueue;
export const getNotificationShowDuration = (state: StoreShape) =>
  state.global.notifications.notificationShowDuration;
export const getMapTilingServiceAddress = (state: StoreShape) =>
  state.global.configuration.items.mapTilingServiceAddress;
export const getGeocodingServiceAddress = (state: StoreShape) =>
  state.global.configuration.items.geocodingServiceAddress;
export const getUserData = (state: StoreShape) => state.global.user.userData;
export const getAuthToken = (state: StoreShape) => state.global.user.userData?.access_token;
export const getProfile = createSelector(getUserData, (userData) => userData?.profile);
export const getToken = (state: StoreShape) => state.global.user.accessTokenData;
export const getChosenRealmId = (state: StoreShape) => state.global.user.realm?.id;
export const getChosenRealmApiUrl = (state: StoreShape) => state.global.user.realm?.apiUrl;
export const getPaymentsSettings = (state: StoreShape) =>
  state.global.configuration.items.paymentsConfiguration;
export const getChosenRealmName = (state: StoreShape) => state.global.user.realm?.name;
export const getUser = (state: StoreShape) => state?.global?.user ?? {};
export const getIdToken = (state: StoreShape) => state?.global?.user.userData?.id_token;
export const getMapboxProximity = (state: StoreShape) => state.global.configuration.items.mapboxProximity;
export const getMapboxBoundingBox = (state: StoreShape) => state.global.configuration.items.mapboxBoundingBox;
export const getMapboxAccessToken = (state: StoreShape) => state.global.configuration.items.mapboxAccessToken;
export const getRequestRegistrationFutureTimeLimitInMinutes = (state: StoreShape) =>
  state.global.configuration.items.requestRegistrationFutureTimeLimitInMinutes;
