import { ReactElement, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import { Layout, MissionDashboard, Common } from "components";
import { Fleet } from "shared/types";
import { FleetApiClient } from "shared/api";

interface Params {
  vehicleId: string;
}

export function MissionDashboardPage(): ReactElement {
  const { vehicleId } = useParams<Params>();
  const [vehiclePosition, setVehiclePosition] = useState<Fleet.VehiclePosition>();
  const [missionState, setMissionState] = useState<Fleet.MissionState>();
  const [highlightsEnabled, setHighlightsEnabled] = useState(true);
  const toggleHighlights = useCallback(() => setHighlightsEnabled((prev) => !prev), [setHighlightsEnabled]);
  const updateState = useCallback(async () => {
    if (!vehicleId) {
      return;
    }
    try {
      const results = await Promise.all([
        FleetApiClient.vehicle.getPosition(vehicleId),
        FleetApiClient.vehicle.getMission(vehicleId),
      ]);
      setVehiclePosition(results[0].status === 200 ? results[0].data : undefined);
      setMissionState(results[1].status === 200 ? results[1].data : undefined);
    } catch (err) {
      console.warn(err);
    }
  }, [vehicleId]);

  useEffect(() => {
    void updateState();

    const interval = setInterval(() => {
      void updateState();
    }, 5000);

    return () => clearInterval(interval);
  }, [updateState]);

  return (
    <Layout.Content
      headerComponent={
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between">
                <h1 data-cy="pageContentHeader" className="m-0 text-dark">
                  Mission Dashboard
                </h1>
                <h1 className="m-0 text-dark">
                  <Common.Clock />
                </h1>
              </div>
            </div>
          </div>
        </section>
      }
    >
      <MissionDashboard.Map
        missionState={missionState}
        vehiclePosition={vehiclePosition}
        enableHighlights={highlightsEnabled}
      >
        <MissionDashboard.SettingsControls
          highlightsEnabled={highlightsEnabled}
          toggleHighlights={toggleHighlights}
        />
      </MissionDashboard.Map>
    </Layout.Content>
  );
}
