/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";

import { Planner } from "shared/types";
import { EvaluatorsInput } from "./EvaluatorsInput";
import { EvaluatorsManualInput } from "../../common/Input/EvaluatorsManualInput";
import { AlgorithmType } from "shared/types/Planner";

interface Props {
  algorithmType: string;
  algorithmConfiguration: Planner.AlgorithmConfiguration;
  loadingOptions: boolean;
  availableAlgorithmOptions: Planner.PreloadedAlgorithmOptions["availableAlgorithmOptions"];
  defaultConfiguration: Planner.AlgorithmConfiguration;
  errors?: Partial<Record<keyof Planner.AlgorithmConfiguration, string>>;
  evaluatorsValueMultiplier: number;
  evaluatorsTotalMax: number;
  onAlgorithmChange: (newAlgorithmType: Planner.AlgorithmType) => void;
  onGeneratorChange: (newGenerator: string) => void;
  onEvaluatorsChange: (newEvaluators: Planner.AlgorithmConfiguration["evaluators"]) => void;
}

const algorithmNameMap: Record<Planner.AlgorithmType, string> = {
  [Planner.AlgorithmType.LOCAL_SEARCH]: "Local Search",
  [Planner.AlgorithmType.GUIDED_LOCAL_SEARCH]: "Guided Local Search",
  [Planner.AlgorithmType.ROUTE_MINIMIZATION]: "Route Minimization",
  [Planner.AlgorithmType.ROUTE_MINIMIZATION_LOCAL_SEARCH]: "Route Minimization Local Search",
  [Planner.AlgorithmType.GUIDED_ROUTE_MINIMIZATION_LOCAL_SEARCH]: "Guided RM Local Search",
  [Planner.AlgorithmType.MEMETIC]: "Memetic",
};

export function AlgorithmConfigurationForm({
  algorithmType,
  onAlgorithmChange,
  algorithmConfiguration,
  onGeneratorChange,
  loadingOptions,
  availableAlgorithmOptions,
  onEvaluatorsChange,
  defaultConfiguration,
  errors,
  evaluatorsValueMultiplier,
  evaluatorsTotalMax,
}: Props): React.ReactElement {
  const [useSliders, setUseSliders] = useState(true);
  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
  const handleAlgorithmChange = (ev: any) => onAlgorithmChange(ev.target.value as AlgorithmType);
  const handleGeneratorChange = (ev: any) => onGeneratorChange(ev.target.value as string);
  const handleEvaluatorsChange = (values: Record<string, number>) =>
    onEvaluatorsChange(
      Object.entries(values).map(([identifier, weight]) => ({
        identifier,
        weight: weight / evaluatorsValueMultiplier,
      }))
    );
  const handleEvaluatorsReset = () => onEvaluatorsChange(defaultConfiguration.evaluators);
  const mapCurrentEvaluatorValues = (evaluators: Planner.AlgorithmConfiguration["evaluators"]) =>
    evaluators.reduce<Record<string, number>>((result, curr) => {
      result[curr.identifier] = curr.weight * evaluatorsValueMultiplier;
      return result;
    }, {});
  const isGeneratorInvalid = !!(errors && errors.initialSolutionGenerator);
  const EvaluatorsComponent = useSliders ? EvaluatorsInput : EvaluatorsManualInput;
  return (
    <Form.Row>
      <Form.Group as={Col} md="6" className="pr-4">
        <Form.Label>Algorithm</Form.Label>
        <Form.Control as="select" value={algorithmType} onChange={handleAlgorithmChange} className="mb-2">
          {Object.values(Planner.AlgorithmType).map((type: Planner.AlgorithmType) => (
            <option key={type} value={type}>
              {algorithmNameMap[type]}
            </option>
          ))}
        </Form.Control>
        <Form.Label>Generator</Form.Label>
        <Form.Control
          as="select"
          value={algorithmConfiguration.initialSolutionGenerator}
          onChange={handleGeneratorChange}
          disabled={loadingOptions}
          isInvalid={isGeneratorInvalid}
        >
          {!loadingOptions &&
            availableAlgorithmOptions.initialSolutionGenerators.map(({ identifier, displayLabel }) => (
              <option key={identifier} value={identifier}>
                {displayLabel}
              </option>
            ))}
          {isGeneratorInvalid && (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Form.Control.Feedback type="invalid">{errors!.initialSolutionGenerator}</Form.Control.Feedback>
          )}
        </Form.Control>
      </Form.Group>
      {availableAlgorithmOptions && availableAlgorithmOptions.evaluators && (
        <Form.Group as={Col} md="5" controlId="generatorSelector">
          <Form.Label className="d-flex justify-content-between">
            <div>Evaluators</div>
            <div>
              <Button
                size="sm"
                variant="info"
                onClick={() => {
                  setUseSliders((prevState) => !prevState);
                }}
                className="mr-1"
              >
                {useSliders ? "Direct" : "Sliders"}
              </Button>
              <Button size="sm" onClick={handleEvaluatorsReset}>
                Reset
              </Button>
            </div>
          </Form.Label>
          <EvaluatorsComponent
            options={availableAlgorithmOptions.evaluators}
            currentValues={mapCurrentEvaluatorValues(algorithmConfiguration.evaluators)}
            totalMax={evaluatorsTotalMax}
            id="EvaluatorsInput"
            onChange={handleEvaluatorsChange}
            error={errors && errors.evaluators}
          />
        </Form.Group>
      )}
    </Form.Row>
  );
}
