import classNames from "classnames";
import { ReactElement } from "react";
import { Badge, Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { RideStatuses } from "shared/types/Fleet";
import styles from "./SegmentsPart.module.css";

interface Props {
  segments: Fleet.Segment[];
  activeIndex: number;
}

export function SegmentsPart({ segments, activeIndex }: Props): ReactElement {
  const { t } = useTranslation();
  const cancellationStatuses = [RideStatuses.Cancelled, RideStatuses.CancelledConfirmed];
  const infiteTime = "0001-01-01T00:00:00+00:00";
  return (
    <ul className={classNames(["m-0 p-0 text-center list-unstyled", styles.listOfActiveSegments])}>
      {segments.map((f, index) => (
        <li
          key={`segment-${index}`}
          className={`${index === activeIndex ? styles.mainSegment : ""} ${classNames(styles.activeSegment)}`}
        >
          <>
            <div className={styles.segmentSection}>
              <div className={styles.segmentAddress}>
                <div>{f.stop.identifier ? `${f.stop.name} ${f.stop.identifier}` : `${f.stop.name}`}</div>
                {f.reportedDepartureTime && (
                  <div>
                    <span>{TimestampTransforms.toShortTime(f.initialPlannedServiceTime)}</span>
                    <span>⏵</span>
                    <span>{TimestampTransforms.toShortTime(f.plannedServiceTime)}</span>
                  </div>
                )}
                {!f.reportedDepartureTime && (
                  <div>
                    <span>{TimestampTransforms.toShortTime(f.plannedServiceTime)}</span>
                  </div>
                )}
                {index === activeIndex && (
                  <>
                    <div className={styles.segmentStatus}>({t(f.segmentStatus.description)})</div>
                    <div>
                      {t("arrived")}: {TimestampTransforms.toShortTime(f.reportedArrivalTime)}
                    </div>
                    <div>
                      {t("departured")}: {TimestampTransforms.toShortTime(f.reportedDepartureTime)}
                    </div>
                    <div className={classNames(styles.users)}>
                      <ul>
                        {f.pickUps.map((p) => {
                          const additionalPassengers = p.passengerCounter - 1;
                          return (
                            <li key={p.rideId} className={styles.userPickedUp}>
                              <i className={classNames("fas fa-plus mr-2")}></i>
                              <span style={{ color: "black" }}>
                                {p.pickupStatusUpdated &&
                                  p.pickupStatusUpdated !== infiteTime &&
                                  `${TimestampTransforms.toShortTime(p.pickupStatusUpdated)} / `}
                              </span>
                              <span>
                                {p.passengerName}
                                {additionalPassengers > 0 && (
                                  <Badge
                                    variant="primary"
                                    className="ml-1"
                                  >{`+ ${additionalPassengers}`}</Badge>
                                )}
                                &nbsp;-&nbsp;
                                {cancellationStatuses.includes(p.status.code)
                                  ? t(p.status.description)
                                  : t(p.pickupStatus.description)}
                              </span>
                            </li>
                          );
                        })}
                        {f.dropOffs.map((d) => {
                          const additionalPassengers = d.passengerCounter - 1;
                          return (
                            <li key={d.rideId} className={classNames(styles.userDroppedOff)}>
                              <i className={classNames("fas fa-minus mr-2")}></i>
                              <span style={{ color: "black" }}>
                                {d.dropoffStatusUpdated &&
                                  d.dropoffStatusUpdated !== infiteTime &&
                                  `${TimestampTransforms.toShortTime(d.dropoffStatusUpdated)} / `}
                              </span>
                              <span>
                                {d.passengerName}
                                {additionalPassengers > 0 && (
                                  <Badge
                                    variant="primary"
                                    className="ml-1"
                                  >{`+ ${additionalPassengers}`}</Badge>
                                )}
                                &nbsp;-&nbsp;
                                {cancellationStatuses.includes(d.status.code)
                                  ? t(d.status.description)
                                  : t(d.dropoffStatus.description)}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.segmentIdCopy}>
                <CopyToClipboard text={f.segmentId}>
                  <Button variant="success">
                    <i className="far fa-clipboard"></i>
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </>
        </li>
      ))}
    </ul>
  );
}
