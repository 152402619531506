import { CellProps } from "react-table";
import styles from "../Rides.module.css";
import { Fleet } from "shared/types";
import { isEmpty } from "shared/helpers";
import { RoleRestriction } from "components/common";
import { TimestampTransforms } from "shared/mappers";
import { Role } from "shared/constants";
import { useTranslation } from "react-i18next";
import { RideStatuses } from "shared/types/Fleet";

function computeReportedTimeForArrival(item: Fleet.RideOverviewListItemDto) {
  if (item.status === RideStatuses.Cancelled || item.status === RideStatuses.CancelledConfirmed) {
    return "";
  }

  if (item.status === RideStatuses.Absent || item.status === RideStatuses.AbsentConfirmed) {
    return "";
  }

  if (item.status === RideStatuses.Registered) {
    return "";
  }

  if (item.status === RideStatuses.Unconfirmed) {
    return "";
  }

  if (item.status === RideStatuses.InProgress) {
    return "";
  }

  return item.arrivalStatusUpdatedAt;
}

function computeReportedTimeForDeparture(item: Fleet.RideOverviewListItemDto) {
  if (item.status === RideStatuses.Cancelled || item.status === RideStatuses.CancelledConfirmed) {
    return item.statusUpdatedAt;
  }

  if (item.status === RideStatuses.Absent || item.status === RideStatuses.AbsentConfirmed) {
    return item.statusUpdatedAt;
  }

  if (item.status === RideStatuses.Registered) {
    return "";
  }

  if (item.status === RideStatuses.Unconfirmed) {
    return "";
  }

  if (item.status === RideStatuses.InProgress) {
    return item.departureStatusUpdatedAt;
  }

  return item.departureStatusUpdatedAt;
}

function computeReportedTimeTextForDeparture(item: Fleet.RideOverviewListItemDto): string {
  if (item.status === RideStatuses.Cancelled || item.status === RideStatuses.CancelledConfirmed) {
    return "table.cells.reportedTime.cancelled";
  }

  if (item.status === RideStatuses.Absent || item.status === RideStatuses.AbsentConfirmed) {
    return "table.cells.reportedTime.absent";
  }

  return "table.cells.reportedTime.completed";
}

function computeReportedTimeTextForArrival(item: Fleet.RideOverviewListItemDto) {
  return "table.cells.reportedTime.completed";
}

export function TimeDestination({
  row,
  isDeparture = false,
}: CellProps<Fleet.RideOverviewListItemDto> & { isDeparture?: boolean }) {
  const { t } = useTranslation("rides");
  let displayData = {
    stopLabel: `${row.original.arrivalStopName}${
      !isEmpty(row.original.arrivalStopNumber) ? ` (${row.original.arrivalStopNumber})` : ""
    }`,
    initialTime: row.original.initialPlannedArrival,
    plannedTime: row.original.plannedArrival,
    reportedTime: computeReportedTimeForArrival(row.original),
  };
  if (isDeparture) {
    displayData = {
      stopLabel: `${row.original.departureStopName}${
        !isEmpty(row.original.departureStopNumber) ? ` (${row.original.departureStopNumber})` : ""
      }`,
      initialTime: row.original.initialPlannedDeparture,
      plannedTime: row.original.plannedDeparture,
      reportedTime: computeReportedTimeForDeparture(row.original),
    };
  }
  return (
    <div>
      <strong>{displayData.stopLabel}</strong>
      <ul className={styles.timeCellList}>
        <li>
          {t("table.cells.initialTime")}: {TimestampTransforms.toFullDate(displayData.initialTime)}
        </li>
        <RoleRestriction requiredRoles={[Role.ADMIN]}>
          <li>
            {t("table.cells.plannedTime")}: {TimestampTransforms.toFullDate(displayData.plannedTime)}
          </li>
        </RoleRestriction>
        <li>
          {t(isDeparture ? 
            computeReportedTimeTextForDeparture(row.original) : 
            computeReportedTimeTextForArrival(row.original))
          }: {TimestampTransforms.toFullDate(displayData.reportedTime)}
        </li>
      </ul>
    </div>
  );
}
