export interface AppSettings {
  routeSearchTimeLimitInSeconds: number;
  geocodingServiceAddress?: string;
  mapTilingServiceAddress?: string;
  version?: string;
  mapboxProximity?: string;
  mapboxBoundingBox?: string;
  mapboxAccessToken?: string;
  requestRegistrationFutureTimeLimitInMinutes: number;
  paymentsConfiguration: {
    chargeStrategy: ChargeStrategy;
  };
}

export interface AlgorithmConfiguration {
  evaluatorWeights: Record<string, number>;
  dynamicPromiseCalculatorConfig: Record<string, number>;
  marginsSpans: Record<string, number>;
  promiseSpans: Record<string, number>;
  promiseCalculator: string;
}

export enum PromiseCalculatorType {
  STATIC = "static",
  DYNAMIC = "dynamic",
}

export enum ChargeStrategy {
  Free = "Free",
  FlatFee = "FlatFee",
}
