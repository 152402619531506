export const forbiddenTransitions = {
  side: {
    title: "Zabronione przejazdy",
  },
  list: "Lista zabronionych przejazdów",
  add: "Dodaj zabroniony przejazd",
  previousValue: "Poprzednia wartość",
  table: {
    header: {
      sourceStopId: "Id przystanku startowego",
      sourceStopName: "Nazwa przystanku startowego",
      destinationStopId: "Id przystanku końcowego",
      destinationStopName: "Nazwa przystanku końcowego",
      actions: "Akcje",
    },
    actions: {
      duplicate: "Utwórz zabroniony przejazd w przeciwnym kierunku",
      delete: "Usuń zabroniony przejazd",
      deleteConfirm:
        "Czy na pewno chcesz usunąć zabroniony przejazd z {{sourceName}} do {{destinationName}}?",
    },
  },
  form: {
    add: "Dodaj zabroniony przejazd",
    submit: "Zapisz",
    sourceStop: "Przystanek startowy:",
    destinationStop: "Przystanek końcowy:",
    placeholderSourceStop: "Wybierz przystanek startowy:",
    placeholderDestinationStop: "Wybierz przystanek końcowy:",
  },
  validation: {
    sourceMustBeDifferentThenDestination:
      "Przystanek początkowy nie może być taki sam jak przystanek końcowy",
    destinationMustBeDifferentThenSource:
      "Przystanek końcowy nie może być taki sam jak przystanek początkowy",
    uniqueForbiddenTransition:
      "Zabroniony przejazd o tym samym przystanku początkowym i końcowym już istnieje",
  },
};
