import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import styles from "./Forms.module.css";
import { Button, Spinner } from "react-bootstrap";

const RegisterSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .max(50, "Your email cannot be more than 50 characters long.")
    .required("Please enter your email"),
  password: Yup.string()
    .min(4, "Your password must be at least 4 characters long.")
    .max(50, "Your password cannot be more than 50 characters long.")
    .required("Please enter your password"),
  confirmationPassword: Yup.string()
    .min(4, "Your password must be at least 4 characters long.")
    .max(50, "Your password cannot be more than 50 characters long.")
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Please confirm your password"),
  phone: Yup.string()
    .max(50, "Your phone number cannot be more than 15 characters long.")
    .required("Please enter your phone number"),
  name: Yup.string()
    .max(50, "Your name cannot be more than 15 characters long.")
    .required("Please enter your name"),
});

type RegisterShape = Yup.TypeOf<typeof RegisterSchema>;

interface Props {
  onSubmit: (loginData: RegisterShape) => Promise<void>;
}

const initialValues: RegisterShape = {
  email: "",
  password: "",
  confirmationPassword: "",
  phone: "",
  name: "",
};

export function RegisterForm({ onSubmit }: Props): React.ReactElement {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterSchema}
      onSubmit={async (values, formikBag) => {
        formikBag.setSubmitting(true);
        await onSubmit(values);
      }}
    >
      {({ handleSubmit, values, handleChange, handleBlur, errors, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <input
            data-cy="emailField"
            type="text"
            placeholder="Email"
            title="Email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className={classNames({ [styles.invalid]: !!errors.email && !!touched.email })}
          />
          {!!touched.email && <span>{errors.email}</span>}
          <input
            data-cy="passwordField"
            type="password"
            placeholder="Password"
            title="Password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className={classNames({ [styles.invalid]: !!errors.password && !!touched.password })}
          />
          {!!touched.password && <span>{errors.password}</span>}
          <input
            data-cy="confirmationPasswordField"
            type="password"
            placeholder="Confirm password"
            title="Confirm password"
            name="confirmationPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmationPassword}
            className={classNames({
              [styles.invalid]: !!errors.confirmationPassword && !!touched.confirmationPassword,
            })}
          />
          {!!touched.confirmationPassword && <span>{errors.confirmationPassword}</span>}
          <input
            data-cy="phoneField"
            type="text"
            placeholder="Phone"
            title="Phone"
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            className={classNames({
              [styles.invalid]: !!errors.phone && !!touched.phone,
            })}
          />
          {!!touched.phone && <span>{errors.phone}</span>}
          <input
            data-cy="nameField"
            type="text"
            placeholder="Name"
            title="Name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            className={classNames({
              [styles.invalid]: !!errors.name && !!touched.name,
            })}
          />
          {!!touched.name && <span>{errors.name}</span>}
          <Button variant="info" disabled={isSubmitting} type="submit" title="Register">
            {isSubmitting && (
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
            )}
            Register
          </Button>
        </form>
      )}
    </Formik>
  );
}
