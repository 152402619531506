import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import notifications from "./notifications";
import configuration from "./configuration";
import user from "./user";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "auth",
  storage,
};

export default combineReducers({
  notifications,
  configuration,
  user: persistReducer(persistConfig, user),
});
