import { useMemo, ReactElement } from "react";
import { useTable, usePagination, CellProps } from "react-table";
import { Button, Card, Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Fleet, Common } from "shared/types";
import { RoleRestriction, Table as InnerTable } from "../../common";
import { TFunction } from "i18next";
import { PaginatedTableState } from "shared/types/Common";
import { Role } from "shared/constants/roles";

function defaultSortFactory<T extends Fleet.Stop>(idGetter: (entity: T) => string) {
  return (a: T, b: T): number => ("" + idGetter(a)).localeCompare("" + idGetter(b));
}

const createSimpleColumns = (t: TFunction) => [
  { Header: t("table.header.id"), accessor: "stopId" },
  { Header: t("table.header.name"), accessor: "name" },
  { Header: t("table.header.city"), accessor: "city" },
  { Header: t("table.header.street"), accessor: "street" },
  { Header: t("table.header.identifier"), accessor: "identifier" },
];

interface Props {
  stops: Fleet.Stop[];
  onStopRemove: (id: string) => void;
  openStopDetails: (id: string) => void;
  openStopEdit: (id: string) => void;
  openStopAdd: () => void;
  isLoading: boolean;
}

export function Table({
  stops,
  onStopRemove,
  openStopEdit,
  openStopAdd,
  openStopDetails,
  isLoading,
}: Props): ReactElement {
  const { t } = useTranslation("stop");

  const sortedStops = useMemo(() => stops.sort(defaultSortFactory((x: Fleet.Stop) => x.name)), [stops]);
  const stopColumns = useMemo(
    () => [
      ...createSimpleColumns(t),
      {
        id: "type",
        Header: function Header() {
          return <div className="text-center">{t("table.header.type")}</div>;
        },
        Cell: function Cell({ row }: CellProps<Fleet.Stop>) {
          return <div className="text-center">{t(`type.${row.original.type}`)}</div>;
        },
      },
      {
        id: "location",
        Header: function Header() {
          return <div className="text-center">{t("table.header.location")}</div>;
        },
        Cell: function Cell({ row }: CellProps<Fleet.Stop>) {
          return (
            <div className="text-center">{`( lat: ${row.original.location.lat}, lng: ${row.original.location.lng} )`}</div>
          );
        },
      },
      {
        id: "selection",
        Header: function Header() {
          return <div className="text-center">{t("table.header.actions")}</div>;
        },
        Cell: function Cell({ row }: CellProps<Fleet.Stop>) {
          return (
            <div className="dropdown-actions text-center">
              <DropdownButton
                id="actionsDropdown"
                size="sm"
                title={<i className="fas fa-ellipsis-h" />}
                variant="outline-primary"
              >
                <Dropdown.Item
                  as={Button}
                  onClick={() => {
                    openStopDetails(row.original.stopId);
                  }}
                >
                  <i className="mr-1 fas fa-info-circle" /> {t("table.actions.details")}
                </Dropdown.Item>
                <RoleRestriction requiredRoles={[Role.ADMIN]}>
                  <>
                    <Dropdown.Item
                      as={Button}
                      onClick={() => {
                        openStopEdit(row.original.stopId);
                      }}
                    >
                      <i className="mr-1 fas fa-edit" /> {t("table.actions.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Button}
                      onClick={() => {
                        if (window.confirm(t("table.actions.deleteConfirm", { name: row.original.name }))) {
                          onStopRemove(row.original.stopId);
                        }
                      }}
                    >
                      <i className="mr-1 fas fa-trash" /> {t("table.actions.delete")}
                    </Dropdown.Item>
                  </>
                </RoleRestriction>
              </DropdownButton>
            </div>
          );
        },
      },
    ],
    [onStopRemove, openStopEdit, openStopDetails, t]
  );
  const table = useTable(
    {
      columns: stopColumns,
      data: sortedStops,
      initialState: { pageIndex: 0, pageSize: 10 } as PaginatedTableState<Fleet.Stop>,
    },
    usePagination
  ) as Common.TableWithPagination<Fleet.Stop>;
  return (
    <Card data-cy="viewStopListCard">
      <InnerTable
        {...table}
        isLoading={isLoading}
        topRowContent={
          <RoleRestriction requiredRoles={[Role.ADMIN]}>
            <Button size="sm" variant="success" onClick={openStopAdd}>
              <i className="fas fa-plus mr-1"></i> {t("add")}
            </Button>
          </RoleRestriction>
        }
      />
    </Card>
  );
}
