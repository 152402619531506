import { Action } from "redux";
import { isType } from "typescript-fsa";
import { getConfiguration } from "../../actions/actionCreators";

import { Settings } from "../../../types";
import { ChargeStrategy } from "shared/types/Settings";

interface ConfigurationState {
  isFetching: boolean;
  items: Settings.AppSettings;
  error?: string;
}

const initialState: ConfigurationState = {
  isFetching: false,
  items: {
    routeSearchTimeLimitInSeconds: 0,
    requestRegistrationFutureTimeLimitInMinutes: 1440,
    paymentsConfiguration: { chargeStrategy: ChargeStrategy.Free },
  },
};

export default function configuration(
  state: ConfigurationState = initialState,
  action: Action
): ConfigurationState {
  if (isType(action, getConfiguration.started)) {
    return { ...state, isFetching: true, error: undefined };
  }

  if (isType(action, getConfiguration.done)) {
    return {
      isFetching: false,
      error: undefined,
      items: action.payload.result.data,
    };
  }

  if (isType(action, getConfiguration.failed)) {
    return { ...state, isFetching: false, error: action.payload.error.error };
  }

  return state;
}
