import * as Yup from "yup";
import { TFunction } from "i18next";

export const stopSchemaFactory = (t: TFunction) =>
  Yup.object({
    name: Yup.string().trim().required(t("general:validation.required")),
    location: Yup.object({
      lat: Yup.number()
        .required(t("general:validation.required"))
        .min(49.0, t("validation.minLat", { minLat: 49.0 }))
        .max(55.0, t("validation.maxLat", { maxLat: 55.0 })),
      lng: Yup.number()
        .required(t("general:validation.required"))
        .min(14.0, t("validation.minLng", { minLng: 14.0 }))
        .max(24.0, t("validation.maxLng", { maxLng: 24.0 })),
    }),
  });
