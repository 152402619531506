import React from "react";
import { Table, Spinner } from "react-bootstrap";

import { Common } from "shared/types";
import styles from "./Table.module.css";

interface Props<TEntity extends Record<string, unknown>>
  extends Pick<
    Common.TableWithPagination<TEntity>,
    "getTableProps" | "headerGroups" | "getTableBodyProps" | "page" | "prepareRow"
  > {
  isLoading?: boolean;
  responsive?: boolean;
}

export function DataRow<TEntity extends Record<string, unknown>>({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  responsive = false,
  isLoading = false,
}: Props<TEntity>): React.ReactElement {
  return (
    <div className="position-relative my-2">
      {isLoading && (
        <div className={styles.loadingSpinner}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <Table striped responsive={responsive} className="border-bottom mb-0" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps({
                    style: column.width && column.width !== 150 ? { width: `${column.width}px` } : undefined,
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
