import React, { useCallback, useState, useEffect, useMemo } from "react";
import { addMilliseconds, differenceInMilliseconds } from "date-fns";
import { DateTransforms } from "shared/mappers";

const secondInMs = 1000;

interface Props {
  initialDate?: Date;
  showDate?: boolean;
}

export function Clock({ initialDate = new Date(), showDate = false }: Props): React.ReactElement {
  const [currentDate, setCurrentDate] = useState(initialDate);
  const offsetFromCurrentInMs = useMemo(
    () => differenceInMilliseconds(initialDate, new Date()),
    [initialDate]
  );
  const tick = useCallback(() => {
    setCurrentDate(addMilliseconds(new Date(), offsetFromCurrentInMs));
  }, [offsetFromCurrentInMs]);

  useEffect(() => {
    tick();

    const interval = setInterval(() => {
      tick();
    }, secondInMs);

    return () => clearInterval(interval);
  }, [tick]);

  if (showDate) {
    return <>{DateTransforms.toFullDateTimeFormatWithSeconds(currentDate)}</>;
  }
  return <>{DateTransforms.toFullTimeFormat(currentDate)}</>;
}
