import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import { FleetApiClient } from "shared/api";
import { AppUrls, Role } from "shared/constants";
import { TimestampTransforms } from "shared/mappers";
import { showNotification } from "shared/state/actions";
import { Fleet } from "shared/types";
import { RoleRestriction } from "components/common";

interface Props {
  onHide: () => void;
  rideId?: string;
}

export function DetailsModal({ onHide, rideId }: Props): React.ReactElement {
  const { t } = useTranslation("rides");
  const [details, setDetails] = useState<Fleet.RideOverviewItemDto | undefined>();
  const loadDetails = useCallback(async (rideId: string) => {
    setDetails(undefined);
    const result = await FleetApiClient.rides.getRideDetails(rideId);
    setDetails(result.data);
  }, []);
  useEffect(() => {
    if (rideId) {
      void loadDetails(rideId);
    }
  }, [loadDetails, rideId]);
  const dispatch = useDispatch();
  const printedEntities: { key: keyof Fleet.RideOverviewItemDto; title: string }[] = [
    { key: "mission", title: t("details.mission") },
    { key: "departureSegment", title: t("details.departureSegment") },
    { key: "arrivalSegment", title: t("details.arrivalSegment") },
    { key: "passenger", title: t("details.passenger") },
    { key: "rideRequest", title: t("details.rideRequest") },
  ];

  return (
    <Modal size="lg" show={!!rideId} onHide={onHide}>
      {details && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{t("details.header")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="general"
              transition={false}
              id="noanim-tab-example"
              className="mb-2 border-bottom"
            >
              <Tab eventKey="general" title={t("details.general")}>
                <Form>
                  <Form.Group as={Row} controlId="formPlaintextEmail" className="mb-0">
                    <Col sm="3">
                      <Row>
                        <Form.Label column sm="4">
                          {t("table.header.status")}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            disabled
                            plaintext
                            defaultValue={t(`status.${details.rideStatus.code}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Form.Label column sm="6">
                          {t("details.departureStatus")}
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            disabled
                            plaintext
                            defaultValue={t(`departureStatus.${details.departureStatus.code}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="5">
                      <Row>
                        <Form.Label column sm="6">
                          {t("details.arrivalStatus")}
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            disabled
                            plaintext
                            defaultValue={t(`arrivalStatus.${details.arrivalStatus.code}`)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextCreated" className="mt-0">
                    <Form.Label column sm="2">
                      {t("table.header.created")}
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        disabled
                        plaintext
                        defaultValue={TimestampTransforms.toFullDate(details.createdAt)}
                      />
                    </Col>
                  </Form.Group>
                </Form>
                <h5 className="mb-3">{t("details.relations")}</h5>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th style={{ width: "150px" }}>{t("details.entity")}</th>
                      <th>{t("details.id")}</th>
                      <th className="text-center" style={{ width: "75px" }}>
                        {t("details.copy")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      { key: "Ride", value: details.id },
                      { key: "Mission", value: details.mission.missionId },
                      { key: "Departure Segment", value: details.departureSegment.segmentId },
                      { key: "Arrival Segment", value: details.arrivalSegment.segmentId },
                      { key: "Passenger", value: details.passenger.subjectId },
                    ].map((item) => (
                      <tr key={item.key}>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                        <td className="text-center font-larger">
                          <CopyToClipboard
                            text={item.value}
                            onCopy={() =>
                              dispatch(
                                showNotification({
                                  message: `${item.key} ID copied to clipboard.`,
                                  color: "success",
                                })
                              )
                            }
                          >
                            <i role="button" className="cursor-pointer far fa-clipboard" />
                          </CopyToClipboard>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              {printedEntities.map((item) => (
                <Tab key={item.key} eventKey={item.key} title={item.title}>
                  <ReactJson
                    src={details[item.key] as unknown as Record<string, unknown>}
                    theme="monokai"
                    collapsed={2}
                  />
                </Tab>
              ))}
            </Tabs>
          </Modal.Body>
          <RoleRestriction requiredRoles={[Role.ADMIN]}>
            <Modal.Footer>
              <Link to={AppUrls.MissionDetails(details.mission.missionId)}>
                <Button variant="primary">
                  {t("details.goToMission")} <i className="ml-1 fas fa-angle-double-right" />
                </Button>
              </Link>
            </Modal.Footer>
          </RoleRestriction>
        </>
      )}
    </Modal>
  );
}
