import { useContext } from "react";
import { AuthService, AuthContext } from "../authService";

export function useAuthService(): typeof AuthService {
  const ctx = useContext(AuthContext);

  if (ctx === null) {
    throw new Error("You forgot the provider");
  }

  return ctx;
}
