import * as Yup from "yup";
import { TFunction } from "i18next";
import { DateTransforms } from "shared/mappers";
import { areIntervalsOverlapping } from "date-fns";
import { WeekDays } from "shared/types/Common";

interface PatternSlot {
  duration: Date;
  timeStart: Date;
}

interface FormPattern {
  slots: PatternSlot[];
  weekDays: WeekDays[];
}

export interface FormValues {
  excludedDays: Date[];
  validFrom: Date;
  validTo: Date;
  pattern: FormPattern[];
  name: string;
}

const getIntervalFromSlot = ({ timeStart, duration }: PatternSlot): Interval => ({
  start: timeStart,
  end: DateTransforms.addTimePortion(timeStart, duration),
});

export const vehicleAvailabilitySchemaFactory = (t: TFunction) =>
  Yup.object({
    name: Yup.string().default("").required(t("form.validation.validNameRequired")),
    excludedDays: Yup.array()
      .of(Yup.date())
      .test("onlyUnique", t("form.validation.excludedDaysUnique"), (value) => {
        if (Array.isArray(value)) {
          return new Set(value.map((d) => d?.toString())).size === value.length;
        }
        return false;
      }),
    validFrom: Yup.date()
      .default(null)
      .typeError(t("form.validation.validDatesRequired"))
      .required(t("form.validation.validDatesRequired"))
      .when("validTo", (validTo: Date | null, schema: Yup.DateSchema) =>
        validTo instanceof Date && !isNaN(validTo.getTime())
          ? schema.max(validTo, t("form.validation.validFromBeforeValidTo"))
          : schema
      ),
    validTo: Yup.date()
      .default(null)
      .typeError(t("form.validation.validDatesRequired"))
      .required(t("form.validation.validDatesRequired")),
    pattern: Yup.array().of(
      Yup.object({
        slots: Yup.array()
          .of(
            Yup.object({
              duration: Yup.date(),
              timeStart: Yup.date(),
            })
          )
          .min(1, t("form.validation.slotsMinimum"))
          .test("nonOverlapping", t("form.validation.slotsNonOverlapping"), (value) => {
            if (!Array.isArray(value)) {
              return false;
            }
            for (let i = 0; i < value.length; i++) {
              const currentSlotInterval = getIntervalFromSlot(value[i] as PatternSlot);
              const comparableArray = [...(value as PatternSlot[])];
              comparableArray.splice(i, 1);
              if (
                comparableArray.some((slot) =>
                  areIntervalsOverlapping(getIntervalFromSlot(slot), currentSlotInterval, { inclusive: true })
                )
              ) {
                return false;
              }
            }
            return true;
          }),
        weekDays: Yup.array().of(Yup.number()).min(1, t("form.validation.weekDaysMinimum")),
      })
    ),
  });
