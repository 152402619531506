export enum MapColors {
  INACTIVE = "#535353",
  ACTIVE = "#3388ff",
  CANCELLED = "#ff0000",
}

export enum MapOpacity {
  INACTIVE = 0.4,
  ACTIVE = 1,
}
