import { Card } from "react-bootstrap";
import { ReactNode } from "react-markdown";

interface Props {
  children: ReactNode;
  title: string;
}

export function ChartCard({ children, title }: Props) {
  return (
    <Card className="px-4">
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div style={{ height: "auto" }}>{children}</div>
      </Card.Body>
    </Card>
  );
}
