/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import "leaflet/dist/leaflet.css";

import L from "leaflet";

// Leaflet fixes
L.Map.prototype._getMapPanePos = function () {
  if (this && this._mapPane) {
    return L.DomUtil.getPosition(this._mapPane);
  }
  return new L.Point(0, 0);
};
// eslint-disable-next-line @typescript-eslint/unbound-method
L.Evented.prototype.fire = function fire(type, data, propagate) {
  if (!this.listens(type, propagate)) {
    return this;
  }
  try {
    const event = L.Util.extend({}, data, {
      type: type,
      target: this,
      sourceTarget: (data && data.sourceTarget) || this,
    });

    if (this._events) {
      const listeners = this._events[type];

      if (listeners) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        this._firingCount = this._firingCount + 1 || 1;
        for (let i = 0, len = listeners.length; i < len; i++) {
          const l = listeners[i];
          if (l.ctx || this) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            l.fn.call(l.ctx || this, event);
          }
        }

        this._firingCount--;
      }
    }

    if (propagate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this._propagateEvent(event);
    }

    return this;
  } catch (error) {
    console.warn(`One of Leaflet components failed to properly handle event of type "${type}"`);
  }
};

// This code is needed to properly load the images in the Leaflet CSS
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
