import { ReactElement, useCallback, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { TimestampTransforms } from "shared/mappers";
import { Common, Fleet } from "shared/types";
import { ControlledTable } from "components/common/Table/ControlledTable";
import { DetailsModal } from "./DetailsModal";
import { useTranslation } from "react-i18next";
import { UseTranslationResult } from "shared/types/Common";
import { Button } from "react-bootstrap";
import { TableCells } from "./TableCells";
import { ChargeStrategy } from "shared/types/Settings";
import { getPaymentsSettings } from "shared/state/selectors/global";
import { store } from "shared/state";

interface Props {
  dataState: Common.Paginated<Fleet.RideOverviewListItemDto>;
  isFetching: boolean;
  loadRides: (params: Common.PaginationParams) => Promise<void>;
  showCreateModal: () => void;
  handleCancel: (rideId: string) => void;
}

export function Table({
  isFetching,
  loadRides,
  dataState,
  showCreateModal,
  handleCancel,
}: Props): ReactElement {
  const { t } = useTranslation("rides") as UseTranslationResult;
  const [detailsId, setDetailsId] = useState<string>();
  const showDetails = useCallback(
    (id: string) => {
      setDetailsId(id);
    },
    [setDetailsId]
  );
  const hideDetails = useCallback(() => {
    setDetailsId(undefined);
  }, [setDetailsId]);

  const ridesColumns = useMemo<Column<Fleet.RideOverviewListItemDto>[]>(
    () => [
      {
        id: "source",
        Header: t("table.header.source"),
        Cell: TableCells.SourceIcon,
        sortAccessor: "source",
        width: 85,
      },
      {
        id: "createdAt",
        Header: t("table.header.created"),
        accessor: (original) => TimestampTransforms.toFullDate(original.createdAt),
        sortAccessor: "createdAt",
        width: 135,
      },
      {
        id: "status",
        Header: t("table.header.status"),
        accessor: (original) => t(`status.${original.status}`),
        sortAccessor: "status",
        width: 120,
      },
      {
        id: "departure",
        Header: t("table.header.departure"),
        Cell: function Cell(cellProps: CellProps<Fleet.RideOverviewListItemDto>) {
          return <TableCells.TimeDestination {...cellProps} isDeparture />;
        },
        sortAccessor: "initialPlannedDeparture",
      },
      {
        id: "arrival",
        Header: t("table.header.arrival"),
        Cell: function Cell(cellProps: CellProps<Fleet.RideOverviewListItemDto>) {
          return <TableCells.TimeDestination {...cellProps} />;
        },
        sortAccessor: "initialPlannedArrival",
      },
      {
        id: "vehicleName",
        Header: t("table.header.vehicleName"),
        accessor: (original) => (original.vehicleName ? original.vehicleName : "-"),
        sortAccessor: "vehicleName",
      },
      {
        id: "username",
        Header: t("table.header.username"),
        Cell: TableCells.Username,
        sortAccessor: "passengerName",
      },
      {
        id: "numberOfPassengers",
        Header: function Header() {
          return <i className="fas fa-users" title={t("table.header.numberOfPassengers")} />;
        },
        Cell: function Cell({ row }: CellProps<Fleet.RideOverviewListItemDto>) {
          return <div className="text-center">{row.original.numberOfPassengers}</div>;
        },
        sortAccessor: "numberOfPassengers",
        width: 60,
      },
      {
        id: "actions",
        Header: t("table.header.actions"),
        Cell: function Cell(cellProps: CellProps<Fleet.RideOverviewListItemDto>) {
          return <TableCells.Actions {...cellProps} handleCancel={handleCancel} showDetails={showDetails} />;
        },
        width: 65,
      },
    ],
    [handleCancel, showDetails, t]
  );
  const refreshRateInMilliseconds = 60000;

  const paymentsSettings = getPaymentsSettings(store.getState());
  const isFreeChargeStrategy = paymentsSettings.chargeStrategy === ChargeStrategy.Free;

  return (
    <>
      <DetailsModal rideId={detailsId} onHide={hideDetails} />
      <ControlledTable
        {...dataState}
        additionalButtons={
          <Button
            data-cy="addNewRide"
            size="sm"
            className="mr-1"
            variant="success"
            onClick={showCreateModal}
            disabled={!isFreeChargeStrategy}
          >
            {t("form.create")} <i className="fas fa-plus ml-1" />
          </Button>
        }
        columns={ridesColumns}
        fetchData={loadRides}
        isFetching={isFetching}
        autoRefreshInterval={refreshRateInMilliseconds}
        responsive
      />
    </>
  );
}
