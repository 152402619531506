const plannerState = {
  entities: {
    vehicles: {
      "1": {
        id: "1",
        name: "Bus 1",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "2": {
        id: "2",
        name: "Bus 2",
        distanceInMeters: 4500,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2890349, 18.6553121],
          [50.2890679, 18.6553762],
          [50.2893556, 18.655936],
          [50.2898779, 18.6569426],
          [50.2899209, 18.6570199],
          [50.2899714, 18.6571108],
          [50.2904918, 18.6579828],
          [50.2907114, 18.6583387],
          [50.2907114, 18.6583387],
          [50.290742, 18.6584758],
          [50.2907492, 18.6585687],
          [50.2907487, 18.6586136],
          [50.2907478, 18.6586743],
          [50.2907382, 18.658758],
          [50.29072, 18.6588459],
          [50.2906912, 18.6589498],
          [50.2906912, 18.6589498],
          [50.29013, 18.6597726],
          [50.2895244, 18.6606898],
          [50.2894336, 18.6608508],
          [50.2893331, 18.6610475],
          [50.2893331, 18.6610475],
          [50.2894396, 18.6611922],
          [50.2897819, 18.6617134],
          [50.2900163, 18.6620751],
          [50.2900827, 18.6621757],
          [50.2906103, 18.662975],
          [50.2906884, 18.6630933],
          [50.2906884, 18.6630933],
          [50.2907483, 18.6631831],
          [50.2913526, 18.6640887],
          [50.2916966, 18.6646451],
          [50.2917544, 18.6647387],
          [50.2918421, 18.6648805],
          [50.2918421, 18.6648805],
          [50.2920867, 18.6651615],
          [50.2921742, 18.6652405],
          [50.2922132, 18.665264],
          [50.2923228, 18.6653299],
          [50.2923228, 18.6653299],
          [50.2923523, 18.6650926],
          [50.2923805, 18.6648481],
          [50.2924341, 18.6643948],
          [50.2924528, 18.6642368],
          [50.2924845, 18.6641221],
          [50.2925675, 18.6638213],
          [50.2927076, 18.663463],
          [50.2927536, 18.6633509],
          [50.2928435, 18.6631971],
          [50.2928586, 18.6631751],
          [50.2929203, 18.6630855],
          [50.2929776, 18.6630139],
          [50.2930476, 18.6629344],
          [50.2930603, 18.66292],
          [50.2932923, 18.6626962],
          [50.2934111, 18.6626001],
          [50.2934111, 18.6626001],
          [50.2934867, 18.662558],
          [50.2936658, 18.6624635],
          [50.2940259, 18.66227],
          [50.294062, 18.6622506],
          [50.2942938, 18.6621241],
          [50.294334, 18.6621022],
          [50.2943801, 18.6620771],
          [50.2944128, 18.6620592],
          [50.2945328, 18.6620106],
          [50.2947087, 18.6621118],
          [50.2948872, 18.6622508],
          [50.294967, 18.6623104],
          [50.2951331, 18.6624345],
          [50.2953802, 18.6626191],
          [50.2954426, 18.66267],
          [50.2958076, 18.6629919],
          [50.2960404, 18.6635293],
          [50.2960576, 18.6635657],
          [50.2961215, 18.6637183],
          [50.2961215, 18.6637183],
          [50.296176, 18.6639072],
          [50.2962096, 18.6640407],
          [50.2962785, 18.664331],
          [50.2963022, 18.664431],
          [50.2963475, 18.6646197],
          [50.296358, 18.6647672],
          [50.2972555, 18.6661488],
          [50.2972069, 18.6660739],
          [50.2971346, 18.6659626],
          [50.2971196, 18.6659395],
          [50.2969303, 18.6656482],
          [50.2968974, 18.6655975],
          [50.2968138, 18.6654689],
          [50.2964368, 18.6648886],
          [50.296358, 18.6647672],
          [50.2973147, 18.6662399],
          [50.2973275, 18.6662597],
          [50.2974071, 18.6663822],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T06:37",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "5",
            timestamp: "2019-08-02T07:20",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "8",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["4", "3"],
          },
        ],
      },
      "3": {
        id: "3",
        name: "Bus 3",
        distanceInMeters: 1000,
        route: [
          [50.298407, 18.654476],
          [50.2983229, 18.6546588],
          [50.2981532, 18.6550151],
          [50.29813, 18.655061],
          [50.2980092, 18.6552883],
          [50.2980092, 18.6552883],
          [50.297763, 18.6557874],
          [50.2975761, 18.6561362],
          [50.2973484, 18.6565467],
          [50.2972933, 18.6566461],
          [50.2971434, 18.6569164],
          [50.2969576, 18.6572637],
          [50.2966365, 18.6577772],
          [50.2965644, 18.6578902],
          [50.2965344, 18.657996],
          [50.2965344, 18.6580765],
          [50.2965725, 18.6583338],
          [50.2965725, 18.6583338],
          [50.2966261, 18.6584152],
          [50.2967091, 18.6584855],
          [50.2967628, 18.6585675],
          [50.2969534, 18.6588587],
          [50.2973021, 18.6593817],
          [50.2971762, 18.6591949],
          [50.2969534, 18.6588587],
          [50.2974539, 18.6596213],
          [50.2973728, 18.6594945],
          [50.2973021, 18.6593817],
          [50.2990931, 18.66221],
          [50.299016, 18.6620891],
          [50.2989519, 18.6619886],
          [50.2988707, 18.6618614],
          [50.2987015, 18.6615963],
          [50.2985804, 18.6614065],
          [50.2981065, 18.6606641],
          [50.2980632, 18.6605924],
          [50.2979779, 18.6604514],
          [50.2976494, 18.6599271],
          [50.297482, 18.6596653],
          [50.2974603, 18.6596313],
          [50.2974539, 18.6596213],
          [50.2990931, 18.66221],
          [50.2979438, 18.6640776],
          [50.2979144, 18.6641292],
          [50.2979144, 18.6641292],
          [50.2978275, 18.664284],
          [50.2978275, 18.664284],
          [50.2977605, 18.6644936],
          [50.2977203, 18.6647506],
          [50.2977203, 18.6647506],
          [50.2977804, 18.6655749],
          [50.2977702, 18.6657343],
          [50.297736, 18.6658671],
          [50.2974071, 18.6663822],
          [50.2974525, 18.6663112],
          [50.2974612, 18.6662975],
          [50.297736, 18.6658671],
        ],
        plan: [
          {
            stopId: "3",
            timestamp: "2019-08-02T08:15",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2", "3", "4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "6",
            timestamp: "2019-08-02T08:20",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["2", "4"],
          },
          {
            stopId: "8",
            timestamp: "2019-08-02T08:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "3"],
          },
        ],
      },
      "4": {
        id: "4",
        name: "Bus 1",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "5": {
        id: "5",
        name: "Bus 1",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "6": {
        id: "6",
        name: "Bus 1",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "7": {
        id: "7",
        name: "Bus 1",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "8": {
        id: "8",
        name: "Bus 8",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "9": {
        id: "9",
        name: "Bus 9",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
      "10": {
        id: "10",
        name: "Bus 10",
        distanceInMeters: Math.random() * 10000,
        route: [
          [50.2887559, 18.6564284],
          [50.2887692, 18.6562071],
          [50.2887984, 18.6557441],
          [50.2888226, 18.655549],
          [50.2888665, 18.6553969],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888966, 18.6552968],
          [50.2888701, 18.6553844],
          [50.2889399, 18.6551733],
          [50.2888477, 18.6551193],
          [50.2887952, 18.6550977],
          [50.2887415, 18.6550786],
          [50.288632, 18.6550557],
          [50.2885607, 18.6550547],
          [50.2885034, 18.655058],
          [50.2884664, 18.6550675],
          [50.2884168, 18.6550882],
          [50.2883737, 18.6551139],
          [50.2882751, 18.6552011],
          [50.2882099, 18.6552746],
          [50.2880846, 18.6554204],
          [50.2877163, 18.6554508],
          [50.2875956, 18.6554136],
          [50.2873087, 18.6553252],
          [50.2873087, 18.6553252],
          [50.2870554, 18.6552512],
          [50.2867337, 18.6551685],
          [50.2864057, 18.6550781],
          [50.2864057, 18.6550781],
          [50.2862315, 18.6550199],
          [50.2860927, 18.6549523],
          [50.2859635, 18.6548556],
          [50.2858634, 18.6547501],
          [50.285792, 18.6546486],
          [50.2856693, 18.6544115],
          [50.2855754, 18.654163],
          [50.2854564, 18.6538395],
          [50.2854013, 18.6537047],
          [50.2853882, 18.6536787],
          [50.2853761, 18.6536547],
          [50.2853131, 18.6535399],
          [50.2852409, 18.6534361],
          [50.285158, 18.6533353],
          [50.2850721, 18.6532569],
          [50.2849831, 18.6531913],
          [50.2848869, 18.6531417],
          [50.2848162, 18.6531111],
          [50.2847416, 18.6530958],
          [50.2846555, 18.6530842],
          [50.2845239, 18.6530909],
          [50.2843922, 18.6531298],
          [50.284313, 18.6531774],
          [50.28414, 18.6532881],
          [50.2838105, 18.6537952],
          [50.2836966, 18.6541862],
          [50.2836168, 18.654668],
          [50.2836168, 18.654668],
          [50.2835706, 18.6546466],
          [50.2834396, 18.6545805],
          [50.2834328, 18.6545771],
          [50.2824225, 18.6540563],
          [50.282402, 18.6540457],
          [50.2821121, 18.6538983],
          [50.2820074, 18.6538502],
          [50.2819025, 18.6538094],
          [50.2815087, 18.6536555],
          [50.2811125, 18.6535046],
          [50.2809922, 18.653451],
          [50.2809328, 18.6534219],
          [50.280874, 18.653384],
          [50.2808397, 18.6533559],
          [50.2807344, 18.6532662],
          [50.2799947, 18.6526364],
          [50.2799756, 18.6526202],
          [50.2799559, 18.6526034],
          [50.2792145, 18.6519759],
          [50.2791207, 18.6518964],
          [50.278928, 18.6517355],
          [50.2788027, 18.6516338],
          [50.2787465, 18.6515929],
          [50.2786872, 18.6515572],
          [50.2786216, 18.6515257],
          [50.2785534, 18.6515025],
          [50.2784904, 18.651487],
          [50.2784271, 18.6514795],
          [50.2782204, 18.6514816],
          [50.2781388, 18.6514818],
          [50.2780829, 18.651484],
          [50.2780187, 18.6514761],
          [50.2779157, 18.6515089],
          [50.2778148, 18.6516689],
          [50.2774108, 18.6521049],
          [50.2772347, 18.6522961],
          [50.2771993, 18.6523388],
          [50.2766457, 18.6529142],
          [50.2766457, 18.6529142],
          [50.2765743, 18.6529188],
          [50.2763029, 18.6529363],
          [50.276239, 18.6529114],
          [50.2760149, 18.6528241],
          [50.2758755, 18.6527698],
          [50.2756666, 18.6527601],
          [50.2754727, 18.6527828],
          [50.2754579, 18.6527845],
          [50.2754579, 18.6527845],
          [50.2752545, 18.6528335],
          [50.2745856, 18.6529714],
          [50.2744037, 18.653009],
          [50.2740518, 18.6530815],
          [50.2736366, 18.6531637],
          [50.273501, 18.6531922],
          [50.273501, 18.6531922],
          [50.273552, 18.6536952],
          [50.2736189, 18.6543552],
          [50.2736449, 18.6546119],
          [50.2736449, 18.6546119],
          [50.2715407, 18.6545307],
        ],
        plan: [
          {
            stopId: "1",
            timestamp: "2019-08-02T07:30",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["1", "2"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "2",
            timestamp: "2019-08-02T07:50",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["4"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "4",
            timestamp: "2019-08-02T07:45",
            distance: Math.random() * 2000,
            pickupPassengerIds: ["3"],
            dropoffPassengerIds: [],
          },
          {
            stopId: "7",
            timestamp: "2019-08-02T08:00",
            distance: Math.random() * 2000,
            pickupPassengerIds: [],
            dropoffPassengerIds: ["1", "2", "3", "4"],
          },
        ],
      },
    },
    stops: {
      "1": {
        id: "1",
        name: "Parking 1",
        latLng: [50.2887559, 18.6564284],
        startingVehicleIds: ["1", "2"],
        finishingVehicleIds: [],
      },
      "2": {
        id: "2",
        name: "ZSO Nr 5",
        latLng: [50.2799947, 18.6526364],
        startingVehicleIds: [],
        finishingVehicleIds: [],
      },
      "3": {
        id: "3",
        name: "Gimnazjum nr 9",
        latLng: [50.298407, 18.654476],
        startingVehicleIds: ["3"],
        finishingVehicleIds: [],
      },
      "4": {
        id: "4",
        name: "Pliszki 43",
        latLng: [50.2745856, 18.6529714],
        startingVehicleIds: [],
        finishingVehicleIds: [],
      },
      "5": {
        id: "5",
        name: "Górnych Wałów 27",
        latLng: [50.2930476, 18.6629344],
        startingVehicleIds: [],
        finishingVehicleIds: [],
      },
      "6": {
        id: "6",
        name: "Jasnogórska 9",
        latLng: [50.2976494, 18.6599271],
        startingVehicleIds: [],
        finishingVehicleIds: [],
      },
      "7": {
        id: "7",
        name: "Bielika 25",
        latLng: [50.2715407, 18.6545307],
        startingVehicleIds: [],
        finishingVehicleIds: ["1"],
      },
      "8": {
        id: "8",
        name: "Plac Piłsudskiego",
        latLng: [50.2974071, 18.6663822],
        startingVehicleIds: [],
        finishingVehicleIds: ["2", "3"],
      },
    },
    passengers: {
      "1": { id: "1", name: "Karol" },
      "2": { id: "2", name: "Przemek" },
      "3": { id: "3", name: "Ola" },
      "4": { id: "4", name: "Kasia" },
    },
  },
  fleetPlan: {
    vehicleIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    stopIds: ["1", "2", "3", "4", "5", "6", "7", "8"],
    totalDistanceInMeters: Math.floor(Math.random() * 100000),
    vehicleSolutionUrl: "https://google.com/",
    passengerSolutionUrl: "https://google.com/",
    totalDurationInMinutes: Math.floor(Math.random() * 100),
    drivingDurationInMinutes: Math.floor(Math.random() * 100),
  },
  isFetching: false,
  filters: {},
  isFetchingOptions: false,
};

export default plannerState;
