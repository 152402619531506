import { useMemo } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import classNames from "classnames";

import { Fleet } from "shared/types";
import { locationToLatLng, TimestampTransforms } from "shared/mappers";
import styles from "./Markers.module.css";

const calculateArrowBearing = (bearing: number) => {
  const north = -45;
  return north + bearing;
};

interface Props extends Fleet.VehiclePosition {
  tooltip?: string | JSX.Element;
}

export function VehicleMarker(props: Props) {
  const markerHtml = useMemo(
    () =>
      renderToStaticMarkup(
        <i
          className={classNames("fas fa-location-arrow", styles.vehicleMarker)}
          style={{ transform: `rotate(${calculateArrowBearing(props.bearingDegrees)}deg)` }}
        />
      ),
    [props.bearingDegrees]
  );
  return (
    <Marker
      icon={divIcon({
        html: markerHtml,
        iconSize: [21.25, 23],
        iconAnchor: [10.6, 11.5],
        tooltipAnchor: [0, -5],
      })}
      position={locationToLatLng(props.location)}
    >
      <Tooltip direction="top">
        {props.tooltip ? props.tooltip : `Last Update: ${TimestampTransforms.toFullDate(props.timestamp)}`}
      </Tooltip>
    </Marker>
  );
}
