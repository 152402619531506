import { useState, useCallback } from "react";
import { useMountedRef } from "./useMountedRef";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyAsyncCallback = (...params: any[]) => Promise<void>;
export function useAsyncCall<Func extends AnyAsyncCallback>(asyncFunc: Func): [Func, boolean] {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const componentIsMounted = useMountedRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const funcWrap = useCallback(asyncFunc, []);

  const wrappedFunction = useCallback(
    async (...params) => {
      try {
        setIsFetching(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        await funcWrap(...params);
      } catch (err) {
        console.warn(err);
      } finally {
        if (componentIsMounted.current) {
          setIsFetching(false);
        }
      }
    },
    [componentIsMounted, funcWrap]
  );
  return [wrappedFunction as Func, isFetching];
}
