export const configuration = {
  header: "Konfiguracja",
  algorithm: {
    header: "Algorytm",
    saveConfirmation: "Czy na pewno chcesz zmodyfikować ustawienia algorytmu?",
    resetFormLabel: "Resetuj formularz",
    resetLabel: "Przywróć ustawienia domyślne",
    resetConfirmation:
      "Czy na pewno chcesz przywrócić domyślne ustawienia? Wszystkie dokonane zmiany zostaną utracone!",
    form: {
      evaluators: "Parametry funkcji kosztu",
      distance: "Dystans",
      "passengers-cost": "Koszt pasażera",
      "soft-time-windows": "Miękkie okna czasowe",
      duration: "Czas podróży",
      "vehicles-utilisation-cost": "Koszt wykorzystania pojazdów",
      marginsSpans: "Wartości marginesów dla nowych zapytań",
      arrivalLeftMarginInMinutes: "Przyjazdy - Lewy margines (w minutach)",
      arrivalRightMarginInMinutes: "Przyjazdy - Prawy margines (w minutach)",
      departureRightMarginInMinutes: "Wyjazdy - Prawy margines (w minutach)",
      departureLeftMarginInMinutes: "Wyjazdy - Lewy margines (w minutach)",
      promiseSpans: "Wartości marginesów dla obietnic",
      arrivalLeftPromiseInMinutes: "Przyjazdy - Lewy margines (w minutach)",
      arrivalRightPromiseInMinutes: "Przyjazdy - Prawy margines (w minutach)",
      departureRightPromiseInMinutes: "Wyjazdy - Prawy margines (w minutach)",
      departureLeftPromiseInMinutes: "Wyjazdy - Lewy margines (w minutach)",
      dynamicPromiseCalculatorConfig: "Parametry dla dynamicznego wyliczania marginesów",
      constantShift: "Stałe przesunięcie",
      denominator: "Mianownik",
      minArrivalPromiseSpan: "Min. wartość marginesu (w minutach)",
      maxArrivalPromiseSpan: "Max. wartość marginesu (w minutach)",
      promiseCalculator: "Rodzaj kalkulatora marginesów",
      staticPromiseCalculator: "Statyczny",
      dynamicPromiseCalculator: "Dynamiczny",
      validation: {
        onlyNumericValues: "Można wprowadzić tylko wartości liczbowe",
        sumNotMuchGreaterThan1: "Suma wag nie może znacznie przekraczać 1",
        cannotBeOutOfRange: "Wartości muszą mieścić się w przedziale <{{min}} - {{max}}>",
      },
    },
  },
};
