import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { Fleet } from "shared/types";
import { useTranslation } from "react-i18next";
import { DefaultDateTimePicker } from "components/common/Input/DefaultDateTimePicker";
import { StopSearchInput } from "./StopSearchInput";
import { Map, MapLoadingOverlay } from "components/common";
import { FormValues } from "./schema";
import { FleetApiClient } from "shared/api";
import { LatLngBounds, LatLngBoundsExpression } from "leaflet";
import classNames from "classnames";
import styles from "../../Rides.module.css";
import { MapMarkers } from "./MapMarkers";
import { addMinutes } from "date-fns";
import { globalSelectors } from "shared/state/selectors";
import { StoreShape } from "shared/state";
import { connect } from "react-redux";

enum TimeTypeSelectorFieldIds {
  DEPARTURE = "timeTypeSelector-departure",
  ARRIVAL = "timeTypeSelector-arrival",
}

interface StateProps {
  requestRegistrationFutureTimeLimitInMinutes: ReturnType<
    typeof globalSelectors.getRequestRegistrationFutureTimeLimitInMinutes
  >;
}

type Props = StateProps & FormikProps<FormValues>;

function CreateFormView({
  handleChange,
  setFieldValue,
  setValues,
  values,
  handleSubmit,
  touched,
  errors,
  isSubmitting,
  requestRegistrationFutureTimeLimitInMinutes,
}: Props) {
  const now = new Date();
  const [allStops, setAllStops] = useState<Fleet.Stop[]>([]);
  const [departureStops, setDepartureStops] = useState<Fleet.Stop[]>([]);
  const [destinationStops, setDestinationStops] = useState<Fleet.Stop[]>([]);
  const [isFetchingDestinationStops, setIsFetchingDestinationStops] = useState(false);
  const { t } = useTranslation("rides");
  const handleTimeTypeSelectorChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("usingDepartureTime", ev.target.id === TimeTypeSelectorFieldIds.DEPARTURE);
  };
  const refreshAllStops = async () => {
    const stops = (await FleetApiClient.stop.getStops()).data;
    const departureStops = (await FleetApiClient.stop.stopsSearch({})).data;
    setAllStops(stops);
    setDepartureStops(departureStops);
  };
  const refreshDestinationStops = useCallback(async () => {
    try {
      setIsFetchingDestinationStops(true);
      const stops = (await FleetApiClient.stop.stopsSearch({ startStop: values.departureStopId })).data;
      setDestinationStops(stops);
    } finally {
      setIsFetchingDestinationStops(false);
    }
  }, [values.departureStopId]);
  const getStopNameForInputValue = (stopId?: string): string => {
    let name = "";
    if (stopId) {
      const stop = allStops.find((s) => s.stopId === stopId);
      if (stop) {
        name = `${stop.name}${stop.identifier ? ` ${stop.identifier}` : ""}`;
      }
    }
    return name;
  };
  useEffect(() => {
    void refreshAllStops();
  }, []);
  useEffect(() => {
    void refreshDestinationStops();
  }, [refreshDestinationStops]);
  const handleDepartureStopChange = useCallback(
    (newStopId?: string) => {
      setValues({
        ...values,
        departureStopId: newStopId,
        arrivalStopId: undefined,
      });
    },
    [setValues, values]
  );
  const handleArrivalStopChange = useCallback(
    (newStopId?: string) => {
      setFieldValue("arrivalStopId", newStopId);
    },
    [setFieldValue]
  );
  const mapBounds = useMemo<LatLngBoundsExpression | undefined>(() => {
    if (allStops.length < 2) {
      return undefined;
    }
    const [firstPoint, secondPoint, ...rest] = allStops;
    const bounds = new LatLngBounds(firstPoint.location, secondPoint.location);
    if (allStops.length > 2) {
      for (const stop of rest) {
        bounds.extend(stop.location);
      }
    }
    return bounds;
  }, [allStops]);
  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title data-cy="rideModalTitle">{t("form.header")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 data-cy="ridePassengerLabel">{t("form.passenger")}</h5>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label data-cy="ridePassengerNameLabel">{t("form.name")}</Form.Label>
            <Form.Control
              data-cy="ridePassengerName"
              type="string"
              name="name"
              onChange={handleChange}
              isInvalid={touched.name && !!errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label data-cy="ridePhoneLabel">{t("form.phone")}</Form.Label>
            <Form.Control
              data-cy="ridePhone"
              type="string"
              name="phone"
              onChange={handleChange}
              isInvalid={touched.phone && !!errors.phone}
            />
            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPassengerCount">
            <Form.Label data-cy="ridePassengerCountLabel">{t("form.passengerCount")}</Form.Label>
            <Form.Control
              data-cy="ridePassengerCount"
              type="number"
              name="passengerCount"
              min={1}
              value={values.passengerCount}
              onChange={handleChange}
              isInvalid={touched.passengerCount && !!errors.passengerCount}
            />
            <Form.Control.Feedback type="invalid">{errors.passengerCount}</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <h5 data-cy="rideRideLabel">{t("form.ride")}</h5>
        <Form.Row>
          <Form.Group data-cy="rideTimeInput" as={Col} controlId="formGridTime">
            <Form.Label data-cy="rideTimeLabel">{t("form.time")}</Form.Label>
            <DefaultDateTimePicker
              className="form-control"
              selected={values.time}
              onChange={(date) => {
                if (!Array.isArray(date)) {
                  setFieldValue("time", date);
                }
              }}
              useLocalDateTime
              minDate={now}
              maxDate={addMinutes(now, requestRegistrationFutureTimeLimitInMinutes)}
              isInvalid={touched.time && !!errors.time}
            />
            <Form.Control.Feedback
              className={classNames({
                "d-block": touched.time && !!errors.time,
              })}
              type="invalid"
            >
              {errors.time}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridTimeType" className="mt-4 pt-3">
            <Form.Check
              data-cy="departureTimeSelector"
              inline
              label={t("form.departure")}
              name="timeTypeSelector"
              type="radio"
              id={TimeTypeSelectorFieldIds.DEPARTURE}
              checked={values.usingDepartureTime}
              onChange={handleTimeTypeSelectorChange}
            />
            <Form.Check
              data-cy="arrivalTimeSelector"
              inline
              label={t("form.arrival")}
              name="timeTypeSelector"
              type="radio"
              id={TimeTypeSelectorFieldIds.ARRIVAL}
              checked={!values.usingDepartureTime}
              onChange={handleTimeTypeSelectorChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridDepartureStop">
            <Form.Label data-cy="searchDepartureStopLabel">{t("form.departureStop")}</Form.Label>
            <InputGroup data-cy="searchDepartureStop" hasValidation>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <div className={classNames("bg-success", styles.stopInputLabel)}>A</div>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <StopSearchInput
                placeholder=""
                currentValue={getStopNameForInputValue(values.departureStopId)}
                onStopSelected={(stop: Fleet.Stop) => {
                  handleDepartureStopChange(stop.stopId);
                }}
                isInvalid={touched.departureStopId && !!errors.departureStopId}
                stops={departureStops}
                alwaysRenderSuggestions
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  "d-block": touched.departureStopId && !!errors.departureStopId,
                })}
              >
                {errors.departureStopId}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridArrivalStop">
            <Form.Label data-cy="searchArrivalStopLabel">{t("form.arrivalStop")}</Form.Label>
            <InputGroup data-cy="searchArrivalStop" hasValidation>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <div className={classNames("bg-danger", styles.stopInputLabel)}>B</div>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <StopSearchInput
                placeholder=""
                currentValue={getStopNameForInputValue(values.arrivalStopId)}
                onStopSelected={(stop: Fleet.Stop) => {
                  handleArrivalStopChange(stop.stopId);
                }}
                isInvalid={touched.arrivalStopId && !!errors.arrivalStopId}
                stops={destinationStops}
                alwaysRenderSuggestions
                disabled={isFetchingDestinationStops || !values.departureStopId}
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  "d-block": touched.arrivalStopId && !!errors.arrivalStopId,
                })}
              >
                {errors.arrivalStopId}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <div>
          <Map height={375} className={styles.map} bounds={mapBounds}>
            <MapLoadingOverlay isShown={isFetchingDestinationStops} />
            <MapMarkers
              departureStopId={values.departureStopId}
              arrivalStopId={values.arrivalStopId}
              allStops={allStops}
              departureStops={departureStops}
              destinationStops={destinationStops}
              handleDepartureStopChange={handleDepartureStopChange}
              handleArrivalStopChange={handleArrivalStopChange}
            />
          </Map>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button data-cy="submitNewRide" disabled={isSubmitting} type="submit">
          {t("general:add")}
        </Button>
      </Modal.Footer>
    </Form>
  );
}

const mapStateToProps = (state: StoreShape): StateProps => {
  return {
    requestRegistrationFutureTimeLimitInMinutes:
      globalSelectors.getRequestRegistrationFutureTimeLimitInMinutes(state),
  };
};

export const CreateForm =
  connect<StateProps, Record<string, unknown>, Record<string, unknown>, StoreShape>(mapStateToProps)(
    CreateFormView
  );
