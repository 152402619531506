import { ReactElement, useRef, useEffect } from "react";
import { Polyline as LeafletPolyline } from "leaflet";
import { Polyline } from "react-leaflet";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { StoreShape } from "shared/state";
import { setProcessingFilters } from "shared/state/actions";
import { plannerSelectors } from "shared/state/selectors";
import { MapColors, MapOpacity } from "./mapColors";

interface StateProps {
  filters: ReturnType<typeof plannerSelectors.getFilters>;
  route: number[][];
}

interface DispatchProps {
  setProcessingFilters: (filters: ReturnType<typeof plannerSelectors.getFilters>) => void;
}

interface OwnProps {
  vehicleId: string;
}

type Props = StateProps & OwnProps & DispatchProps;

function ResultsMapRouteView({ vehicleId, route, filters, setProcessingFilters }: Props): ReactElement {
  const isSelected = filters.vehicleId === vehicleId;
  const isVehicleFilterSelected = filters.vehicleId !== null && filters.vehicleId !== undefined;
  const handleVehicleSelect = (): void => {
    setProcessingFilters({ vehicleId });
  };
  const polylineRef = useRef<LeafletPolyline>(null);
  useEffect(() => {
    const style = {
      color: isSelected ? MapColors.ACTIVE : MapColors.INACTIVE,
      opacity: isVehicleFilterSelected && !isSelected ? MapOpacity.INACTIVE : MapOpacity.ACTIVE,
    };
    polylineRef.current?.setStyle(style);
    if (isSelected) {
      polylineRef.current?.bringToFront();
    }
  }, [isSelected, isVehicleFilterSelected]);
  return (
    <Polyline
      ref={polylineRef}
      eventHandlers={{
        click: handleVehicleSelect,
      }}
      color={MapColors.INACTIVE}
      opacity={MapOpacity.ACTIVE}
      positions={route as [number, number][]}
    />
  );
}

const mapStateToProps = (state: StoreShape, ownProps: OwnProps): StateProps => ({
  filters: plannerSelectors.getFilters(state),
  route: plannerSelectors.getFleetPlanVehicleRoute(state, ownProps) || { route: [] },
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setProcessingFilters: (filters: ReturnType<typeof plannerSelectors.getFilters>) =>
    dispatch(setProcessingFilters(filters)),
});

export const ResultsMapRoute = connect<StateProps, DispatchProps, OwnProps, StoreShape>(
  mapStateToProps,
  mapDispatchToProps
)(ResultsMapRouteView);
