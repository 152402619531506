import { ReactElement } from "react";
import { NavbarLeft } from "./NavbarLeft";
import { NavbarRight } from "./NavbarRight";

interface Props {
  toggleSidebar(): void;
}

export function Navbar({ toggleSidebar }: Props): ReactElement {
  return (
    <nav className="main-header navbar navbar-expand navbar-dark navbar-info d-flex justify-content-between">
      <NavbarLeft toggleSidebar={toggleSidebar} />
      <NavbarRight />
    </nav>
  );
}
