import { Common } from "../..";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RoleRestriction } from "components/common";
import { Role } from "shared/constants";
import { useAuthService } from "shared/hooks/useAuthService";

enum MissionStatus {
  Pending = "0",
  Started = "1",
  Finished = "2",
  Cancelled = "3",
}

export interface FilterState {
  startsAfter?: Date | null;
  startsBefore?: Date | null;
  id?: string;
  vehicleName?: string;
  status?: string;
  showDeleted?: boolean;
}

interface Props {
  filterState: FilterState;
  updateFilter: <StateKey extends keyof FilterState>(key: StateKey, value: FilterState[StateKey]) => void;
}

export function Filters({ filterState, updateFilter }: Props) {
  const { t } = useTranslation("missions");
  const authService = useAuthService();
  const isAdmin = authService.userHasRole(Role.ADMIN);
  const updateNativeInputFilter =
    (input: "id" | "status" | "vehicleName" | "showDeleted") => (ev: React.ChangeEvent<HTMLInputElement>) => {
      updateFilter(input, ev.currentTarget.value);
    };
  const updateDateInputFilter =
    (input: "startsAfter" | "startsBefore") => (date: Date | [Date, Date] | null) => {
      if (!Array.isArray(date)) {
        updateFilter(input, date);
      }
    };
  return (
    <Form className="px-3 pt-3 pb-0">
      <Form.Row>
        <Form.Group as={Col} xs={4} xl={2} controlId="formGridId">
          <Form.Label>{t("filters.id")}</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            value={filterState.id || ""}
            onChange={updateNativeInputFilter("id")}
          />
        </Form.Group>
        <Form.Group as={Col} xs={4} xl={2} controlId="formGridVehicleName">
          <Form.Label>{t("filters.vehicleName")}</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            value={filterState.vehicleName || ""}
            onChange={updateNativeInputFilter("vehicleName")}
          />
        </Form.Group>
        <Form.Group as={Col} xs={4} xl={2} controlId="formGridStatus">
          <Form.Label>{t("filters.status")}</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={filterState.status || ""}
            onChange={updateNativeInputFilter("status")}
          >
            <option value="" />
            {Object.entries(MissionStatus).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={6} xl={isAdmin ? 2 : 3} controlId="formGridStartsAfter">
          <Form.Label>{t("filters.startsAfter")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridStartsAfter"
            className="form-control form-control-sm"
            selected={filterState.startsAfter}
            onChange={updateDateInputFilter("startsAfter")}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} xl={isAdmin ? 2 : 3} controlId="formGridStartsBefore">
          <Form.Label>{t("filters.startsBefore")}</Form.Label>
          <Common.Input.DefaultDatePicker
            id="formGridStartsBefore"
            className="form-control form-control-sm"
            selected={filterState.startsBefore}
            onChange={updateDateInputFilter("startsBefore")}
          />
        </Form.Group>
        <RoleRestriction requiredRoles={[Role.ADMIN]}>
          <Form.Group as={Col} xs={4} md={2} controlId="formGridShowDeleted">
            <Form.Label>{t("filters.showDeleted")}</Form.Label>
            <Form.Check
              className="mr-3"
              type="switch"
              checked={filterState.showDeleted ?? false}
              onChange={() => updateFilter("showDeleted", !filterState.showDeleted)}
            />
          </Form.Group>
        </RoleRestriction>
      </Form.Row>
    </Form>
  );
}
