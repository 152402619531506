import { useTranslation } from "react-i18next";
import styles from "../Rides.module.css";

export function ErrorNotification({ message }: { message: string | null }) {
  const { t } = useTranslation("rides");
  return (
    <div className={styles.errorNotification}>
      <h5>
        <strong>{t("newRide.rideNotFound")}</strong>
      </h5>
      <h6>
        <strong>{t("general:map.error")}:</strong> {message}
      </h6>
    </div>
  );
}
