import React from "react";
import { Marker, Tooltip } from "react-leaflet";
import classNames from "classnames";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";

import { Fleet } from "shared/types";
import { locationToLatLng } from "shared/mappers";
import styles from "./MissionDashboard.module.css";

interface Props {
  plan: Fleet.Segment[];
  isStopHighlighted: (stop: Fleet.Segment) => boolean;
  handleStopHighlight: (stop?: Fleet.Segment) => () => void;
}

export function StopMarkers({ plan, handleStopHighlight, isStopHighlighted }: Props): React.ReactElement {
  const getStopId = (segment: Fleet.Segment) =>
    `${segment.stop.city}${segment.stop.street}${segment.stop.location.lat}${segment.stop.location.lng}`;
  const uniqueStops: { [key: string]: Fleet.Segment } = {};
  for (const stop of plan) {
    uniqueStops[getStopId(stop)] = stop;
  }
  return (
    <>
      {Object.entries(uniqueStops).map(([stopId, segment]) => (
        <Marker
          key={stopId}
          icon={divIcon({
            html: renderToStaticMarkup(
              <div>
                <i
                  className={classNames(
                    "fas fa-map-marker fa-3x text-primary",
                    styles.stopMarker,
                    segment.segmentStatus.code === Fleet.SegmentStatuses.Cancelled && styles.cancelled,
                    isStopHighlighted(segment) && styles.highlighted
                  )}
                />
              </div>
            ),
            iconSize: [24, 32],
            iconAnchor: [12, 30],
            tooltipAnchor: [0, -29],
          })}
          position={locationToLatLng(segment.stop.location)}
          eventHandlers={{
            mouseover: handleStopHighlight(segment),
            mouseout: handleStopHighlight(),
          }}
        >
          <Tooltip direction="top">
            {segment.stop.city} - {segment.stop.street}
          </Tooltip>
        </Marker>
      ))}
    </>
  );
}
