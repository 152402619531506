import { ReactElement, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { StoreShape } from "shared/state";
import { setProcessingFilters } from "shared/state/actions";
import { plannerSelectors } from "shared/state/selectors";
import { ResultsMapMarkerWithCounter } from "./ResultsMapMarkerWithCounter";
import styles from "./ResultsMapMarker.module.css";
import { MapOpacity } from "../ResultsMap/mapColors";

interface StateProps {
  stop?: ReturnType<typeof plannerSelectors.getFleetPlanStop>;
  selectedVehicle?: ReturnType<typeof plannerSelectors.getFilteredVehicle>;
  filters: ReturnType<typeof plannerSelectors.getFilters>;
}

interface DispatchProps {
  setProcessingFilters: (filters: ReturnType<typeof plannerSelectors.getFilters>) => void;
}

interface OwnProps {
  stopId: string;
}

type Props = StateProps & OwnProps & DispatchProps;

function ResultsMapMarkerView({
  stopId,
  stop,
  selectedVehicle,
  setProcessingFilters,
  filters,
}: Props): ReactElement | null {
  const markerEventHandlers = useMemo(
    () => ({
      click: (): void => {
        setProcessingFilters({ stopId });
      },
    }),
    [setProcessingFilters, stopId]
  );

  if (!stop) {
    return null;
  }

  const onVehicleSelect = (vehicleId: string): void => {
    setProcessingFilters({ vehicleId });
  };

  const isAnyVehicleSelected = !!filters.vehicleId;
  const isActive = (vehiclesIds: string[]): boolean => {
    return vehiclesIds.some((vehicleId) => vehicleId === filters.vehicleId);
  };
  const getOpacity = (isMarkerActive: boolean): number =>
    isAnyVehicleSelected && !isMarkerActive ? MapOpacity.INACTIVE : MapOpacity.ACTIVE;

  const tooltip = <Tooltip direction="left">{stop.name}</Tooltip>;

  if (stop.startingVehicleIds.length) {
    const active = isActive(stop.startingVehicleIds);
    return (
      <ResultsMapMarkerWithCounter
        active={active}
        opacity={getOpacity(active)}
        vehicles={stop.startingVehicleIds}
        position={stop.latLng as [number, number]}
        onVehicleSelect={onVehicleSelect}
        type="start"
      >
        {tooltip}
      </ResultsMapMarkerWithCounter>
    );
  }

  if (stop.finishingVehicleIds.length) {
    const active = isActive(stop.finishingVehicleIds);
    return (
      <ResultsMapMarkerWithCounter
        active={active}
        opacity={getOpacity(active)}
        vehicles={stop.finishingVehicleIds}
        position={stop.latLng as [number, number]}
        onVehicleSelect={onVehicleSelect}
        type="finish"
      >
        {tooltip}
      </ResultsMapMarkerWithCounter>
    );
  }

  const active = selectedVehicle ? selectedVehicle.plan.some((v) => v.stopId === stop.id) : false;
  return (
    <Marker
      icon={divIcon({
        html: renderToStaticMarkup(<div className={styles.regularStop} />),
        iconSize: [13, 13],
      })}
      position={stop.latLng as [number, number]}
      eventHandlers={markerEventHandlers}
      opacity={getOpacity(active)}
    >
      {tooltip}
    </Marker>
  );
}

const mapStateToProps = (state: StoreShape, ownProps: OwnProps): StateProps => ({
  stop: plannerSelectors.getFleetPlanStop(state, ownProps),
  filters: plannerSelectors.getFilters(state),
  selectedVehicle: plannerSelectors.getFilteredVehicle(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setProcessingFilters: (filters: ReturnType<typeof plannerSelectors.getFilters>) =>
    dispatch(setProcessingFilters(filters)),
});

export const ResultsMapMarker = connect<StateProps, DispatchProps, OwnProps, StoreShape>(
  mapStateToProps,
  mapDispatchToProps
)(ResultsMapMarkerView);
