import { StopLocationMarker, StopLocationMarkerVariants } from "components/common";
import React from "react";
import { Fleet } from "shared/types";

interface Props {
  departureStopId?: string;
  arrivalStopId?: string;
  allStops: Fleet.Stop[];
  destinationStops: Fleet.Stop[];
  departureStops: Fleet.Stop[];
  handleDepartureStopChange: (stopId?: string) => void;
  handleArrivalStopChange: (stopId?: string) => void;
}

function MapMarkersComponent({
  departureStopId,
  allStops,
  departureStops,
  handleDepartureStopChange,
  destinationStops,
  arrivalStopId,
  handleArrivalStopChange,
}: Props) {
  const tooltipContent = (stop: Fleet.Stop) => `${stop.name}${stop.identifier ? ` ${stop.identifier}` : ""}`;
  if (departureStopId) {
    const departureStop = allStops.find((s) => s.stopId === departureStopId);
    return (
      <>
        {departureStop && (
          <StopLocationMarker
            location={departureStop.location}
            variant={StopLocationMarkerVariants.DEPARTURE}
            onClick={() => {
              handleDepartureStopChange(undefined);
            }}
            tooltipContent={tooltipContent(departureStop)}
          >
            A
          </StopLocationMarker>
        )}
        {destinationStops.map((stop) => {
          const isArrival = arrivalStopId === stop.stopId;
          return (
            <StopLocationMarker
              key={stop.stopId}
              location={stop.location}
              variant={isArrival ? StopLocationMarkerVariants.ARRIVAL : StopLocationMarkerVariants.REGULAR}
              onClick={() => {
                handleArrivalStopChange(isArrival ? undefined : stop.stopId);
              }}
              tooltipContent={tooltipContent(stop)}
            >
              {isArrival && "B"}
            </StopLocationMarker>
          );
        })}
      </>
    );
  }
  return (
    <>
      {departureStops.map((stop) => (
        <StopLocationMarker
          key={stop.stopId}
          location={stop.location}
          variant={StopLocationMarkerVariants.REGULAR}
          onClick={() => {
            handleDepartureStopChange(stop.stopId);
          }}
          tooltipContent={tooltipContent(stop)}
        />
      ))}
    </>
  );
}

export const MapMarkers = React.memo(MapMarkersComponent);
