import { isSameDay } from "date-fns";
import { ComponentPropsWithRef, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { diff } from "deep-diff";
import { TimestampTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { TableRowCell } from "./TableRowCell";
import styles from "./DriverRides.module.css";

const ADD_REMOVE_ANIMATION_TIMEOUT = 2000;
const UPDATE_ANIMATION_TIMEOUT = 2000;

interface Props extends ComponentPropsWithRef<typeof CSSTransition> {
  segment: Fleet.VehicleDriverSegment;
  previousSegment?: Fleet.VehicleDriverSegment;
}

type AnimatingState = {
  [Property in keyof Omit<Fleet.VehicleDriverSegment, "id">]: boolean;
} & {
  plannedServiceDate: boolean;
  anyAnimationsRunning: boolean;
};

const noAnimations: AnimatingState = {
  plannedServiceTime: false,
  plannedServiceDate: false,
  stopIdentifier: false,
  stopName: false,
  passengerPickups: false,
  passengerDropoffs: false,
  anyAnimationsRunning: false,
};

function getAnimatingState(
  prevSegment: Fleet.VehicleDriverSegment | undefined,
  currSegment: Fleet.VehicleDriverSegment
): AnimatingState {
  if (!prevSegment) {
    return noAnimations;
  }
  const diffResult = diff(prevSegment, currSegment);
  if (!diffResult) {
    return noAnimations;
  }
  const result = { ...noAnimations, anyAnimationsRunning: true };
  diffResult.forEach((ch) => {
    const path = ch.path?.[0] as keyof AnimatingState | undefined;
    if (path) {
      if (path === "plannedServiceTime") {
        const prevDate = TimestampTransforms.parseISODate(prevSegment.plannedServiceTime);
        const currDate = TimestampTransforms.parseISODate(currSegment.plannedServiceTime);
        result.plannedServiceTime = true;
        result.plannedServiceDate = !isSameDay(prevDate, currDate);
      } else {
        result[path] = true;
      }
    }
  });
  return result;
}
const formatStopName = (s: Fleet.VehicleDriverSegment) =>
  s.stopIdentifier ? `${s.stopName} ${s.stopIdentifier}` : s.stopName;

export function TableRow({ segment, previousSegment, ...rest }: Props) {
  const nodeRef = useRef(null);
  const [animatingState, setAnimatingState] = useState(noAnimations);
  useEffect(() => {
    const animatingState = getAnimatingState(previousSegment, segment);
    if (animatingState.anyAnimationsRunning) {
      setAnimatingState(animatingState);
      const interval = setInterval(() => {
        setAnimatingState(noAnimations);
      }, UPDATE_ANIMATION_TIMEOUT);
      return () => {
        clearInterval(interval);
      };
    }
  }, [segment, previousSegment]);
  return (
    <CSSTransition
      {...rest}
      nodeRef={nodeRef}
      timeout={ADD_REMOVE_ANIMATION_TIMEOUT}
      appear={false}
      classNames={{
        enter: styles.itemEnter,
        enterActive: styles.itemEnterActive,
        exit: styles.itemExit,
        exitActive: styles.itemExitActive,
      }}
    >
      <tr ref={nodeRef}>
        <TableRowCell hasChanged={animatingState.plannedServiceDate}>
          {TimestampTransforms.toShortDate(segment.plannedServiceTime)}
        </TableRowCell>
        <TableRowCell hasChanged={animatingState.plannedServiceTime}>
          {TimestampTransforms.toShortTime(segment.plannedServiceTime)}
        </TableRowCell>
        <TableRowCell hasChanged={animatingState.stopIdentifier || animatingState.stopName}>
          {formatStopName(segment)}
        </TableRowCell>
        <TableRowCell hasChanged={animatingState.passengerPickups}>{segment.passengerPickups}</TableRowCell>
        <TableRowCell hasChanged={animatingState.passengerDropoffs}>{segment.passengerDropoffs}</TableRowCell>
      </tr>
    </CSSTransition>
  );
}
