import { store } from "./state";
import { showNotification } from "./state/actions";

export const nameof = <T>(name: Extract<keyof T, string>): string => name;
export const apiCallErrorHandler = <A extends Array<unknown>>(
  action: (...a: A) => Promise<void>,
  finaliser?: () => void
): ((...args: A) => Promise<void>) => {
  return async function (...args: A) {
    try {
      await action(...args);
    } catch (ex) {
      store.dispatch(
        showNotification({
          message: "Api error",
          color: "danger",
          disableAutoHide: true,
        })
      );
    } finally {
      finaliser?.();
    }
  };
};
export const isEmpty = (value: number | undefined | null): value is undefined | null =>
  typeof value === "undefined" || value === null;
