import { CellProps } from "react-table";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { addMilliseconds } from "date-fns";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DateTransforms, TimeSpanTransforms, TimeTransforms } from "shared/mappers";
import { Fleet } from "shared/types";
import { WeekdaysInput } from "../Form/WeekdaysInput";
import styles from "../VehicleAvailability.module.css";

const getEmptyDate = () => new Date(0, 0, 0, 0, 0, 0);

export function PatternHourlyDisplay(cellProps: CellProps<Fleet.VehicleAvailabilityListItem>) {
  const { t } = useTranslation("vehicle/availability");
  return (
    <div className="mt-1 px-3">
      {cellProps.row.original.pattern.map((pattern, index) => (
        <div key={index}>
          <div className="progress position-relative">
            {pattern.slots.map((slot) => {
              const timeStartInMilliseconds = TimeSpanTransforms.extractTotalMilliseconds(slot.timeStart);
              const durationInMilliseconds = TimeSpanTransforms.extractTotalMilliseconds(slot.duration);
              const startTime = addMilliseconds(getEmptyDate(), timeStartInMilliseconds);
              const endTime = addMilliseconds(startTime, durationInMilliseconds);
              const duration = addMilliseconds(getEmptyDate(), durationInMilliseconds);
              const timespanLeftMargin =
                TimeTransforms.getDayPercentageFromMilliseconds(timeStartInMilliseconds);
              let timespanWidth = TimeTransforms.getDayPercentageFromMilliseconds(durationInMilliseconds);
              if (timespanLeftMargin + timespanWidth > 100) {
                timespanWidth = 100 - timespanLeftMargin;
              }
              return (
                <OverlayTrigger
                  key={slot.timeStart}
                  placement="left"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Title as="h4">
                        {`${DateTransforms.toShortTimeFormat(startTime)} - ${DateTransforms.toShortTimeFormat(
                          endTime
                        )}`}
                      </Popover.Title>
                      <Popover.Content>
                        <p className="mb-1">
                          {t("patternDisplay.duration")}:{" "}
                          <strong>{DateTransforms.toShortTimeFormat(duration)}</strong>
                        </p>
                        <WeekdaysInput readOnly value={pattern.weekDays} />
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div
                    className={classNames("progress-bar h-100 position-absolute", styles.patternTimeSlot)}
                    style={{ left: `${timespanLeftMargin}%`, width: `${timespanWidth}%` }}
                  />
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
