import { useState } from "react";
import classNames from "classnames";
import { FieldArrayRenderProps, FormikProps } from "formik";
import { Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DefaultDatePicker } from "components/common/Input";
import { DateTransforms } from "shared/mappers";
import { FormValues } from "./schema";

const createDateId = (date: Date, index: number) => `${date.toString()}.${index}`;

export function DatesInput({ form, push, remove }: FieldArrayRenderProps) {
  const formProp = form as FormikProps<FormValues>;
  const { t } = useTranslation("vehicle/availability");
  const [newDate, setNewDate] = useState<Date | null>(null);
  return (
    <div className="w-100">
      <Table striped bordered size="sm" className="mb-2">
        <thead>
          <tr>
            <th colSpan={2}>{t("form.datesInput.header")}</th>
          </tr>
        </thead>
        <tbody>
          {formProp.values.excludedDays.length <= 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                {t("form.datesInput.noData")}
              </td>
            </tr>
          )}
          {formProp.values.excludedDays.map((excludedDay, index) => (
            <tr key={createDateId(excludedDay, index)}>
              <td className="border-right-0">{DateTransforms.toInputFormat(excludedDay)}</td>
              <td className="text-right border-left-0 pr-2">
                <Button
                  variant="outline-danger"
                  size={"xs" as "sm"}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <i className="mr-1 fas fa-minus" /> {t("general:delete")}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Form.Control.Feedback
        type="invalid"
        className={classNames("mb-1", { "d-block": !!formProp.errors.excludedDays })}
      >
        {formProp.errors.excludedDays}
      </Form.Control.Feedback>
      <div className="d-flex justify-content-between">
        <DefaultDatePicker
          isClearable
          className="form-control form-control-sm"
          selected={newDate}
          onChange={(date) => {
            if (!Array.isArray(date)) {
              setNewDate(date);
            }
          }}
        />
        <Button
          className="ml-1"
          variant="outline-success"
          size="sm"
          onClick={() => {
            if (newDate) {
              push(newDate);
              setNewDate(null);
            }
          }}
        >
          <i className="mr-1 fas fa-plus" /> {t("general:add")}
        </Button>
      </div>
    </div>
  );
}
